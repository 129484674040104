.error404{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.error404_top{
    display: flex;
}

.error404_big_title{
    font-size: 130px;
    font-weight: 100;
    margin-right: 40px;
}

.error404_top_content{
    margin-top: 20px;
}

.error404_title{
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 20px;
}

.error404_description{}


.error404_subcontent{
    display: block;
}

.error404_subcontent p{
    text-align: center;
}

.error404_buttons{
    margin-top: 30px;
    text-align: center;
}

.error404_subcontent .error404_buttons a{
    padding: 10px 30px;
    color: #fff;
    background: #3377ff;
    border-radius: 50px;
    margin: 2px;
}

@media screen and (max-width: 769px){
    .error404_top{
        display: block;
    }

    .error404_buttons a{
        display: block !important;
    }
}