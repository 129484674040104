i.la, i.header-arrow {
  font-style: normal; }

.la, .header-arrow,
.lar,
.las,
.lab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto; }

@font-face {
  font-family: Line Awesome Brands;
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/line-awesome/la-brands-400.eot");
  src: url("../fonts/line-awesome/la-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/line-awesome/la-brands-400.woff2") format("woff2"), url("../fonts/line-awesome/la-brands-400.woff") format("woff"), url("../fonts/line-awesome/la-brands-400.ttf") format("truetype"), url("../fonts/line-awesome/la-brands-400.svg#lineawesome") format("svg"); }

.lab {
  font-family: Line Awesome Brands;
  font-weight: 400; }

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/line-awesome/la-regular-400.eot");
  src: url("../fonts/line-awesome/la-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/line-awesome/la-regular-400.woff2") format("woff2"), url("../fonts/line-awesome/la-regular-400.woff") format("woff"), url("../fonts/line-awesome/la-regular-400.ttf") format("truetype"), url("../fonts/line-awesome/la-regular-400.svg#lineawesome") format("svg"); }

.la, .header-arrow {
  font-family: Line Awesome Free;
  font-weight: 400; }

@font-face {
  font-family: Line Awesome Free;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/line-awesome/la-solid-900.eot");
  src: url("../fonts/line-awesome/la-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/line-awesome/la-solid-900.woff2") format("woff2"), url("../fonts/line-awesome/la-solid-900.woff") format("woff"), url("../fonts/line-awesome/la-solid-900.ttf") format("truetype"), url("../fonts/line-awesome/la-solid-900.svg#lineawesome") format("svg"); }

.la, .header-arrow {
  font-family: Line Awesome Free;
  font-weight: 900; }

.la.la-lg, .la-lg.header-arrow {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em; }

.la-xs {
  font-size: 0.75em; }

.la-2x {
  font-size: 1em; }

.la-2x {
  font-size: 2em; }

.la-3x {
  font-size: 3em; }

.la-4x {
  font-size: 4em; }

.la-5x {
  font-size: 5em; }

.la-6x {
  font-size: 6em; }

.la-7x {
  font-size: 7em; }

.la-8x {
  font-size: 8em; }

.la-9x {
  font-size: 9em; }

.la-10x {
  font-size: 10em; }

.la-fw {
  text-align: center;
  width: 1.25em; }

.la-fw {
  width: 1.25em;
  text-align: center; }

.la-ul {
  padding-left: 0;
  margin-left: calc(20em / 14);
  list-style-type: none; }
  .la-ul > li {
    position: relative; }

.la-li {
  position: absolute;
  left: -2em;
  text-align: center;
  width: calc(20em / 14);
  line-height: inherit; }
  .la-li.la-lg {
    left: calc(20em / 14);
  }

.la-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em; }

.la-pull-left {
  float: left; }

.la-pull-right {
  float: right; }

.la.la-pull-left, .la-pull-left.header-arrow {
  margin-right: .3em; }

.la.la-pull-right, .la-pull-right.header-arrow {
  margin-left: .3em; }

.la.pull-left, .pull-left.header-arrow {
  margin-right: .3em; }

.la.pull-right, .pull-right.header-arrow {
  margin-left: .3em; }

.la-pull-left {
  float: left; }

.la-pull-right {
  float: right; }

.la.la-pull-left, .la-pull-left.header-arrow,
.las.la-pull-left,
.lar.la-pull-left,
.lal.la-pull-left,
.lab.la-pull-left {
  margin-right: .3em; }

.la.la-pull-right, .la-pull-right.header-arrow,
.las.la-pull-right,
.lar.la-pull-right,
.lal.la-pull-right,
.lab.la-pull-right {
  margin-left: .3em; }

.la-spin {
  -webkit-animation: la-spin 2s infinite linear;
  animation: la-spin 2s infinite linear; }

.la-pulse {
  -webkit-animation: la-spin 1s infinite steps(8);
  animation: la-spin 1s infinite steps(8); }

@-webkit-keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes la-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.la-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg); }

.la-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

.la-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg); }

.la-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1); }

.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1); }

.la-flip-both, .la-flip-horizontal.la-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1); }

:root .la-rotate-90,
:root .la-rotate-180,
:root .la-rotate-270,
:root .la-flip-horizontal,
:root .la-flip-vertical,
:root .la-flip-both {
  -webkit-filter: none;
  filter: none; }

.la-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em; }

.la-stack-1x,
.la-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%; }

.la-stack-1x {
  line-height: inherit; }

.la-stack-2x {
  font-size: 2em; }

.la-inverse {
  color: #fff; }

.la-500px:before {
  content: "\f26e"; }

.la-accessible-icon:before {
  content: "\f368"; }

.la-accusoft:before {
  content: "\f369"; }

.la-acquisitions-incorporated:before {
  content: "\f6af"; }

.la-ad:before {
  content: "\f641"; }

.la-address-book:before {
  content: "\f2b9"; }

.la-address-card:before {
  content: "\f2bb"; }

.la-adjust:before {
  content: "\f042"; }

.la-adn:before {
  content: "\f170"; }

.la-adobe:before {
  content: "\f778"; }

.la-adversal:before {
  content: "\f36a"; }

.la-affiliatetheme:before {
  content: "\f36b"; }

.la-air-freshener:before {
  content: "\f5d0"; }

.la-airbnb:before {
  content: "\f834"; }

.la-algolia:before {
  content: "\f36c"; }

.la-align-center:before {
  content: "\f037"; }

.la-align-justify:before {
  content: "\f039"; }

.la-align-left:before {
  content: "\f036"; }

.la-align-right:before {
  content: "\f038"; }

.la-alipay:before {
  content: "\f642"; }

.la-allergies:before {
  content: "\f461"; }

.la-amazon:before {
  content: "\f270"; }

.la-amazon-pay:before {
  content: "\f42c"; }

.la-ambulance:before {
  content: "\f0f9"; }

.la-american-sign-language-interpreting:before {
  content: "\f2a3"; }

.la-amilia:before {
  content: "\f36d"; }

.la-anchor:before {
  content: "\f13d"; }

.la-android:before {
  content: "\f17b"; }

.la-angellist:before {
  content: "\f209"; }

.la-angle-double-down:before {
  content: "\f103"; }

.la-angle-double-left:before {
  content: "\f100"; }

.la-angle-double-right:before {
  content: "\f101"; }

.la-angle-double-up:before {
  content: "\f102"; }

.la-angle-down:before, .header-arrow:before {
  content: "\f107"; }

.la-angle-left:before {
  content: "\f104"; }

.la-angle-right:before, .header-arrow.expanded:before {
  content: "\f105"; }

.la-angle-up:before {
  content: "\f106"; }

.la-angry:before {
  content: "\f556"; }

.la-angrycreative:before {
  content: "\f36e"; }

.la-angular:before {
  content: "\f420"; }

.la-ankh:before {
  content: "\f644"; }

.la-app-store:before {
  content: "\f36f"; }

.la-app-store-ios:before {
  content: "\f370"; }

.la-apper:before {
  content: "\f371"; }

.la-apple:before {
  content: "\f179"; }

.la-apple-alt:before {
  content: "\f5d1"; }

.la-apple-pay:before {
  content: "\f415"; }

.la-archive:before {
  content: "\f187"; }

.la-archway:before {
  content: "\f557"; }

.la-arrow-alt-circle-down:before {
  content: "\f358"; }

.la-arrow-alt-circle-left:before {
  content: "\f359"; }

.la-arrow-alt-circle-right:before {
  content: "\f35a"; }

.la-arrow-alt-circle-up:before {
  content: "\f35b"; }

.la-arrow-circle-down:before {
  content: "\f0ab"; }

.la-arrow-circle-left:before {
  content: "\f0a8"; }

.la-arrow-circle-right:before {
  content: "\f0a9"; }

.la-arrow-circle-up:before {
  content: "\f0aa"; }

.la-arrow-down:before {
  content: "\f063"; }

.la-arrow-left:before {
  content: "\f060"; }

.la-arrow-right:before {
  content: "\f061"; }

.la-arrow-up:before {
  content: "\f062"; }

.la-arrows-alt:before {
  content: "\f0b2"; }

.la-arrows-alt-h:before {
  content: "\f337"; }

.la-arrows-alt-v:before {
  content: "\f338"; }

.la-artstation:before {
  content: "\f77a"; }

.la-assistive-listening-systems:before {
  content: "\f2a2"; }

.la-asterisk:before {
  content: "\f069"; }

.la-asymmetrik:before {
  content: "\f372"; }

.la-at:before {
  content: "\f1fa"; }

.la-atlas:before {
  content: "\f558"; }

.la-atlassian:before {
  content: "\f77b"; }

.la-atom:before {
  content: "\f5d2"; }

.la-audible:before {
  content: "\f373"; }

.la-audio-description:before {
  content: "\f29e"; }

.la-autoprefixer:before {
  content: "\f41c"; }

.la-avianex:before {
  content: "\f374"; }

.la-aviato:before {
  content: "\f421"; }

.la-award:before {
  content: "\f559"; }

.la-aws:before {
  content: "\f375"; }

.la-baby:before {
  content: "\f77c"; }

.la-baby-carriage:before {
  content: "\f77d"; }

.la-backspace:before {
  content: "\f55a"; }

.la-backward:before {
  content: "\f04a"; }

.la-bacon:before {
  content: "\f7e5"; }

.la-balance-scale:before {
  content: "\f24e"; }

.la-balance-scale-left:before {
  content: "\f515"; }

.la-balance-scale-right:before {
  content: "\f516"; }

.la-ban:before {
  content: "\f05e"; }

.la-band-aid:before {
  content: "\f462"; }

.la-bandcamp:before {
  content: "\f2d5"; }

.la-barcode:before {
  content: "\f02a"; }

.la-bars:before {
  content: "\f0c9"; }

.la-baseball-ball:before {
  content: "\f433"; }

.la-basketball-ball:before {
  content: "\f434"; }

.la-bath:before {
  content: "\f2cd"; }

.la-battery-empty:before {
  content: "\f244"; }

.la-battery-full:before {
  content: "\f240"; }

.la-battery-half:before {
  content: "\f242"; }

.la-battery-quarter:before {
  content: "\f243"; }

.la-battery-three-quarters:before {
  content: "\f241"; }

.la-battle-net:before {
  content: "\f835"; }

.la-bed:before {
  content: "\f236"; }

.la-beer:before {
  content: "\f0fc"; }

.la-behance:before {
  content: "\f1b4"; }

.la-behance-square:before {
  content: "\f1b5"; }

.la-bell:before {
  content: "\f0f3"; }

.la-bell-slash:before {
  content: "\f1f6"; }

.la-bezier-curve:before {
  content: "\f55b"; }

.la-bible:before {
  content: "\f647"; }

.la-bicycle:before {
  content: "\f206"; }

.la-biking:before {
  content: "\f84a"; }

.la-bimobject:before {
  content: "\f378"; }

.la-binoculars:before {
  content: "\f1e5"; }

.la-biohazard:before {
  content: "\f780"; }

.la-birthday-cake:before {
  content: "\f1fd"; }

.la-bitbucket:before {
  content: "\f171"; }

.la-bitcoin:before {
  content: "\f379"; }

.la-bity:before {
  content: "\f37a"; }

.la-black-tie:before {
  content: "\f27e"; }

.la-blackberry:before {
  content: "\f37b"; }

.la-blender:before {
  content: "\f517"; }

.la-blender-phone:before {
  content: "\f6b6"; }

.la-blind:before {
  content: "\f29d"; }

.la-blog:before {
  content: "\f781"; }

.la-blogger:before {
  content: "\f37c"; }

.la-blogger-b:before {
  content: "\f37d"; }

.la-bluetooth:before {
  content: "\f293"; }

.la-bluetooth-b:before {
  content: "\f294"; }

.la-bold:before {
  content: "\f032"; }

.la-bolt:before {
  content: "\f0e7"; }

.la-bomb:before {
  content: "\f1e2"; }

.la-bone:before {
  content: "\f5d7"; }

.la-bong:before {
  content: "\f55c"; }

.la-book:before {
  content: "\f02d"; }

.la-book-dead:before {
  content: "\f6b7"; }

.la-book-medical:before {
  content: "\f7e6"; }

.la-book-open:before {
  content: "\f518"; }

.la-book-reader:before {
  content: "\f5da"; }

.la-bookmark:before {
  content: "\f02e"; }

.la-bootstrap:before {
  content: "\f836"; }

.la-border-all:before {
  content: "\f84c"; }

.la-border-none:before {
  content: "\f850"; }

.la-border-style:before {
  content: "\f853"; }

.la-bowling-ball:before {
  content: "\f436"; }

.la-box:before {
  content: "\f466"; }

.la-box-open:before {
  content: "\f49e"; }

.la-boxes:before {
  content: "\f468"; }

.la-braille:before {
  content: "\f2a1"; }

.la-brain:before {
  content: "\f5dc"; }

.la-bread-slice:before {
  content: "\f7ec"; }

.la-briefcase:before {
  content: "\f0b1"; }

.la-briefcase-medical:before {
  content: "\f469"; }

.la-broadcast-tower:before {
  content: "\f519"; }

.la-broom:before {
  content: "\f51a"; }

.la-brush:before {
  content: "\f55d"; }

.la-btc:before {
  content: "\f15a"; }

.la-buffer:before {
  content: "\f837"; }

.la-bug:before {
  content: "\f188"; }

.la-building:before {
  content: "\f1ad"; }

.la-bullhorn:before {
  content: "\f0a1"; }

.la-bullseye:before {
  content: "\f140"; }

.la-burn:before {
  content: "\f46a"; }

.la-buromobelexperte:before {
  content: "\f37f"; }

.la-bus:before {
  content: "\f207"; }

.la-bus-alt:before {
  content: "\f55e"; }

.la-business-time:before {
  content: "\f64a"; }

.la-buysellads:before {
  content: "\f20d"; }

.la-calculator:before {
  content: "\f1ec"; }

.la-calendar:before {
  content: "\f133"; }

.la-calendar-alt:before {
  content: "\f073"; }

.la-calendar-check:before {
  content: "\f274"; }

.la-calendar-day:before {
  content: "\f783"; }

.la-calendar-minus:before {
  content: "\f272"; }

.la-calendar-plus:before {
  content: "\f271"; }

.la-calendar-times:before {
  content: "\f273"; }

.la-calendar-week:before {
  content: "\f784"; }

.la-camera:before {
  content: "\f030"; }

.la-camera-retro:before {
  content: "\f083"; }

.la-campground:before {
  content: "\f6bb"; }

.la-canadian-maple-leaf:before {
  content: "\f785"; }

.la-candy-cane:before {
  content: "\f786"; }

.la-cannabis:before {
  content: "\f55f"; }

.la-capsules:before {
  content: "\f46b"; }

.la-car:before {
  content: "\f1b9"; }

.la-car-alt:before {
  content: "\f5de"; }

.la-car-battery:before {
  content: "\f5df"; }

.la-car-crash:before {
  content: "\f5e1"; }

.la-car-side:before {
  content: "\f5e4"; }

.la-caret-down:before {
  content: "\f0d7"; }

.la-caret-left:before {
  content: "\f0d9"; }

.la-caret-right:before {
  content: "\f0da"; }

.la-caret-square-down:before {
  content: "\f150"; }

.la-caret-square-left:before {
  content: "\f191"; }

.la-caret-square-right:before {
  content: "\f152"; }

.la-caret-square-up:before {
  content: "\f151"; }

.la-caret-up:before {
  content: "\f0d8"; }

.la-carrot:before {
  content: "\f787"; }

.la-cart-arrow-down:before {
  content: "\f218"; }

.la-cart-plus:before {
  content: "\f217"; }

.la-cash-register:before {
  content: "\f788"; }

.la-cat:before {
  content: "\f6be"; }

.la-cc-amazon-pay:before {
  content: "\f42d"; }

.la-cc-amex:before {
  content: "\f1f3"; }

.la-cc-apple-pay:before {
  content: "\f416"; }

.la-cc-diners-club:before {
  content: "\f24c"; }

.la-cc-discover:before {
  content: "\f1f2"; }

.la-cc-jcb:before {
  content: "\f24b"; }

.la-cc-mastercard:before {
  content: "\f1f1"; }

.la-cc-paypal:before {
  content: "\f1f4"; }

.la-cc-stripe:before {
  content: "\f1f5"; }

.la-cc-visa:before {
  content: "\f1f0"; }

.la-centercode:before {
  content: "\f380"; }

.la-centos:before {
  content: "\f789"; }

.la-certificate:before {
  content: "\f0a3"; }

.la-chair:before {
  content: "\f6c0"; }

.la-chalkboard:before {
  content: "\f51b"; }

.la-chalkboard-teacher:before {
  content: "\f51c"; }

.la-charging-station:before {
  content: "\f5e7"; }

.la-chart-area:before {
  content: "\f1fe"; }

.la-chart-bar:before {
  content: "\f080"; }

.la-chart-line:before {
  content: "\f201"; }

.la-chart-pie:before {
  content: "\f200"; }

.la-check:before {
  content: "\f00c"; }

.la-check-circle:before {
  content: "\f058"; }

.la-check-double:before {
  content: "\f560"; }

.la-check-square:before {
  content: "\f14a"; }

.la-cheese:before {
  content: "\f7ef"; }

.la-chess:before {
  content: "\f439"; }

.la-chess-bishop:before {
  content: "\f43a"; }

.la-chess-board:before {
  content: "\f43c"; }

.la-chess-king:before {
  content: "\f43f"; }

.la-chess-knight:before {
  content: "\f441"; }

.la-chess-pawn:before {
  content: "\f443"; }

.la-chess-queen:before {
  content: "\f445"; }

.la-chess-rook:before {
  content: "\f447"; }

.la-chevron-circle-down:before {
  content: "\f13a"; }

.la-chevron-circle-left:before {
  content: "\f137"; }

.la-chevron-circle-right:before {
  content: "\f138"; }

.la-chevron-circle-up:before {
  content: "\f139"; }

.la-chevron-down:before {
  content: "\f078"; }

.la-chevron-left:before {
  content: "\f053"; }

.la-chevron-right:before {
  content: "\f054"; }

.la-chevron-up:before {
  content: "\f077"; }

.la-child:before {
  content: "\f1ae"; }

.la-chrome:before {
  content: "\f268"; }

.la-chromecast:before {
  content: "\f838"; }

.la-church:before {
  content: "\f51d"; }

.la-circle:before {
  content: "\f111"; }

.la-circle-notch:before {
  content: "\f1ce"; }

.la-city:before {
  content: "\f64f"; }

.la-clinic-medical:before {
  content: "\f7f2"; }

.la-clipboard:before {
  content: "\f328"; }

.la-clipboard-check:before {
  content: "\f46c"; }

.la-clipboard-list:before {
  content: "\f46d"; }

.la-clock:before {
  content: "\f017"; }

.la-clone:before {
  content: "\f24d"; }

.la-closed-captioning:before {
  content: "\f20a"; }

.la-cloud:before {
  content: "\f0c2"; }

.la-cloud-download-alt:before {
  content: "\f381"; }

.la-cloud-meatball:before {
  content: "\f73b"; }

.la-cloud-moon:before {
  content: "\f6c3"; }

.la-cloud-moon-rain:before {
  content: "\f73c"; }

.la-cloud-rain:before {
  content: "\f73d"; }

.la-cloud-showers-heavy:before {
  content: "\f740"; }

.la-cloud-sun:before {
  content: "\f6c4"; }

.la-cloud-sun-rain:before {
  content: "\f743"; }

.la-cloud-upload-alt:before {
  content: "\f382"; }

.la-cloudscale:before {
  content: "\f383"; }

.la-cloudsmith:before {
  content: "\f384"; }

.la-cloudversify:before {
  content: "\f385"; }

.la-cocktail:before {
  content: "\f561"; }

.la-code:before {
  content: "\f121"; }

.la-code-branch:before {
  content: "\f126"; }

.la-codepen:before {
  content: "\f1cb"; }

.la-codiepie:before {
  content: "\f284"; }

.la-coffee:before {
  content: "\f0f4"; }

.la-cog:before {
  content: "\f013"; }

.la-cogs:before {
  content: "\f085"; }

.la-coins:before {
  content: "\f51e"; }

.la-columns:before {
  content: "\f0db"; }

.la-comment:before {
  content: "\f075"; }

.la-comment-alt:before {
  content: "\f27a"; }

.la-comment-dollar:before {
  content: "\f651"; }

.la-comment-dots:before {
  content: "\f4ad"; }

.la-comment-medical:before {
  content: "\f7f5"; }

.la-comment-slash:before {
  content: "\f4b3"; }

.la-comments:before {
  content: "\f086"; }

.la-comments-dollar:before {
  content: "\f653"; }

.la-compact-disc:before {
  content: "\f51f"; }

.la-compass:before {
  content: "\f14e"; }

.la-compress:before {
  content: "\f066"; }

.la-compress-arrows-alt:before {
  content: "\f78c"; }

.la-concierge-bell:before {
  content: "\f562"; }

.la-confluence:before {
  content: "\f78d"; }

.la-connectdevelop:before {
  content: "\f20e"; }

.la-contao:before {
  content: "\f26d"; }

.la-cookie:before {
  content: "\f563"; }

.la-cookie-bite:before {
  content: "\f564"; }

.la-copy:before {
  content: "\f0c5"; }

.la-copyright:before {
  content: "\f1f9"; }

.la-cotton-bureau:before {
  content: "\f89e"; }

.la-couch:before {
  content: "\f4b8"; }

.la-cpanel:before {
  content: "\f388"; }

.la-creative-commons:before {
  content: "\f25e"; }

.la-creative-commons-by:before {
  content: "\f4e7"; }

.la-creative-commons-nc:before {
  content: "\f4e8"; }

.la-creative-commons-nc-eu:before {
  content: "\f4e9"; }

.la-creative-commons-nc-jp:before {
  content: "\f4ea"; }

.la-creative-commons-nd:before {
  content: "\f4eb"; }

.la-creative-commons-pd:before {
  content: "\f4ec"; }

.la-creative-commons-pd-alt:before {
  content: "\f4ed"; }

.la-creative-commons-remix:before {
  content: "\f4ee"; }

.la-creative-commons-sa:before {
  content: "\f4ef"; }

.la-creative-commons-sampling:before {
  content: "\f4f0"; }

.la-creative-commons-sampling-plus:before {
  content: "\f4f1"; }

.la-creative-commons-share:before {
  content: "\f4f2"; }

.la-creative-commons-zero:before {
  content: "\f4f3"; }

.la-credit-card:before {
  content: "\f09d"; }

.la-critical-role:before {
  content: "\f6c9"; }

.la-crop:before {
  content: "\f125"; }

.la-crop-alt:before {
  content: "\f565"; }

.la-cross:before {
  content: "\f654"; }

.la-crosshairs:before {
  content: "\f05b"; }

.la-crow:before {
  content: "\f520"; }

.la-crown:before {
  content: "\f521"; }

.la-crutch:before {
  content: "\f7f7"; }

.la-css3:before {
  content: "\f13c"; }

.la-css3-alt:before {
  content: "\f38b"; }

.la-cube:before {
  content: "\f1b2"; }

.la-cubes:before {
  content: "\f1b3"; }

.la-cut:before {
  content: "\f0c4"; }

.la-cuttlefish:before {
  content: "\f38c"; }

.la-d-and-d:before {
  content: "\f38d"; }

.la-d-and-d-beyond:before {
  content: "\f6ca"; }

.la-dashcube:before {
  content: "\f210"; }

.la-database:before {
  content: "\f1c0"; }

.la-deaf:before {
  content: "\f2a4"; }

.la-delicious:before {
  content: "\f1a5"; }

.la-democrat:before {
  content: "\f747"; }

.la-deploydog:before {
  content: "\f38e"; }

.la-deskpro:before {
  content: "\f38f"; }

.la-desktop:before {
  content: "\f108"; }

.la-dev:before {
  content: "\f6cc"; }

.la-deviantart:before {
  content: "\f1bd"; }

.la-dharmachakra:before {
  content: "\f655"; }

.la-dhl:before {
  content: "\f790"; }

.la-diagnoses:before {
  content: "\f470"; }

.la-diaspora:before {
  content: "\f791"; }

.la-dice:before {
  content: "\f522"; }

.la-dice-d20:before {
  content: "\f6cf"; }

.la-dice-d6:before {
  content: "\f6d1"; }

.la-dice-five:before {
  content: "\f523"; }

.la-dice-four:before {
  content: "\f524"; }

.la-dice-one:before {
  content: "\f525"; }

.la-dice-six:before {
  content: "\f526"; }

.la-dice-three:before {
  content: "\f527"; }

.la-dice-two:before {
  content: "\f528"; }

.la-digg:before {
  content: "\f1a6"; }

.la-digital-ocean:before {
  content: "\f391"; }

.la-digital-tachograph:before {
  content: "\f566"; }

.la-directions:before {
  content: "\f5eb"; }

.la-discord:before {
  content: "\f392"; }

.la-discourse:before {
  content: "\f393"; }

.la-divide:before {
  content: "\f529"; }

.la-dizzy:before {
  content: "\f567"; }

.la-dna:before {
  content: "\f471"; }

.la-dochub:before {
  content: "\f394"; }

.la-docker:before {
  content: "\f395"; }

.la-dog:before {
  content: "\f6d3"; }

.la-dollar-sign:before {
  content: "\f155"; }

.la-dolly:before {
  content: "\f472"; }

.la-dolly-flatbed:before {
  content: "\f474"; }

.la-donate:before {
  content: "\f4b9"; }

.la-door-closed:before {
  content: "\f52a"; }

.la-door-open:before {
  content: "\f52b"; }

.la-dot-circle:before {
  content: "\f192"; }

.la-dove:before {
  content: "\f4ba"; }

.la-download:before {
  content: "\f019"; }

.la-draft2digital:before {
  content: "\f396"; }

.la-drafting-compass:before {
  content: "\f568"; }

.la-dragon:before {
  content: "\f6d5"; }

.la-draw-polygon:before {
  content: "\f5ee"; }

.la-dribbble:before {
  content: "\f17d"; }

.la-dribbble-square:before {
  content: "\f397"; }

.la-dropbox:before {
  content: "\f16b"; }

.la-drum:before {
  content: "\f569"; }

.la-drum-steelpan:before {
  content: "\f56a"; }

.la-drumstick-bite:before {
  content: "\f6d7"; }

.la-drupal:before {
  content: "\f1a9"; }

.la-dumbbell:before {
  content: "\f44b"; }

.la-dumpster:before {
  content: "\f793"; }

.la-dumpster-fire:before {
  content: "\f794"; }

.la-dungeon:before {
  content: "\f6d9"; }

.la-dyalog:before {
  content: "\f399"; }

.la-earlybirds:before {
  content: "\f39a"; }

.la-ebay:before {
  content: "\f4f4"; }

.la-edge:before {
  content: "\f282"; }

.la-edit:before {
  content: "\f044"; }

.la-egg:before {
  content: "\f7fb"; }

.la-eject:before {
  content: "\f052"; }

.la-elementor:before {
  content: "\f430"; }

.la-ellipsis-h:before {
  content: "\f141"; }

.la-ellipsis-v:before {
  content: "\f142"; }

.la-ello:before {
  content: "\f5f1"; }

.la-ember:before {
  content: "\f423"; }

.la-empire:before {
  content: "\f1d1"; }

.la-envelope:before {
  content: "\f0e0"; }

.la-envelope-open:before {
  content: "\f2b6"; }

.la-envelope-open-text:before {
  content: "\f658"; }

.la-envelope-square:before {
  content: "\f199"; }

.la-envira:before {
  content: "\f299"; }

.la-equals:before {
  content: "\f52c"; }

.la-eraser:before {
  content: "\f12d"; }

.la-erlang:before {
  content: "\f39d"; }

.la-ethereum:before {
  content: "\f42e"; }

.la-ethernet:before {
  content: "\f796"; }

.la-etsy:before {
  content: "\f2d7"; }

.la-euro-sign:before {
  content: "\f153"; }

.la-evernote:before {
  content: "\f839"; }

.la-exchange-alt:before {
  content: "\f362"; }

.la-exclamation:before {
  content: "\f12a"; }

.la-exclamation-circle:before {
  content: "\f06a"; }

.la-exclamation-triangle:before {
  content: "\f071"; }

.la-expand:before {
  content: "\f065"; }

.la-expand-arrows-alt:before {
  content: "\f31e"; }

.la-expeditedssl:before {
  content: "\f23e"; }

.la-external-link-alt:before {
  content: "\f35d"; }

.la-external-link-square-alt:before {
  content: "\f360"; }

.la-eye:before {
  content: "\f06e"; }

.la-eye-dropper:before {
  content: "\f1fb"; }

.la-eye-slash:before {
  content: "\f070"; }

.la-facebook:before {
  content: "\f09a"; }

.la-facebook-f:before {
  content: "\f39e"; }

.la-facebook-messenger:before {
  content: "\f39f"; }

.la-facebook-square:before {
  content: "\f082"; }

.la-fan:before {
  content: "\f863"; }

.la-fantasy-flight-games:before {
  content: "\f6dc"; }

.la-fast-backward:before {
  content: "\f049"; }

.la-fast-forward:before {
  content: "\f050"; }

.la-fax:before {
  content: "\f1ac"; }

.la-feather:before {
  content: "\f52d"; }

.la-feather-alt:before {
  content: "\f56b"; }

.la-fedex:before {
  content: "\f797"; }

.la-fedora:before {
  content: "\f798"; }

.la-female:before {
  content: "\f182"; }

.la-fighter-jet:before {
  content: "\f0fb"; }

.la-figma:before {
  content: "\f799"; }

.la-file:before {
  content: "\f15b"; }

.la-file-alt:before {
  content: "\f15c"; }

.la-file-archive:before {
  content: "\f1c6"; }

.la-file-audio:before {
  content: "\f1c7"; }

.la-file-code:before {
  content: "\f1c9"; }

.la-file-contract:before {
  content: "\f56c"; }

.la-file-csv:before {
  content: "\f6dd"; }

.la-file-download:before {
  content: "\f56d"; }

.la-file-excel:before {
  content: "\f1c3"; }

.la-file-export:before {
  content: "\f56e"; }

.la-file-image:before {
  content: "\f1c5"; }

.la-file-import:before {
  content: "\f56f"; }

.la-file-invoice:before {
  content: "\f570"; }

.la-file-invoice-dollar:before {
  content: "\f571"; }

.la-file-medical:before {
  content: "\f477"; }

.la-file-medical-alt:before {
  content: "\f478"; }

.la-file-pdf:before {
  content: "\f1c1"; }

.la-file-powerpoint:before {
  content: "\f1c4"; }

.la-file-prescription:before {
  content: "\f572"; }

.la-file-signature:before {
  content: "\f573"; }

.la-file-upload:before {
  content: "\f574"; }

.la-file-video:before {
  content: "\f1c8"; }

.la-file-word:before {
  content: "\f1c2"; }

.la-fill:before {
  content: "\f575"; }

.la-fill-drip:before {
  content: "\f576"; }

.la-film:before {
  content: "\f008"; }

.la-filter:before {
  content: "\f0b0"; }

.la-fingerprint:before {
  content: "\f577"; }

.la-fire:before {
  content: "\f06d"; }

.la-fire-alt:before {
  content: "\f7e4"; }

.la-fire-extinguisher:before {
  content: "\f134"; }

.la-firefox:before {
  content: "\f269"; }

.la-first-aid:before {
  content: "\f479"; }

.la-first-order:before {
  content: "\f2b0"; }

.la-first-order-alt:before {
  content: "\f50a"; }

.la-firstdraft:before {
  content: "\f3a1"; }

.la-fish:before {
  content: "\f578"; }

.la-fist-raised:before {
  content: "\f6de"; }

.la-flag:before {
  content: "\f024"; }

.la-flag-checkered:before {
  content: "\f11e"; }

.la-flag-usa:before {
  content: "\f74d"; }

.la-flask:before {
  content: "\f0c3"; }

.la-flickr:before {
  content: "\f16e"; }

.la-flipboard:before {
  content: "\f44d"; }

.la-flushed:before {
  content: "\f579"; }

.la-fly:before {
  content: "\f417"; }

.la-folder:before {
  content: "\f07b"; }

.la-folder-minus:before {
  content: "\f65d"; }

.la-folder-open:before {
  content: "\f07c"; }

.la-folder-plus:before {
  content: "\f65e"; }

.la-font:before {
  content: "\f031"; }

.la-font-awesome:before {
  content: "\f2b4"; }

.la-font-awesome-alt:before {
  content: "\f35c"; }

.la-font-awesome-flag:before {
  content: "\f425"; }

.la-fonticons:before {
  content: "\f280"; }

.la-fonticons-fi:before {
  content: "\f3a2"; }

.la-football-ball:before {
  content: "\f44e"; }

.la-fort-awesome:before {
  content: "\f286"; }

.la-fort-awesome-alt:before {
  content: "\f3a3"; }

.la-forumbee:before {
  content: "\f211"; }

.la-forward:before {
  content: "\f04e"; }

.la-foursquare:before {
  content: "\f180"; }

.la-free-code-camp:before {
  content: "\f2c5"; }

.la-freebsd:before {
  content: "\f3a4"; }

.la-frog:before {
  content: "\f52e"; }

.la-frown:before {
  content: "\f119"; }

.la-frown-open:before {
  content: "\f57a"; }

.la-fulcrum:before {
  content: "\f50b"; }

.la-funnel-dollar:before {
  content: "\f662"; }

.la-futbol:before {
  content: "\f1e3"; }

.la-galactic-republic:before {
  content: "\f50c"; }

.la-galactic-senate:before {
  content: "\f50d"; }

.la-gamepad:before {
  content: "\f11b"; }

.la-gas-pump:before {
  content: "\f52f"; }

.la-gavel:before {
  content: "\f0e3"; }

.la-gem:before {
  content: "\f3a5"; }

.la-genderless:before {
  content: "\f22d"; }

.la-get-pocket:before {
  content: "\f265"; }

.la-gg:before {
  content: "\f260"; }

.la-gg-circle:before {
  content: "\f261"; }

.la-ghost:before {
  content: "\f6e2"; }

.la-gift:before {
  content: "\f06b"; }

.la-gifts:before {
  content: "\f79c"; }

.la-git:before {
  content: "\f1d3"; }

.la-git-alt:before {
  content: "\f841"; }

.la-git-square:before {
  content: "\f1d2"; }

.la-github:before {
  content: "\f09b"; }

.la-github-alt:before {
  content: "\f113"; }

.la-github-square:before {
  content: "\f092"; }

.la-gitkraken:before {
  content: "\f3a6"; }

.la-gitlab:before {
  content: "\f296"; }

.la-gitter:before {
  content: "\f426"; }

.la-glass-cheers:before {
  content: "\f79f"; }

.la-glass-martini:before {
  content: "\f000"; }

.la-glass-martini-alt:before {
  content: "\f57b"; }

.la-glass-whiskey:before {
  content: "\f7a0"; }

.la-glasses:before {
  content: "\f530"; }

.la-glide:before {
  content: "\f2a5"; }

.la-glide-g:before {
  content: "\f2a6"; }

.la-globe:before {
  content: "\f0ac"; }

.la-globe-africa:before {
  content: "\f57c"; }

.la-globe-americas:before {
  content: "\f57d"; }

.la-globe-asia:before {
  content: "\f57e"; }

.la-globe-europe:before {
  content: "\f7a2"; }

.la-gofore:before {
  content: "\f3a7"; }

.la-golf-ball:before {
  content: "\f450"; }

.la-goodreads:before {
  content: "\f3a8"; }

.la-goodreads-g:before {
  content: "\f3a9"; }

.la-google:before {
  content: "\f1a0"; }

.la-google-drive:before {
  content: "\f3aa"; }

.la-google-play:before {
  content: "\f3ab"; }

.la-google-plus:before {
  content: "\f2b3"; }

.la-google-plus-g:before {
  content: "\f0d5"; }

.la-google-plus-square:before {
  content: "\f0d4"; }

.la-google-wallet:before {
  content: "\f1ee"; }

.la-gopuram:before {
  content: "\f664"; }

.la-graduation-cap:before {
  content: "\f19d"; }

.la-gratipay:before {
  content: "\f184"; }

.la-grav:before {
  content: "\f2d6"; }

.la-greater-than:before {
  content: "\f531"; }

.la-greater-than-equal:before {
  content: "\f532"; }

.la-grimace:before {
  content: "\f57f"; }

.la-grin:before {
  content: "\f580"; }

.la-grin-alt:before {
  content: "\f581"; }

.la-grin-beam:before {
  content: "\f582"; }

.la-grin-beam-sweat:before {
  content: "\f583"; }

.la-grin-hearts:before {
  content: "\f584"; }

.la-grin-squint:before {
  content: "\f585"; }

.la-grin-squint-tears:before {
  content: "\f586"; }

.la-grin-stars:before {
  content: "\f587"; }

.la-grin-tears:before {
  content: "\f588"; }

.la-grin-tongue:before {
  content: "\f589"; }

.la-grin-tongue-squint:before {
  content: "\f58a"; }

.la-grin-tongue-wink:before {
  content: "\f58b"; }

.la-grin-wink:before {
  content: "\f58c"; }

.la-grip-horizontal:before {
  content: "\f58d"; }

.la-grip-lines:before {
  content: "\f7a4"; }

.la-grip-lines-vertical:before {
  content: "\f7a5"; }

.la-grip-vertical:before {
  content: "\f58e"; }

.la-gripfire:before {
  content: "\f3ac"; }

.la-grunt:before {
  content: "\f3ad"; }

.la-guitar:before {
  content: "\f7a6"; }

.la-gulp:before {
  content: "\f3ae"; }

.la-h-square:before {
  content: "\f0fd"; }

.la-hacker-news:before {
  content: "\f1d4"; }

.la-hacker-news-square:before {
  content: "\f3af"; }

.la-hackerrank:before {
  content: "\f5f7"; }

.la-hamburger:before {
  content: "\f805"; }

.la-hammer:before {
  content: "\f6e3"; }

.la-hamsa:before {
  content: "\f665"; }

.la-hand-holding:before {
  content: "\f4bd"; }

.la-hand-holding-heart:before {
  content: "\f4be"; }

.la-hand-holding-usd:before {
  content: "\f4c0"; }

.la-hand-lizard:before {
  content: "\f258"; }

.la-hand-middle-finger:before {
  content: "\f806"; }

.la-hand-paper:before {
  content: "\f256"; }

.la-hand-peace:before {
  content: "\f25b"; }

.la-hand-point-down:before {
  content: "\f0a7"; }

.la-hand-point-left:before {
  content: "\f0a5"; }

.la-hand-point-right:before {
  content: "\f0a4"; }

.la-hand-point-up:before {
  content: "\f0a6"; }

.la-hand-pointer:before {
  content: "\f25a"; }

.la-hand-rock:before {
  content: "\f255"; }

.la-hand-scissors:before {
  content: "\f257"; }

.la-hand-spock:before {
  content: "\f259"; }

.la-hands:before {
  content: "\f4c2"; }

.la-hands-helping:before {
  content: "\f4c4"; }

.la-handshake:before {
  content: "\f2b5"; }

.la-hanukiah:before {
  content: "\f6e6"; }

.la-hard-hat:before {
  content: "\f807"; }

.la-hashtag:before {
  content: "\f292"; }

.la-hat-wizard:before {
  content: "\f6e8"; }

.la-haykal:before {
  content: "\f666"; }

.la-hdd:before {
  content: "\f0a0"; }

.la-heading:before {
  content: "\f1dc"; }

.la-headphones:before {
  content: "\f025"; }

.la-headphones-alt:before {
  content: "\f58f"; }

.la-headset:before {
  content: "\f590"; }

.la-heart:before {
  content: "\f004"; }

.la-heart-broken:before {
  content: "\f7a9"; }

.la-heartbeat:before {
  content: "\f21e"; }

.la-helicopter:before {
  content: "\f533"; }

.la-highlighter:before {
  content: "\f591"; }

.la-hiking:before {
  content: "\f6ec"; }

.la-hippo:before {
  content: "\f6ed"; }

.la-hips:before {
  content: "\f452"; }

.la-hire-a-helper:before {
  content: "\f3b0"; }

.la-history:before {
  content: "\f1da"; }

.la-hockey-puck:before {
  content: "\f453"; }

.la-holly-berry:before {
  content: "\f7aa"; }

.la-home:before {
  content: "\f015"; }

.la-hooli:before {
  content: "\f427"; }

.la-hornbill:before {
  content: "\f592"; }

.la-horse:before {
  content: "\f6f0"; }

.la-horse-head:before {
  content: "\f7ab"; }

.la-hospital:before {
  content: "\f0f8"; }

.la-hospital-alt:before {
  content: "\f47d"; }

.la-hospital-symbol:before {
  content: "\f47e"; }

.la-hot-tub:before {
  content: "\f593"; }

.la-hotdog:before {
  content: "\f80f"; }

.la-hotel:before {
  content: "\f594"; }

.la-hotjar:before {
  content: "\f3b1"; }

.la-hourglass:before {
  content: "\f254"; }

.la-hourglass-end:before {
  content: "\f253"; }

.la-hourglass-half:before {
  content: "\f252"; }

.la-hourglass-start:before {
  content: "\f251"; }

.la-house-damage:before {
  content: "\f6f1"; }

.la-houzz:before {
  content: "\f27c"; }

.la-hryvnia:before {
  content: "\f6f2"; }

.la-html5:before {
  content: "\f13b"; }

.la-hubspot:before {
  content: "\f3b2"; }

.la-i-cursor:before {
  content: "\f246"; }

.la-ice-cream:before {
  content: "\f810"; }

.la-icicles:before {
  content: "\f7ad"; }

.la-icons:before {
  content: "\f86d"; }

.la-id-badge:before {
  content: "\f2c1"; }

.la-id-card:before {
  content: "\f2c2"; }

.la-id-card-alt:before {
  content: "\f47f"; }

.la-igloo:before {
  content: "\f7ae"; }

.la-image:before {
  content: "\f03e"; }

.la-images:before {
  content: "\f302"; }

.la-imdb:before {
  content: "\f2d8"; }

.la-inbox:before {
  content: "\f01c"; }

.la-indent:before {
  content: "\f03c"; }

.la-industry:before {
  content: "\f275"; }

.la-infinity:before {
  content: "\f534"; }

.la-info:before {
  content: "\f129"; }

.la-info-circle:before {
  content: "\f05a"; }

.la-instagram:before {
  content: "\f16d"; }

.la-intercom:before {
  content: "\f7af"; }

.la-internet-explorer:before {
  content: "\f26b"; }

.la-invision:before {
  content: "\f7b0"; }

.la-ioxhost:before {
  content: "\f208"; }

.la-italic:before {
  content: "\f033"; }

.la-itch-io:before {
  content: "\f83a"; }

.la-itunes:before {
  content: "\f3b4"; }

.la-itunes-note:before {
  content: "\f3b5"; }

.la-java:before {
  content: "\f4e4"; }

.la-jedi:before {
  content: "\f669"; }

.la-jedi-order:before {
  content: "\f50e"; }

.la-jenkins:before {
  content: "\f3b6"; }

.la-jira:before {
  content: "\f7b1"; }

.la-joget:before {
  content: "\f3b7"; }

.la-joint:before {
  content: "\f595"; }

.la-joomla:before {
  content: "\f1aa"; }

.la-journal-whills:before {
  content: "\f66a"; }

.la-js:before {
  content: "\f3b8"; }

.la-js-square:before {
  content: "\f3b9"; }

.la-jsfiddle:before {
  content: "\f1cc"; }

.la-kaaba:before {
  content: "\f66b"; }

.la-kaggle:before {
  content: "\f5fa"; }

.la-key:before {
  content: "\f084"; }

.la-keybase:before {
  content: "\f4f5"; }

.la-keyboard:before {
  content: "\f11c"; }

.la-keycdn:before {
  content: "\f3ba"; }

.la-khanda:before {
  content: "\f66d"; }

.la-kickstarter:before {
  content: "\f3bb"; }

.la-kickstarter-k:before {
  content: "\f3bc"; }

.la-kiss:before {
  content: "\f596"; }

.la-kiss-beam:before {
  content: "\f597"; }

.la-kiss-wink-heart:before {
  content: "\f598"; }

.la-kiwi-bird:before {
  content: "\f535"; }

.la-korvue:before {
  content: "\f42f"; }

.la-landmark:before {
  content: "\f66f"; }

.la-language:before {
  content: "\f1ab"; }

.la-laptop:before {
  content: "\f109"; }

.la-laptop-code:before {
  content: "\f5fc"; }

.la-laptop-medical:before {
  content: "\f812"; }

.la-laravel:before {
  content: "\f3bd"; }

.la-lastfm:before {
  content: "\f202"; }

.la-lastfm-square:before {
  content: "\f203"; }

.la-laugh:before {
  content: "\f599"; }

.la-laugh-beam:before {
  content: "\f59a"; }

.la-laugh-squint:before {
  content: "\f59b"; }

.la-laugh-wink:before {
  content: "\f59c"; }

.la-layer-group:before {
  content: "\f5fd"; }

.la-leaf:before {
  content: "\f06c"; }

.la-leanpub:before {
  content: "\f212"; }

.la-lemon:before {
  content: "\f094"; }

.la-less:before {
  content: "\f41d"; }

.la-less-than:before {
  content: "\f536"; }

.la-less-than-equal:before {
  content: "\f537"; }

.la-level-down-alt:before {
  content: "\f3be"; }

.la-level-up-alt:before {
  content: "\f3bf"; }

.la-life-ring:before {
  content: "\f1cd"; }

.la-lightbulb:before {
  content: "\f0eb"; }

.la-line:before {
  content: "\f3c0"; }

.la-link:before {
  content: "\f0c1"; }

.la-linkedin:before {
  content: "\f08c"; }

.la-linkedin-in:before {
  content: "\f0e1"; }

.la-linode:before {
  content: "\f2b8"; }

.la-linux:before {
  content: "\f17c"; }

.la-lira-sign:before {
  content: "\f195"; }

.la-list:before {
  content: "\f03a"; }

.la-list-alt:before {
  content: "\f022"; }

.la-list-ol:before {
  content: "\f0cb"; }

.la-list-ul:before {
  content: "\f0ca"; }

.la-location-arrow:before {
  content: "\f124"; }

.la-lock:before {
  content: "\f023"; }

.la-lock-open:before {
  content: "\f3c1"; }

.la-long-arrow-alt-down:before {
  content: "\f309"; }

.la-long-arrow-alt-left:before {
  content: "\f30a"; }

.la-long-arrow-alt-right:before {
  content: "\f30b"; }

.la-long-arrow-alt-up:before {
  content: "\f30c"; }

.la-low-vision:before {
  content: "\f2a8"; }

.la-luggage-cart:before {
  content: "\f59d"; }

.la-lyft:before {
  content: "\f3c3"; }

.la-magento:before {
  content: "\f3c4"; }

.la-magic:before {
  content: "\f0d0"; }

.la-magnet:before {
  content: "\f076"; }

.la-mail-bulk:before {
  content: "\f674"; }

.la-mailchimp:before {
  content: "\f59e"; }

.la-male:before {
  content: "\f183"; }

.la-mandalorian:before {
  content: "\f50f"; }

.la-map:before {
  content: "\f279"; }

.la-map-marked:before {
  content: "\f59f"; }

.la-map-marked-alt:before {
  content: "\f5a0"; }

.la-map-marker:before {
  content: "\f041"; }

.la-map-marker-alt:before {
  content: "\f3c5"; }

.la-map-pin:before {
  content: "\f276"; }

.la-map-signs:before {
  content: "\f277"; }

.la-markdown:before {
  content: "\f60f"; }

.la-marker:before {
  content: "\f5a1"; }

.la-mars:before {
  content: "\f222"; }

.la-mars-double:before {
  content: "\f227"; }

.la-mars-stroke:before {
  content: "\f229"; }

.la-mars-stroke-h:before {
  content: "\f22b"; }

.la-mars-stroke-v:before {
  content: "\f22a"; }

.la-mask:before {
  content: "\f6fa"; }

.la-mastodon:before {
  content: "\f4f6"; }

.la-maxcdn:before {
  content: "\f136"; }

.la-medal:before {
  content: "\f5a2"; }

.la-medapps:before {
  content: "\f3c6"; }

.la-medium:before {
  content: "\f23a"; }

.la-medium-m:before {
  content: "\f3c7"; }

.la-medkit:before {
  content: "\f0fa"; }

.la-medrt:before {
  content: "\f3c8"; }

.la-meetup:before {
  content: "\f2e0"; }

.la-megaport:before {
  content: "\f5a3"; }

.la-meh:before {
  content: "\f11a"; }

.la-meh-blank:before {
  content: "\f5a4"; }

.la-meh-rolling-eyes:before {
  content: "\f5a5"; }

.la-memory:before {
  content: "\f538"; }

.la-mendeley:before {
  content: "\f7b3"; }

.la-menorah:before {
  content: "\f676"; }

.la-mercury:before {
  content: "\f223"; }

.la-meteor:before {
  content: "\f753"; }

.la-microchip:before {
  content: "\f2db"; }

.la-microphone:before {
  content: "\f130"; }

.la-microphone-alt:before {
  content: "\f3c9"; }

.la-microphone-alt-slash:before {
  content: "\f539"; }

.la-microphone-slash:before {
  content: "\f131"; }

.la-microscope:before {
  content: "\f610"; }

.la-microsoft:before {
  content: "\f3ca"; }

.la-minus:before {
  content: "\f068"; }

.la-minus-circle:before {
  content: "\f056"; }

.la-minus-square:before {
  content: "\f146"; }

.la-mitten:before {
  content: "\f7b5"; }

.la-mix:before {
  content: "\f3cb"; }

.la-mixcloud:before {
  content: "\f289"; }

.la-mizuni:before {
  content: "\f3cc"; }

.la-mobile:before {
  content: "\f10b"; }

.la-mobile-alt:before {
  content: "\f3cd"; }

.la-modx:before {
  content: "\f285"; }

.la-monero:before {
  content: "\f3d0"; }

.la-money-bill:before {
  content: "\f0d6"; }

.la-money-bill-alt:before {
  content: "\f3d1"; }

.la-money-bill-wave:before {
  content: "\f53a"; }

.la-money-bill-wave-alt:before {
  content: "\f53b"; }

.la-money-check:before {
  content: "\f53c"; }

.la-money-check-alt:before {
  content: "\f53d"; }

.la-monument:before {
  content: "\f5a6"; }

.la-moon:before {
  content: "\f186"; }

.la-mortar-pestle:before {
  content: "\f5a7"; }

.la-mosque:before {
  content: "\f678"; }

.la-motorcycle:before {
  content: "\f21c"; }

.la-mountain:before {
  content: "\f6fc"; }

.la-mouse-pointer:before {
  content: "\f245"; }

.la-mug-hot:before {
  content: "\f7b6"; }

.la-music:before {
  content: "\f001"; }

.la-napster:before {
  content: "\f3d2"; }

.la-neos:before {
  content: "\f612"; }

.la-network-wired:before {
  content: "\f6ff"; }

.la-neuter:before {
  content: "\f22c"; }

.la-newspaper:before {
  content: "\f1ea"; }

.la-nimblr:before {
  content: "\f5a8"; }

.la-node:before {
  content: "\f419"; }

.la-node-js:before {
  content: "\f3d3"; }

.la-not-equal:before {
  content: "\f53e"; }

.la-notes-medical:before {
  content: "\f481"; }

.la-npm:before {
  content: "\f3d4"; }

.la-ns8:before {
  content: "\f3d5"; }

.la-nutritionix:before {
  content: "\f3d6"; }

.la-object-group:before {
  content: "\f247"; }

.la-object-ungroup:before {
  content: "\f248"; }

.la-odnoklassniki:before {
  content: "\f263"; }

.la-odnoklassniki-square:before {
  content: "\f264"; }

.la-oil-can:before {
  content: "\f613"; }

.la-old-republic:before {
  content: "\f510"; }

.la-om:before {
  content: "\f679"; }

.la-opencart:before {
  content: "\f23d"; }

.la-openid:before {
  content: "\f19b"; }

.la-opera:before {
  content: "\f26a"; }

.la-optin-monster:before {
  content: "\f23c"; }

.la-osi:before {
  content: "\f41a"; }

.la-otter:before {
  content: "\f700"; }

.la-outdent:before {
  content: "\f03b"; }

.la-page4:before {
  content: "\f3d7"; }

.la-pagelines:before {
  content: "\f18c"; }

.la-pager:before {
  content: "\f815"; }

.la-paint-brush:before {
  content: "\f1fc"; }

.la-paint-roller:before {
  content: "\f5aa"; }

.la-palette:before {
  content: "\f53f"; }

.la-palfed:before {
  content: "\f3d8"; }

.la-pallet:before {
  content: "\f482"; }

.la-paper-plane:before {
  content: "\f1d8"; }

.la-paperclip:before {
  content: "\f0c6"; }

.la-parachute-box:before {
  content: "\f4cd"; }

.la-paragraph:before {
  content: "\f1dd"; }

.la-parking:before {
  content: "\f540"; }

.la-passport:before {
  content: "\f5ab"; }

.la-pastafarianism:before {
  content: "\f67b"; }

.la-paste:before {
  content: "\f0ea"; }

.la-patreon:before {
  content: "\f3d9"; }

.la-pause:before {
  content: "\f04c"; }

.la-pause-circle:before {
  content: "\f28b"; }

.la-paw:before {
  content: "\f1b0"; }

.la-paypal:before {
  content: "\f1ed"; }

.la-peace:before {
  content: "\f67c"; }

.la-pen:before {
  content: "\f304"; }

.la-pen-alt:before {
  content: "\f305"; }

.la-pen-fancy:before {
  content: "\f5ac"; }

.la-pen-nib:before {
  content: "\f5ad"; }

.la-pen-square:before {
  content: "\f14b"; }

.la-pencil-alt:before {
  content: "\f303"; }

.la-pencil-ruler:before {
  content: "\f5ae"; }

.la-penny-arcade:before {
  content: "\f704"; }

.la-people-carry:before {
  content: "\f4ce"; }

.la-pepper-hot:before {
  content: "\f816"; }

.la-percent:before {
  content: "\f295"; }

.la-percentage:before {
  content: "\f541"; }

.la-periscope:before {
  content: "\f3da"; }

.la-person-booth:before {
  content: "\f756"; }

.la-phabricator:before {
  content: "\f3db"; }

.la-phoenix-framework:before {
  content: "\f3dc"; }

.la-phoenix-squadron:before {
  content: "\f511"; }

.la-phone:before {
  content: "\f095"; }

.la-phone-alt:before {
  content: "\f879"; }

.la-phone-slash:before {
  content: "\f3dd"; }

.la-phone-square:before {
  content: "\f098"; }

.la-phone-square-alt:before {
  content: "\f87b"; }

.la-phone-volume:before {
  content: "\f2a0"; }

.la-photo-video:before {
  content: "\f87c"; }

.la-php:before {
  content: "\f457"; }

.la-pied-piper:before {
  content: "\f2ae"; }

.la-pied-piper-alt:before {
  content: "\f1a8"; }

.la-pied-piper-hat:before {
  content: "\f4e5"; }

.la-pied-piper-pp:before {
  content: "\f1a7"; }

.la-piggy-bank:before {
  content: "\f4d3"; }

.la-pills:before {
  content: "\f484"; }

.la-pinterest:before {
  content: "\f0d2"; }

.la-pinterest-p:before {
  content: "\f231"; }

.la-pinterest-square:before {
  content: "\f0d3"; }

.la-pizza-slice:before {
  content: "\f818"; }

.la-place-of-worship:before {
  content: "\f67f"; }

.la-plane:before {
  content: "\f072"; }

.la-plane-arrival:before {
  content: "\f5af"; }

.la-plane-departure:before {
  content: "\f5b0"; }

.la-play:before {
  content: "\f04b"; }

.la-play-circle:before {
  content: "\f144"; }

.la-playstation:before {
  content: "\f3df"; }

.la-plug:before {
  content: "\f1e6"; }

.la-plus:before {
  content: "\f067"; }

.la-plus-circle:before {
  content: "\f055"; }

.la-plus-square:before {
  content: "\f0fe"; }

.la-podcast:before {
  content: "\f2ce"; }

.la-poll:before {
  content: "\f681"; }

.la-poll-h:before {
  content: "\f682"; }

.la-poo:before {
  content: "\f2fe"; }

.la-poo-storm:before {
  content: "\f75a"; }

.la-poop:before {
  content: "\f619"; }

.la-portrait:before {
  content: "\f3e0"; }

.la-pound-sign:before {
  content: "\f154"; }

.la-power-off:before {
  content: "\f011"; }

.la-pray:before {
  content: "\f683"; }

.la-praying-hands:before {
  content: "\f684"; }

.la-prescription:before {
  content: "\f5b1"; }

.la-prescription-bottle:before {
  content: "\f485"; }

.la-prescription-bottle-alt:before {
  content: "\f486"; }

.la-print:before {
  content: "\f02f"; }

.la-procedures:before {
  content: "\f487"; }

.la-product-hunt:before {
  content: "\f288"; }

.la-project-diagram:before {
  content: "\f542"; }

.la-pushed:before {
  content: "\f3e1"; }

.la-puzzle-piece:before {
  content: "\f12e"; }

.la-python:before {
  content: "\f3e2"; }

.la-qq:before {
  content: "\f1d6"; }

.la-qrcode:before {
  content: "\f029"; }

.la-question:before {
  content: "\f128"; }

.la-question-circle:before {
  content: "\f059"; }

.la-quidditch:before {
  content: "\f458"; }

.la-quinscape:before {
  content: "\f459"; }

.la-quora:before {
  content: "\f2c4"; }

.la-quote-left:before {
  content: "\f10d"; }

.la-quote-right:before {
  content: "\f10e"; }

.la-quran:before {
  content: "\f687"; }

.la-r-project:before {
  content: "\f4f7"; }

.la-radiation:before {
  content: "\f7b9"; }

.la-radiation-alt:before {
  content: "\f7ba"; }

.la-rainbow:before {
  content: "\f75b"; }

.la-random:before {
  content: "\f074"; }

.la-raspberry-pi:before {
  content: "\f7bb"; }

.la-ravelry:before {
  content: "\f2d9"; }

.la-react:before {
  content: "\f41b"; }

.la-reacteurope:before {
  content: "\f75d"; }

.la-readme:before {
  content: "\f4d5"; }

.la-rebel:before {
  content: "\f1d0"; }

.la-receipt:before {
  content: "\f543"; }

.la-recycle:before {
  content: "\f1b8"; }

.la-red-river:before {
  content: "\f3e3"; }

.la-reddit:before {
  content: "\f1a1"; }

.la-reddit-alien:before {
  content: "\f281"; }

.la-reddit-square:before {
  content: "\f1a2"; }

.la-redhat:before {
  content: "\f7bc"; }

.la-redo:before {
  content: "\f01e"; }

.la-redo-alt:before {
  content: "\f2f9"; }

.la-registered:before {
  content: "\f25d"; }

.la-remove-format:before {
  content: "\f87d"; }

.la-renren:before {
  content: "\f18b"; }

.la-reply:before {
  content: "\f3e5"; }

.la-reply-all:before {
  content: "\f122"; }

.la-replyd:before {
  content: "\f3e6"; }

.la-republican:before {
  content: "\f75e"; }

.la-researchgate:before {
  content: "\f4f8"; }

.la-resolving:before {
  content: "\f3e7"; }

.la-restroom:before {
  content: "\f7bd"; }

.la-retweet:before {
  content: "\f079"; }

.la-rev:before {
  content: "\f5b2"; }

.la-ribbon:before {
  content: "\f4d6"; }

.la-ring:before {
  content: "\f70b"; }

.la-road:before {
  content: "\f018"; }

.la-robot:before {
  content: "\f544"; }

.la-rocket:before {
  content: "\f135"; }

.la-rocketchat:before {
  content: "\f3e8"; }

.la-rockrms:before {
  content: "\f3e9"; }

.la-route:before {
  content: "\f4d7"; }

.la-rss:before {
  content: "\f09e"; }

.la-rss-square:before {
  content: "\f143"; }

.la-ruble-sign:before {
  content: "\f158"; }

.la-ruler:before {
  content: "\f545"; }

.la-ruler-combined:before {
  content: "\f546"; }

.la-ruler-horizontal:before {
  content: "\f547"; }

.la-ruler-vertical:before {
  content: "\f548"; }

.la-running:before {
  content: "\f70c"; }

.la-rupee-sign:before {
  content: "\f156"; }

.la-sad-cry:before {
  content: "\f5b3"; }

.la-sad-tear:before {
  content: "\f5b4"; }

.la-safari:before {
  content: "\f267"; }

.la-salesforce:before {
  content: "\f83b"; }

.la-sass:before {
  content: "\f41e"; }

.la-satellite:before {
  content: "\f7bf"; }

.la-satellite-dish:before {
  content: "\f7c0"; }

.la-save:before {
  content: "\f0c7"; }

.la-schlix:before {
  content: "\f3ea"; }

.la-school:before {
  content: "\f549"; }

.la-screwdriver:before {
  content: "\f54a"; }

.la-scribd:before {
  content: "\f28a"; }

.la-scroll:before {
  content: "\f70e"; }

.la-sd-card:before {
  content: "\f7c2"; }

.la-search:before {
  content: "\f002"; }

.la-search-dollar:before {
  content: "\f688"; }

.la-search-location:before {
  content: "\f689"; }

.la-search-minus:before {
  content: "\f010"; }

.la-search-plus:before {
  content: "\f00e"; }

.la-searchengin:before {
  content: "\f3eb"; }

.la-seedling:before {
  content: "\f4d8"; }

.la-sellcast:before {
  content: "\f2da"; }

.la-sellsy:before {
  content: "\f213"; }

.la-server:before {
  content: "\f233"; }

.la-servicestack:before {
  content: "\f3ec"; }

.la-shapes:before {
  content: "\f61f"; }

.la-share:before {
  content: "\f064"; }

.la-share-alt:before {
  content: "\f1e0"; }

.la-share-alt-square:before {
  content: "\f1e1"; }

.la-share-square:before {
  content: "\f14d"; }

.la-shekel-sign:before {
  content: "\f20b"; }

.la-shield-alt:before {
  content: "\f3ed"; }

.la-ship:before {
  content: "\f21a"; }

.la-shipping-fast:before {
  content: "\f48b"; }

.la-shirtsinbulk:before {
  content: "\f214"; }

.la-shoe-prints:before {
  content: "\f54b"; }

.la-shopping-bag:before {
  content: "\f290"; }

.la-shopping-basket:before {
  content: "\f291"; }

.la-shopping-cart:before {
  content: "\f07a"; }

.la-shopware:before {
  content: "\f5b5"; }

.la-shower:before {
  content: "\f2cc"; }

.la-shuttle-van:before {
  content: "\f5b6"; }

.la-sign:before {
  content: "\f4d9"; }

.la-sign-in-alt:before {
  content: "\f2f6"; }

.la-sign-language:before {
  content: "\f2a7"; }

.la-sign-out-alt:before {
  content: "\f2f5"; }

.la-signal:before {
  content: "\f012"; }

.la-signature:before {
  content: "\f5b7"; }

.la-sim-card:before {
  content: "\f7c4"; }

.la-simplybuilt:before {
  content: "\f215"; }

.la-sistrix:before {
  content: "\f3ee"; }

.la-sitemap:before {
  content: "\f0e8"; }

.la-sith:before {
  content: "\f512"; }

.la-skating:before {
  content: "\f7c5"; }

.la-sketch:before {
  content: "\f7c6"; }

.la-skiing:before {
  content: "\f7c9"; }

.la-skiing-nordic:before {
  content: "\f7ca"; }

.la-skull:before {
  content: "\f54c"; }

.la-skull-crossbones:before {
  content: "\f714"; }

.la-skyatlas:before {
  content: "\f216"; }

.la-skype:before {
  content: "\f17e"; }

.la-slack:before {
  content: "\f198"; }

.la-slack-hash:before {
  content: "\f3ef"; }

.la-slash:before {
  content: "\f715"; }

.la-sleigh:before {
  content: "\f7cc"; }

.la-sliders-h:before {
  content: "\f1de"; }

.la-slideshare:before {
  content: "\f1e7"; }

.la-smile:before {
  content: "\f118"; }

.la-smile-beam:before {
  content: "\f5b8"; }

.la-smile-wink:before {
  content: "\f4da"; }

.la-smog:before {
  content: "\f75f"; }

.la-smoking:before {
  content: "\f48d"; }

.la-smoking-ban:before {
  content: "\f54d"; }

.la-sms:before {
  content: "\f7cd"; }

.la-snapchat:before {
  content: "\f2ab"; }

.la-snapchat-ghost:before {
  content: "\f2ac"; }

.la-snapchat-square:before {
  content: "\f2ad"; }

.la-snowboarding:before {
  content: "\f7ce"; }

.la-snowflake:before {
  content: "\f2dc"; }

.la-snowman:before {
  content: "\f7d0"; }

.la-snowplow:before {
  content: "\f7d2"; }

.la-socks:before {
  content: "\f696"; }

.la-solar-panel:before {
  content: "\f5ba"; }

.la-sort:before {
  content: "\f0dc"; }

.la-sort-alpha-down:before {
  content: "\f15d"; }

.la-sort-alpha-down-alt:before {
  content: "\f881"; }

.la-sort-alpha-up:before {
  content: "\f15e"; }

.la-sort-alpha-up-alt:before {
  content: "\f882"; }

.la-sort-amount-down:before {
  content: "\f160"; }

.la-sort-amount-down-alt:before {
  content: "\f884"; }

.la-sort-amount-up:before {
  content: "\f161"; }

.la-sort-amount-up-alt:before {
  content: "\f885"; }

.la-sort-down:before {
  content: "\f0dd"; }

.la-sort-numeric-down:before {
  content: "\f162"; }

.la-sort-numeric-down-alt:before {
  content: "\f886"; }

.la-sort-numeric-up:before {
  content: "\f163"; }

.la-sort-numeric-up-alt:before {
  content: "\f887"; }

.la-sort-up:before {
  content: "\f0de"; }

.la-soundcloud:before {
  content: "\f1be"; }

.la-sourcetree:before {
  content: "\f7d3"; }

.la-spa:before {
  content: "\f5bb"; }

.la-space-shuttle:before {
  content: "\f197"; }

.la-speakap:before {
  content: "\f3f3"; }

.la-speaker-deck:before {
  content: "\f83c"; }

.la-spell-check:before {
  content: "\f891"; }

.la-spider:before {
  content: "\f717"; }

.la-spinner:before {
  content: "\f110"; }

.la-splotch:before {
  content: "\f5bc"; }

.la-spotify:before {
  content: "\f1bc"; }

.la-spray-can:before {
  content: "\f5bd"; }

.la-square:before {
  content: "\f0c8"; }

.la-square-full:before {
  content: "\f45c"; }

.la-square-root-alt:before {
  content: "\f698"; }

.la-squarespace:before {
  content: "\f5be"; }

.la-stack-exchange:before {
  content: "\f18d"; }

.la-stack-overflow:before {
  content: "\f16c"; }

.la-stackpath:before {
  content: "\f842"; }

.la-stamp:before {
  content: "\f5bf"; }

.la-star:before {
  content: "\f005"; }

.la-star-and-crescent:before {
  content: "\f699"; }

.la-star-half:before {
  content: "\f089"; }

.la-star-half-alt:before {
  content: "\f5c0"; }

.la-star-of-david:before {
  content: "\f69a"; }

.la-star-of-life:before {
  content: "\f621"; }

.la-staylinked:before {
  content: "\f3f5"; }

.la-steam:before {
  content: "\f1b6"; }

.la-steam-square:before {
  content: "\f1b7"; }

.la-steam-symbol:before {
  content: "\f3f6"; }

.la-step-backward:before {
  content: "\f048"; }

.la-step-forward:before {
  content: "\f051"; }

.la-stethoscope:before {
  content: "\f0f1"; }

.la-sticker-mule:before {
  content: "\f3f7"; }

.la-sticky-note:before {
  content: "\f249"; }

.la-stop:before {
  content: "\f04d"; }

.la-stop-circle:before {
  content: "\f28d"; }

.la-stopwatch:before {
  content: "\f2f2"; }

.la-store:before {
  content: "\f54e"; }

.la-store-alt:before {
  content: "\f54f"; }

.la-strava:before {
  content: "\f428"; }

.la-stream:before {
  content: "\f550"; }

.la-street-view:before {
  content: "\f21d"; }

.la-strikethrough:before {
  content: "\f0cc"; }

.la-stripe:before {
  content: "\f429"; }

.la-stripe-s:before {
  content: "\f42a"; }

.la-stroopwafel:before {
  content: "\f551"; }

.la-studiovinari:before {
  content: "\f3f8"; }

.la-stumbleupon:before {
  content: "\f1a4"; }

.la-stumbleupon-circle:before {
  content: "\f1a3"; }

.la-subscript:before {
  content: "\f12c"; }

.la-subway:before {
  content: "\f239"; }

.la-suitcase:before {
  content: "\f0f2"; }

.la-suitcase-rolling:before {
  content: "\f5c1"; }

.la-sun:before {
  content: "\f185"; }

.la-superpowers:before {
  content: "\f2dd"; }

.la-superscript:before {
  content: "\f12b"; }

.la-supple:before {
  content: "\f3f9"; }

.la-surprise:before {
  content: "\f5c2"; }

.la-suse:before {
  content: "\f7d6"; }

.la-swatchbook:before {
  content: "\f5c3"; }

.la-swimmer:before {
  content: "\f5c4"; }

.la-swimming-pool:before {
  content: "\f5c5"; }

.la-symfony:before {
  content: "\f83d"; }

.la-synagogue:before {
  content: "\f69b"; }

.la-sync:before {
  content: "\f021"; }

.la-sync-alt:before {
  content: "\f2f1"; }

.la-syringe:before {
  content: "\f48e"; }

.la-table:before {
  content: "\f0ce"; }

.la-table-tennis:before {
  content: "\f45d"; }

.la-tablet:before {
  content: "\f10a"; }

.la-tablet-alt:before {
  content: "\f3fa"; }

.la-tablets:before {
  content: "\f490"; }

.la-tachometer-alt:before {
  content: "\f3fd"; }

.la-tag:before {
  content: "\f02b"; }

.la-tags:before {
  content: "\f02c"; }

.la-tape:before {
  content: "\f4db"; }

.la-tasks:before {
  content: "\f0ae"; }

.la-taxi:before {
  content: "\f1ba"; }

.la-teamspeak:before {
  content: "\f4f9"; }

.la-teeth:before {
  content: "\f62e"; }

.la-teeth-open:before {
  content: "\f62f"; }

.la-telegram:before {
  content: "\f2c6"; }

.la-telegram-plane:before {
  content: "\f3fe"; }

.la-temperature-high:before {
  content: "\f769"; }

.la-temperature-low:before {
  content: "\f76b"; }

.la-tencent-weibo:before {
  content: "\f1d5"; }

.la-tenge:before {
  content: "\f7d7"; }

.la-terminal:before {
  content: "\f120"; }

.la-text-height:before {
  content: "\f034"; }

.la-text-width:before {
  content: "\f035"; }

.la-th:before {
  content: "\f00a"; }

.la-th-large:before {
  content: "\f009"; }

.la-th-list:before {
  content: "\f00b"; }

.la-the-red-yeti:before {
  content: "\f69d"; }

.la-theater-masks:before {
  content: "\f630"; }

.la-themeco:before {
  content: "\f5c6"; }

.la-themeisle:before {
  content: "\f2b2"; }

.la-thermometer:before {
  content: "\f491"; }

.la-thermometer-empty:before {
  content: "\f2cb"; }

.la-thermometer-full:before {
  content: "\f2c7"; }

.la-thermometer-half:before {
  content: "\f2c9"; }

.la-thermometer-quarter:before {
  content: "\f2ca"; }

.la-thermometer-three-quarters:before {
  content: "\f2c8"; }

.la-think-peaks:before {
  content: "\f731"; }

.la-thumbs-down:before {
  content: "\f165"; }

.la-thumbs-up:before {
  content: "\f164"; }

.la-thumbtack:before {
  content: "\f08d"; }

.la-ticket-alt:before {
  content: "\f3ff"; }

.la-times:before {
  content: "\f00d"; }

.la-times-circle:before {
  content: "\f057"; }

.la-tint:before {
  content: "\f043"; }

.la-tint-slash:before {
  content: "\f5c7"; }

.la-tired:before {
  content: "\f5c8"; }

.la-toggle-off:before {
  content: "\f204"; }

.la-toggle-on:before {
  content: "\f205"; }

.la-toilet:before {
  content: "\f7d8"; }

.la-toilet-paper:before {
  content: "\f71e"; }

.la-toolbox:before {
  content: "\f552"; }

.la-tools:before {
  content: "\f7d9"; }

.la-tooth:before {
  content: "\f5c9"; }

.la-torah:before {
  content: "\f6a0"; }

.la-torii-gate:before {
  content: "\f6a1"; }

.la-tractor:before {
  content: "\f722"; }

.la-trade-federation:before {
  content: "\f513"; }

.la-trademark:before {
  content: "\f25c"; }

.la-traffic-light:before {
  content: "\f637"; }

.la-train:before {
  content: "\f238"; }

.la-tram:before {
  content: "\f7da"; }

.la-transgender:before {
  content: "\f224"; }

.la-transgender-alt:before {
  content: "\f225"; }

.la-trash:before {
  content: "\f1f8"; }

.la-trash-alt:before {
  content: "\f2ed"; }

.la-trash-restore:before {
  content: "\f829"; }

.la-trash-restore-alt:before {
  content: "\f82a"; }

.la-tree:before {
  content: "\f1bb"; }

.la-trello:before {
  content: "\f181"; }

.la-tripadvisor:before {
  content: "\f262"; }

.la-trophy:before {
  content: "\f091"; }

.la-truck:before {
  content: "\f0d1"; }

.la-truck-loading:before {
  content: "\f4de"; }

.la-truck-monster:before {
  content: "\f63b"; }

.la-truck-moving:before {
  content: "\f4df"; }

.la-truck-pickup:before {
  content: "\f63c"; }

.la-tshirt:before {
  content: "\f553"; }

.la-tty:before {
  content: "\f1e4"; }

.la-tumblr:before {
  content: "\f173"; }

.la-tumblr-square:before {
  content: "\f174"; }

.la-tv:before {
  content: "\f26c"; }

.la-twitch:before {
  content: "\f1e8"; }

.la-twitter:before {
  content: "\f099"; }

.la-twitter-square:before {
  content: "\f081"; }

.la-typo3:before {
  content: "\f42b"; }

.la-uber:before {
  content: "\f402"; }

.la-ubuntu:before {
  content: "\f7df"; }

.la-uikit:before {
  content: "\f403"; }

.la-umbrella:before {
  content: "\f0e9"; }

.la-umbrella-beach:before {
  content: "\f5ca"; }

.la-underline:before {
  content: "\f0cd"; }

.la-undo:before {
  content: "\f0e2"; }

.la-undo-alt:before {
  content: "\f2ea"; }

.la-uniregistry:before {
  content: "\f404"; }

.la-universal-access:before {
  content: "\f29a"; }

.la-university:before {
  content: "\f19c"; }

.la-unlink:before {
  content: "\f127"; }

.la-unlock:before {
  content: "\f09c"; }

.la-unlock-alt:before {
  content: "\f13e"; }

.la-untappd:before {
  content: "\f405"; }

.la-upload:before {
  content: "\f093"; }

.la-ups:before {
  content: "\f7e0"; }

.la-usb:before {
  content: "\f287"; }

.la-user:before {
  content: "\f007"; }

.la-user-alt:before {
  content: "\f406"; }

.la-user-alt-slash:before {
  content: "\f4fa"; }

.la-user-astronaut:before {
  content: "\f4fb"; }

.la-user-check:before {
  content: "\f4fc"; }

.la-user-circle:before {
  content: "\f2bd"; }

.la-user-clock:before {
  content: "\f4fd"; }

.la-user-cog:before {
  content: "\f4fe"; }

.la-user-edit:before {
  content: "\f4ff"; }

.la-user-friends:before {
  content: "\f500"; }

.la-user-graduate:before {
  content: "\f501"; }

.la-user-injured:before {
  content: "\f728"; }

.la-user-lock:before {
  content: "\f502"; }

.la-user-md:before {
  content: "\f0f0"; }

.la-user-minus:before {
  content: "\f503"; }

.la-user-ninja:before {
  content: "\f504"; }

.la-user-nurse:before {
  content: "\f82f"; }

.la-user-plus:before {
  content: "\f234"; }

.la-user-secret:before {
  content: "\f21b"; }

.la-user-shield:before {
  content: "\f505"; }

.la-user-slash:before {
  content: "\f506"; }

.la-user-tag:before {
  content: "\f507"; }

.la-user-tie:before {
  content: "\f508"; }

.la-user-times:before {
  content: "\f235"; }

.la-users:before {
  content: "\f0c0"; }

.la-users-cog:before {
  content: "\f509"; }

.la-usps:before {
  content: "\f7e1"; }

.la-ussunnah:before {
  content: "\f407"; }

.la-utensil-spoon:before {
  content: "\f2e5"; }

.la-utensils:before {
  content: "\f2e7"; }

.la-vaadin:before {
  content: "\f408"; }

.la-vector-square:before {
  content: "\f5cb"; }

.la-venus:before {
  content: "\f221"; }

.la-venus-double:before {
  content: "\f226"; }

.la-venus-mars:before {
  content: "\f228"; }

.la-viacoin:before {
  content: "\f237"; }

.la-viadeo:before {
  content: "\f2a9"; }

.la-viadeo-square:before {
  content: "\f2aa"; }

.la-vial:before {
  content: "\f492"; }

.la-vials:before {
  content: "\f493"; }

.la-viber:before {
  content: "\f409"; }

.la-video:before {
  content: "\f03d"; }

.la-video-slash:before {
  content: "\f4e2"; }

.la-vihara:before {
  content: "\f6a7"; }

.la-vimeo:before {
  content: "\f40a"; }

.la-vimeo-square:before {
  content: "\f194"; }

.la-vimeo-v:before {
  content: "\f27d"; }

.la-vine:before {
  content: "\f1ca"; }

.la-vk:before {
  content: "\f189"; }

.la-vnv:before {
  content: "\f40b"; }

.la-voicemail:before {
  content: "\f897"; }

.la-volleyball-ball:before {
  content: "\f45f"; }

.la-volume-down:before {
  content: "\f027"; }

.la-volume-mute:before {
  content: "\f6a9"; }

.la-volume-off:before {
  content: "\f026"; }

.la-volume-up:before {
  content: "\f028"; }

.la-vote-yea:before {
  content: "\f772"; }

.la-vr-cardboard:before {
  content: "\f729"; }

.la-vuejs:before {
  content: "\f41f"; }

.la-walking:before {
  content: "\f554"; }

.la-wallet:before {
  content: "\f555"; }

.la-warehouse:before {
  content: "\f494"; }

.la-water:before {
  content: "\f773"; }

.la-wave-square:before {
  content: "\f83e"; }

.la-waze:before {
  content: "\f83f"; }

.la-weebly:before {
  content: "\f5cc"; }

.la-weibo:before {
  content: "\f18a"; }

.la-weight:before {
  content: "\f496"; }

.la-weight-hanging:before {
  content: "\f5cd"; }

.la-weixin:before {
  content: "\f1d7"; }

.la-whatsapp:before {
  content: "\f232"; }

.la-whatsapp-square:before {
  content: "\f40c"; }

.la-wheelchair:before {
  content: "\f193"; }

.la-whmcs:before {
  content: "\f40d"; }

.la-wifi:before {
  content: "\f1eb"; }

.la-wikipedia-w:before {
  content: "\f266"; }

.la-wind:before {
  content: "\f72e"; }

.la-window-close:before {
  content: "\f410"; }

.la-window-maximize:before {
  content: "\f2d0"; }

.la-window-minimize:before {
  content: "\f2d1"; }

.la-window-restore:before {
  content: "\f2d2"; }

.la-windows:before {
  content: "\f17a"; }

.la-wine-bottle:before {
  content: "\f72f"; }

.la-wine-glass:before {
  content: "\f4e3"; }

.la-wine-glass-alt:before {
  content: "\f5ce"; }

.la-wix:before {
  content: "\f5cf"; }

.la-wizards-of-the-coast:before {
  content: "\f730"; }

.la-wolf-pack-battalion:before {
  content: "\f514"; }

.la-won-sign:before {
  content: "\f159"; }

.la-wordpress:before {
  content: "\f19a"; }

.la-wordpress-simple:before {
  content: "\f411"; }

.la-wpbeginner:before {
  content: "\f297"; }

.la-wpexplorer:before {
  content: "\f2de"; }

.la-wpforms:before {
  content: "\f298"; }

.la-wpressr:before {
  content: "\f3e4"; }

.la-wrench:before {
  content: "\f0ad"; }

.la-x-ray:before {
  content: "\f497"; }

.la-xbox:before {
  content: "\f412"; }

.la-xing:before {
  content: "\f168"; }

.la-xing-square:before {
  content: "\f169"; }

.la-y-combinator:before {
  content: "\f23b"; }

.la-yahoo:before {
  content: "\f19e"; }

.la-yammer:before {
  content: "\f840"; }

.la-yandex:before {
  content: "\f413"; }

.la-yandex-international:before {
  content: "\f414"; }

.la-yarn:before {
  content: "\f7e3"; }

.la-yelp:before {
  content: "\f1e9"; }

.la-yen-sign:before {
  content: "\f157"; }

.la-yin-yang:before {
  content: "\f6ad"; }

.la-yoast:before {
  content: "\f2b1"; }

.la-youtube:before {
  content: "\f167"; }

.la-youtube-square:before {
  content: "\f431"; }

.la-zhihu:before {
  content: "\f63f"; }

.la-hat-cowboy:before {
  content: "\f8c0"; }

.la-hat-cowboy-side:before {
  content: "\f8c1"; }

.la-mdb:before {
  content: "\f8ca"; }

.la-mouse:before {
  content: "\f8cc"; }

.la-orcid:before {
  content: "\f8d2"; }

.la-record-vinyl:before {
  content: "\f8d9"; }

.la-swift:before {
  content: "\f8e1"; }

.la-umbraco:before {
  content: "\f8e8"; }

.la-buy-n-large:before {
  content: "\f8a6"; }

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

/*!
   * Bootstrap  v5.3.0 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-custom: #2a4365;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-text-align : #101010;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: none;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-custom : "#000000";
  --bs-body-color: #adb5bd;
  --bs-body-color-rgb: 173, 181, 189;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(173, 181, 189, 0.75);
  --bs-secondary-color-rgb: 173, 181, 189;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(173, 181, 189, 0.5);
  --bs-tertiary-color-rgb: 173, 181, 189;
  --bs-tertiary-bg: #2b3035;
  --bs-body-text-align : #fff;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }



/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }



iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #bacbe6;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #cbccce;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.4rem + var(--bs-border-width));
  padding-bottom: calc(0.4rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }


.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3cpath fill='none' stroke='%23aaa' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.4rem 1.5rem 0.4rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.7rem center;
  background-size: 10px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.5rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  print-color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 2.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -2.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }


[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-tertiary-bg);
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 0.5rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 0.5rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.25rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg);
      border-radius: var(--bs-border-radius); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label {
    color: #6c757d; }
    .form-floating > :disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm); }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 2rem; }

.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.8rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2rem) center;
  background-size: calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.8rem);
  background-position: top calc(0.375em + 0.2rem) right calc(0.375em + 0.2rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 2.75rem;
    background-position: right 0.7rem center, center right 1.5rem;
    background-size: 10px 12px, calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.8rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.8rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.2rem) center;
  background-size: calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.8rem);
  background-position: top calc(0.375em + 0.2rem) right calc(0.375em + 0.2rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    padding-right: 2.75rem;
    background-position: right 0.7rem center, center right 1.5rem;
    background-size: 10px 12px, calc(0.75em + 0.4rem) calc(0.75em + 0.4rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.8rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }


.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }


.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }



.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius); }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: var(--bs-accordion-inner-border-radius);
      border-top-right-radius: var(--bs-accordion-inner-border-radius); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius); }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
      border-bottom-left-radius: var(--bs-accordion-inner-border-radius); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: var(--bs-accordion-border-radius);
      border-bottom-left-radius: var(--bs-accordion-border-radius); }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider: 1px;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: 14px;
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    /*content: var(--bs-breadcrumb-divider) !* rtl: var(--bs-breadcrumb-divider, "/") *!;*/
  }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius); }

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }


#kiiako-builder .list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  #kiiako-builder .list-group-numbered > #kiiako-builder .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

#kiiako-builder .list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  #kiiako-builder .list-group-item-action:hover, #kiiako-builder .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  #kiiako-builder .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

#kiiako-builder .list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  #kiiako-builder .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  #kiiako-builder .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  #kiiako-builder .list-group-item.disabled, #kiiako-builder .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  #kiiako-builder .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
    border-top-width: 0; }
    #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

#kiiako-builder .list-group-horizontal {
  flex-direction: row; }
  #kiiako-builder .list-group-horizontal > #kiiako-builder .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0; }
  #kiiako-builder .list-group-horizontal > #kiiako-builder .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0; }
  #kiiako-builder .list-group-horizontal > #kiiako-builder .list-group-item.active {
    margin-top: 0; }
  #kiiako-builder .list-group-horizontal > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    #kiiako-builder .list-group-horizontal > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  #kiiako-builder .list-group-horizontal-sm {
    flex-direction: row; }
    #kiiako-builder .list-group-horizontal-sm > #kiiako-builder .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    #kiiako-builder .list-group-horizontal-sm > #kiiako-builder .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    #kiiako-builder .list-group-horizontal-sm > #kiiako-builder .list-group-item.active {
      margin-top: 0; }
    #kiiako-builder .list-group-horizontal-sm > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      #kiiako-builder .list-group-horizontal-sm > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  #kiiako-builder .list-group-horizontal-md {
    flex-direction: row; }
    #kiiako-builder .list-group-horizontal-md > #kiiako-builder .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    #kiiako-builder .list-group-horizontal-md > #kiiako-builder .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    #kiiako-builder .list-group-horizontal-md > #kiiako-builder .list-group-item.active {
      margin-top: 0; }
    #kiiako-builder .list-group-horizontal-md > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      #kiiako-builder .list-group-horizontal-md > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  #kiiako-builder .list-group-horizontal-lg {
    flex-direction: row; }
    #kiiako-builder .list-group-horizontal-lg > #kiiako-builder .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    #kiiako-builder .list-group-horizontal-lg > #kiiako-builder .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    #kiiako-builder .list-group-horizontal-lg > #kiiako-builder .list-group-item.active {
      margin-top: 0; }
    #kiiako-builder .list-group-horizontal-lg > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      #kiiako-builder .list-group-horizontal-lg > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  #kiiako-builder .list-group-horizontal-xl {
    flex-direction: row; }
    #kiiako-builder .list-group-horizontal-xl > #kiiako-builder .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    #kiiako-builder .list-group-horizontal-xl > #kiiako-builder .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    #kiiako-builder .list-group-horizontal-xl > #kiiako-builder .list-group-item.active {
      margin-top: 0; }
    #kiiako-builder .list-group-horizontal-xl > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      #kiiako-builder .list-group-horizontal-xl > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  #kiiako-builder .list-group-horizontal-xxl {
    flex-direction: row; }
    #kiiako-builder .list-group-horizontal-xxl > #kiiako-builder .list-group-item:first-child:not(:last-child) {
      border-bottom-left-radius: var(--bs-list-group-border-radius);
      border-top-right-radius: 0; }
    #kiiako-builder .list-group-horizontal-xxl > #kiiako-builder .list-group-item:last-child:not(:first-child) {
      border-top-right-radius: var(--bs-list-group-border-radius);
      border-bottom-left-radius: 0; }
    #kiiako-builder .list-group-horizontal-xxl > #kiiako-builder .list-group-item.active {
      margin-top: 0; }
    #kiiako-builder .list-group-horizontal-xxl > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      #kiiako-builder .list-group-horizontal-xxl > #kiiako-builder .list-group-item + #kiiako-builder .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

#kiiako-builder .list-group-flush {
  border-radius: 0; }
  #kiiako-builder .list-group-flush > #kiiako-builder .list-group-item {
    border-width: 0 0 var(--bs-list-group-border-width); }
    #kiiako-builder .list-group-flush > #kiiako-builder .list-group-item:last-child {
      border-bottom-width: 0; }

#kiiako-builder .list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

#kiiako-builder .list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

#kiiako-builder .list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

#kiiako-builder .list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

#kiiako-builder .list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

#kiiako-builder .list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

#kiiako-builder .list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

#kiiako-builder .list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width)); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header,
  .modal-fullscreen .modal-footer {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header,
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header,
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header,
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header,
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header,
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
    margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y)); }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after, .drag-elements-sidepane ul > li ol::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(13, 110, 253, var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(25, 135, 84, var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(13, 202, 240, var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(255, 193, 7, var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(220, 53, 69, var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(248, 249, 250, var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(33, 37, 41, var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: 0 0 0 var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: translate3d(0.25em, 0, 0) }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 3rem !important; }

.column-gap-0 {
  column-gap: 0 !important; }

.column-gap-1 {
  column-gap: 0.25rem !important; }

.column-gap-2 {
  column-gap: 0.5rem !important; }

.column-gap-3 {
  column-gap: 1rem !important; }

.column-gap-4 {
  column-gap: 1.5rem !important; }

.column-gap-5 {
  column-gap: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 3rem !important; }
  .column-gap-sm-0 {
    column-gap: 0 !important; }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    column-gap: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 3rem !important; }
  .column-gap-md-0 {
    column-gap: 0 !important; }
  .column-gap-md-1 {
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    column-gap: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 3rem !important; }
  .column-gap-lg-0 {
    column-gap: 0 !important; }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    column-gap: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 3rem !important; }
  .column-gap-xl-0 {
    column-gap: 0 !important; }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    column-gap: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 3rem !important; }
  .column-gap-xxl-0 {
    column-gap: 0 !important; }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    column-gap: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*
Copyright 2017 Ziadin Givan

Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at

   http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.

https://github.com/givanz/VvvebJs
*/
/* CSS Tree menu styles */
.tree {
  width: 100%;
  padding: 0; }
  .tree > ol {
    padding: 0rem 0 0 0rem;
    margin: 0;
    font-size: 12px;
    color: var(--bs-body-color); }
    .tree > ol li {
      position: relative;
      margin-bottom: 0.3rem;
      list-style: none;
      color: var(--bs-body-color);
      overflow: hidden; }
      html[data-bs-theme="dark"] .tree > ol li > label {
        color: #333;
        filter: invert(100%); }
      .tree > ol li:hover , .tree > ol li .active {
        color: #007bff; }
    .tree > ol li.file {
      border: 1px solid transparent; }
      .tree > ol li.file:hover, .tree > ol li.file.active {
        background-color: rgba(13, 110, 253, 0.07); }
      .tree > ol li.file:hover {
        background-color: rgba(13, 110, 253, 0.07); }
        .tree > ol li.file:hover .file-actions {
          display: block; }
      .tree > ol li.file > label {
        background-size: 21px 21px;
        background: url(../icons/file.svg) no-repeat 8px 4px;
        cursor: pointer;
        display: block;
        padding-left: 35px;
        margin: 0;
        font-size: 12px;
        line-height: 28px;
        max-height: 2rem; }
        .tree > ol li.file > label:hover {
          color: #0d6efd; }
      .tree > ol li.file .file-actions {
        display: none;
        position: absolute;
        top: -1px;
        right: 5px; }
        .tree > ol li.file .file-actions .btn {
          padding: 0.25rem 0.3rem;
          line-height: 1;
          border-radius: 4px;
          border-color: rgba(13, 110, 253, 0.15); }
    .tree > ol li.page > label {
      background-image: url(../icons/panel.svg); }
    .tree > ol li input {
      position: absolute;
      left: 0;
      margin-left: 0;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
      height: 2em;
      width: 2em;
      top: 0; }
      .tree > ol li input + ol {
        background: url(../icons/arrow-right.svg) 5px 0.3rem no-repeat;
        background-size: 12px 12px;
        margin: -1.9rem 0 0 0rem;
        padding: 2rem 0 0 2rem;
        height: 0; }
        .tree > ol li input + ol > li {
          display: none;
          margin-left: -14px !important;
          padding-left: 1px; }
    .tree > ol li label {
      background: url(../icons/folder.svg) 24px 1px no-repeat;
      background-size: 24px 24px;
      cursor: pointer;
      display: block;
      padding-left: 56px;
      margin: 0px;
      font-size: 12px;
      line-height: 28px; }
    .tree > ol li input:checked + ol {
      background: url(../icons/arrow-down.svg) 5px 0.1rem no-repeat;
      background-size: 12px 12px;
      margin: -1.8em 0 0 0rem;
      padding: 2rem 0 0 3rem;
      height: auto; }
      .tree > ol li input:checked + ol > li {
        display: block;
        margin: 0 0 0.5em;
        /* 2px */ }
      .tree > ol li input:checked + ol > li:last-child {
        margin: 0 0 0.7em;
        /* 1px */ }

body {
  --builder-filemanager-height: 200px;
  --builder-canvas-margin: 0px;
  --builder-header-top-height:50px;
  --builder-bottom-panel-height:35px;
  --drag-items-tabs-height:40px;
  scrollbar-color: rgba(var(--bs-body-color-rgb), 0.1) var(--bs-body-bg);
  scrollbar-width: thin;
  -webkit-font-smoothing: subpixel-antialiased; }
  @media (min-width: 576px) {
    body {
      --builder-left-panel-width:25vw;
      --builder-right-panel-width:25vw;
      --builder-component-list-element-width:90%; } }
  @media (min-width: 768px) {
    body {
      --builder-left-panel-width:25vw;
      --builder-right-panel-width:25vw;
      --builder-component-list-element-width:90%; } }
  @media (min-width: 992px) {
    body {
      --builder-left-panel-width:250px;
      --builder-right-panel-width:250px;
      --builder-component-list-element-width:44%; } }
  @media (min-width: 1200px) {
    body {
      --builder-left-panel-width:300px;
      --builder-right-panel-width:300px;
      --builder-component-list-element-width:44%; } }
  @media (min-width: 1600px) {
    body {
      --builder-left-panel-width: 350px;
      --builder-right-panel-width: 350px;
      --builder-component-list-element-width:44%; } }
  @media (min-width: 2000px) {
    body {
      --builder-left-panel-width: 400px;
      --builder-right-panel-width: 400px;
      --builder-component-list-element-width:28%; } }
  body ::-webkit-scrollbar {
    width: 0.5rem;
    background-color: rgba(var(--bs-body-color-rgb), 0.05); }
  body ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(var(--bs-body-color-rgb), 0.05);
    background-color: rgba(var(--bs-body-color-rgb), 0.05); }
  body ::-webkit-scrollbar-thumb {
    background-color: rgba(var(--bs-body-color-rgb), 0.15);
    border-radius: 10px; }

.modal-content {
  font-size: .875rem; }

.modal-footer .btn {
  font-size: .875rem; }

#kiiako-builder {
  display: block;
  height: 100%; }
  #kiiako-builder #left-panel, #kiiako-builder #right-panel {
    border-color: var(--bs-border-color);
    border-style: solid;
    border-width: 0px;
    background-color: var(--bs-body-bg);
    height: 100%;
    position: fixed;
    top: 50px;
    z-index: 10; }
  #kiiako-builder #left-panel {
    float: left;
    border-right-width: 1px;
    /*
		box-shadow: 1px 0px 2px rgba(var(--bs-body-color-rgb),.1);
		-moz-box-shadow: 1px 0px 2px rgba(var(--bs-body-color-rgb),.1);
		-webkit-box-shadow: 1px 0px 2px rgba(var(--bs-body-color-rgb),.1);
*/
    /*
		background:-moz-linear-gradient(left, var(--bs-body-bg) 85%, #fafbfc 100%);
		background:-webkit-linear-gradient(left, var(--bs-body-bg) 85%, #fafbfc 100%);
		background:linear-gradient(left, var(--bs-body-bg) 85%, #fafbfc 100%);
		box-shadow: -1px -2px 2px #f3f3f3 inset;*/
    width: 15vw;
    width: calc(var(--builder-left-panel-width));
    height: 100%;
    max-height: 100%; }
    #kiiako-builder #filemanager {
      width: 30vw;
      width: calc( var(--builder-left-panel-width));
      border-bottom: 1px solid var(--bs-border-color); }
      #kiiako-builder #filemanager .header {
        font-size: 11px;
        font-weight: 600;
        height: 2rem;
        margin: 0;
        padding: 0;
        width: 100%;
        line-height: 2rem;
        text-align: left;
        padding-left: 1rem;
        border-bottom: 1px solid var(--bs-border-color);
        margin-bottom: 0rem;
        padding: 0.0rem 0.5rem;
        padding: 0rem 0rem 0.5rem 1rem; }
        #kiiako-builder #filemanager .header a {
          color: var(--bs-link-color); }
      #kiiako-builder #filemanager .tree {
        height: 120px;
        height: calc( var(--builder-filemanager-height) - 50px);
        padding: 0.5rem;
        overflow: hidden;
        resize: vertical; }
        #kiiako-builder #filemanager .tree:hover {
          overflow-y: auto; }
    #kiiako-builder #left-panel .components-list, #kiiako-builder #left-panel .blocks-list, #kiiako-builder #left-panel .component-properties, #kiiako-builder #left-panel .sections-list {
      width: var(--builder-left-panel-width); }
    #kiiako-builder #left-panel .component-properties {
      width: auto; }
  #kiiako-builder #right-panel {
    float: right;
    right: 0px;
    overflow: hidden;
    border-left-width: 1px;
    float: right;
    transition: margin-right 0.1s linear;
    -moz-transition: margin-right 0.1s linear;
    -webkit-transition: margin-right 0.1s linear;
    background: -moz-linear-gradient(right, var(--bs-body-bg) 85%, #fafbfc 100%);
    background: -webkit-linear-gradient(right, var(--bs-body-bg) 85%, #fafbfc 100%);
    background: linear-gradient(right, var(--bs-body-bg) 85%, #fafbfc 100%);
    box-shadow: 1px -2px 2px #f3f3f3 inset;
    width: 15vw;
    width: var(--builder-right-panel-width); }
    #kiiako-builder #right-panel .components-list, #kiiako-builder #right-panel .blocks-list, #kiiako-builder #right-panel .component-properties, #kiiako-builder #right-panel .sections-list {
      width: var(--builder-right-panel-width); }
    #kiiako-builder #right-panel label.header .header-arrow {
      right: 10px; }
    #kiiako-builder #right-panel .nav-link.active {
      border-top: 1px solid rgba(var(--bs-link-color-rgb), 0.7); }
  #kiiako-builder #top-panel {
    height: 50px;
    padding: 0.2rem 0;
    border-bottom: 1px solid var(--bs-border-color);
    text-align: center;
    background-color: #fff;
    color: var(--bs-body-text-align);
    overflow: hidden;
    width: 100%; }
    #kiiako-builder #top-panel .btn-group .btn.btn-light {
      background-color: #fff;
      border: none !important;
      color: var(--bs-body-color);
      font-size: 1.25rem;
      padding: 0.25rem 0.5rem; }
    #kiiako-builder #top-panel .btn-group .btn.btn-primary {
      margin-top: 0.2rem; }
      #kiiako-builder #top-panel .btn-group .btn.btn-primary i {
        font-size: 21px;
        line-height: 21px; }
    #kiiako-builder #top-panel .btn-group .btn span {
      font-size: 14px; }
    #kiiako-builder #top-panel .btn-group.responsive-btns .btn.btn-light {
      font-size: 1.6rem;
      padding: 0rem 0.5rem; }
  #kiiako-builder #bottom-panel {
    width: auto;
    height: 35px;
    bottom: 0px;
    position: fixed;
    border-top: 1px solid var(--bs-border-color);
    left: calc( var(--builder-left-panel-width) + var(--builder-canvas-margin));
    right: var(--builder-right-panel-width);
    background-color: var(--bs-body-bg); }
    #kiiako-builder #bottom-panel > div {
      position: relative; }
      #kiiako-builder #bottom-panel > div .btn-group {
        position: absolute;
        right: 0;
        top: 0;
        background: var(--bs-body-bg); }
    #kiiako-builder #bottom-panel #vvveb-code-editor {
      width: 100%;
      height: 100%;
      clear: both; }
      #kiiako-builder #bottom-panel #vvveb-code-editor textarea {
        height: 100%;
        width: 100%;
        border: none;
        font-size: 14px;
        margin-bottom: 10px;
        background: rgba(var(--bs-secondary-color-rgb), 0.03); }
  #kiiako-builder.bottom-panel-expand #bottom-panel {
    height: 50%; }
  #kiiako-builder.bottom-panel-expand #canvas {
    height: 50%; }
  #kiiako-builder .drag-elements {
    padding-top: 0rem; }
    #kiiako-builder .drag-elements > .header .nav {
      background: rgba(var(--bs-secondary-color-rgb), 0.03); }
    #kiiako-builder .drag-elements .nav-item .nav-link {
      text-align: center;
      border-top-color: var(--bs-body-bg);
      border-radius: 0px;
      min-width: 4.5rem;
      color: var(--bs-body-color);
      line-height: 1.2rem; }
      #kiiako-builder .drag-elements .nav-item .nav-link div {
        margin-top: .2rem; }
      #kiiako-builder .drag-elements .nav-item .nav-link i.la-lg {
        font-size: 1.4rem;
        margin: 0.2rem 0; }
      #kiiako-builder .drag-elements .nav-item .nav-link small, #kiiako-builder .drag-elements .nav-item .nav-link .small {
        font-size: 0.8rem;
        display: none; }
      #kiiako-builder .drag-elements .nav-item .nav-link.active, #kiiako-builder .drag-elements .nav-item .nav-link:hover {
        color: var(--bs-link-color); }
    #kiiako-builder .drag-elements#add-section-box .nav-item:first-child .nav-link {
      border-left: none; }
  #kiiako-builder .search {
    position: relative; }
    #kiiako-builder .search .form-control {
      border-color: var(--bs-body-bg);
      border-bottom-color: var(--bs-border-color);
      border-radius: 0px;
      box-shadow: none; }
    #kiiako-builder .search .form-control:focus {
      border-color: #80bdff; }
    #kiiako-builder .search .form-control::placeholder {
      /*font-size: $font-size-sm;*/
      font-size: .75rem; }
    #kiiako-builder .search .clear-backspace {
      border: none;
      background: none;
      position: absolute;
      top: 0.5rem;
      right: 12px;
      opacity: 0.5; }
      #kiiako-builder .search .clear-backspace i {
        font-size: 13px; }
      #kiiako-builder .search .clear-backspace:hover {
        opacity: 1;
        background: var(--bs-border-color); }
    #kiiako-builder .search input:focus + .clear-backspace,
    #kiiako-builder .search input:hover + .clear-backspace {
      opacity: 1;
      background: var(--bs-border-color); }
    #kiiako-builder .search .expand {
      position: absolute;
      top: 4px;
      right: 3px; }
      #kiiako-builder .search .expand button {
        padding: 0.2rem;
        background: transparent;
        border: none;
        color: var(--bs-body-color); }
        #kiiako-builder .search .expand button:hover {
          background: var(--bs-border-color); }
    #kiiako-builder .search .expand ~ .clear-backspace {
      right: 56px; }
  #kiiako-builder .components-list, #kiiako-builder .blocks-list, #kiiako-builder .component-properties, #kiiako-builder .sections-list {
    list-style: none;
    }
  #kiiako-builder #add-section-box {
    animation: popup-animation 0.1s cubic-bezier(0, 0, 0.2, 1) 0s;
    animation-fill-mode: forwards; }
    #kiiako-builder #add-section-box .header > div.section-box-actions {
      position: absolute;
      top: 0.5rem;
      right: 1rem; }
    #kiiako-builder #add-section-box .components-list, #kiiako-builder #add-section-box .blocks-list, #kiiako-builder #add-section-box .component-properties, #kiiako-builder #add-section-box .sections-list {
      width: auto;
      height: auto;
      padding: 0px;
      height: 500px;
      margin: 0; }
      #kiiako-builder #add-section-box .components-list ol, #kiiako-builder #add-section-box .blocks-list ol, #kiiako-builder #add-section-box .component-properties ol, #kiiako-builder #add-section-box .sections-list ol {
        padding: 0rem 0rem 0rem 0rem;
        list-style: none; }
        #kiiako-builder #add-section-box .components-list ol li, #kiiako-builder #add-section-box .blocks-list ol li, #kiiako-builder #add-section-box .component-properties ol li, #kiiako-builder #add-section-box .sections-list ol li {
          width: 14%;
          min-width: 90px;
          float: left;
          margin: 0% 1% 0% 1%;
        }
          #kiiako-builder #add-section-box .components-list ol li a, #kiiako-builder #add-section-box .blocks-list ol li a, #kiiako-builder #add-section-box .component-properties ol li a, #kiiako-builder #add-section-box .sections-list ol li a {
            display: block;
            color: var(--bs-gray-900);
            text-decoration: none;
            text-shadow: none;
            margin-top: 5px; }
    #kiiako-builder #add-section-box .blocks-list ol, #kiiako-builder #add-section-box .sections-list ol {
      list-style: none; }
      #kiiako-builder #add-section-box .blocks-list ol li, #kiiako-builder #add-section-box .sections-list ol li {
        width: 92%;
        height: auto;
        min-height: 100px;
        position: relative;
        text-align: center;
        font-weight: normal;
        font-size: 11px;
        color: #000;
        background-repeat: no-repeat;
        padding: 0px;
        margin: 1rem auto;
        display: block;
        border-color: var(--bs-border-color);
        border-style: solid;
        border-width: 1px;
        border-radius: 0px;
        background-position: center;
        box-shadow: 0px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.15);
        -webkit-box-shadow: 0px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.15);
        /*
		border-left-width:1px;
		border-top-width:1px;
		*/
        background-size: 100%;
        z-index: 100;
        background-color: var(--bs-body-bg);
        cursor: grab;
        opacity: 1;
        width: 29%;
        margin-right: 1.5rem; }
        #kiiako-builder #add-section-box .blocks-list ol li .name, #kiiako-builder #add-section-box .sections-list ol li .name {
          background: rgba(var(--bs-body-bg-rgb), 0.9);
          background: rgba(var(--bs-body-color-rgb), 0.7);
          background: rgba(var(--bs-link-color-rgb), 0.7);
          background: rgba(var(--bs-body-bg-rgb), 0.9);
          color: var(--bs-body-color);
          padding: 0.5rem 0;
          position: absolute;
          bottom: 0px;
          width: 100%;
          left: 0;
          font-size: 12px;
          border-top: 1px solid rgba(var(--bs-body-color-rgb), 0.1); }
        #kiiako-builder #add-section-box .blocks-list ol li:hover, #kiiako-builder #add-section-box .sections-list ol li:hover {
          border-color: var(--bs-link-color);
          box-shadow: 0px 1px 5px 2px rgba(var(--bs-link-color-rgb), 0.25);
          -webkit-box-shadow: 0px 1px 5px 2px rgba(var(--bs-link-color-rgb), 0.25); }
          #kiiako-builder #add-section-box .blocks-list ol li:hover:before, #kiiako-builder #add-section-box .sections-list ol li:hover:before {
            opacity: 0.15; }
          #kiiako-builder #add-section-box .blocks-list ol li:hover .name, #kiiako-builder #add-section-box .sections-list ol li:hover .name {
            visibility: visible; }
  #kiiako-builder .component-properties-sidepane {
    z-index: 0;
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: auto;
    width: 100%;
    overflow-y: auto; }
    #kiiako-builder .component-properties-sidepane > div {
      position: absolute;
      min-height: 950px;
      height: 200%;
      width: 100%; }
  #kiiako-builder .component-properties {
    background: var(--bs-body-bg);
    height: 95%;
    height: calc(100% - 50px);
    /*
		.form-control, .form-select
		{
			padding:.275rem 0.25rem;
		}*/ }
    #kiiako-builder .component-properties .tab-content {
      padding: 0.5rem 0;
      overflow-x: hidden;
      overflow-y: auto; }
    #kiiako-builder .component-properties .nav-tabs {
      background: rgba(var(--bs-secondary-color-rgb), 0.03); }
    #kiiako-builder .component-properties #right-panel {
      color: #777; }
    #kiiako-builder .component-properties, #kiiako-builder .component-properties select, #kiiako-builder .component-properties input {
      font-size: 12px; }
    #kiiako-builder .component-properties label {
      margin-top: 4px; }
    #kiiako-builder .component-properties .form-select {
      height: auto; }
    #kiiako-builder .component-properties .mb-3.row {
      margin: 0; }
    #kiiako-builder .component-properties .mb-3 > label {
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 5px;
      color: var(--bs-body-color);
      flex-grow: 1; }
      #kiiako-builder .component-properties .mb-3 > label i {
        font-size: 14px; }
    #kiiako-builder .component-properties .mb-3 .input .input-range {
      position: relative;
      padding-top: 0.4rem; }
      #kiiako-builder .component-properties .mb-3 .input .input-range input[type="number"] {
        position: absolute;
        right: 0;
        top: -0.7rem;
        width: 5em;
        padding: 0.1rem 0.2rem;
        text-align: center;
        line-height: 12px; }
    #kiiako-builder .component-properties .mb-3 .input [type="color"] {
      float: right; }
    #kiiako-builder .component-properties .mb-3 .custom-control {
      min-height: 1.1rem; }
    #kiiako-builder .component-properties .mb-3.inline {
      display: flex; }
      #kiiako-builder .component-properties .mb-3.inline .control-label {
        flex-grow: 1; }
      #kiiako-builder .component-properties .mb-3.inline .input {
        flex: 1 1 auto;
        width: auto; }
  #kiiako-builder #canvas {
    margin-right: 15vw;
    margin-left: 15vw;
    margin-right: var(--builder-right-panel-width);
    margin-left: var(--builder-left-panel-width);
    width: 100%;
    height: 100%;
    width: calc( 100vw - 600px);
    height: calc( 100vh - 50px);
    top: 50px;
    bottom: 0;
    width: calc( 100vw - (var(--builder-left-panel-width) + var(--builder-right-panel-width) + var(--builder-canvas-margin)));
    top: var(--builder-header-top-height);
    position: fixed;
    transition: width 0.35s, left 0.35s; }
    #kiiako-builder #canvas #iframe-wrapper, #kiiako-builder #canvas iframe, #kiiako-builder #canvas iframe body {
      width: 100%;
      height: 100%;
      border: none; }
    #kiiako-builder #canvas.tablet {
      width: 768px !important;
      left: calc(50% - ((768px / 2) + 25vw));
    }
    #kiiako-builder #canvas.mobile {
      width: 320px !important;
      left: calc(50% - ((320px / 2) + var(--builder-left-panel-width))); }
  #kiiako-builder.preview #canvas {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px; }
  #kiiako-builder.preview #left-panel, #kiiako-builder.preview #right-panel {
    display: none; }
  #kiiako-builder.no-right-panel {
    --builder-right-panel-width:0px; }
    #kiiako-builder.no-right-panel #right-panel {
      display: none; }
  #kiiako-builder #iframe-layer {
    overflow: hidden;
    pointer-events: none;
    white-space: nowrap; }
    #kiiako-builder #iframe-layer .loading-message {
      width: 100%;
      height: 100%;
      position: absolute;
      display: none; }
      #kiiako-builder #iframe-layer .loading-message.active {
        display: block; }
  #kiiako-builder #highlight-box {
    position: absolute;
    border: 1px solid var(--bs-primary);
    color: var(--bs-white);
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
    display: none; }
  #kiiako-builder #drop-highlight-box {
    position: absolute;
    border: 2px solid var(--bs-primary);
    color: var(--bs-white);
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
    transition: all 0.3s;
    border-radius: 4px;
    display: none; }
  #kiiako-builder .text-edit#select-box {
    border-style: dashed;
    /*border-width:1px;*/
    border: 1px solid rgba(var(--bs-body-color-rgb), 0.2);
    box-shadow: 1px 1px 3px 0px rgba(var(--bs-body-color-rgb), 0.1) inset;
    background: transparent;
    border-radius: 0 0 3px 3px; }
  #kiiako-builder #select-box {
    position: absolute;
    border: 1px solid var(--bs-primary);
    color: var(--bs-white);
    background: rgba(var(--bs-primary), 0.1);
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
    display: none; }
    #kiiako-builder #select-box.resizable .resize {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 10; }
      #kiiako-builder #select-box.resizable .resize > div {
        position: absolute;
        pointer-events: all;
        border: 2px solid var(--bs-primary);
        width: 10px;
        height: 10px;
        background-color: rgba(var(--bs-primary), 0.1);
        margin: -5px; }
      #kiiako-builder #select-box.resizable .resize .top-left {
        top: 0;
        left: 0;
        cursor: nwse-resize; }
      #kiiako-builder #select-box.resizable .resize .top-center {
        top: 0;
        left: 0;
        right: 0;
        margin: -5px auto;
        cursor: ns-resize; }
      #kiiako-builder #select-box.resizable .resize .top-right {
        top: 0;
        right: 0;
        cursor: nesw-resize; }
      #kiiako-builder #select-box.resizable .resize .center-left {
        left: 0;
        margin: auto -5px;
        top: 0;
        bottom: 0;
        cursor: ew-resize; }
      #kiiako-builder #select-box.resizable .resize .center-right {
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto -5px;
        cursor: ew-resize; }
      #kiiako-builder #select-box.resizable .resize .bottom-left {
        bottom: 0;
        left: 0;
        cursor: nesw-resize; }
      #kiiako-builder #select-box.resizable .resize .bottom-center {
        bottom: 0;
        left: 0;
        right: 0;
        margin: -5px auto;
        cursor: ns-resize; }
      #kiiako-builder #select-box.resizable .resize .bottom-right {
        bottom: 0;
        right: 0;
        cursor: nwse-resize; }
  #kiiako-builder #select-actions, #kiiako-builder #wysiwyg-editor {
    position: absolute;
    z-index: 1;
    right: -1px;
    top: -25px;
    background: var(--bs-primary);
    color: var(--bs-body-bg);
    padding: 0px 0px;
    border-radius: 3px 3px 0px 0px; }
  #kiiako-builder #select-actions a, #kiiako-builder #wysiwyg-editor.default-editor a, #kiiako-builder #section-actions span#add-section-btn {
    pointer-events: auto;
    color: var(--bs-white);
    text-decoration: none;
    font-size: 16px;
    cursor : pointer;
    padding-right: 2px;
    padding: 2px 5px; }
  #kiiako-builder #wysiwyg-editor {
    top: auto;
    bottom: 100%;
    height: 44px;
    right: auto;
    left: -1px;
    display: none;
    color: var(--bs-body-color);
    background: var(--bs-body-bg);
    border: 1px solid rgba(var(--bs-body-color-rgb), 0.07);
    box-shadow: 0 0 2px 0 rgba(var(--bs-body-color-rgb), 0.07), 0 4px 8px 0 rgba(var(--bs-body-color-rgb), 0.05);
    pointer-events: auto; }
    #kiiako-builder #wysiwyg-editor.default-editor a {
      display: inline-block;
      padding: 8px;
      line-height: 1;
      color: var(--bs-body-color);
      font-size: 18px; }
      #kiiako-builder #wysiwyg-editor.default-editor a:last-child {
        border-right: none; }
      #kiiako-builder #wysiwyg-editor.default-editor a:hover {
        background: var(--bs-border-color); }
    #kiiako-builder #wysiwyg-editor.default-editor div.separator {
      display: inline-block;
      background: var(--bs-border-color);
      width: 1px;
      height: 18px;
      vertical-align: text-bottom;
      top: 0;
      padding: 0;
      margin: 0; }
    #kiiako-builder #wysiwyg-editor.default-editor > div.dropdown, #kiiako-builder #wysiwyg-editor.default-editor > select, #kiiako-builder #wysiwyg-editor.default-editor > input {
      display: inline-block;
      width: auto;
      vertical-align: top;
      border: none;
      pointer-events: all;
      line-height: 2.2rem;
      height: 34px;
      padding: 0;
      box-shadow: none; }
      #kiiako-builder #wysiwyg-editor.default-editor > div.dropdown[type="color"], #kiiako-builder #wysiwyg-editor.default-editor > select[type="color"], #kiiako-builder #wysiwyg-editor.default-editor > input[type="color"] {
        padding: 4px;
        height: 30px;
        width: 32px;
        box-shadow: none;
        border: 1px dashed var(--bs-border-color);
        margin: 0;
        margin: 3px;
        border-radius: 3px; }
        #kiiako-builder #wysiwyg-editor.default-editor > div.dropdown[type="color"].form-control-color::-moz-color-swatch, #kiiako-builder #wysiwyg-editor.default-editor > select[type="color"].form-control-color::-moz-color-swatch, #kiiako-builder #wysiwyg-editor.default-editor > input[type="color"].form-control-color::-moz-color-swatch {
          border-radius: 3px;
          box-shadow: 1px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.25);
          border: none; }
        #kiiako-builder #wysiwyg-editor.default-editor > div.dropdown[type="color"].form-control-color::-webkit-color-swatch, #kiiako-builder #wysiwyg-editor.default-editor > select[type="color"].form-control-color::-webkit-color-swatch, #kiiako-builder #wysiwyg-editor.default-editor > input[type="color"].form-control-color::-webkit-color-swatch {
          padding: 0;
          border-radius: 3px;
          box-shadow: 1px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.25);
          border: none; }
    #kiiako-builder #wysiwyg-editor.default-editor select {
      padding: 0rem 1.8rem 0 1rem;
      margin-top: 2px; }
    #kiiako-builder #wysiwyg-editor.default-editor div.dropdown a:after {
      vertical-align: middle !important;
      font-size: 14px;
      margin-bottom: 0.2rem; }
    #kiiako-builder #wysiwyg-editor.default-editor div.dropdown a.dropdown-item {
      display: block;
      font-size: 1rem; }
      #kiiako-builder #wysiwyg-editor.default-editor div.dropdown a.dropdown-item i {
        margin-right: 0; }
  #kiiako-builder #section-actions {
    bottom: 0px;
    position: absolute;
    border-radius: 3px 3px 0px 0px;
    bottom: -10px;
    left: 50%;
    left: calc(50% - 12px); }
    #kiiako-builder #section-actions.outside {
      bottom: -30px; }
    #kiiako-builder #section-actions span#add-section-btn {
      position: relative;
      bottom: 0px;
      background: var(--bs-primary);
      color: var(--bs-white);
      font-size: 18px;
      border-radius: 24px;
      width: 32px;
      height: 32px;
      padding: 3px 7px;
      cursor: pointer;
      display: block;
      box-shadow: 0px 0px 1px 2px rgba(18, 83, 205, 0.05);
      box-shadow: 0px 3px 7px 1px rgba(var(--bs-body-color-rgb), 0.1), 1px 2px 7px 1px rgba(255, 255, 255, 0.1) inset; }
      #kiiako-builder #section-actions span#add-section-btn i {
        font-weight: 600; }
      #kiiako-builder #section-actions span#add-section-btn:hover {
        text-decoration: none;
        filter: brightness(1.2); }
  #kiiako-builder #add-section-box {
    width: 50%;
    min-height: 300px;
    position: absolute;
    background: var(--bs-body-bg);
    top: 100px;
    left: 100px;
    box-shadow: 0 8px 24px rgba(var(--bs-body-color-rgb), 0.07);
    border: 1px solid var(--bs-border-color);
    min-width: 500px;
    pointer-events: auto;
    display: none;
    z-index: 10;
    background: var(--bs-body-bg); }
  #kiiako-builder #highlight-name {
    background: var(--bs-primary);
    color: var(--bs-white);
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    width: auto;
    padding: 2px 5px;
    display: inline-block;
    border-radius: 3px 3px 0px 0px;
  }
    #kiiako-builder #highlight-name .type {
      font-size: 10px;
      padding-left: 10px;
      text-transform: capitalize !important;
      opacity: 0.7; }


  #kiiako-builder #elements-tabs {
    overflow: hidden; }
    #kiiako-builder #elements-tabs .nav-item {
      background: var(--bs-body-bg); }
    #kiiako-builder #elements-tabs a {
      font-size: 1.4rem;
      outline: none;
      border: none;
      padding: 0.3rem 0.2rem 0.4rem; }
      #kiiako-builder #elements-tabs a i {
        padding: 0.4rem 0rem;
        font-size: 1.4rem;
        border-radius: 3px;
        display: block;
        border: 1px solid transparent; }
      #kiiako-builder #elements-tabs a:hover i {
        border: 1px solid rgba(var(--bs-link-color-rgb), 0.2); }
      #kiiako-builder #elements-tabs a.active {
        border-top: none;
        border-left: none;
        border-right: none;
        border-top-color: rgba(var(--bs-link-color-rgb), 0.7);
        color: var(--bs-link-color);
        box-shadow: none; }
        #kiiako-builder #elements-tabs a.active i {
          background: rgba(var(--bs-link-color-rgb), 0.05);
          border: 1px solid rgba(var(--bs-link-color-rgb), 0.2);
          color: var(--bs-link-color); }
  #kiiako-builder .nav-tabs .nav-item:first-child .nav-link {
    border-left: none; }
  #kiiako-builder .nav-tabs .nav-link {
    padding: 0.8rem 0 0.5rem;
    padding: 0.8rem 0 0.8rem;
    text-align: center; }
    #kiiako-builder .nav-tabs .nav-link i {
      font-size: 1.6rem;
      line-height: 1.2rem; }
  #kiiako-builder .nav-tabs .nav-link.active,
  #kiiako-builder .nav-tabs .nav-item.show .nav-link {
    background-color: var(--bs-body-bg);
    border-radius: 0px;
    background: linear-gradient(0deg, var(--bs-body-bg) 70%, rgba(var(--bs-link-color-rgb), 0.03) 100%);
    box-shadow: 0px -5px 5px 5px rgba(var(--bs-link-color-rgb), 0.05);
    border-top: 1px solid rgba(var(--bs-link-color-rgb), 0.7); }
  #kiiako-builder .nav-fill .nav-link {
    padding-left: 0rem;
    padding-right: 0rem; }
  #kiiako-builder .sections-tabs .nav-link i, #kiiako-builder .sections-tabs .nav-link div {
    display: inline-block;
    vertical-align: bottom; }
  #kiiako-builder .sections-tabs .nav-link i {
    margin-right: 0.3rem; }

/* style for drag element */
li[data-type] {
  width: 32%;
  min-width: 100px;
  height: 150px;
  margin: 0% 1% 1% 1%;
  text-align: center;
  font-weight: normal;
  font-size: 11px;
  color: #000;
  background-repeat: no-repeat;
  padding-top: 30px;
  padding-bottom: 7px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  /*
		border-left-width:1px;
		border-top-width:1px;
		*/
  background-color: var(--bs-white);
  border-color: var(--bs-border-color);
  border-color: var(--bs-gray-300);
  background-position: 50% 30%;
  background-size: auto 42px;
  z-index: 100;
  cursor: move;
  cursor: grab;
  box-shadow: 0px 1px 4px 0px rgba(var(--bs-body-color-rgb), 0.05);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(var(--bs-body-color-rgb), 0.05);
  /*border-width:1px;*/ }
  li[data-type] a {
    min-height: 20px;
    white-space: break-spaces;
    padding: 0 3px; }
  html[data-bs-theme="dark"] li[data-type][data-drag-type="component"] {
    filter: invert(100%);
    border-color: var(--bs-gray-500);
    background-color: transparent; }
    html[data-bs-theme="dark"] li[data-type][data-drag-type="component"] a {
      color: var(--bs-gray-900); }

.drag-elements-sidepane {
  z-index: 0;
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  overflow: hidden;
  width: 100%; }
  .drag-elements-sidepane > div {
    overflow: hidden; }
    .drag-elements-sidepane > div:hover {
      overflow-y: auto; }
  .drag-elements-sidepane ul {
    z-index: 1;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    text-align: center; }
    .drag-elements-sidepane ul > li {
      float: none;
      clear: both; }
      .drag-elements-sidepane ul > li.header {
        height: auto;
        margin: 0;
        padding: 0;
        border-bottom: 0.01rem solid var(--bs-border-color);
        background-color : var(--bs-body-bg);
        width: 100%;
        position: relative; }
        .drag-elements-sidepane ul > li.header label {
          font-size: 11px;
          font-weight: 600;
          line-height: 28px;
          text-align: left;
          padding: 0.3rem 1rem;
          padding: 0.5rem 1.8rem; }
          .drag-elements-sidepane ul > li.header label > a {
            padding-left: 1rem;
            color: var(--bs-body-text-align) }
        .drag-elements-sidepane ul > li.header:first-child {
          margin-top: 0rem;
          border-top: none; }
      .drag-elements-sidepane ul > li ol {
        margin: 0px;
        padding: 0rem;
        padding-bottom: 0rem;
        list-style: none; }
        .drag-elements-sidepane ul > li ol li {
          float: left; }
          .drag-elements-sidepane ul > li ol li a {
            color: var(--bs-body-color);
            text-decoration: none;
            text-shadow: none; }
          .drag-elements-sidepane ul > li ol li[data-type] {
            border: none; }
            .drag-elements-sidepane ul > li ol li[data-type]:hover {
              cursor: pointer;
              background-color: var(--bs-body-bg);
              opacity: 1;
              text-align: center; }
              .drag-elements-sidepane ul > li ol li[data-type]:hover .add-section-btn {
                visibility: visible; }

.sections-container {
  width: 100%;
  background: var(--bs-body-bg);
  padding-top: 1rem; }
  .sections-container > div.section-item {
    box-shadow: 0px 0px 1px 0px var(--bs-primary);
    box-shadow: 0px 0px 1px 0px rgba(18, 83, 205, 0.7), 0px 0px 5px 0px rgba(18, 83, 205, 0.1);
    box-shadow: 0px 0px 1px 2px rgba(18, 83, 205, 0.05);
    border: 1px solid var(--bs-border-color);
    background: var(--bs-body-bg);
    margin: 0rem 0rem 1rem;
    padding: 0.3rem 0.5rem 0.3rem 1.4rem;
    position: relative;
    border-radius: 3px; }
    .sections-container > div.section-item .controls {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
      .sections-container > div.section-item .controls .handle {
        width: 20px;
        height: 10px;
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        cursor: move; }
        .sections-container > div.section-item .controls .handle::after {
          content: "...";
          color: #262626;
          width: 5px;
          font-size: 28px;
          word-wrap: break-word;
          line-height: 7px;
          vertical-align: middle;
          display: inline-block;
          margin-left: 5px;
          margin-top: 5px; }
      .sections-container > div.section-item .controls .name {
        text-transform: capitalize;
        font-weight: 500; }
      .sections-container > div.section-item .controls .type {
        color: #777; }
      .sections-container > div.section-item .controls .info {
        margin-left: 0.2rem; }
        .sections-container > div.section-item .controls .info .name {
          font-size: 13px;
          padding : 5px 0 5px 0;
        }
        .sections-container > div.section-item .controls .info .type {
          font-size: 13px; text-align: right !important; }
      .sections-container > div.section-item .controls .buttons {
        margin-right: 2rem;
        margin-top: 0.2rem; }
        .sections-container > div.section-item .controls .buttons a {
          padding: 0.3rem 0.5rem 0.3rem;
          display: inline-block; }
          .sections-container > div.section-item .controls .buttons a:hover {
            background-color: rgba(18, 83, 205, 0.1);
            text-decoration: none; }
        .sections-container > div.section-item .controls .buttons .delete-btn,
        .sections-container > div.section-item .controls .buttons .up-btn,
        .sections-container > div.section-item .controls .buttons .down-btn {
          visibility: hidden; }
        .sections-container > div.section-item .controls .buttons:hover .delete-btn,
        .sections-container > div.section-item .controls .buttons:hover .up-btn,
        .sections-container > div.section-item .controls .buttons:hover .down-btn {
          visibility: visible; }
    .sections-container > div.section-item.drag-over {
      border: 2px dashed var(--bs-link-color);
      border-radius: 5px; }
    .sections-container > div.section-item .header_check ~ label {
      color: var(--bs-link-color);
      display: block; }
    .sections-container > div.section-item .header_check ~ label .header-arrow {
      right: 0.5rem;
      top: 0.7rem;
      padding: 0.3rem 0.5rem; }
      .sections-container > div.section-item .header_check ~ label .header-arrow:hover {
        background: rgba(18, 83, 205, 0.1); }
    .sections-container > div.section-item .header_check ~ label .header-arrow:before {
      content: "\f105"; }
    .sections-container > div.section-item .header_check:checked ~ label .header-arrow:before {
      content: "\f107"; }
    .sections-container > div.section-item .header_check:checked ~ .tree {
      display: block; }
    .sections-container > div.section-item .tree {
      display: none;
      border-top: 1px solid var(--bs-border-color);
      padding: 0.5rem 0rem 0rem;
      margin: 0.5rem 0rem 0rem; }

.blocks .block-preview, .sections .block-preview {
  position: absolute;
  left: 100%;
  height: auto;
  z-index: 1000;
  border: 1px solid var(--bs-border-color);
  box-shadow: 0px 0px 1px 2px rgba(18, 83, 205, 0.05); }

.blocks .drag-elements-sidepane li[data-type], .sections .drag-elements-sidepane li[data-type] {
  width: 92%;
  height: auto;
  min-height: 100px;
  position: relative;
  text-align: center;
  font-weight: normal;
  font-size: 11px;
  color: #000;
  background-repeat: no-repeat;
  padding: 0px;
  margin: 1rem auto;
  display: block;
  border-color: var(--bs-border-color);
  border-style: solid;
  border-width: 1px;
  border-radius: 0px;
  background-position: center;
  box-shadow: 0px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.15);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.15);
  /*
		border-left-width:1px;
		border-top-width:1px;
		*/
  background-size: 100%;
  z-index: 100;
  background-color: var(--bs-body-bg);
  cursor: grab;
  opacity: 1;
  margin: 1rem; }
  .blocks .drag-elements-sidepane li[data-type] .name, .sections .drag-elements-sidepane li[data-type] .name {
    background: rgba(var(--bs-body-bg-rgb), 0.9);
    background: rgba(var(--bs-body-color-rgb), 0.7);
    background: rgba(var(--bs-link-color-rgb), 0.7);
    background: rgba(var(--bs-body-bg-rgb), 0.9);
    color: var(--bs-body-color);
    padding: 0.5rem 0;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    font-size: 12px;
    border-top: 1px solid rgba(var(--bs-body-color-rgb), 0.1); }
  .blocks .drag-elements-sidepane li[data-type]:hover, .sections .drag-elements-sidepane li[data-type]:hover {
    border-color: var(--bs-link-color);
    box-shadow: 0px 1px 5px 2px rgba(var(--bs-link-color-rgb), 0.25);
    -webkit-box-shadow: 0px 1px 5px 2px rgba(var(--bs-link-color-rgb), 0.25); }
    .blocks .drag-elements-sidepane li[data-type]:hover:before, .sections .drag-elements-sidepane li[data-type]:hover:before {
      opacity: 0.15; }
    .blocks .drag-elements-sidepane li[data-type]:hover .name, .sections .drag-elements-sidepane li[data-type]:hover .name {
      visibility: visible; }
  .blocks .drag-elements-sidepane li[data-type]:before, .sections .drag-elements-sidepane li[data-type]:before {
    content: " ";
    background: var(--bs-body-bg);
    background: var(--bs-primary);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.5s; }
  .blocks .drag-elements-sidepane li[data-type] img.preview, .sections .drag-elements-sidepane li[data-type] img.preview {
    max-width: 100%; }
  .blocks .drag-elements-sidepane li[data-type] .name, .sections .drag-elements-sidepane li[data-type] .name {
    visibility: hidden; }

.components .drag-elements-sidepane li[data-type],
#add-section-box li[data-type] {
  width: var(--builder-component-list-element-width);
  min-width: 100px;
  height: 100px;
  margin: 0% 1% 3% 3%;
  text-align: center;
  font-weight: normal;
  font-size: 11px;
  color: #000;
  background-repeat: no-repeat;
  padding-top: 60px;
  padding-bottom: 7px;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  /*
		border-left-width:1px;
		border-top-width:1px;
		*/
  background-color: var(--bs-white);
  border-color: var(--bs-border-color);
  border-color: var(--bs-gray-300);
  background-position: 50% 30%;
  background-size: auto 42px;
  z-index: 100;
  cursor: move;
  cursor: grab;
  box-shadow: 0px 1px 4px 0px rgba(var(--bs-body-color-rgb), 0.05);
  -webkit-box-shadow: 0px 1px 4px 0px rgba(var(--bs-body-color-rgb), 0.05); }
  .components .drag-elements-sidepane li[data-type] a,
  #add-section-box li[data-type] a {
    min-height: 20px;
    white-space: break-spaces;
    padding: 0 3px; }
  html[data-bs-theme="dark"] .components .drag-elements-sidepane li[data-type][data-drag-type="component"], html[data-bs-theme="dark"]
  #add-section-box li[data-type][data-drag-type="component"] {
    filter: invert(100%);
    border-color: var(--bs-gray-500);
    background-color: transparent; }
    html[data-bs-theme="dark"] .components .drag-elements-sidepane li[data-type][data-drag-type="component"] a, html[data-bs-theme="dark"]
    #add-section-box li[data-type][data-drag-type="component"] a {
      color: var(--bs-gray-900); }
  .components .drag-elements-sidepane li[data-type]:hover,
  #add-section-box li[data-type]:hover {
    box-shadow: 0px 0px 1px 0px var(--bs-primary);
    background-color: rgba(var(--bs-primary-rgb), 0.05) !important;
    border-color: rgba(var(--bs-primary-rgb), 0.5) !important; }
    .components .drag-elements-sidepane li[data-type]:hover a,
    #add-section-box li[data-type]:hover a {
      color: var(--bs-primary); }

/*
#right-panel
{
*/
.header-arrow {
  padding: 0.3rem 0.7rem;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 0; }

label.header {
  font-size: 11px;
  font-weight: 600;
  height: auto;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 32px;
  text-align: left;
  padding: 0.3rem 0.8rem;
  border-top: 1px solid var(--bs-border-color);
  color: var(--bs-link-color);
  cursor: pointer;
  position: relative; }
  label.header:first-child {
    margin-top: 0rem;
    border-top: none; }
  label.header :checked {
    color: red; }

input.header_check {
  position: absolute;
  left: 0;
  margin-left: 0;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  height: 1em;
  width: 1em;
  top: 0; }

input.header_check:checked + div.section,
li.header > input.header_check:checked + ol {
  opacity: 1;
  padding: 0 1rem;
  height: auto; }

div.section, .header > ol {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height,opacity 0.5s; }

#right-panel label.header .header-arrow {
  right: 10px; }

.toggle {
  position: relative;
  width: 65px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.toggle-checkbox {
  display: none; }

.toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid var(--bs-body-bg);
  border-radius: 30px; }

.toggle-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s; }

.toggle-inner:before, .toggle-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box; }

.toggle-inner:before {
  content: "ON";
  padding-left: 14px;
  background-color: var(--bs-link-color); }

.toggle-inner:after {
  content: "OFF";
  padding-right: 14px;
  background-color: #999999;
  color: var(--bs-body-color);
  text-align: right; }

.toggle-switch {
  display: block;
  width: 18px;
  margin: 4px;
  background: var(--bs-body-bg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 39px;
  border: 2px solid var(--bs-body-bg);
  border-radius: 30px;
  transition: all 0.3s ease-in 0s; }

.toggle-checkbox:checked + .toggle-label .toggle-inner {
  margin-left: 0; }

.toggle-checkbox:checked + .toggle-label .toggle-switch {
  right: 0px; }

.form-select {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: inherit;
  -webkit-appearance: inherit;
  appearance: auto; }

.form-select.small-arrow {
  background-position: right .5rem center;
  background-size: 10px 8px; }

.input-group.css-unit .form-select.small-arrow {
  max-width: 100px;
  padding-left: 0.5rem;
  padding-right: 1.2em; }

.input-group.auto input[type="number"] {
  display: none; }

.input-group.auto .input-group-append {
  width: 100%; }
  .input-group.auto .input-group-append .form-select.small-arrow {
    max-width: 100%; }

.btn-group.btn-group-fullwidth {
  display: flex;
  width: 100%; }

.btn-group.btn-group-fullwidth .btn {
  flex: 1 1 auto; }

.btn-group .btn.btn-gray.active {
  background-color: var(--bs-border-color); }

.btn-group .btn i {
  vertical-align: middle; }

.form-control::placeholder {
  opacity: 0.7; }

.form-control, .form-select {
  box-shadow: 1px 1px 3px 0px rgba(var(--bs-body-color-rgb), 0.05) inset; }

.btn-link:hover {
  text-decoration: none; }

/* autocomplete */
input.autocomplete-loading {
  background-image: url(../autocomplete/autocomplete.gif);
  background-position: center right;
  background-repeat: no-repeat; }

ul.autocomplete {
  position: absolute;
  overflow: hidden;
  background-color: var(--bs-body-bg);
  border: 1px solid var(--bs-border-color);
  border-top: none;
  margin-top: -1px;
  margin: 0px;
  padding: 0px;
  list-style: none;
  color: #000;
  display: none;
  z-index: 1000; }

ul.autocomplete li {
  display: block;
  padding: 0.3em;
  overflow: hidden;
  width: 100%;
  cursor: pointer; }

ul.autocomplete li.selected {
  background-color: Highlight;
  color: var(--bs-body-bg); }


.input-list-select .elements {
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 1px 1px 3px 0px rgba(var(--bs-body-color-rgb), 0.05) inset;
  border: 1px solid #ced4da;
  padding: 0.3rem; }
  .input-list-select .elements .row {
    --bs-gutter-y:0.5rem;
    --bs-gutter-x:0.5rem; }
  .input-list-select .elements .element {
    border: 1px solid #ced4da;
    box-shadow: 1px 1px 3px 0px rgba(var(--bs-body-color-rgb), 0.05) inset;
    padding: 0.2rem;
    height: 100%;
    text-align: center; }
    .input-list-select .elements .element:hover {
      border-color: var(--bs-link-color);
      color: var(--bs-link-color); }
    .input-list-select .elements .element label {
      display: block;
      font-size: 11px; }

#accordionSections .card {
  border-color: rgba(18, 83, 205, 0.1); }
  #accordionSections .card .card-header {
    background: rgba(18, 83, 205, 0.05);
    border: none;
    padding: 0rem; }
    #accordionSections .card .card-header button {
      font-size: 13px;
      text-align: left;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(18, 83, 205, 0.2); }
      #accordionSections .card .card-header button:focus {
        text-decoration: none; }
      #accordionSections .card .card-header button .header-arrow {
        padding: 0.2rem; }

#sections-list {
  background: var(--bs-body-bg); }

.sections-tabs .nav-link span, .component-properties .nav-link span {
  font-size: 12px; }

.add-section-btn {
  position: relative;
  bottom: 0px;
  background: var(--bs-primary);
  color: var(--bs-white);
  font-size: 18px;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  padding: 3px 7px;
  cursor: pointer;
  display: block;
  box-shadow: 0px 0px 1px 2px rgba(18, 83, 205, 0.05);
  box-shadow: 0px 3px 7px 1px rgba(var(--bs-body-color-rgb), 0.1), 1px 2px 7px 1px rgba(255, 255, 255, 0.1) inset;
  margin: auto;
  position: absolute;
  font-size: 21px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  visibility: hidden;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-color: var(--bs-link-color); }
  .add-section-btn i {
    font-weight: 600; }
  .add-section-btn:hover {
    text-decoration: none;
    filter: brightness(1.2); }
  .add-section-btn i {
    color: var(--bs-body-bg); }

.animation-container {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  filter: url("#goo");
  animation: rotate-move 2s ease-in-out infinite; }

.dot {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(var(--bs-body-bg-rgb), 0.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 49px;
  text-align: center; }

.dot-3 {
  background-color: var(--bs-link-color);
  animation: dot-3-move 2s ease infinite, index 6s ease infinite; }

.dot-2 {
  background-color: #198754;
  animation: dot-2-move 2s ease infinite, index 6s -4s ease infinite; }

.dot-1 {
  background-color: #dc3545;
  animation: dot-1-move 2s ease infinite, index 6s -2s ease infinite; }

@keyframes dot-3-move {
  20% {
    transform: scale(1); }
  45% {
    transform: translateY(-18px) scale(0.45); }
  60% {
    transform: translateY(-90px) scale(0.45); }
  80% {
    transform: translateY(-90px) scale(0.45); }
  100% {
    transform: translateY(0px) scale(1); } }

@keyframes dot-2-move {
  20% {
    transform: scale(1); }
  45% {
    transform: translate(-16px, 12px) scale(0.45); }
  60% {
    transform: translate(-80px, 80px) scale(0.45); }
  80% {
    transform: translate(-80px, 80px) scale(0.45); }
  100% {
    transform: translateY(0px) scale(1); } }

@keyframes dot-1-move {
  20% {
    transform: scale(1); }
  45% {
    transform: translate(16px, 12px) scale(0.45); }
  60% {
    transform: translate(80px, 70px) scale(0.45); }
  80% {
    transform: translate(80px, 60px) scale(0.45); }
  100% {
    transform: translateY(0px) scale(1); } }

@keyframes rotate-move {
  55% {
    transform: translate(-50%, -50%) rotate(0deg); }
  80% {
    transform: translate(-50%, -50%) rotate(360deg); }
  100% {
    transform: translate(-50%, -50%) rotate(360deg); } }

@keyframes index {
  0%, 100% {
    z-index: 3; }
  33.3% {
    z-index: 2; }
  66.6% {
    z-index: 1; } }

input[type="color"] {
  width: 28px;
  height: 28px;
  border-radius: 3px;
  padding: 2px;
  box-shadow: none; }
  input[type="color"].form-control-color::-moz-color-swatch {
    min-width: 21px;
    min-height: 21px;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.25);
    border: none; }
  input[type="color"].form-control-color::-webkit-color-swatch {
    min-width: 21px;
    min-height: 21px;
    border-radius: 3px;
    box-shadow: 1px 1px 5px 0px rgba(var(--bs-body-color-rgb), 0.25);
    border: none; }

.breadcrumb-navigator {
  padding: 0.4rem 1rem;
  min-height: 30px; }
  .breadcrumb-navigator .breadcrumb {
    font-size: 90%;
    margin: 0; }
    .breadcrumb-navigator .breadcrumb .breadcrumb-item {
      padding: 0.2rem 0.5rem; }
      .breadcrumb-navigator .breadcrumb .breadcrumb-item a {
        color: var(--bs-body-color);
        background: rgba(var(--bs-secondary-color-rgb), 0.03);
        padding: 0.2rem 0.4rem;
        border-radius: 4px;
        border: 1px solid var(--bs-border-color);
        text-decoration: none; }
        .breadcrumb-navigator .breadcrumb .breadcrumb-item a:hover {
          background: rgba(var(--bs-link-color-rgb), 0.05);
          border: 1px solid rgba(var(--bs-link-color-rgb), 0.2);
          text-decoration: none; }
        .breadcrumb-navigator .breadcrumb .breadcrumb-item a.el-component {
          border-color: var(--bs-primary-bg-subtle);
          background-color: rgba(var(--bs-primary-rgb), 0.07); }
        .breadcrumb-navigator .breadcrumb .breadcrumb-item a.el-attribute {
          border-color: var(--bs-success-bg-subtle);
          background-color: rgba(var(--bs-success-rgb), 0.07); }

#dragElement-clone {
  background: rgba(var(--bs-body-bg-rgb), 0.5);
  border: 1px solid rgba(var(--bs-body-color-rgb), 0.15);
  border-radius: 4px;
  pointer-events: none; }

@keyframes popup-animation {
  0% {
    transform: translateY(-3rem) scaleY(0) scaleX(0); }
  to {
    transform: translateY(0) scaleY(1) scaleX(1); } }

/* input-html-list-select */
.input-html-list-select .elements {
  margin-top: 5px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid var(--bs-border-color); }
  .input-html-list-select .elements li {
    cursor: pointer; }

.tab-content > .active {
  overflow-y: auto;
  overflow-x: hidden; }

img.preview {
  max-width: 100%;
  margin: auto; }

.hint {
  position: relative;
  /*
    input:hover + &,
    select:focus + &, */ }
  .hint:before {
    position: absolute;
    bottom: 100%;
    margin-bottom: -11px;
    left: calc(50% - 6px);
    content: '';
    z-index: 1000001;
    transform: translateY(-8px);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    transition: .3s ease;
    transition-delay: 0s;
    border: 6px solid transparent;
    border-top-color: rgba(var(--bs-body-color-rgb), 0.9); }
  .hint:after {
    content: attr(aria-label);
    bottom: 100%;
    left: 50%;
    background: rgba(var(--bs-body-color-rgb), 0.9);
    color: var(--bs-body-bg);
    padding: 8px 10px;
    font-size: 12px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    line-height: 12px;
    white-space: nowrap;
    text-shadow: 0 -1px 0 #000;
    box-shadow: rgba(var(--bs-body-color-rgb), 0.3);
    position: absolute;
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    z-index: 1000000;
    pointer-events: none;
    transition: .3s ease;
    transition-delay: 0s; }
  .hint:hover:before, .hint:hover:after {
    visibility: visible;
    opacity: 1;
    transition-delay: .1s;
    transform: translateX(-50%) translateY(-8px); }
  .hint:hover:before {
    transform: translateY(-8px); }




.gallery {
  column-count: 4;
  column-gap: 20px; }
  .gallery.flex {
    display: flex;
    flex-wrap: wrap; }
    .gallery.flex .item {
      flex: 1 0 21%; }
  .gallery.has-shadow .item img {
    box-shadow: 2px 2px 4px 0 #ccc; }
  .gallery.masonry {
    margin: 0px;
    padding: 0; }
  .gallery .item {
    margin-bottom: 20px;
    width: 100%;
    transition: 1s ease all;
    box-sizing: border-box; }
  .gallery .item a {
    display: block; }
  .gallery .item img {
    max-width: 100%; }
  @media only screen and (max-width: 320px) {
    .gallery .masonry {
      column-count: 1; } }
  @media only screen and (min-width: 321px) and (max-width: 768px) {
    .gallery .masonry {
      column-count: 2; } }
  @media only screen and (min-width: 769px) and (max-width: 1200px) {
    .gallery .masonry {
      column-count: 3; } }
  @media only screen and (min-width: 1201px) {
    .gallery .masonry {
      column-count: 4; } }


label.form-check .form-check-input {
  float: none;
  vertical-align: middle;
  margin-right: 0.2rem; }

.form-check .form-check-input {
  position: relative;
  box-shadow: 0px 0px 2px 1px rgba(var(--bs-body-color-rgb), 0.07) inset; }

.modal-full .modal-dialog.modal-xl {
  max-width: 90%; }

.modal-full .modal-dialog.modal-xl.modal-dialog-scrollable .modal-content {
  min-height: 50%; }

.align-left {
  float: left;
  margin-bottom: 25px;
  max-width: 50%; }

.align-right {
  float: right;
  margin-bottom: 25px;
  max-width: 50%; }

.align-center {
  display: block;
  margin-left: auto;
  margin-right: auto; }

video, audio {
  max-width: 100%; }

.onIsOver{
  border : 1px dotted #262626;
  opacity: 0.2;
  background-color: #ccc;
}

#canvas {
  overflow-y: scroll !important;
}



.modal__section{
  height: 500px !important;
  overflow-y: scroll;
}

.modal__section .card__to__add{
  border : 1px solid #ccc;
  height: 200px;
}

.modal__section .card__to__add:hover {
  border : 1px solid #2a5fc1;
  cursor : pointer;
}

.modal__section .card__to__add:hover  .add__block__btn{
  visibility : visible;
}

.modal__section .card__to__add .add__block__btn {
  position: relative;
  bottom: 0px;
  background: var(--bs-primary);
  color: var(--bs-white);
  font-size: 18px;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  padding: 2px 7px;
  cursor: pointer;
  display: block;
  box-shadow: 0px 0px 1px 2px rgba(18, 83, 205, 0.05);
  box-shadow: 0px 3px 7px 1px rgba(var(--bs-body-color-rgb), 0.1), 1px 2px 7px 1px rgba(255, 255, 255, 0.1) inset;
  margin: auto;
  position: absolute;
  font-size: 21px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  visibility: hidden;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-color: var(--bs-link-color);
}


.modal__section .card__to__add .add__block__btn i {
  font-weight: 600; }
.modal__section .card__to__add .add__block__btn:hover {
  text-decoration: none;
  filter: brightness(1.2); }
.modal__section .card__to__add .add__block__btn i {
  color: var(--bs-body-bg); }





.dynamic_block_container{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.dynamic_block_container h5{
  border : 1px solid var(--bs-border-color);
  width: 70%;
  margin-right: 1%;
  padding: 10px;
  margin-bottom: 10px;
}
.dynamic_block_container i {
  margin-right: 1%;
  width: 40px;
  height : 40px;
  text-align: center;
  border : 1px solid var(--bs-border-color);
  line-height: 40px;
 font-size: 15px;
  cursor: pointer;
}

.file__list{
  display: flex;
  flex-direction: row;
  width: 100%;
}
.file__list img{
  width: 40%;
  height: auto;
}

.file__list div {
  width: 60%;
  display: block;
}

.dottedcard{
  padding : 10px;
  background : #f4f4f4;
  border-radius : 6px;
  border: 1px dotted #ccc;
  margin-bottom: 10px;
}
