@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

@import url('./app.css');
@import url('./css/utility.css');
@import url('./css/vendor.css');

.trial__content__bloc__style{
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.trial__content__bloc__style .boxed {
    background-color: #eee;
}


.trial__content__bloc__style {
    line-height: 1.5;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased; }

.trial__content__bloc__style .config {
    overflow: hidden; }

/**
 * Basic styles for headings and paragraph
 */
.trial__content__bloc__style h1,.trial__content__bloc__style h2,.trial__content__bloc__style h3,.trial__content__bloc__style h4,.trial__content__bloc__style h5,.trial__content__bloc__style h6 {
    padding: 0;
    margin: 0; }

.trial__content__bloc__style h1 {
    font-size: 50px;
    line-height: 50px; }

.trial__content__bloc__style h2 {
    font-size: 32px;
    line-height: 46px; }

.trial__content__bloc__style h3 {
    font-size: 18px;
    line-height: 32px; }

.trial__content__bloc__style h4 {
    font-size: 16px;
    line-height: 30px; }

.trial__content__bloc__style h5 {
    font-size: 15px;
    line-height: 30px; }

.trial__content__bloc__style p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 24px; }

/**
 * Basic styles for links
 */
.trial__content__bloc__style a,.trial__content__bloc__style a:hover,.trial__content__bloc__style a:focus {
    outline: none;
    text-decoration: none;
    cursor: pointer; }

/**
 * Basic style for image element
 */
.trial__content__bloc__style img {
    border: 0; }
.trial__content__bloc__style img:focus {
    outline: none; }

/**
 * Basic style for iframe element
 */
.trial__content__bloc__style iframe {
    border: none;
    overflow: hidden; }

.trial__content__bloc__style iframe[src*="soundcloud"] {
    width: 100%; }

/*--------------------------------------------------------------
Box Module
--------------------------------------------------------------*/

/* Header with container Element */
@media (max-width: 1024px) {
    .trial__content__bloc__style .boxed header .container {
        padding-left: 20px;
        padding-right: 20px; } }

@media (min-width: 1025px) {
    .trial__content__bloc__style .boxed header .container {
        padding-left: 40px;
        padding-right: 40px; } }

/* Header & Footer Element */
@media (min-width: 576px) {
    .trial__content__bloc__style .boxed header, .trial__content__bloc__style .boxed footer {
        margin-right: auto;
        margin-left: auto;
        width: 100%; } }

@media (min-width: 576px) {
    .trial__content__bloc__style .boxed header, .trial__content__bloc__style .boxed footer {
        max-width: 456px; } }

@media (min-width: 791px) {
    .trial__content__bloc__style .boxed header, .trial__content__bloc__style .boxed footer {
        max-width: 770px; } }

@media (min-width: 1025px) {
    .trial__content__bloc__style .boxed header, .trial__content__bloc__style .boxed footer {
        max-width: 990px; } }

@media (min-width: 1230px) {
    .trial__content__bloc__style .boxed header, .trial__content__bloc__style .boxed footer {
        max-width: 1200px; } }

/* App Content exclude Header & Footer */
@media (min-width: 576px) {
    .trial__content__bloc__style .boxed .app-content {
        margin: 0 auto;
        overflow: hidden; } }

@media (min-width: 576px) {
    .trial__content__bloc__style .boxed .app-content {
        max-width: 546px; } }

@media (min-width: 791px) {
    .trial__content__bloc__style .boxed .app-content {
        max-width: 770px; } }

@media (min-width: 1025px) {
    .trial__content__bloc__style .boxed .app-content {
        max-width: 990px; } }

@media (min-width: 1230px) {
    .trial__content__bloc__style .boxed .app-content {
        max-width: 1200px; } }

/* App Content with container */
@media (max-width: 1024px) {
    .trial__content__bloc__style .boxed .app-content .container {
        padding-left: 20px;
        padding-right: 20px; } }

@media (min-width: 1025px) {
    .trial__content__bloc__style .boxed .app-content .container {
        padding-left: 40px;
        padding-right: 40px; } }

/* Footer with container Element */
@media (max-width: 1024px) {
    .trial__content__bloc__style .boxed footer .container {
        padding-left: 20px;
        padding-right: 20px; } }

@media (min-width: 1025px) {
    .trial__content__bloc__style .boxed footer .container {
        padding-left: 40px;
        padding-right: 40px; } }

/*--------------------------------------------------------------
2.0 Typography
--------------------------------------------------------------*/
/**
 * Basic typography style for copy text
 */
.trial__content__bloc__style {
    color: #7f7f7f;
    font-family: "Open Sans", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; }



.trial__logo{
    text-transform: uppercase !important;
    font-size: 28px;
}

.links{
    display: flex;
    justify-content: space-between;
}

.cartIncrement span:first-child , .cartIncrement span:last-child{
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    cursor: pointer;
}

.cartIncrement span:first-child{
    background: #bd2130;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.cartIncrement span:nth-child(2){
    border-radius: 50%;
}

.cartIncrement span:last-child{
    background: #21bd29;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

