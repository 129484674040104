/*-----------------------------------------------------------------------------------
    Template Name: Landio - Multipurpose Landing Page HTML Template
    Template URI: https://webtend.net/demo/html/landio/
    Author: WebTend
    Author URI:  https://webtend.net/
    Version: 1.3

    Note: This is Main Style CSS File.
-----------------------------------------------------------------------------------
	CSS INDEX
	===================
    #. Fonts
    #. Common
    #. Input field
    #. Keyframes & Animation
    #. Header
        ##. Header Common
        ##. Header Search
        ##. Off Canvas And Mobile Slide Panel
    #. components
        ##. Preloader
        ##. Template Button
        ##. Hero Area
            ###. Hero Buttons
            ###. Hero Area v1
            ###. Hero Area v2
            ###. Hero Area v3
            ###. Hero Area v4
            ###. Hero Area v5
            ###. Hero Area v6
            ###. Hero Area v7
            ###. Hero Area v8
            ###. Hero Area v9
        ##. Common Heading
        ##. Check List
            ###. Check List
            ###. Check List Two
            ###. Check List Three
        ##. Simple Icon Box
        ##. Image Boxes
            ###. Image Boxes v1
            ###. Image Boxes v2
            ###. Image Boxes v3
        ##. Iconic Boxes
            ###. Iconic Boxes v1
            ###. Iconic Boxes v1 Square Bordered
            ###. Iconic Boxes v2
            ###. Iconic Boxes v3
            ###. Icon Bordered Box
        ##. Fancy Icon Boxes
            ###. Fancy Icon Boxes v1
            ###. Fancy Icon Boxes v2
        ##. Preview
            ###. Preview Gallery v1
            ###. Preview Gallery v2
            ###. Preview Gallery v3
            ###. Preview Gallery v4
            ###. Preview Gallery v5
            ###. Preview Gallery v6
            ###. Preview Gallery v7
            ###. Preview Gallery v8
            ###. Preview Gallery v9
            ###. Preview Image v1
            ###. Preview Image v2
            ###. Preview Image v3
            ###. Preview Image v4
            ###. Preview Image v5
            ###. Preview Square oval Image
            ###. Preview Blob Image
            ###. Preview Image showcase
        ##. Counter
            ###. Counter Items v1
            ###. Counter Items v2
        ##. Fancy Check List
            ###. Fancy Check List v1
            ###. Fancy Check List v2
        ##. Testimonials
            ###. Testimonials v1
            ###. Testimonials v1 Boxed
            ###. Testimonials v1 Fancy Boxed
            ###. Testimonials SLider v2
            ###. Testimonials Boxes v1
            ###. Testimonials Quote Bordered
            ###. Testimonials Boxes v2
        ##. Pricing Table
            ###. Pricing Table One
            ###. Pricing Table Two
            ###. Pricing Table Three
        ##. Programs
        ##. Team Member
        ##. Seo Score Box
        ##. Accordion
            ###. Accordion v1
            ###. Accordion v1 Bordered
        ##. Landio Vidoe v1
        ##. Info Box
        ##. Brand Items
        ##. Latest News
            ###. Latest News v1
            ###. Latest News v2
        ##. Contact
            ###. Contact Boxes v1
            ###. Contact Boxes v2
            ###. Contact Form v1
            ###. Contact Form v2
            ###. Contact Map
        ##. Progress Bar
        ##. Newsletter Form
        ##. Projects
        ##. Video Call To Action
        ##. Service Tab
        ##. Screenshot Slider
        ##. Specific Section
        ##. Blog
            ###. Blog Standard
            ###. Blog Details
            ###. Blog Details
            ###. Blog Sidebar
            ###. Blog Pagination
            ###. Comment Template
        ##. Footer
        ##. Page Title
        ##. 404
        ##. Coming Soon
-----------------------------------------------------------------------------------*/
/*=======  Fonts =======*/
@font-face {
  font-family: "CircularStdBold";
  src: url("../fonts/circularstd/CircularStdBold.eot");
  src: url("../fonts/circularstd/CircularStdBold.eot") format("embedded-opentype"), url("../fonts/circularstd/CircularStdBold.woff2") format("woff2"), url("../fonts/circularstd/CircularStdBold.woff") format("woff"), url("../fonts/circularstd/CircularStdBold.ttf") format("truetype"), url("../fonts/circularstd/CircularStdBold.svg#CircularStdBold") format("svg"); }

@font-face {
  font-family: "CircularStdMedium";
  src: url("../fonts/circularstd/CircularStdMedium.eot");
  src: url("../fonts/circularstd/CircularStdMedium.eot") format("embedded-opentype"), url("../fonts/circularstd/CircularStdMedium.woff2") format("woff2"), url("../fonts/circularstd/CircularStdMedium.woff") format("woff"), url("../fonts/circularstd/CircularStdMedium.ttf") format("truetype"), url("../fonts/circularstd/CircularStdMedium.svg#CircularStdMedium") format("svg"); }

@font-face {
  font-family: "CircularStdBook";
  src: url("../fonts/circularstd/CircularStdBook.eot");
  src: url("../fonts/circularstd/CircularStdBook.eot") format("embedded-opentype"), url("../fonts/circularstd/CircularStdBook.woff2") format("woff2"), url("../fonts/circularstd/CircularStdBook.woff") format("woff"), url("../fonts/circularstd/CircularStdBook.ttf") format("truetype"), url("../fonts/circularstd/CircularStdBook.svg#CircularStdBook") format("svg"); }

/*======= Common =======*/
:root {
  --color-primary: #473bf0;
  --color-primary-2: #0040e5;
  --color-primary-3: #e95e6c;
  --color-primary-4: #299cdb;
  --color-primary-5: #299cdb;
  --color-primary-6: #42a66c;
  --color-primary-7: #ff5d0c;
  --color-secondary: #f4732a;
  --color-secondary-2: #f6b9a7;
  --color-secondary-3: #8208fe;
  --color-secondary-4: #2b354f;
  --color-secondary-5: #343351;
  --color-secondary-6: #fdf5e2;
  --color-secondary-7: #e0fafb;
  --color-heading: #161c2d;
  --color-heading-2: #1a1b1e;
  --color-heading-3: #36234b;
  --color-heading-4: #1f1f1f;
  --color-heading-5: #000002;
  --color-body: #6e727d;
  --color-body-2: #6f667b;
  --color-body-3: #7a7a7a;
  --color-body-4: #656871;
  --color-dark: #1b202f;
  --color-dark-2: #16171b;
  --color-dark-3: #191a1e;
  --color-dark-4: #282a33;
  --color-white: #fff;
  --color-grey: #f5f3f5;
  --color-soft-grey: #f2f5fe;
  --color-soft-grey-2: #f4f7fa;
  --color-offwhite: #e7e8ea;
  --font-cs-bold: 'CircularStdBold';
  --font-cs-medium: 'CircularStdMedium';
  --font-cs-book: 'CircularStdBook'; }

body {
  color: var(--color-body);
  font-family: var(--font-cs-book);
  font-weight: normal;
  font-size: 15px;
  line-height: 1.8;
  overflow-x: hidden !important; }

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

img {
  max-width: 100%;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }

.slick-slide img {
  display: inline-block; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-heading);
  margin: 0px; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: var(--color-heading); }

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none; }

p {
  margin: 0px; }

a {
  display: inline-block;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }
  a:hover {
    text-decoration: none; }

a,
span {
  display: inline-block; }

/*======= Common Classes =======*/
.border-bottom-primary {
  border-bottom: 1px solid rgba(71, 59, 240, 0.1); }

.border-top-primary {
  border-top: 1px solid rgba(71, 59, 240, 0.1); }

.border-bottom-primary-3 {
  border-bottom: 1px solid rgba(233, 94, 108, 0.12); }

.border-top-primary-3 {
  border-top: 1px solid rgba(233, 94, 108, 0.12); }

.border-bottom-off-white {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1); }

.border-top-off-white {
  border-top: 2px solid rgba(255, 255, 255, 0.1); }

.border-top-secondary {
  border-top: 1px solid rgba(22, 28, 45, 0.1); }

.border-bottom-secondary {
  border-bottom: 1px solid rgba(22, 28, 45, 0.1); }

.section-inner-line {
  height: 1px;
  width: 100%;
  background-color: var(--color-heading-3);
  opacity: 0.1; }

.slick-dots {
  text-align: center;
  margin-top: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    line-height: 1;
    height: 12px;
    width: 12px;
    background: #dad8fc;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer; }
    .slick-dots li button {
      width: 100%;
      height: 100%;
      font-size: 0;
      background-color: transparent;
      border: none; }
    .slick-dots li::after {
      position: absolute;
      content: "";
      height: 30px;
      width: 30px;
      background: transparent;
      z-index: -1;
      border-radius: 50%;
      left: -9px;
      top: -9px;
      border: 2px solid #299cdb;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      -webkit-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      z-index: 1; }
    .slick-dots li.slick-active {
      background-color: #299cdb; }
      .slick-dots li.slick-active::after {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }

.text-pullquote {
  position: relative;
  padding-left: 30px;
  font-family: var(--font-cs-medium);
  color: var(--color-heading); }
  .text-pullquote::after {
    position: absolute;
    content: "";
    left: 0;
    top: 3px;
    background-color: var(--color-primary-5);
    height: 90%;
    width: 4px; }
  .text-pullquote.pullquote-secondary-color {
    color: var(--color-heading-2); }
    .text-pullquote.pullquote-secondary-color::after {
      background-color: var(--color-secondary); }

/*======= Custom Container =======*/
.container-1420 {
  max-width: 1450px; }

.container-1430 {
  max-width: 1460px; }

.container-1380 {
  max-width: 1410px; }

.container-1470 {
  max-width: 1500px; }

.container-1570 {
  max-width: 1600px; }

.container-1600 {
  max-width: 1630px; }

.container-1720 {
  max-width: 1750px; }

@media (min-width: 1400px) {
  .container-fluid.fluid-gap-70 {
    padding-left: 70px;
    padding-right: 70px; } }

.fluid-in-container-left {
  padding-left: calc((100% - 1200px) / 2); }

.fluid-in-container-right {
  padding-right: calc((100% - 1200px) / 2); }

.bg-cover-center {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.bg-cover-left {
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat; }

/*======= Specific Page Color =======*/
.app-landing-page {
  color: var(--color-body-2); }
  .app-landing-page h1,
  .app-landing-page h2,
  .app-landing-page h3,
  .app-landing-page h4,
  .app-landing-page h5,
  .app-landing-page h6 {
    color: var(--color-heading-3); }
    .app-landing-page h1 a,
    .app-landing-page h2 a,
    .app-landing-page h3 a,
    .app-landing-page h4 a,
    .app-landing-page h5 a,
    .app-landing-page h6 a {
      color: var(--color-heading-3); }

.fintech-landing-page {
  color: var(--color-body-3); }
  .fintech-landing-page h1,
  .fintech-landing-page h2,
  .fintech-landing-page h3,
  .fintech-landing-page h4,
  .fintech-landing-page h5,
  .fintech-landing-page h6 {
    color: var(--color-heading-4); }
    .fintech-landing-page h1 a,
    .fintech-landing-page h2 a,
    .fintech-landing-page h3 a,
    .fintech-landing-page h4 a,
    .fintech-landing-page h5 a,
    .fintech-landing-page h6 a {
      color: var(--color-heading-4); }

.software-landing-page h1,
.software-landing-page h2,
.software-landing-page h3,
.software-landing-page h4,
.software-landing-page h5,
.software-landing-page h6 {
  color: var(--color-heading-4); }
  .software-landing-page h1 a,
  .software-landing-page h2 a,
  .software-landing-page h3 a,
  .software-landing-page h4 a,
  .software-landing-page h5 a,
  .software-landing-page h6 a {
    color: var(--color-heading-4); }

.e-wallet-landing {
  background-color: var(--color-dark-2);
  color: var(--color-body-4); }
  .e-wallet-landing h1,
  .e-wallet-landing h2,
  .e-wallet-landing h3,
  .e-wallet-landing h4,
  .e-wallet-landing h5,
  .e-wallet-landing h6 {
    color: var(--color-white); }
    .e-wallet-landing h1 a,
    .e-wallet-landing h2 a,
    .e-wallet-landing h3 a,
    .e-wallet-landing h4 a,
    .e-wallet-landing h5 a,
    .e-wallet-landing h6 a {
      color: var(--color-white); }

.e-wallet-boxed-container {
  margin: 30px 0;
  }
  .e-wallet-boxed-container.boxed-bordered {
    border-right: 7px solid var(--color-primary-4);
    border-bottom: 7px solid var(--color-primary-4); }
  .e-wallet-boxed-container.boxed-shape {
    position: relative;
    z-index: 1; }
    .e-wallet-boxed-container.boxed-shape::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url(../img/e-wallet-boxed-shape.png);
      background-repeat: no-repeat;
      background-position: left top;
      background-size: contain; }

.webinar-landing {
  color: var(--color-body-3); }
  .webinar-landing h1,
  .webinar-landing h2,
  .webinar-landing h3,
  .webinar-landing h4,
  .webinar-landing h5,
  .webinar-landing h6 {
    color: var(--color-heading-5); }
    .webinar-landing h1 a,
    .webinar-landing h2 a,
    .webinar-landing h3 a,
    .webinar-landing h4 a,
    .webinar-landing h5 a,
    .webinar-landing h6 a {
      color: var(--color-heading-5); }

/*======= Background & Colors =======*/
.bg-primary-color {
  background-color: #299cdb; }

.bg-primary-color-2 {
  background-color: var(--color-primary-2); }

.bg-primary-color-4 {
  background-color: var(--color-primary-4); }

.bg-secondary-color {
  background-color: var(--color-secondary); }

.bg-secondary-color-3 {
  background-color: var(--color-secondary-2); }

.bg-secondary-color-2 {
  background-color: var(--color-secondary-3); }

.bg-secondary-color-4 {
  background-color: var(--color-secondary-4); }

.bg-secondary-color-5 {
  background-color: var(--color-secondary-5); }

.bg-heading-color {
  background-color: var(--color-heading); }

.bg-heading-color-2 {
  background-color: var(--color-heading-2); }

.bg-heading-color-3 {
  background-color: var(--color-heading-3); }

.bg-grey-color {
  background-color: var(--color-grey); }

.bg-soft-grey-color {
  background-color: var(--color-soft-grey); }

.bg-soft-grey-color-2 {
  background-color: var(--color-soft-grey-2); }

.bg-dark-color {
  background-color: var(--color-dark); }

.bg-dark-color-3 {
  background-color: var(--color-dark-3); }

.color-primary-4 {
  color: var(--color-primary-4); }

.color-primary-5 {
  color: var(--color-primary-5); }

.color-primary-6 {
  color: var(--color-primary-6); }

.bg-secondary-color-7 {
  background-color: var(--color-secondary-7); }

.bg-heading-color-5 {
  background-color: var(--color-heading-5); }

.bg-golden-light {
  background: rgba(255, 169, 0, 0.07); }

/*======= Input field =======*/
a:focus,
.slick-initialized .slick-slide:focus,
input.front:focus,
textarea:focus,
select:focus,
button:focus {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input.front:focus {
  -webkit-box-shadow: none;
  box-shadow: none; }

::-webkit-input-placeholder {
  color: inherit;
  opacity: 1; }

:-ms-input-placeholder {
  color: inherit;
  opacity: 1; }

::-ms-input-placeholder {
  color: inherit;
  opacity: 1; }

::placeholder {
  color: inherit;
  opacity: 1; }

input.front[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input.front[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

input.front[type="search"]::-webkit-search-decoration,
input.front[type="search"]::-webkit-search-cancel-button,
input.front[type="search"]::-webkit-search-results-button,
input.front[type="search"]::-webkit-search-results-decoration {
  display: none; }

input.front,
textarea,
.nice-select.front {

  width: 100%;
  height: 45px;
  padding: 0 30px;
  border: 1px solid  #dcdcdc;
  color: var(--color-body);
  font-size: 16px;

}

.front{
  border-radius: 5px !important;
}
.front-select{
 border: none;
}

textarea {
  padding-top: 20px;
  height: 200px;
  display: inherit; }

.nice-select {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .nice-select .list {
    width: 100%;
    border: none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid var(--color-soft-grey-2); }
    .nice-select .list .option {
      padding: 5px 30px;
      font-size: 16px;
      height: auto; }
      .nice-select .list .option:hover, .nice-select .list .option.selected {
        background-color: var(--color-soft-grey-2); }
      .nice-select .list .option:hover, .nice-select .list .option.focus, .nice-select .list .option.selected.focus {
        background-color: var(--color-soft-grey-2); }
      .nice-select .list .option.selected {
        font-weight: normal; }
      .nice-select .list .option:not(:last-child) {
        border-bottom: 1px solid var(--color-soft-grey-2); }
  .nice-select::after {
    content: "\f107";
    border: none;
    position: absolute;
    line-height: 1;
    right: 30px;
    top: 0;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform-origin: unset;
    -ms-transform-origin: unset;
    transform-origin: unset;
    width: auto;
    margin: 0;
    font-size: 15px;
    font-weight: 800;
    font-family: "Font Awesome 5 Pro"; }
  .nice-select.open::after {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg); }

/*======= keyframes & Animation =======*/
@-webkit-keyframes sticky {
  0% {
    top: -200px; }
  100% {
    top: 0; } }
@keyframes sticky {
  0% {
    top: -200px; }
  100% {
    top: 0; } }

@-webkit-keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@-webkit-keyframes zoomInOut {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0; }
  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(360deg) scale(0.7);
    transform: rotate(360deg) scale(0.7);
    opacity: 0; } }

@keyframes zoomInOut {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7);
    transform: rotate(0deg) scale(0.7);
    opacity: 0; }
  50% {
    -webkit-transform: rotate(180deg) scale(1);
    transform: rotate(180deg) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(360deg) scale(0.7);
    transform: rotate(360deg) scale(0.7);
    opacity: 0; } }

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); } }

@keyframes float-bob {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px); } }

@-webkit-keyframes float-bob-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@keyframes float-bob-x {
  0% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  50% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-webkit-keyframes float-bob-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes float-bob-y {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  50% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.animate-zoominout {
  -webkit-animation-name: zoomInOut;
  animation-name: zoomInOut;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out; }

.animate-zoom-fade {
  -webkit-animation-name: zoom-fade;
  animation-name: zoom-fade;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.animate-float-bob {
  -webkit-animation-name: float-bob;
  animation-name: float-bob;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.animate-float-bob-x {
  -webkit-animation-name: float-bob-x;
  animation-name: float-bob-x;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.animate-float-bob-y {
  -webkit-animation-name: float-bob-y;
  animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.animate-rotate-me {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 24s;
  animation-duration: 24s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear; }

.hover-overly-left-to-right {
  position: relative;
  overflow: hidden; }
  .hover-overly-left-to-right::before {
    position: absolute;
    top: 0;
    left: -100%;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.7)), to(rgba(255, 255, 255, 0.3)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(25deg);
    -ms-transform: skewX(25deg);
    transform: skewX(25deg);
    z-index: 2; }
  .hover-overly-left-to-right:hover:before {
    -webkit-animation: shine 1.5s;
    animation: shine 1.5s; }

.hover-overly-zoom {
  position: relative;
  overflow: hidden; }
  .hover-overly-zoom:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: block;
    content: "";
    width: 0px;
    height: 0px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 7px; }
  .hover-overly-zoom:hover:before {
    -webkit-animation-duration: 0.95s;
    animation-duration: 0.95s;
    -webkit-animation-timing-function: ease;
    animation-timing-function: ease;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    -webkit-animation-direction: normal;
    animation-direction: normal;
    -webkit-animation-fill-mode: none;
    animation-fill-mode: none;
    -webkit-animation-play-state: running;
    animation-play-state: running;
    -webkit-animation-name: circle;
    animation-name: circle; }

/*=======  Header  =======*/
.template-header {
  position: relative;
  z-index: 999; }
  .template-header .header-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .template-header .header-left,
  .template-header .header-center,
  .template-header .header-right,
  .template-header .header-right .header-extra,
  .template-header .branding-and-language-selection {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .template-header .language-selection .nice-select {
    background-color: var(--color-soft-grey-2);
    border: 0;
    width: 160px;
    height: 50px;
    padding: 0 30px 0 50px;
    border-radius: 50px;
    z-index: 1;
    color: var(--color-heading);
    font-size: 18px;
    font-family: var(--font-cs-medium);
    font-weight: 500; }
    .template-header .language-selection .nice-select .list {
      -webkit-box-shadow: 0 8px 20px rgba(55, 125, 255, 0.08);
      box-shadow: 0 8px 20px rgba(55, 125, 255, 0.08); }
      .template-header .language-selection .nice-select .list .option {
        padding: 3px 10px;
        color: var(--color-body); }
        .template-header .language-selection .nice-select .list .option.selected {
          color: #299cdb; }
    .template-header .language-selection .nice-select::before {
      content: "\f0ac";
      font-family: "Font Awesome 5 Pro";
      position: absolute;
      left: 25px;
      font-size: 18px;
      color: #299cdb; }
  .template-header .language-selection.bg-transparent .nice-select {
    width: 100px;
    background-color: transparent;
    padding: 0 0 0 25px; }
    .template-header .language-selection.bg-transparent .nice-select::before {
      left: 0; }
    .template-header .language-selection.bg-transparent .nice-select::after {
      right: 0; }
  .template-header .branding-and-language-selection .nice-select {
    margin-left: 50px; }
  .template-header .branding-and-language-selection.branding-border-right .nice-select {
    margin-left: 50px; }
  .template-header .branding-and-language-selection.branding-border-right .language-selection {
    position: relative;
    z-index: 1;
    padding-left: 20px; }
    .template-header .branding-and-language-selection.branding-border-right .language-selection::before {
      content: "";
      position: absolute;
      left: 35px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 2px;
      height: 20px;
      background-color: var(--color-dark-4); }
  .template-header .nav-menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .template-header .nav-menu li {
    line-height: 110px;
    margin: 0 15px;
    position: relative; }
    .template-header .nav-menu li a {
      position: static;
      padding: 5px;
      line-height: 1.4;
      color: var(--color-heading);
      font-size: 18px;
      font-family: var(--font-cs-medium);
      font-weight: 500; }
      .template-header .nav-menu li a .dd-trigger {
        padding-left: 6px;
        font-size: 15px; }
      .template-header .nav-menu li a::before {
        content: "";
        position: absolute;
        left: 5px;
        right: 5px;
        width: 0;
        top: 0;
        height: 5px;
        background-color: #299CDB;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0; }
      .template-header .nav-menu li a:hover {
        color:  #299CDB; }
        .template-header .nav-menu li a:hover::before {
          width: calc(100% - 10px);
          visibility: visible;
          opacity: 1; }
    .template-header .nav-menu li.active > a {
      color:  #299CDB; }
      .template-header .nav-menu li.active > a::before {
        visibility: visible;
        opacity: 1;
        width: calc(100% - 10px); }
  .template-header .nav-menu .sub-menu {
    display: block;
    position: absolute;
    width: 220px;
    background-color: var(--color-white);
    -webkit-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    z-index: 99;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 29, 35, 0.05);
    box-shadow: 0 2px 8px 0 rgba(0, 29, 35, 0.05);
    left: 0;
    top: calc(100% + 40px);
    visibility: hidden;
    opacity: 0; }
    .template-header .nav-menu .sub-menu li {
      line-height: 1;
      margin: 0; }
      .template-header .nav-menu .sub-menu li:not(:last-child) {
        border-bottom: 1px solid rgba(0, 29, 35, 0.05); }
      .template-header .nav-menu .sub-menu li::before {
        display: none; }
      .template-header .nav-menu .sub-menu li a {
        font-size: 15px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 12px 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        line-height: 1.4; }
        .template-header .nav-menu .sub-menu li a .dd-trigger i {
          -webkit-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg); }
        .template-header .nav-menu .sub-menu li a:hover {
          background-color:  #299CDB;
          color: var(--color-white); }
        .template-header .nav-menu .sub-menu li a::before {
          display: none; }
    .template-header .nav-menu .sub-menu .sub-menu {
      left: 100%;
      top: 50%; }
  .template-header .nav-menu li:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
    top: 100%; }
  .template-header .nav-menu .sub-menu li:hover > .sub-menu {
    top: 0; }
  .template-header .search-btn {
    font-size: 18px;
    color: var(--color-heading); }
    .template-header .search-btn.search-border-right {
      margin-right: 40px;
      padding-right: 30px;
      border-right: 2px solid #dcdde0; }
  .template-header .user-login {
    font-size: 18px;
    font-family: var(--font-cs-medium);
    font-weight: 500;
    color: var(--color-heading); }
    .template-header .user-login i {
      margin-right: 5px; }
    .template-header .user-login:hover {
      color: #299cdb; }
    .template-header .user-login.user-login-border-right {
      padding-right: 30px;
      border-right: 2px solid var(--color-dark-4); }
  .template-header .header-extra > li {
    line-height: 1; }
    .template-header .header-extra > li:not(:first-child) {
      margin-left: 35px; }
  .template-header .off-canvas-btn {
    width: 50px; }
    .template-header .off-canvas-btn span {
      display: block;
      height: 4px;
      background-color: var(--color-heading);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .template-header .off-canvas-btn span:nth-child(2) {
        margin: 10px 0; }
      .template-header .off-canvas-btn span:nth-child(1) {
        margin-left: 8px; }
      .template-header .off-canvas-btn span:nth-child(3) {
        margin-right: 8px; }
    .template-header .off-canvas-btn:hover span:nth-child(1) {
      margin-left: 0;
      margin-right: 8px; }
    .template-header .off-canvas-btn:hover span:nth-child(3) {
      margin-right: 0;
      margin-left: 8px; }
  .template-header .template-btn {
    padding: 13px 32px; }
  .template-header .navbar-toggler {
    width: 55px;
    padding: 10px;
    font-size: 0;
    border: 2px solid var(--color-heading);
    border-radius: 5px; }
    .template-header .navbar-toggler span {
      display: block;
      height: 3px;
      background-color: var(--color-heading); }
      .template-header .navbar-toggler span:nth-child(2) {
        margin: 6px 0; }
  .template-header.navbar-right .nav-menu ul > li:last-child {
    margin-right: 0; }
    .template-header.navbar-right .nav-menu ul > li:last-child > a {
      padding-right: 0; }
      .template-header.navbar-right .nav-menu ul > li:last-child > a::before {
        left: auto;
        right: 0; }
  .template-header.navbar-right .header-right .header-extra {
    margin-left: 40px; }
  .template-header.navbar-left .nav-menu > ul > li:first-child {
    margin-left: 0; }
    .template-header.navbar-left .nav-menu > ul > li:first-child > a {
      padding-left: 0; }
      .template-header.navbar-left .nav-menu > ul > li:first-child > a::before {
        left: 0; }
  .template-header.navbar-left .header-left .brand-logo {
    margin-right: 100px; }
  .template-header.logo-center .header-left,
  .template-header.logo-center .header-right {
    max-width: 42%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 42%;
    flex: 0 0 42%; }
  .template-header.logo-center .header-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .template-header.logo-center .header-center {
    max-width: 16%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16%;
    flex: 0 0 16%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .template-header.absolute-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999; }
  .template-header.bordered-header .header-inner {
    border-bottom: 1px solid #299cdb; }

    .template-header.bordered-header .nav-menu li a::before {
      background-color: #299cdb; }
  .template-header.bordered-header .nav-menu li .sub-menu a {
    color: var(--color-heading); }
    .template-header.bordered-header .nav-menu li .sub-menu a:hover {
      background-color: #299cdb;
      color: #299cdb; }
  .template-header.bordered-header .user-login i,
  .template-header.bordered-header .nice-select::before {
    color: #299cdb; }
  .template-header.bordered-header .search-btn,
  .template-header.bordered-header .nice-select,
  .template-header.bordered-header .user-login {
    color: #299cdb }
  .template-header.bordered-header .navbar-toggler {
    border-color: #101010; }
    .template-header.bordered-header .navbar-toggler span {
      background-color: #101010; }
  .template-header.nav-white-color:not(.sticky-on) .nav-menu > ul > li > a {
    color: #101010; }
    .template-header.nav-white-color:not(.sticky-on) .nav-menu > ul > li > a::before {
      background-color: var(--color-white); }
  .template-header.nav-white-color:not(.sticky-on) .search-btn,
  .template-header.nav-white-color:not(.sticky-on) .user-login,
  .template-header.nav-white-color:not(.sticky-on) .nice-select,
  .template-header.nav-white-color:not(.sticky-on) .nice-select::before {
    color: var(--color-white); }
  .template-header.nav-white-color:not(.sticky-on) .off-canvas-btn span {
    background-color: var(--color-white); }
  .template-header.nav-white-color:not(.sticky-on) .navbar-toggler {
    border-color: var(--color-white); }
    .template-header.nav-white-color:not(.sticky-on) .navbar-toggler span {
      background-color: var(--color-white); }
  .template-header.nav-primary-3 .nav-menu > ul > li > a {
    color: var(--color-heading); }
    .template-header.nav-primary-3 .nav-menu > ul > li > a::before {
      background-color: var(--color-primary-3); }
  .template-header.nav-primary-3 .nav-menu > ul > li.active > a {
    color: var(--color-primary-3); }
  .template-header.nav-primary-3 .language-selection .nice-select {
    background-color: var(--color-white); }
    .template-header.nav-primary-3 .language-selection .nice-select::before {
      color: var(--color-primary-3); }
  .template-header.nav-primary-5 .nav-menu > ul > li > a {
    color: var(--color-heading); }
    .template-header.nav-primary-5 .nav-menu > ul > li > a::before {
      background-color: var(--color-primary-5); }
  .template-header.nav-primary-5 .nav-menu > ul > li.active > a {
    color: var(--color-primary-5); }
  .template-header.nav-primary-5 .language-selection .nice-select {
    background-color: var(--color-white); }
    .template-header.nav-primary-5 .language-selection .nice-select::before {
      color: var(--color-primary-5); }
  .template-header.submenu-seconday-color .nav-menu li .sub-menu a:hover {
    background-color: var(--color-secondary); }
  .template-header.submenu-seconday-color.sticky-on .nav-menu li a::before {
    background-color: var(--color-secondary); }
  .template-header.submenu-seconday-color.sticky-on .nav-menu li.active > a {
    color: var(--color-secondary); }
  .template-header.submenu-seconday-color.sticky-on .nav-menu > ul > li > a:hover {
    color: var(--color-secondary); }
  .template-header.submenu-primary-3 .nav-menu li .sub-menu a:hover {
    background-color: var(--color-primary-3); }
  .template-header.submenu-primary-4 .nav-menu li .sub-menu a:hover {
    background-color: var(--color-primary-4); }
  .template-header.submenu-primary-4.sticky-on .nav-menu li a::before {
    background-color: var(--color-primary-4); }
  .template-header.submenu-primary-4.sticky-on .nav-menu li.active > a {
    color: var(--color-primary-4); }
  .template-header.submenu-primary-4.sticky-on .nav-menu > ul > li > a:hover {
    color: var(--color-primary-4); }
  .template-header.submenu-primary-5 .nav-menu li .sub-menu a:hover {
    background-color: var(--color-primary-5); }
  .template-header.nav-primary-7 .nav-menu > ul > li > a {
    color: var(--color-heading-5); }
    .template-header.nav-primary-7 .nav-menu > ul > li > a::before {
      background-color: var(--color-primary-7); }
  .template-header.nav-primary-7 .nav-menu > ul > li.active > a {
    color: var(--color-primary-7); }
  .template-header.nav-primary-7 .nav-menu li .sub-menu a:hover {
    background-color: var(--color-primary-7); }
  .template-header.nav-primary-7 .template-btn {
    background-color: var(--color-primary-7);
    border-radius: 0;
    text-transform: uppercase;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
    line-height: 1;
    padding: 18px 30px; }
    .template-header.nav-primary-7 .template-btn:hover {
      background-color: var(--color-heading-5); }
  .template-header.header-ten.nav-border-bottom:not(.sticky-on) {
    padding-top: 50px; }
    @media (max-width: 1199px) {
      .template-header.header-ten.nav-border-bottom:not(.sticky-on) {
        padding-top: 25px; } }
    .template-header.header-ten.nav-border-bottom:not(.sticky-on) .nav-menu li {
      line-height: 1; }
    .template-header.header-ten.nav-border-bottom:not(.sticky-on) .nav-menu > ul > li > a::before {
      height: 2px; }
    .template-header.header-ten.nav-border-bottom:not(.sticky-on) .template-btn {
      color: white;
      border-color: white; }
  .template-header.header-ten .nav-search > a {
    margin: 0;
    padding: 0;
    border: none; }
    .template-header.header-ten .nav-search > a:before {
      display: none; }
  .template-header.header-ten .template-btn {
    padding-top: 14px;
    padding-bottom: 10px; }
  .template-header.header-ten .nav-menu ul ul li a:hover {
    background: #d4156e; }
  .template-header.header-ten.sticky-on .nav-menu > ul > li > a:hover,
  .template-header.header-ten.sticky-on .nav-menu li.active > a {
    color: #d4156e; }
    .template-header.header-ten.sticky-on .nav-menu > ul > li > a:hover:before,
    .template-header.header-ten.sticky-on .nav-menu li.active > a:before {
      background: #d4156e; }
  .template-header.header-ten .navbar-toggler {
    border: none; }
  .template-header.header-eleven .header-center {
    margin-left: 80px;
    margin-right: auto; }
  .template-header.header-eleven .nav-menu li a:before {
    height: 2px; }
  .template-header.header-eleven .nav-search a {
    margin-right: 0;
    border-right: none;
    padding-right: 5px; }
    .template-header.header-eleven .nav-search a:before {
      display: none; }
  @media (min-width: 1200px) {
    .template-header.header-eleven.absolute-header {
      top: 15px; } }
  .template-header.header-eleven.nav-border-bottom.sticky-on .nav-menu li, .template-header.header-eleven.nav-border-bottom:not(.sticky-on) .nav-menu li {
    line-height: 45px; }
  .template-header.header-eleven .navbar-toggler {
    border: none; }
  .template-header.header-twelve .nav-menu li a:hover {
    color: #ffa900; }
  .template-header.header-twelve .nav-menu li a:before {
    height: 2px;
    background-color: #ffa900; }
  .template-header.header-twelve .nav-menu li.active > a {
    color: #ffa900; }
  .template-header.header-twelve .nav-menu .sub-menu li a:hover {
    color: white;
    background-color: #ffa900; }
  .template-header.header-twelve:not(.sticky-on) .header-inner {
    border-bottom: 1px solid #282a33; }
    @media (max-width: 1199px) {
      .template-header.header-twelve:not(.sticky-on) .header-inner {
        padding-bottom: 18px; } }
    .template-header.header-twelve:not(.sticky-on) .header-inner .nav-menu li a:before {
      height: 2px;
      background-color: #ffa900; }
  .template-header.header-twelve .navbar-toggler {
    border: none; }
  .template-header.nav-border-bottom:not(.sticky-on) .nav-menu li {
    line-height: 95px; }
    .template-header.nav-border-bottom:not(.sticky-on) .nav-menu li a::before {
      top: auto;
      bottom: 0; }
  .template-header.nav-border-bottom:not(.sticky-on) .nav-menu li:hover > .sub-menu {
    visibility: visible;
    opacity: 1; }
  .template-header.nav-border-bottom:not(.sticky-on) .nav-menu .sub-menu li:hover > .sub-menu {
    top: 0; }
  .template-header.sticky-header .sticky-logo {
    display: none; }
  .template-header.sticky-header.sticky-on {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
    -webkit-box-shadow: 0 8px 20px rgba(55, 125, 255, 0.08);
    box-shadow: 0 8px 20px rgba(55, 125, 255, 0.08);
    background-color: var(--color-white); }
    .template-header.sticky-header.sticky-on .nav-menu ul li {
      line-height: 95px; }
    .template-header.sticky-header.sticky-on .sticky-logo {
      display: block; }
    .template-header.sticky-header.sticky-on .main-logo {
      display: none; }
  .template-header.sticky-header.bordered-header.sticky-on {
    background-color: var(--color-dark-3);
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 2px solid var(--color-dark-4); }
    .template-header.sticky-header.bordered-header.sticky-on .header-inner {
      border-bottom: 0; }

/*=======  Header Search  =======*/
.search-area {
  background: rgba(23, 26, 33, 0.85); }
  .search-area .modal-dialog {
    border: none;
    outline: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .search-area form {
    max-width: 555px;
    position: relative; }
  .search-area .modal-content {
    background: 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none; }
    .search-area .modal-content:focus {
      -webkit-box-shadow: none;
      box-shadow: none; }
  .search-area input {
    padding-left: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid #e5e5e5;
    color: var(--color-white);
    margin-bottom: 0;
    height: 50px;
    width: 100%;
    padding-right: 40px; }
  .search-area .search-btn {
    position: absolute;
    background: transparent;
    top: 10px;
    right: 0;
    color: var(--color-white);
    border: none; }

/*=======  Off Canvas And Mobile Slide Panel  =======*/
.off-canvas-wrapper,
.mobile-slide-panel {
  position: fixed;
  right: 0;
  top: 0;
  background-color: transparent;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  opacity: 0;
  visibility: hidden; }

.off-canvas-wrapper .canvas-inner,
.mobile-slide-panel .panel-inner {
  max-width: 400px;
  height: 100vh;
  background-color: var(--color-white);
  padding: 40px;
  position: relative;
  z-index: 2;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-right: -400px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s; }

.off-canvas-wrapper .canvas-overlay,
.mobile-slide-panel .panel-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-heading);
  cursor: pointer;
  z-index: 1;
  -webkit-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  opacity: 0;
  visibility: hidden; }

.off-canvas-wrapper .canvas-close,
.mobile-slide-panel .panel-close {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 2;
  font-size: 28px;
  line-height: 1;
  color: var(--color-heading); }

.mobile-slide-panel .panel-inner {
  width: 300px;
  margin-right: -300px;
  padding: 40px 30px; }

.mobile-slide-panel .mobile-logo {
  margin-bottom: 30px; }

.mobile-slide-panel .mobile-menu li a {
  position: relative;
  display: block;
  line-height: 1.5;
  padding: 10px 45px 10px 0;
  border-bottom: 1px solid #e5e5e5;
  color: var(--color-heading);
  font-size: 15px;
  font-family: var(--font-cs-medium);
  font-weight: 500; }
  .mobile-slide-panel .mobile-menu li a .dd-trigger {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 1;
    z-index: 2;
    border-left: 1px solid #e5e5e5; }

.mobile-slide-panel .mobile-menu li .sub-menu {
  display: none;
  padding-left: 10px; }

.mobile-slide-panel .mobile-menu li.active > a {
  color: #299cdb; }
  .mobile-slide-panel .mobile-menu li.active > a::before {
    visibility: visible;
    opacity: 1;
    width: calc(100% - 10px); }

.off-canvas-wrapper .canvas-close {
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 2;
  font-size: 28px;
  line-height: 1;
  color: var(--color-heading); }

.off-canvas-wrapper .canvas-widget-title {
  font-size: 20px;
  margin-bottom: 20px;
  padding-bottom: 15px;
  position: relative;
  border-bottom: 1px solid #e8e9ec;
  font-weight: 600;
  color: var(--color-heading); }

.off-canvas-wrapper .canvas-logo,
.off-canvas-wrapper .contact-us,
.off-canvas-wrapper .about-us {
  margin-bottom: 40px; }

.off-canvas-wrapper .contact-us li {
  position: relative;
  padding-left: 60px; }
  .off-canvas-wrapper .contact-us li:not(:last-child) {
    margin-bottom: 25px; }
  .off-canvas-wrapper .contact-us li,
  .off-canvas-wrapper .contact-us li a {
    color: var(--color-body); }
  .off-canvas-wrapper .contact-us li i {
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #299cdb;
    border-radius: 50%;
    font-size: 14px;
    border-radius: 50%;
    color: var(--color-white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }

.off-canvas-wrapper.canvas-on,
.mobile-slide-panel.panel-on {
  opacity: 1;
  visibility: visible; }

.off-canvas-wrapper.canvas-on .canvas-inner,
.mobile-slide-panel.panel-on .panel-inner {
  visibility: visible;
  opacity: 1;
  margin-right: -18px; }

.off-canvas-wrapper.canvas-on .canvas-overlay,
.mobile-slide-panel.panel-on .panel-overlay {
  opacity: 1;
  visibility: visible;
  opacity: 0.4; }

/*======= Preloader =======*/
#preloader {
  position: fixed;
  background-color: var(--color-heading);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }
  #preloader .preloader-image {
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: flipInY;
    animation-name: flipInY;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    background-blend-mode: multiply; }

/*======= Template Button =======*/
.template-btn {
  display: inline-block;
  color: var(--color-white);
  padding: 10px 40px;
  font-size: 14px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1.4;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
  vertical-align: middle;
  border-radius: 5px;
  background-color: #299cdb;
  border: 2px solid #299cdb;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
  box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
  overflow: hidden; }
  .template-btn i {
    margin-left: 10px; }
  .template-btn:hover {
    color: var(--color-white);
    -webkit-box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 30px 0px rgba(0, 0, 0, 0.3);
    background-color: var(--color-heading);
    border-color: var(--color-heading); }
  .template-btn.template-btn-2 {
    border-radius: 7px; }
  .template-btn.bordered-btn {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--color-heading);
    border-color: rgba(71, 59, 240, 0.1); }
    .template-btn.bordered-btn:hover {
      border-color: var(--color-heading);
      color: var(--color-white);
      background-color: var(--color-heading); }
  .template-btn.bordered-body-4 {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--color-body-4);
    border-color: #282a33; }
    .template-btn.bordered-body-4:hover {
      background-color: var(--color-primary-4);
      color: var(--color-white);
      border-color: var(--color-primary-4); }
  .template-btn.primary-bg-2:not(:hover) {
    background-color: var(--color-primary-2);
    border-color: var(--color-primary-2);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(0, 64, 229, 0.36); }
  .template-btn.primary-bg-2:hover {
    background-color: var(--color-heading-2);
    border-color: var(--color-heading-2); }
  .template-btn.primary-bg-3:not(:hover) {
    background-color: var(--color-primary-3);
    border-color: var(--color-primary-3);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36); }
  .template-btn.primary-bg-4 {
    background-color: var(--color-primary-4);
    border-color: var(--color-primary-4);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(51, 119, 255, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(51, 119, 255, 0.36); }
    .template-btn.primary-bg-4.bordered-btn {
      background-color: transparent;
      border-color: rgba(71, 59, 240, 0.1);
      -webkit-box-shadow: none;
      box-shadow: none; }
      .template-btn.primary-bg-4.bordered-btn:hover {
        background-color: var(--color-primary-4);
        color: var(--color-white);
        border-color: var(--color-primary-4); }
  .template-btn.primary-bg-5:not(:hover) {
    background-color: var(--color-primary-5);
    border-color: var(--color-primary-5);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(85, 128, 255, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(85, 128, 255, 0.36); }
  .template-btn.primary-bg-5:hover {
    background-color: var(--color-heading-4); }
  .template-btn.primary-bg-6:not(:hover) {
    background-color: var(--color-primary-6);
    border-color: var(--color-primary-6);
    -webkit-box-shadow: 0px 3px 30px 0px rgba(66, 166, 108, 0.36);
    box-shadow: 0px 3px 30px 0px rgba(66, 166, 108, 0.36); }
  .template-btn.primary-bg-6:hover {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-heading);
    -webkit-box-shadow: none;
    box-shadow: none; }
  .template-btn.secondary-bg:not(:hover) {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.36); }
  .template-btn.secondary-bg:hover {
    background-color: var(--color-heading-2);
    border-color: var(--color-heading-2); }
  .template-btn.secondary-2-bg:not(:hover) {
    background-color: var(--color-secondary-2);
    border-color: var(--color-secondary-2);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
    box-shadow: 0px 10px 30px 0px rgba(246, 185, 167, 0.61);
    color: var(--color-heading-3); }
  .template-btn.secondary-3-bg:not(:hover) {
    background-color: var(--color-secondary-3);
    border-color: var(--color-secondary-3);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(130, 8, 254, 0.36); }
  .template-btn.heading-3-bg:not(:hover) {
    background-color: var(--color-heading-3);
    border-color: var(--color-heading-3);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(54, 35, 75, 0.36); }
  .template-btn.bg-soft-grey-color {
    background-color: var(--color-soft-grey);
    border-color: var(--color-soft-grey);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--color-heading); }
  .template-btn.white-bg {
    background-color: var(--color-white);
    border-color: var(--color-white);
    -webkit-box-shadow: none;
    box-shadow: none;
    color: var(--color-heading-2); }
    .template-btn.white-bg:hover, .template-btn.bg-soft-grey-color:hover {
      background-color: var(--color-heading);
      border-color: var(--color-heading);
      color: var(--color-white); }
    .template-btn.white-bg.bordered-btn {
      background-color: transparent;
      color: var(--color-white);
      border-color: rgba(255, 255, 255, 0.15); }
      .template-btn.white-bg.bordered-btn:hover {
        background-color: var(--color-white);
        color: var(--color-heading-2);
        border-color: var(--color-white); }
  .template-btn.gradient-bg {
    border: none;
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 80%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 80%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 80%);
    background-size: 200%;
    background-position: 50%;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s; }
    .template-btn.gradient-bg:hover {
      background-position: 0%; }
  .template-btn.template-btn-3 {
    border-radius: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: rgba(0, 0, 2, 0.15);
    font-size: 14px;
    color: var(--color-heading-5);
    text-transform: uppercase; }
    .template-btn.template-btn-3:hover {
      border-color: var(--color-heading-5);
      color: var(--color-white);
      background-color: var(--color-heading-5); }
    .template-btn.template-btn-3.br-50 {
      border-radius: 50px; }
  .template-btn.template-btn-4 {
    font-size: 14px;
    border-radius: 0;
    text-transform: uppercase; }

/*======= Hero Buttons =======*/
.hero-btns {
  margin: -5px; }
  .hero-btns li {
    padding: 5px; }
  .hero-btns .play-btn {
    height: 54px;
    width: 54px;
    background-color: var(--color-white);
    color: #299cdb;
    font-size: 14px;
    text-align: center;
    line-height: 54px;
    border-radius: 50px;
    position: relative;
    z-index: 1; }

/*======= Hero Area v1 =======*/
.hero-area-v1 {
  position: relative;
  z-index: 1;
  padding-bottom: 135px;
  background-image: url(../img/hero/hero-1-overly.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden; }
  .hero-area-v1 .hero-content {
    position: relative;
    z-index: 1;
    padding-top: 130px;
    padding-bottom: 135px;
    text-align: center; }
    .hero-area-v1 .hero-content .title-tag {
      margin-bottom: 20px;
      color: #299cdb;
      font-size: 18px;
      font-family: var(--font-cs-medium);
      font-weight: 500;
      line-height: 1.3;
      background-color: transparent; }
      .hero-area-v1 .hero-content .title-tag span {
        background-color: #d3d3f8;
        border-radius: 25px;
        padding: 5px 15px;
        margin-right: 12px; }
    .hero-area-v1 .hero-content .hero-title {
      font-size: 85px;
      line-height: 1.15;
      margin-bottom: 45px;
      letter-spacing: -1px; }
      .hero-area-v1 .hero-content .hero-title span {
        color: #299cdb;
        padding: 5px 20px;
        position: relative;
        z-index: 1; }
        .hero-area-v1 .hero-content .hero-title span::after {
          position: absolute;
          content: "";
          width: 100%;
          left: 0;
          background-image: url(../img/particle/heading-line.png);
          background-repeat: no-repeat;
          background-size: 100%;
          z-index: -1;
          background-attachment: scroll;
          background-position: bottom;
          top: auto;
          bottom: 0;
          height: 100%; }
    .hero-area-v1 .hero-content::after {
      position: absolute;
      content: "";
      z-index: -1;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: url(../img/hero/hero-1-shadow.png) no-repeat scroll;
      background-size: 100% 100%;
      background-position: 0 0; }
  .hero-area-v1 .hero-img img {
    border-radius: 20px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.36); }
  .hero-area-v1 .container {
    position: relative; }
  .hero-area-v1 .hero-particle-effect {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; }
    .hero-area-v1 .hero-particle-effect img {
      position: absolute; }
    .hero-area-v1 .hero-particle-effect .particle-1 {
      top: 100px;
      left: -120px; }
    .hero-area-v1 .hero-particle-effect .particle-2 {
      left: -55px;
      top: 160px; }
    .hero-area-v1 .hero-particle-effect .particle-3 {
      right: 20px;
      top: 80px; }
    .hero-area-v1 .hero-particle-effect .particle-4 {
      bottom: 130px;
      left: 0; }
    .hero-area-v1 .hero-particle-effect .particle-5 {
      right: 0;
      bottom: 100px; }

/*======= Hero Area v2 =======*/
.hero-area-v2 {
  background-color: var(--color-primary-2);
  background-image: url(../img/hero/hero-map-bg.png);
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding: 155px 0 40px; }
  .hero-area-v2 .hero-content-wrapper {
    padding-left: calc((100% - 1200px) / 2);
    padding-bottom: 100px; }
  .hero-area-v2 .hero-content .title-tag {
    margin-bottom: 30px;
    color: var(--color-white);
    font-size: 18px;
    font-family: var(--font-cs-medium);
    font-weight: 500;
    line-height: 1.3;
    background-color: transparent; }
    .hero-area-v2 .hero-content .title-tag span {
      background-color: var(--color-secondary);
      border-radius: 25px;
      padding: 5px 15px;
      margin-right: 12px; }
  .hero-area-v2 .hero-content .hero-title {
    font-size: 90px;
    line-height: 1.15;
    margin-bottom: 65px;
    letter-spacing: -2px;
    color: var(--color-white); }
    .hero-area-v2 .hero-content .hero-title span {
      position: relative;
      z-index: 1; }
      .hero-area-v2 .hero-content .hero-title span::after {
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        z-index: -1;
        background-position: bottom;
        background-repeat: no-repeat;
        bottom: -18px;
        height: 39px;
        background-image: url(../img/particle/title-line-white.png);
        background-size: contain; }
  .hero-area-v2 .hero-content .template-btn.bordered-btn {
    border-color: rgba(255, 255, 255, 0.2);
    color: var(--color-white); }
    .hero-area-v2 .hero-content .template-btn.bordered-btn:hover {
      border-color: var(--color-white);
      background-color: var(--color-white);
      color: var(--color-secondary); }
  .hero-area-v2 .hero-content .play-btn {
    color: var(--color-secondary); }

/*======= Hero Area v3 =======*/
.hero-area-v3 {
  position: relative;
  z-index: 1;
  background-color: var(--color-heading-3);
  background-image: url(../img/hero/hero-leaf-bg.jpg);
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat; }
  .hero-area-v3::after {
    position: absolute;
    content: "";
    height: 340px;
    width: 270px;
    left: 0;
    top: 0;
    z-index: -1;
    background-image: url(../img/hero/hero-3-line.png);
    background-size: contain;
    background-position: top left;
    background-repeat: no-repeat; }
  .hero-area-v3 .hero-content {
    padding-top: 265px;
    padding-bottom: 150px; }
    .hero-area-v3 .hero-content .hero-title {
      font-size: 85px;
      line-height: 1.15;
      margin-bottom: 60px;
      letter-spacing: -3px;
      color: var(--color-white); }
      .hero-area-v3 .hero-content .hero-title span {
        position: relative;
        z-index: 1; }
        .hero-area-v3 .hero-content .hero-title span::after {
          position: absolute;
          content: "";
          width: 65%;
          left: 0;
          z-index: -1;
          background-attachment: scroll;
          background-position: bottom;
          background-repeat: no-repeat;
          bottom: -15px;
          height: 39px;
          background-image: url(../img/particle/title-line-white-2.png);
          background-size: contain; }
    .hero-area-v3 .hero-content p {
      color: var(--color-white);
      margin-bottom: 35px; }
  .hero-area-v3 .hero-img {
    padding-left: 50px; }
  .hero-area-v3 .particle-1 {
    position: absolute;
    left: 6%;
    top: 22%; }
  .hero-area-v3 .particle-2 {
    position: absolute;
    left: 12%;
    bottom: 10%; }

/*======= Hero Area v4 =======*/
.hero-area-v4 {
  background-color: #f0f6ff;
  background-image: url(../img/hero/hero-curve-bg.png);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 52%;
  overflow: hidden;
  padding-bottom: 50px; }
  .hero-area-v4 .hero-content {
    padding-top: 260px;
    padding-bottom: 110px; }
    .hero-area-v4 .hero-content .hero-title {
      font-size: 90px;
      line-height: 1;
      margin-bottom: 45px; }
      .hero-area-v4 .hero-content .hero-title span {
        position: relative;
        z-index: 1; }
        .hero-area-v4 .hero-content .hero-title span::after {
          position: absolute;
          content: "";
          right: 0;
          z-index: -1;
          background-image: url(../img/particle/heading-line-2.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: right center;
          top: auto;
          bottom: 0;
          height: 100%;
          width: 100%; }
    .hero-area-v4 .hero-content .user-images {
      margin-top: 35px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
      .hero-area-v4 .hero-content .user-images li {
        width: 50px;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px; }
        .hero-area-v4 .hero-content .user-images li:not(:first-child) {
          margin-left: -25px; }
        .hero-area-v4 .hero-content .user-images li img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 3px solid var(--color-white); }
        .hero-area-v4 .hero-content .user-images li.note {
          margin-left: 20px;
          font-size: 20px;
          font-family: var(--font-cs-bold);
          font-weight: 700;
          line-height: 1;
          color: var(--color-heading);
          width: auto;
          -webkit-box-flex: 0;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
      .hero-area-v4 .hero-content .user-images .more-icon {
        display: block;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        background-color: #ffc001;
        color: var(--color-heading);
        font-size: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        .hero-area-v4 .hero-content .user-images .more-icon:hover {
          background-color: var(--color-primary-3);
          color: var(--color-white); }
  .hero-area-v4 .hero-preview-galley {
    position: relative;
    z-index: 1;
    height: 700px;
    width: 100%; }
    .hero-area-v4 .hero-preview-galley img {
      position: absolute;
      z-index: 1; }
      .hero-area-v4 .hero-preview-galley img.main-image-1 {
        left: 0;
        top: 0; }
      .hero-area-v4 .hero-preview-galley img.main-image-2 {
        right: -250px;
        bottom: 0; }
    .hero-area-v4 .hero-preview-galley .preview-inner {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2; }
      .hero-area-v4 .hero-preview-galley .preview-inner img {
        border-radius: 15px; }
        .hero-area-v4 .hero-preview-galley .preview-inner img.image-1 {
          right: 20px;
          top: 94px;
          z-index: 2; }
        .hero-area-v4 .hero-preview-galley .preview-inner img.image-2 {
          right: 50px;
          bottom: 160px;
          z-index: 2;
          -webkit-box-shadow: 0 10px 30px 0px rgba(89, 89, 89, 0.18);
          box-shadow: 0 10px 30px 0px rgba(89, 89, 89, 0.18); }
        .hero-area-v4 .hero-preview-galley .preview-inner img.image-3 {
          right: 180px;
          bottom: 105px;
          -webkit-box-shadow: 0 10px 30px 0px rgba(89, 89, 89, 0.1);
          box-shadow: 0 10px 30px 0px rgba(89, 89, 89, 0.1); }

/*======= Hero Area v5 =======*/
.hero-area-v5 {
  background-color: var(--color-dark);
  padding-top: 330px;
  padding-bottom: 230px;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .hero-area-v5::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../img/hero/map-bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.2; }
  .hero-area-v5 .container {
    position: relative;
    z-index: 1; }
  .hero-area-v5 .hero-thumbnail-wrap {
    height: 685px;
    width: 740px;
    position: absolute;
    right: -100px;
    top: -148px;
    z-index: -1; }
    .hero-area-v5 .hero-thumbnail-wrap .hero-thumbnail {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      .hero-area-v5 .hero-thumbnail-wrap .hero-thumbnail::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: var(--color-dark);
        opacity: 0.5; }
    .hero-area-v5 .hero-thumbnail-wrap::before {
      position: absolute;
      content: "";
      top: 10px;
      right: -10px;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-color: #299cdb; }
    .hero-area-v5 .hero-thumbnail-wrap::before,
    .hero-area-v5 .hero-thumbnail-wrap .hero-thumbnail,
    .hero-area-v5 .hero-thumbnail-wrap .hero-thumbnail::after {
      border-radius: 50% 50% 50% 50% / 0% 50% 50% 50%; }
  .hero-area-v5 .hero-content .hero-title {
    font-size: 90px;
    line-height: 1.12;
    letter-spacing: -2px;
    margin-bottom: 40px;
    color: var(--color-white); }
  .hero-area-v5 .hero-content p {
    font-size: 17px;
    color: var(--color-white);
    position: relative;
    padding-left: 32px;
    margin-bottom: 35px; }
    .hero-area-v5 .hero-content p::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 4px;
      background-color: #299cdb; }

/*======= Hero Area v6 =======*/
.hero-area-v6 {
  background-color: var(--color-dark); }
  .hero-area-v6 .single-slider {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1; }
    .hero-area-v6 .single-slider::after {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background: -webkit-linear-gradient(140deg, rgba(9, 16, 35, 0.2902) 0%, rgba(6, 14, 34, 0.58) 61%, rgba(4, 13, 34, 0.87843) 100%);
      background: -o-linear-gradient(140deg, rgba(9, 16, 35, 0.2902) 0%, rgba(6, 14, 34, 0.58) 61%, rgba(4, 13, 34, 0.87843) 100%);
      background: linear-gradient(310deg, rgba(9, 16, 35, 0.2902) 0%, rgba(6, 14, 34, 0.58) 61%, rgba(4, 13, 34, 0.87843) 100%); }
  .hero-area-v6 .hero-content {
    padding-top: 280px;
    padding-bottom: 180px; }
    .hero-area-v6 .hero-content .hero-title {
      font-size: 80px;
      line-height: 1.12;
      letter-spacing: -2px;
      margin-bottom: 40px;
      color: var(--color-white); }
    .hero-area-v6 .hero-content p {
      font-size: 17px;
      color: var(--color-white);
      position: relative;
      padding-left: 32px;
      margin-bottom: 35px; }
      .hero-area-v6 .hero-content p::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: var(--color-white); }
    .hero-area-v6 .hero-content .template-btn {
      -webkit-box-shadow: none;
      box-shadow: none; }
    .hero-area-v6 .hero-content .play-text-btn {
      font-size: 15px;
      font-family: var(--font-cs-bold);
      color: var(--color-white);
      position: relative;
      margin-left: 14px; }
      .hero-area-v6 .hero-content .play-text-btn:after {
        position: absolute;
        content: "";
        height: 3px;
        width: 100%;
        background-color: var(--color-white);
        bottom: -2px;
        left: 0; }

/*======= Hero Area v7 =======*/
.hero-area-v7 {
  position: relative;
  z-index: 1;
  padding-left: calc((100% - 1200px) / 2);
  padding-top: 200px;
  padding-bottom: 125px; }
  .hero-area-v7::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    background-color: var(--color-soft-grey);
    z-index: -1; }
  .hero-area-v7 .hero-img {
    position: absolute;
    left: 50%;
    top: 15%;
    bottom: 0;
    max-width: 50%;
    max-height: 90%;
  }
  .hero-area-v7 .hero-content {
    max-width: 35%; }
    .hero-area-v7 .hero-content .hero-title {
      font-size: 80px;
      line-height: 1.05;
      letter-spacing: -2px;
      margin-bottom: 45px; }
    .hero-area-v7 .hero-content p {
      font-size: 17px;
      position: relative;
      padding-left: 30px;
      margin-bottom: 45px; }
      .hero-area-v7 .hero-content p::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: #dde6ff; }
    .hero-area-v7 .hero-content .notice {
      margin-top: 35px;
      display: block;
      font-size: 17px; }
      .hero-area-v7 .hero-content .notice img {
        margin-right: 15px; }

/*======= Hero Area v8 =======*/
.hero-area-v8 {
  overflow: hidden; }
  .hero-area-v8 .hero-content {
    padding: 250px 60px 150px 0;
    position: relative;
    z-index: 6; }
    .hero-area-v8 .hero-content .hero-title {
      font-size: 60px;
      line-height: 1.05;
      letter-spacing: -2px;
      color: var(--color-dark-2);
      margin-bottom: 45px; }
    .hero-area-v8 .hero-content p {
      font-size: 17px;
      color: #abb0bf;
      position: relative;
      padding-left: 30px;
      margin-bottom: 45px; }
      .hero-area-v8 .hero-content p::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: #abb0bf; }
    .hero-area-v8 .hero-content .notice {
      margin-top: 35px;
      display: block;
      color: #abb0bf;
      font-size: 17px; }
      .hero-area-v8 .hero-content .notice i {
        margin-right: 8px;
        color: #f7d71e; }
  .hero-area-v8 .hero-img {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .hero-area-v8 .hero-img .hero-img-inner {
      position: relative;
      z-index: 1; }
    .hero-area-v8 .hero-img .circle-shape {
      position: absolute;
      left: 0;
      top: 0;
      -webkit-transform: translate(-42%, -42%);
      -ms-transform: translate(-42%, -42%);
      transform: translate(-42%, -42%);
      z-index: -1;
      opacity: 0.15; }
    .hero-area-v8 .hero-img::before {
      content: "";
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 0;
      z-index: -1;
      height: 100%;
      width: 150%;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain; }

/*======= Hero Area v9 =======*/
.hero-area-v9 {
  padding: 220px 0 0;
  background-color: var(--color-secondary-6);
  position: relative;
  z-index: 1; }
  .hero-area-v9::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: transparent;
    background-image: -webkit-radial-gradient(at center left, var(--color-primary-7) 0%, var(--color-secondary-6) 56%);
    background-image: -o-radial-gradient(at center left, var(--color-primary-7) 0%, var(--color-secondary-6) 56%);
    background-image: radial-gradient(at center left, var(--color-primary-7) 0%, var(--color-secondary-6) 56%);
    opacity: 0.5; }
  .hero-area-v9 .hero-content {
    position: relative;
    z-index: 2; }
    .hero-area-v9 .hero-content .hero-title {
      font-size: 200px;
      line-height: 0.85;
      letter-spacing: -0.027em;
      color: var(--color-heading-5);
      margin-bottom: 30px; }
    .hero-area-v9 .hero-content p {
      font-size: 18px;
      font-weight: 500;
      font-family: var(--font-cs-medium);
      color: var(--color-heading-5);
      margin-bottom: 30px; }
  .hero-area-v9 .hero-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .hero-area-v9 .hero-images .image-one {
      width: 60%;
      max-width: 60%; }
      .hero-area-v9 .hero-images .image-one .image-inner {
        margin: 15px -200px 0px 0px; }
    .hero-area-v9 .hero-images .image-two {
      width: 40%;
      max-width: 40%; }
      .hero-area-v9 .hero-images .image-two .image-inner {
        margin: -70px -300px 0px 230px; }
    .hero-area-v9 .hero-images .image-three {
      width: 60%;
      max-width: 60%; }
      .hero-area-v9 .hero-images .image-three .image-inner {
        margin: 30px 30px -30px -150px; }
    .hero-area-v9 .hero-images .image-four {
      width: 40%;
      max-width: 40%; }
      .hero-area-v9 .hero-images .image-four .image-inner {
        margin: 30px -150px 0px 0px; }

/*======= Hero Area v10 =======*/
.hero-area-v10 {
  position: relative;
  z-index: 1; }
  .hero-area-v10 .hero-content {
    padding-top: 245px;
    padding-bottom: 130px; }
    @media (max-width: 1199px) {
      .hero-area-v10 .hero-content {
        padding-top: 170px;
        padding-bottom: 80px; } }
    .hero-area-v10 .hero-content h1 {
      font-size: 200px;
      line-height: 0.88;
      margin-bottom: 45px;
      letter-spacing: -10px;
      color: var(--color-white); }
      @media (max-width: 1399px) {
        .hero-area-v10 .hero-content h1 {
          font-size: 150px;
          letter-spacing: -5px; } }
      @media (max-width: 1199px) {
        .hero-area-v10 .hero-content h1 {
          font-size: 100px;
          letter-spacing: 0; } }
      @media (max-width: 767px) {
        .hero-area-v10 .hero-content h1 {
          font-size: 80px;
          line-height: 1;
          margin-bottom: 20px; } }
      @media (max-width: 575px) {
        .hero-area-v10 .hero-content h1 {
          font-size: 60px; } }
      @media (max-width: 424px) {
        .hero-area-v10 .hero-content h1 {
          font-size: 50px; } }
    .hero-area-v10 .hero-content p {
      font-size: 18px;
      color: var(--color-white); }
      @media (max-width: 424px) {
        .hero-area-v10 .hero-content p {
          font-size: 16px; } }
    .hero-area-v10 .hero-content .hero-btn-contact-info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-top: 10px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .hero-area-v10 .hero-content .hero-btn-contact-info .template-btn {
        margin-top: 20px;
        margin-right: 40px;
        margin-bottom: 20px; }
      .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info .icon {
          color: white;
          line-height: 1;
          font-size: 50px;
          margin-right: 25px;
          padding-right: 25px;
          border-right: 5px solid; }
          @media (max-width: 424px) {
            .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info .icon {
              font-size: 40px; } }
        .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info .content h6 {
          color: white;
          font-size: 14px;
          margin-top: 8px;
          margin-bottom: 8px;
          text-transform: uppercase; }
        .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info .content > a {
          color: white;
          line-height: 1;
          font-size: 30px;
          font-family: var(--font-cs-medium); }
          @media (max-width: 424px) {
            .hero-area-v10 .hero-content .hero-btn-contact-info .hero-contact-info .content > a {
              font-size: 25px; } }
  .hero-area-v10:before {
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    background: -webkit-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background: -o-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background: linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%); }
  .hero-area-v10 .hero-right-man {
    bottom: 0;
    right: 13%;
    z-index: -1;
    max-width: 40%;
    position: absolute; }
    @media (max-width: 1199px) {
      .hero-area-v10 .hero-right-man {
        right: 5%; } }

/*======= Hero Area v10 =======*/
.hero-area-v11 {
  z-index: 1;
  position: relative; }
  @media (max-width: 575px) {
    .hero-area-v11 .hero-content {
      padding-bottom: 100px;
      padding-top: 155px; } }
  .hero-area-v11 .hero-content h1 {
    color: white;
    font-size: 150px;
    letter-spacing: -10px; }
    @media (max-width: 1399px) {
      .hero-area-v11 .hero-content h1 {
        font-size: 100px;
        letter-spacing: -5px; } }
    @media (max-width: 1199px) {
      .hero-area-v11 .hero-content h1 {
        font-size: 80px; } }
    @media (max-width: 767px) {
      .hero-area-v11 .hero-content h1 {
        font-size: 60px;
        letter-spacing: -2px; } }
    @media (max-width: 575px) {
      .hero-area-v11 .hero-content h1 {
        font-size: 50px; } }
    @media (max-width: 424px) {
      .hero-area-v11 .hero-content h1 {
        font-size: 40px; } }
  .hero-area-v11 .hero-content p {
    color: #b6b8da;
    font-size: 17px;
    max-width: 700px;
    padding-left: 20px;
    border-left: 3px solid var(--color-secondary); }
    @media (max-width: 424px) {
      .hero-area-v11 .hero-content p {
        font-size: 15px;
        border-width: 2px;
        padding-left: 15px; } }
  .hero-area-v11 .hero-content .read-more {
    color: #b6b8da;
    font-size: 15px;
    margin-left: 20px;
    padding-bottom: 2px;
    font-family: var(--font-cs-bold);
    border-bottom: 1px solid rgba(255, 255, 255, 0.35); }
    .hero-area-v11 .hero-content .read-more i {
      font-size: 12px;
      margin-left: 5px; }
  @media (min-width: 1200px) {
    .hero-area-v11 .container:not(:first-child) {
      max-width: 1365px; } }
  .hero-area-v11 .hero-bg-shapes {
    left: 0;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 75%;
    overflow: hidden;
    position: absolute;
    background: #0d0f2c; }
    @media (max-width: 767px) {
      .hero-area-v11 .hero-bg-shapes {
        height: 85%; } }
    @media (max-width: 575px) {
      .hero-area-v11 .hero-bg-shapes {
        height: 90%; } }
    .hero-area-v11 .hero-bg-shapes:after, .hero-area-v11 .hero-bg-shapes:before {
      content: '';
      z-index: -2;
      border-radius: 50%;
      position: absolute;
      background: #fd695a; }
      @media (max-width: 991px) {
        .hero-area-v11 .hero-bg-shapes:after, .hero-area-v11 .hero-bg-shapes:before {
          opacity: 0.5; } }
    .hero-area-v11 .hero-bg-shapes:before {
      left: 4%;
      bottom: 10%;
      width: 200px;
      height: 200px;
      -webkit-filter: blur(60px) opacity(0.5);
      filter: blur(60px) opacity(0.5); }
    .hero-area-v11 .hero-bg-shapes:after {
      top: 20%;
      right: 20%;
      width: 400px;
      height: 400px;
      -webkit-filter: blur(150px) opacity(0.65);
      filter: blur(150px) opacity(0.65); }
    .hero-area-v11 .hero-bg-shapes .hero-shape-one,
    .hero-area-v11 .hero-bg-shapes .hero-shape-two {
      z-index: -1;
      position: absolute; }
    .hero-area-v11 .hero-bg-shapes .hero-shape-one {
      top: 20%;
      right: 15%;
      max-width: 15%; }
    .hero-area-v11 .hero-bg-shapes .hero-shape-two {
      top: 50%;
      right: 30%;
      max-width: 10%; }

/* Hero Area 12 */
.hero-area-v12 {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  padding-top: 285px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #040d1a;
  padding-bottom: 200px; }
  @media (max-width: 1399px) {
    .hero-area-v12 {
      padding-bottom: 100px;
      padding-top: 185px; } }
  @media (max-width: 1199px) {
    .hero-area-v12 {
      padding-top: 175px; } }
  .hero-area-v12 .hero-content h1 {
    color: white;
    font-size: 70px; }
    @media (max-width: 1399px) {
      .hero-area-v12 .hero-content h1 {
        font-size: 60px; } }
    @media (max-width: 991px) {
      .hero-area-v12 .hero-content h1 {
        font-size: 55px; } }
    @media (max-width: 767px) {
      .hero-area-v12 .hero-content h1 {
        font-size: 50px; } }
    @media (max-width: 575px) {
      .hero-area-v12 .hero-content h1 {
        font-size: 44px; } }
    @media (max-width: 424px) {
      .hero-area-v12 .hero-content h1 {
        font-size: 34px; } }
  .hero-area-v12 .hero-content p {
    color: #abb0bf;
    font-size: 17px;
    max-width: 400px;
    padding-left: 25px;
    border-left: 3px solid; }
  .hero-area-v12 .hero-content .hero-btns {
    margin: -5px; }
    .hero-area-v12 .hero-content .hero-btns .template-btn {
      margin: 5px; }
  .hero-area-v12 .hero-bg-image {
    right: 0;
    bottom: 50%;
    z-index: -1;
    max-width: 80%;
    position: absolute;
    -webkit-transform: translateY(53%);
    -ms-transform: translateY(53%);
    transform: translateY(53%); }

/*======= Hero Slider Arrow & Nav =======*/
.hero-slider .slick-dots {
  position: absolute;
  right: 13%;
  top: 50%;
  display: block;
  margin: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  .hero-slider .slick-dots li {
    display: block;
    margin: 20px 0;
    background-color: var(--color-white); }
    .hero-slider .slick-dots li::after {
      border-color: var(--color-white); }
    .hero-slider .slick-dots li.slick-active {
      background-color: var(--color-white); }

.hero-slider .slick-arrow {
  position: absolute;
  right: 4%;
  bottom: 0;
  height: 50px;
  width: 50px;
  background-color: var(--color-white);
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-heading);
  font-size: 18px;
  z-index: 2;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .hero-slider .slick-arrow.prev-arrow {
    right: calc(4% + 52px); }
  .hero-slider .slick-arrow:hover {
    background-color: var(--color-primary-4);
    color: var(--color-white); }

/*======= Common Heading =======*/
.common-heading .tagline {
  font-size: 18px;
  font-family: var(--font-cs-medium);
  line-height: 1.4;
  margin-bottom: 15px; }

.common-heading .title {
  font-size: 42px;
  letter-spacing: -2px;
  line-height: 1.2; }

.common-heading.tagline-boxed .tagline {
  background-color: #d3d4f8;
  color: #299cdb;
  padding: 4px 18px;
  border-radius: 30px;
  margin-bottom: 25px; }

.common-heading.tagline-boxed-two .tagline {
  background-color: #e5ecfc;
  color: var(--color-primary-2);
  padding: 4px 18px;
  border-radius: 30px;
  margin-bottom: 25px; }

.common-heading.title-line .title span {
  color: var(--color-primary-2);
  position: relative;
  z-index: 1; }
  .common-heading.title-line .title span img {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -25px; }

.common-heading.title-line.line-less-bottom .title span img {
  bottom: -10px; }

.common-heading.title-line-bottom .heading-line {
  margin-top: 10px; }

.common-heading.heading-white .tagline {
  color: var(--color-white); }

.common-heading.heading-white.tagline-boxed .tagline {
  background-color: #299cdb; }

.common-heading.heading-white.tagline-boxed-two .tagline {
  background-color: #1a53e8; }

.common-heading.heading-white .title {
  color: var(--color-white); }

.common-heading-2 .tagline {
  font-size: 14px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 20px;
  letter-spacing: 4px;
  word-spacing: 2px; }

.common-heading-2 .title {
  font-size: 42px;
  letter-spacing: -1px;
  line-height: 1.2; }

.common-heading-2.heading-white .tagline {
  color: var(--color-white); }

.common-heading-2.heading-white .title {
  color: var(--color-white); }

/*======= Check List =======*/
.check-list li {
  font-size: 18px;
  color: var(--color-body);
  position: relative;
  z-index: 1;
  padding-left: 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .check-list li:not(:last-child) {
    margin-bottom: 10px; }
  .check-list li::after {
    position: absolute;
    content: "\f00c";
    left: 0;
    top: 2px;
    font-family: "Font Awesome 5 Pro";
    font-size: 16px;
    color: #299cdb;
    font-weight: 800; }

/*======= Check List Two =======*/
.check-list-2 li {
  position: relative;
  z-index: 1;
  padding-left: 40px;
  color: var(--color-heading); }
  .check-list-2 li::before {
    position: absolute;
    content: "\f00c";
    left: 0;
    height: 25px;
    width: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    background-color: var(--color-primary-3);
    color: var(--color-white);
    font-size: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%; }
  .check-list-2 li:not(:last-child) {
    margin-bottom: 12px; }

/*======= Check List Three =======*/
.check-list-3 li {
  position: relative;
  padding-left: 50px; }
  .check-list-3 li:not(:last-child) {
    margin-bottom: 30px; }
  .check-list-3 li::before {
    position: absolute;
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--color-primary-6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--color-white);
    font-size: 15px; }
  .check-list-3 li .title {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 10px; }

/*======= Simple Icon Box =======*/
.simple-icon-boxes .icon {
  margin-bottom: 20px;
  font-size: 40px;
  color: #299cdb;
  line-height: 0.5; }

.simple-icon-boxes .title {
  font-size: 20px;
  line-height: 1.15; }

.simple-icon-boxes p {
  margin-top: 10px; }

/*======= Icon Box =======*/
.icon-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1; }
  .icon-box .icon {
    color: var(--color-primary-7);
    margin-right: 15px;
    font-size: 50px;
    line-height: 0.5; }
  .icon-box h5 {
    font-size: 35px;
    letter-spacing: -0.2px; }
  .icon-box span {
    font-size: 18px; }

/*======= Image Boxes v1 =======*/
.image-boxes-v1 .image-box {
  background: #fff;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
  box-shadow: 0px 10px 30px 0px rgba(225, 223, 255, 0.4);
  padding: 50px 50px;
  text-align: center;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s; }
  .image-boxes-v1 .image-box:before {
    position: absolute;
    content: "";
    height: 3px;
    width: 0;
    top: 0;
    left: 0;
    background: #299cdb;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
  .image-boxes-v1 .image-box .thumb {
    margin-bottom: 30px; }
  .image-boxes-v1 .image-box .title {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700; }
  .image-boxes-v1 .image-box .box-link {
    height: 55px;
    width: 55px;
    border-radius: 50px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    z-index: 1;
    color: #299cdb;
    border: 2px solid #ecebfd;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .image-boxes-v1 .image-box:hover::before, .image-boxes-v1 .image-box.active::before {
    width: 100%; }
  .image-boxes-v1 .image-box:hover .box-link, .image-boxes-v1 .image-box.active .box-link {
    border-color: #473bf0; }

.image-boxes-v1.image-thumbnail-boxed .image-box {
  padding: 45px 30px;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: relative; }
  .image-boxes-v1.image-thumbnail-boxed .image-box .thumb {
    background-color: #f2f5fe;
    height: 180px;
    width: 180px;
    border-radius: 50%;
    margin: 0 auto 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .image-boxes-v1.image-thumbnail-boxed .image-box .thumb img {
      max-width: 120px; }
  .image-boxes-v1.image-thumbnail-boxed .image-box .title {
    font-size: 22px; }
  .image-boxes-v1.image-thumbnail-boxed .image-box:before {
    background-color: var(--color-primary-2); }
  .image-boxes-v1.image-thumbnail-boxed .image-box .box-link {
    color: var(--color-primary-2); }
  .image-boxes-v1.image-thumbnail-boxed .image-box:hover .box-link {
    background-color: var(--color-primary-2);
    border-color: var(--color-primary-2);
    color: var(--color-white); }

/*======= Image Boxes v2 =======*/
.image-boxes-v2 .image-box .box-image {
  border-radius: 7px;
  overflow: hidden;
  width: 100%;
  height: 210px;
  position: relative;
  z-index: 1; }
  .image-boxes-v2 .image-box .box-image img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.image-boxes-v2 .image-box .box-content {
  background-color: var(--color-white);
  border-radius: 0 0 7px 7px;
  padding: 30px; }
  .image-boxes-v2 .image-box .box-content .title {
    font-size: 18px;
    margin-bottom: 15px; }
  .image-boxes-v2 .image-box .box-content .box-link {
    color: #7a7a7a;
    margin-top: 12px;
    font-size: 15px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    line-height: 1;
    position: relative;
    z-index: 1; }
    .image-boxes-v2 .image-box .box-content .box-link i {
      margin-left: 8px; }
    .image-boxes-v2 .image-box .box-content .box-link::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 0;
      background-color: #299cdb;
      left: 0;
      bottom: -4px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }

.image-boxes-v2 .image-box:hover .box-image img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.image-boxes-v2 .image-box:hover .box-link {
  color: #299cdb; }
  .image-boxes-v2 .image-box:hover .box-link::after {
    width: 100%; }

/*======= Image Boxes v3 =======*/
.image-boxes-v3 .image-box {
  background-color: var(--color-white);
  border-radius: 15px;
  padding: 40px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .image-boxes-v3 .image-box .box-image {
    width: 130px;
    height: 130px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 130px;
    flex: 0 0 130px;
    margin-right: 35px; }
    .image-boxes-v3 .image-box .box-image img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -o-object-position: center;
      object-position: center;
      -o-object-fit: cover;
      object-fit: cover; }
  .image-boxes-v3 .image-box .box-content .title {
    font-size: 24px;
    margin-bottom: 10px; }
  .image-boxes-v3 .image-box .box-content .box-link {
    color: var(--color-body-3);
    margin-top: 12px;
    font-size: 15px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    line-height: 1;
    position: relative;
    z-index: 1; }
    .image-boxes-v3 .image-box .box-content .box-link i {
      margin-left: 8px; }
  .image-boxes-v3 .image-box::before, .image-boxes-v3 .image-box::after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border-radius: 15px;
    z-index: -1;
    width: 100%;
    height: 100%; }
  .image-boxes-v3 .image-box::before {
    opacity: 0;
    visibility: hidden;
    left: -5px;
    top: -5px;
    z-index: -1;
    background-color: var(--color-primary-4);
    -webkit-transform: rotateY(-70deg);
    transform: rotateY(-70deg);
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    transform-origin: left; }
  .image-boxes-v3 .image-box::after {
    left: 0;
    top: 0;
    background-color: var(--color-white);
    opacity: 1; }
  .image-boxes-v3 .image-box:hover {
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
    box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1); }
    .image-boxes-v3 .image-box:hover::before {
      visibility: visible;
      opacity: 1;
      -webkit-transform: rotateY(0);
      transform: rotateY(0); }

/*======= Iconic Boxes v1  =======*/
.iconic-boxes-v1 .iconic-box {
  position: relative;
  z-index: 1;
  padding: 45px 30px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
  box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3); }
  .iconic-boxes-v1 .iconic-box.no-shadow {
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .iconic-boxes-v1 .iconic-box .icon {
    height: 60px;
    width: 60px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #299cdb;
    color: var(--color-white);
    border-radius: 50%;
    font-size: 30px;
    color: #fff;
    margin-bottom: 30px; }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-1 {
      background: -webkit-linear-gradient(320deg, #7839f3 0%, #b31de1 59%, #ed00ce 100%);
      background: -o-linear-gradient(320deg, #7839f3 0%, #b31de1 59%, #ed00ce 100%);
      background: linear-gradient(130deg, #7839f3 0%, #b31de1 59%, #ed00ce 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28); }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-2 {
      background-image: -webkit-linear-gradient(320deg, #ff9200 0%, #f64967 59%, #ed00ce 100%);
      background-image: -o-linear-gradient(320deg, #ff9200 0%, #f64967 59%, #ed00ce 100%);
      background-image: linear-gradient(130deg, #ff9200 0%, #f64967 59%, #ed00ce 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(253, 132, 19, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(253, 132, 19, 0.28); }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-3 {
      background-image: -webkit-linear-gradient(320deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
      background-image: -o-linear-gradient(320deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
      background-image: linear-gradient(130deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28); }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-4 {
      background-image: -webkit-linear-gradient(320deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
      background-image: -o-linear-gradient(320deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
      background-image: linear-gradient(130deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28); }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-5 {
      background-image: -webkit-linear-gradient(320deg, #1f5dfb 0%, #10a5cf 59%, #00eda2 100%);
      background-image: -o-linear-gradient(320deg, #1f5dfb 0%, #10a5cf 59%, #00eda2 100%);
      background-image: linear-gradient(130deg, #1f5dfb 0%, #10a5cf 59%, #00eda2 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(33, 113, 238, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(33, 113, 238, 0.28); }
    .iconic-boxes-v1 .iconic-box .icon.icon-gradient-6 {
      background-image: -webkit-linear-gradient(320deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
      background-image: -o-linear-gradient(320deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
      background-image: linear-gradient(130deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
      box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28); }
  .iconic-boxes-v1 .iconic-box .title {
    font-size: 22px;
    margin-bottom: 15px; }
  .iconic-boxes-v1 .iconic-box p {
    color: var(--color-body-2); }
  .iconic-boxes-v1 .iconic-box .box-link {
    color: var(--color-body-2);
    font-size: 15px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    text-transform: capitalize;
    position: relative;
    z-index: 1;
    line-height: 1;
    margin-top: 20px; }
    .iconic-boxes-v1 .iconic-box .box-link i {
      padding-left: 10px; }
    .iconic-boxes-v1 .iconic-box .box-link::before, .iconic-boxes-v1 .iconic-box .box-link::after {
      position: absolute;
      content: "";
      height: 2px;
      width: 100%;
      background-color: var(--color-body-2);
      left: 0;
      bottom: -3px;
      z-index: -1;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
    .iconic-boxes-v1 .iconic-box .box-link::after {
      width: 40%;
      background-color: var(--color-heading);
      opacity: 0; }
  .iconic-boxes-v1 .iconic-box:hover .box-link {
    color: var(--color-heading); }
    .iconic-boxes-v1 .iconic-box:hover .box-link::after {
      width: 100%;
      opacity: 1; }

/*======= Iconic Boxes v1 Square Bordered   =======*/
.iconic-boxes-v1.icon-boxes-square-bordered {
  border-left: 1px solid rgba(54, 35, 75, 0.07);
  border-top: 1px solid rgba(54, 35, 75, 0.07); }
  .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box {
    padding: 38px 35px;
    -webkit-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
    border-right: 1px solid rgba(54, 35, 75, 0.07);
    border-bottom: 1px solid rgba(54, 35, 75, 0.07);
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none; }
    .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box::before, .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box::after {
      content: "";
      position: absolute;
      -webkit-transition: all 0.3s linear;
      -o-transition: all 0.3s linear;
      transition: all 0.3s linear;
      border-radius: 10px;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      -webkit-transform: rotateY(-70deg);
      transform: rotateY(-70deg);
      -webkit-transform-origin: left;
      -ms-transform-origin: left;
      transform-origin: left; }
    .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box::before {
      left: -20px;
      top: -20px;
      bottom: -10px;
      right: -10px;
      background-color: var(--color-secondary); }
    .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box::after {
      left: -15px;
      top: -15px;
      bottom: -15px;
      right: -15px;
      background-color: var(--color-white);
      -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
      box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3); }
    .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box:hover {
      z-index: 2; }
      .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box:hover::before, .iconic-boxes-v1.icon-boxes-square-bordered .iconic-box:hover::after {
        visibility: visible;
        opacity: 1;
        -webkit-transform: rotateY(0);
        transform: rotateY(0); }

/*======= Iconic Boxes v2  =======*/
.iconic-boxes-v2 .iconic-box .icon {
  margin-bottom: 30px; }
  .iconic-boxes-v2 .iconic-box .icon img,
  .iconic-boxes-v2 .iconic-box .icon i {
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease; }

.iconic-boxes-v2 .iconic-box .title {
  font-size: 22px;
  margin-bottom: 15px; }
  .iconic-boxes-v2 .iconic-box .title a:hover {
    color: var(--color-primary-4); }

.iconic-boxes-v2 .iconic-box .box-link {
  margin-top: 30px;
  color: var(--color-primary-4);
  border: 2px solid #299cdb;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  z-index: 1; }
  .iconic-boxes-v2 .iconic-box .box-link::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color-primary-4);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.iconic-boxes-v2 .iconic-box:hover .box-link {
  color: var(--color-white);
  border-color: var(--color-primary-4); }
  .iconic-boxes-v2 .iconic-box:hover .box-link::after {
    width: 100%;
    height: 100%;
    visibility: visible;
    opacity: 1; }

.iconic-boxes-v2 .iconic-box:hover .icon img,
.iconic-boxes-v2 .iconic-box:hover .icon i {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg); }

.iconic-boxes-v2 .iconic-box.color-1 .title a:hover {
  color: #19cb55; }

.iconic-boxes-v2 .iconic-box.color-1 .box-link {
  color: #19cb55;
  border-color: #19cb55; }
  .iconic-boxes-v2 .iconic-box.color-1 .box-link::after {
    background-color: #19cb55; }

.iconic-boxes-v2 .iconic-box.color-1:hover .box-link {
  color: var(--color-white);
  border-color: #19cb55; }

.iconic-boxes-v2 .iconic-box.color-2 .title a:hover {
  color: #1d79fb; }

.iconic-boxes-v2 .iconic-box.color-2 .box-link {
  color: #1d79fb;
  border-color: #1d79fb; }
  .iconic-boxes-v2 .iconic-box.color-2 .box-link::after {
    background-color: #1d79fb; }

.iconic-boxes-v2 .iconic-box.color-2:hover .box-link {
  color: var(--color-white);
  border-color: #1d79fb; }

.iconic-boxes-v2 .iconic-box.color-3 .title a:hover {
  color: #f95f75; }

.iconic-boxes-v2 .iconic-box.color-3 .box-link {
  color: #f95f75;
  border-color: #f95f75; }
  .iconic-boxes-v2 .iconic-box.color-3 .box-link::after {
    background-color: #f95f75; }

.iconic-boxes-v2 .iconic-box.color-3:hover .box-link {
  color: var(--color-white);
  border-color: #f95f75; }

.iconic-boxes-v2 .iconic-box.color-4 .title a:hover {
  color: #ff8500; }

.iconic-boxes-v2 .iconic-box.color-4 .box-link {
  color: #ff8500;
  border-color: #ff8500; }
  .iconic-boxes-v2 .iconic-box.color-4 .box-link::after {
    background-color: #ff8500; }

.iconic-boxes-v2 .iconic-box.color-4:hover .box-link {
  color: var(--color-white);
  border-color: #ff8500; }

/*======= Iconic Boxes v3  =======*/
.icon-boxes-v3 .iconic-box .icon {
  position: relative;
  z-index: 1; }
  .icon-boxes-v3 .iconic-box .icon i {
    height: 65px;
    width: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--color-white);
    border-radius: 50%;
    background-color: var(--color-primary-5);
    font-size: 24px;
    line-height: 0.5;
    margin-bottom: 30px; }

.icon-boxes-v3 .iconic-box.have-icon-overly .icon::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 72px;
  height: 72px;
  background-color: var(--color-white);
  z-index: -1;
  border-radius: 50%;
  opacity: 0.07; }

.icon-boxes-v3 .iconic-box .title {
  font-size: 24px;
  margin-bottom: 15px;
  letter-spacing: -1px; }
  .icon-boxes-v3 .iconic-box .title a:hover {
    color: inherit; }

.icon-boxes-v3 .iconic-box .box-btn {
  border: 2px solid var(--color-body);
  color: var(--color-body-4);
  font-weight: 700;
  line-height: 1;
  padding: 13px 30px;
  border-radius: 30px;
  margin-top: 30px;
  font-size: 15px; }
  .icon-boxes-v3 .iconic-box .box-btn i {
    margin-left: 5px; }
  .icon-boxes-v3 .iconic-box .box-btn:hover {
    background-color: var(--color-primary-5);
    border-color: var(--color-primary-5);
    color: var(--color-white); }

.icon-boxes-v3 .iconic-box.color-v1 .icon i,
.icon-boxes-v3 .iconic-box.color-v1 .box-btn:hover {
  background-color: #2ecea8;
  border-color: #2ecea8; }

.icon-boxes-v3 .iconic-box.color-v2 .icon i,
.icon-boxes-v3 .iconic-box.color-v2 .box-btn:hover {
  background-color: #fead44;
  border-color: #fead44; }

.icon-boxes-v3 .iconic-box.color-v3 .icon i,
.icon-boxes-v3 .iconic-box.color-v3 .box-btn:hover {
  background-color: #ff00ff;
  border-color: #ff00ff; }

.icon-boxes-v3 .iconic-box.color-v4 .icon i,
.icon-boxes-v3 .iconic-box.color-v4 .box-btn:hover {
  background-color: #009fe3;
  border-color: #009fe3; }

.icon-boxes-v3 .iconic-box.box-version-white,
.icon-boxes-v3 .iconic-box.box-version-white .title a {
  color: var(--color-white); }

.icon-boxes-v3 .iconic-box.box-version-white .box-btn:not(:hover) {
  border-color: #46446b;
  color: #afade9; }

/*======= Icon Bordered Box  =======*/
.icon-bordered-box {
  padding: 30px;
  border: 1px solid #eaeaed; }
  .icon-bordered-box .title {
    font-size: 22px;
    letter-spacing: -1px;
    margin-bottom: 10px;
    line-height: 1.2; }
  .icon-bordered-box .icon {
    font-size: 30px;
    color: var(--color-primary-5);
    line-height: 0.5;
    margin-bottom: 20px; }
  .icon-bordered-box.icon-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start; }
    .icon-bordered-box.icon-left .icon {
      margin: 0 20px 0 0; }

/*======= Iconic Boxes v4  =======*/
.icon-boxes-v4 {
  position: relative;
  padding-right: 50px; }
  .icon-boxes-v4 .icon {
    margin-bottom: 30px; }
  .icon-boxes-v4 .title {
    font-size: 20px;
    line-height: 1.42;
    margin-bottom: 20px; }

/*======= Fancy Icon Boxes v1  =======*/
.fancy-icon-boxes-v1 .fancy-icon-box {
  border: 2px solid rgba(71, 59, 240, 0.07);
  padding: 20px 25px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  z-index: 1;
  border-radius: 7px; }
  .fancy-icon-boxes-v1 .fancy-icon-box::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 50%;
    height: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(../img/fancy-icon-box/fancy-circle-dots.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden; }
  .fancy-icon-boxes-v1 .fancy-icon-box .box-icon {
    font-size: 20px;
    line-height: 0.5;
     max-width: 20px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    color: #299cdb; }
    .fancy-icon-boxes-v1 .fancy-icon-box .box-icon img,
    .fancy-icon-boxes-v1 .fancy-icon-box .box-icon i {
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease; }
  .fancy-icon-boxes-v1 .fancy-icon-box .box-content {
    padding-left: 40px; }
  .fancy-icon-boxes-v1 .fancy-icon-box .title {
    font-size: 24px;
    margin-bottom: 15px;
    text-transform: capitalize; }
  .fancy-icon-boxes-v1 .fancy-icon-box:hover {
    border-color: #299cdb;
    background-color: #299cdb; }
    .fancy-icon-boxes-v1 .fancy-icon-box:hover::before {
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: visible;
      border-radius: 0; }
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .box-icon img,
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .box-icon i {
      -webkit-transform: rotateY(360deg);
      transform: rotateY(360deg); }
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .box-icon,
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .title,
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .title a,
    .fancy-icon-boxes-v1 .fancy-icon-box:hover .box-content {
      color: var(--color-white); }

.fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box {
  border-color: #262c3c; }
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box::before {
    display: none; }
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box .box-icon,
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box .title,
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box .title a {
    color: var(--color-white); }
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box .box-content {
    color: #a8acb7; }
  .fancy-icon-boxes-v1.boxes-white-version .fancy-icon-box:hover {
    background-color: transparent; }

/*======= Fancy Icon Boxes v2  =======*/
.fancy-icon-boxes-v2 .fancy-icon-box {
  border-radius: 7px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
  box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative; }
  .fancy-icon-boxes-v2 .fancy-icon-box::before, .fancy-icon-boxes-v2 .fancy-icon-box::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 0%;
    background-color: #ff0101;
    -webkit-transition: 0.3s linear;
    -o-transition: 0.3s linear;
    transition: 0.3s linear;
    visibility: hidden;
    border-radius: 2.5px; }
  .fancy-icon-boxes-v2 .fancy-icon-box::after {
    top: auto;
    bottom: 0; }
  .fancy-icon-boxes-v2 .fancy-icon-box .box-icon {
    color: #ff0101;
    font-size: 55px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 55px;
    flex: 0 0 55px;
    line-height: 0.5; }
    .fancy-icon-boxes-v2 .fancy-icon-box .box-icon img,
    .fancy-icon-boxes-v2 .fancy-icon-box .box-icon i {
      -webkit-transition: all 1s ease;
      -o-transition: all 1s ease;
      transition: all 1s ease; }
    .fancy-icon-boxes-v2 .fancy-icon-box .box-icon img {
      max-width: 55px; }
  .fancy-icon-boxes-v2 .fancy-icon-box .box-content {
    padding-left: 40px; }
    .fancy-icon-boxes-v2 .fancy-icon-box .box-content .title {
      font-size: 24px;
      margin-bottom: 15px; }
      .fancy-icon-boxes-v2 .fancy-icon-box .box-content .title a:hover {
        color: #ff0101; }
  .fancy-icon-boxes-v2 .fancy-icon-box:hover::before, .fancy-icon-boxes-v2 .fancy-icon-box:hover::after {
    height: 100%;
    visibility: visible; }
  .fancy-icon-boxes-v2 .fancy-icon-box:hover .box-icon img,
  .fancy-icon-boxes-v2 .fancy-icon-box:hover .box-icon i {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg); }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-2 .box-icon,
  .fancy-icon-boxes-v2 .fancy-icon-box.color-2 .title a:hover {
    color: #dc64ff; }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-2::before, .fancy-icon-boxes-v2 .fancy-icon-box.color-2::after {
    background-color: #dc64ff; }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-3 .box-icon,
  .fancy-icon-boxes-v2 .fancy-icon-box.color-3 .title a:hover {
    color: #2f84fb; }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-3::before, .fancy-icon-boxes-v2 .fancy-icon-box.color-3::after {
    background-color: #2f84fb; }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-4 .box-icon,
  .fancy-icon-boxes-v2 .fancy-icon-box.color-4 .title a:hover {
    color: #ff6c02; }
  .fancy-icon-boxes-v2 .fancy-icon-box.color-4::before, .fancy-icon-boxes-v2 .fancy-icon-box.color-4::after {
    background-color: #ff6c02; }

/*======= Preview Gallery v1 =======*/
.preview-galley-v1 {
  position: relative;
  height: 645px; }
  .preview-galley-v1 img {
    border-radius: 10px;
    -webkit-box-shadow: 0 10px 30px 0px rgba(185, 182, 235, 0.4);
    box-shadow: 0 10px 30px 0px rgba(185, 182, 235, 0.4);
    position: relative;
    z-index: 1; }
    .preview-galley-v1 img.preview-image-2 {
      position: absolute;
      left: 26%;
      z-index: 2;
      top: 75px; }
    .preview-galley-v1 img.preview-image-3 {
      position: absolute;
      right: 80px;
      z-index: 3;
      top: 25px; }
    .preview-galley-v1 img.preview-image-4 {
      position: absolute;
      left: 30px;
      z-index: 1;
      bottom: 0; }
    .preview-galley-v1 img.preview-image-5 {
      position: absolute;
      right: 20%;
      z-index: 4;
      bottom: 78px; }
    .preview-galley-v1 img.preview-image-6 {
      position: absolute;
      right: 65px;
      bottom: 40%;
      z-index: 1; }

/*======= Preview Gallery v2 =======*/
.preview-galley-v2 {
  position: relative;
  z-index: 1;
  padding: 80px 70px 80px 0; }
  .preview-galley-v2::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 75%;
    height: 100%;
    background-color: #f2f1fe;
    background-image: url(../img/preview-gallery/preview-2-line.png);
    z-index: -1; }
  .preview-galley-v2 img {
    border-radius: 10px;
    vertical-align: top; }
  .preview-galley-v2 .image-1,
  .preview-galley-v2 .image-2 {
    margin-bottom: 30px; }
    .preview-galley-v2 .image-1 img,
    .preview-galley-v2 .image-2 img {
      -webkit-box-shadow: 0px 10px 30px 0px rgba(184, 179, 252, 0.49);
      box-shadow: 0px 10px 30px 0px rgba(184, 179, 252, 0.49); }
  .preview-galley-v2 .image-4 img {
    margin-top: -80px; }

/*======= Preview Gallery v3 =======*/
.preview-galley-v3 {
  position: relative;
  z-index: 1;
  text-align: center; }
  .preview-galley-v3::after {
    position: absolute;
    content: "";
    background-image: url(../img/preview-gallery/preview-two-line.png);
    background-repeat: no-repeat;
    background-size: 90% 100%;
    top: -95px;
    right: 0px;
    height: 150%;
    width: 100%;
    z-index: -1; }
  .preview-galley-v3 img.preview-image-2 {
    position: absolute;
    top: -60px;
    right: 70px;
    z-index: 1; }
  .preview-galley-v3 img.preview-image-3 {
    position: absolute;
    bottom: -40px;
    left: 30px;
    z-index: 1; }

/*======= Preview Gallery v4 =======*/
.preview-galley-v4 {
  position: relative;
  z-index: 1;
  text-align: center;
  width: 600px;
  height: 470px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .preview-galley-v4 img {
    border-radius: 15px;
    position: relative;
    z-index: 1; }
    .preview-galley-v4 img.preview-image-2 {
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .preview-galley-v4 img.preview-image-3 {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(22, 22, 44, 0.18);
      box-shadow: 0px 10px 30px 0px rgba(22, 22, 44, 0.18); }
    .preview-galley-v4 img.preview-image-4 {
      position: absolute;
      position: absolute;
      right: 0;
      bottom: 40px;
      z-index: 1;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(22, 22, 44, 0.18);
      box-shadow: 0px 10px 30px 0px rgba(22, 22, 44, 0.18); }
  .preview-galley-v4::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 130%;
    height: 180%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -2;
    background-image: url(../img/preview-gallery/oval-gradient-2.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center; }

/*======= Preview Gallery v5 =======*/
.preview-galley-v5 {
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .preview-galley-v5 .preview-image-one img {
    border-radius: 18px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(193, 193, 193, 0.18);
    box-shadow: 0px 10px 30px 0px rgba(193, 193, 193, 0.18); }
  .preview-galley-v5 .preview-image-two {
    margin-left: -100px; }
    .preview-galley-v5 .preview-image-two img {
      border-radius: 20px;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(173, 173, 173, 0.24);
      box-shadow: 0px 10px 30px 0px rgba(173, 173, 173, 0.24); }
  .preview-galley-v5::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 150%;
    height: 125%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center; }

/*======= Preview Gallery v6 =======*/
.preview-galley-v6 {
  position: relative;
  z-index: 1;
  min-height: 435px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .preview-galley-v6 .preview-image-bottom,
  .preview-galley-v6 .preview-image-top {
    position: absolute;
    z-index: 2; }
    .preview-galley-v6 .preview-image-bottom img,
    .preview-galley-v6 .preview-image-top img {
      border-radius: 20px;
      -webkit-box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.15);
      box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.15); }
  .preview-galley-v6 .preview-image-top {
    top: 0;
    right: 0; }
  .preview-galley-v6 .preview-image-bottom {
    bottom: 0;
    left: 0; }

/*======= Preview Gallery v7 =======*/
.preview-galley-v7 {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 50px 60px 60px; }
  .preview-galley-v7 .main-img img {
    border-radius: 7px;
    width: 100%; }
  .preview-galley-v7 .preview-image-top,
  .preview-galley-v7 .preview-image-bottom {
    position: absolute;
    z-index: 2; }
  .preview-galley-v7 .preview-image-top {
    right: 0;
    top: 0; }
  .preview-galley-v7 .preview-image-bottom {
    left: 0;
    bottom: 0; }
    .preview-galley-v7 .preview-image-bottom img {
      border-radius: 5px;
      -webkit-box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.15);
      box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.15); }

/*======= Preview Gallery v8 =======*/
.preview-galley-v8 {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 550px; }
  .preview-galley-v8 img {
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.1);
    box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.1);
    border-radius: 30px; }
    .preview-galley-v8 img.preview-three {
      position: absolute;
      left: 15%;
      bottom: 20px; }
    .preview-galley-v8 img.preview-two {
      position: absolute;
      left: 45px;
      top: 80px; }

/*======= Preview Gallery v9 =======*/
.preview-galley-v9 {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 430px;
  margin: auto; }
  .preview-galley-v9 .image-two {
    position: absolute;
    left: 0;
    top: 30%;
    z-index: 2; }
  .preview-galley-v9::before, .preview-galley-v9::after {
    content: "";
    position: absolute;
    height: 300px;
    width: 150px;
    z-index: -1;
    background-color: #299cdb; }
  .preview-galley-v9::before {
    left: 0;
    top: -40px;
    border-top-left-radius: 300px;
    border-bottom-left-radius: 300px; }
  .preview-galley-v9::after {
    bottom: -40px;
    right: 0;
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px; }
  .preview-galley-v9 .icons img {
    position: absolute;
    z-index: 1; }
  .preview-galley-v9 .icons .icon-one {
    right: 35px;
    top: 0; }
  .preview-galley-v9 .icons .icon-two {
    right: 20px;
    bottom: 15px; }
  .preview-galley-v9 .icons .icon-three {
    left: 10px;
    bottom: 100px; }

/*======= Preview Image v1 =======*/
.preview-image-v1 {
  position: relative;
  z-index: 1; }
  .preview-image-v1::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    z-index: -1;
    top: 0;
    left: 0;
    background-image: url(../img/preview-gallery/oval-gradient.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat; }

/*======= Preview Image v2 =======*/
.preview-image-v2 {
  position: relative;
  z-index: 1; }
  .preview-image-v2:after {
    position: absolute;
    content: "";
    height: 275px;
    width: 275px;
    background-color: var(--color-white);
    top: -75px;
    right: -100px;
    z-index: -1;
    border-radius: 50%; }
  .preview-image-v2 img {
    border-radius: 35px;
    -webkit-box-shadow: 0 10px 80px rgba(54, 35, 75, 0.27);
    box-shadow: 0 10px 80px rgba(54, 35, 75, 0.27); }

/*======= Preview Image v3 =======*/
.preview-image-v3 {
  position: relative;
  z-index: 1; }
  .preview-image-v3::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 140%;
    height: 120%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: -1;
    background-image: url(../img/preview-gallery/oval-gradient-2.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center; }

/*======= Preview Image v4 =======*/
.preview-image-v4 {
  padding-right: 80px; }
  .preview-image-v4 img {
    border-radius: 20px; }
  .preview-image-v4 .image-two {
    margin-top: -170px;
    margin-right: -80px;
    position: relative;
    z-index: 2;
    text-align: right; }

/*======= Preview Image v5 =======*/
.preview-image-v5 {
  position: relative;
  z-index: 1; }
  .preview-image-v5 img {
    border-radius: 260px;
    width: 100%; }
  .preview-image-v5::before, .preview-image-v5::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 260px; }
  .preview-image-v5::after {
    background-color: var(--color-primary-5);
    bottom: -10px;
    right: -10px; }
  .preview-image-v5::before {
    left: -10px;
    top: -20px;
    border: 3px solid var(--color-soft-grey);
    background-color: transparent; }

/*======= Preview Square oval Image =======*/
.preview-square-oval-image {
  position: relative;
  padding-right: 107.5px;
  z-index: 1; }
  .preview-square-oval-image .square-image {
    position: relative;
    overflow: hidden; }
    .preview-square-oval-image .square-image::before {
      position: absolute;
      top: 0;
      left: -100%;
      content: "";
      width: 50%;
      height: 100%;
      background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0.7)), to(rgba(255, 255, 255, 0.3)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.3) 100%);
      -webkit-transform: skewX(25deg);
      -ms-transform: skewX(25deg);
      transform: skewX(25deg);
      z-index: 2; }
  .preview-square-oval-image .oval-image {
    position: absolute;
    right: 0;
    bottom: 80px;
    width: 215px;
    height: 215px;
    border-radius: 50%;
    z-index: 3;
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.3);
    box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.3);
    overflow: hidden; }
    .preview-square-oval-image .oval-image img {
      border-radius: 50%;
      height: 100%;
      width: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border: 10px solid var(--color-white); }
    .preview-square-oval-image .oval-image::before {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      content: "";
      width: 0px;
      height: 0px;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      opacity: 0;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%; }
  .preview-square-oval-image.with-counter-box {
    padding-left: 40px; }
  .preview-square-oval-image .counter-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.3);
    box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.3);
    border-radius: 12px;
    position: absolute;
    top: 65px;
    left: -30px;
    z-index: 5;
    background-color: var(--color-white);
    padding: 15px 30px; }
    .preview-square-oval-image .counter-item .count-icon {
      margin-right: 15px;
      background-color: #299cdb;
      height: 50px;
      width: 50px;
      font-size: 20px;
      border-radius: 50%;
      color: var(--color-white);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
    .preview-square-oval-image .counter-item .count-details {
      line-height: 1; }
      .preview-square-oval-image .counter-item .count-details .counter {
        font-size: 24px;
        font-weight: 500;
        font-family: var(--font-cs-bold);
        font-line-height: 1;
        color: var(--color-heading);
        margin-bottom: 8px; }
      .preview-square-oval-image .counter-item .count-details p {
        font-size: 17px;
        font-weight: 500;
        font-family: var(--font-cs-medium); }

/*======= Preview Blob Image =======*/
.preview-blob-image.with-floating-icon {
  position: relative;
  z-index: 1; }
  .preview-blob-image.with-floating-icon .floating-icons img {
    position: absolute;
    max-width: 50px;
    z-index: -1; }
  .preview-blob-image.with-floating-icon .floating-icons .icon-1 {
    left: 8%;
    top: 5%; }
  .preview-blob-image.with-floating-icon .floating-icons .icon-2 {
    right: 10%;
    top: 17%; }
  .preview-blob-image.with-floating-icon .floating-icons .icon-3 {
    left: 25%;
    bottom: -5%; }

/*======= Preview Image showcase =======*/
.preview-image-showcase {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  .preview-image-showcase .images-left,
  .preview-image-showcase .images-right {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    padding-left: 15px;
    padding-right: 15px; }
  .preview-image-showcase .images-left {
    margin-top: 60px; }
  .preview-image-showcase img {
    border-radius: 12px;
    margin-bottom: 30px; }

/*======= Preview Image v6 =======*/
.preview-image-v6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .preview-image-v6 .image-one {
    max-width: 35%;
    width: 35%;
    text-align: left;
    padding: 70px 30px 0px 0px; }
  .preview-image-v6 .image-two {
    max-width: 65%;
    width: 65%;
    text-align: right; }
  .preview-image-v6 .video-wrap {
    width: 35%;
    max-width: 35%;
    margin: -85px -60px 0px 55px;
    padding: 50px 0px 50px 0px;
    border: 7px solid var(--color-white);
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center; }
    .preview-image-v6 .video-wrap .popup-video {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: var(--color-primary-7);
      font-size: 12px;
      color: var(--color-white);
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }

/*======= Counter Items v1  =======*/
.counter-items-v1 .counter-item .icon {
  font-size: 30px;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  color: var(--color-white);
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.07);
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .counter-items-v1 .counter-item .icon::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color-white);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.counter-items-v1 .counter-item .counter-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 42px;
  line-height: 1;
  color: var(--color-white); }

.counter-items-v1 .counter-item .title {
  color: var(--color-white);
  margin-top: 15px;
  line-height: 1.4; }

.counter-items-v1 .counter-item:hover .icon {
  color: #299cdb; }
  .counter-items-v1 .counter-item:hover .icon::after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }

/*======= Counter Items v2  =======*/
.counter-items-v2 .counter-item .counter-wrap {
  position: relative;
  z-index: 1;
  display: inline-block; }

.counter-items-v2 .counter-item .counter {
  font-size: 85px;
  line-height: 0.8;
  color: var(--color-heading); }

.counter-items-v2 .counter-item .suffix {
  font-size: 20px;
  top: -10px;
  position: absolute;
  right: -25px;
  line-height: 0.9;
  color: var(--color-primary-5); }

.counter-items-v2 .counter-item .title {
  padding-top: 10px;
  font-size: 18px;
  line-height: 1.4;
  font-family: var(--font-cs-medium);
  font-weight: 500; }

.counter-items-v2 .counter-item.counter-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .counter-items-v2 .counter-item.counter-left .counter-wrap {
    min-width: 95px; }
  .counter-items-v2 .counter-item.counter-left .title {
    padding-top: 0;
    padding-left: 25px; }

.counter-items-v2 .counter-item.white-color .title,
.counter-items-v2 .counter-item.white-color .counter,
.counter-items-v2 .counter-item.white-color .suffix {
  color: var(--color-white); }

/* Counter Three */
.counter-three-inner {
  position: relative;
  z-index: 1;
  margin-top: -170px; }
  .counter-three-inner:before {
    position: absolute;
    height: 100%;
    left: -115px;
    content: '';
    top: 0;
    z-index: -1;
    width: calc(100% + 230px);
    background: #ffa900; }

.counter-item.style-two {
  margin-bottom: 50px; }
  .counter-item.style-two .icon {
    height: 80px;
    width: 80px;
    color: white;
    font-size: 30px;
    line-height: 80px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 30px;
    background: rgba(4, 13, 26, 0.1); }
  .counter-item.style-two .counter-wrap {
    line-height: 1;
    font-size: 42px;
    margin-bottom: 5px;
    color: #040d1a;
    font-family: var(--font-cs-bold); }
    @media (max-width: 424px) {
      .counter-item.style-two .counter-wrap {
        font-size: 30px; } }
  .counter-item.style-two .title {
    color: #040d1a;
    font-family: var(--font-cs-book); }

/*======= Fancy Check List v1   =======*/
.fancy-check-list-v1 li {
  padding: 35px 35px 35px 80px;
  position: relative;
  z-index: 1; }
  .fancy-check-list-v1 li:first-child {
    background-color: var(--color-white);
    border-radius: 15px;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3);
    box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.3); }
  .fancy-check-list-v1 li:not(:last-child) {
    margin-bottom: 5px; }
  .fancy-check-list-v1 li::before {
    position: absolute;
    z-index: -1;
    left: 30px;
    top: 35px;
    width: 30px;
    height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--color-secondary);
    color: var(--color-white);
    content: "\f00c";
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 15px; }
  .fancy-check-list-v1 li .title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    text-transform: capitalize; }

/*======= Fancy Check List v2   =======*/
.fancy-check-list-v2 li {
  color: var(--color-heading);
  font-size: 20px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1.4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .fancy-check-list-v2 li .list-inner {
    position: relative;
    z-index: 1;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.28);
    box-shadow: 0px 10px 60px 0px rgba(189, 189, 189, 0.28);
    padding: 20px 35px 20px 85px;
    border-radius: 10px;
    background-color: var(--color-white); }
    .fancy-check-list-v2 li .list-inner::before {
      position: absolute;
      z-index: -1;
      left: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #299cdb;
      color: var(--color-white);
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
      font-size: 15px; }
  .fancy-check-list-v2 li:not(:last-child) {
    margin-bottom: 15px; }
  .fancy-check-list-v2 li:nth-child(1), .fancy-check-list-v2 li:nth-child(4) {
    margin-left: 15px; }
  .fancy-check-list-v2 li:nth-child(2), .fancy-check-list-v2 li:nth-child(5) {
    margin-left: 30px; }

.fancy-check-list-v2.color-heading-3 li {
  color: var(--color-heading-3); }
  .fancy-check-list-v2.color-heading-3 li .list-inner::before {
    background-color: var(--color-secondary); }

/*======= Testimonials v1  =======*/
.testimonial-slider-v1 .testimonial-item {
  text-align: center; }
  .testimonial-slider-v1 .testimonial-item .quote-icon {
    color: #299cdb;
    font-size: 90px;
    line-height: 0.5;
    margin-bottom: 20px; }
  .testimonial-slider-v1 .testimonial-item p {
    font-size: 20px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium);
    line-height: 1.7; }
  .testimonial-slider-v1 .testimonial-item .author-info {
    margin-top: 25px; }
    .testimonial-slider-v1 .testimonial-item .author-info img {
      width: 80px;
      height: 80px;
      border-radius: 50%; }
    .testimonial-slider-v1 .testimonial-item .author-info .name {
      font-size: 18px;
      color: var(--color-heading);
      margin-top: 20px; }
    .testimonial-slider-v1 .testimonial-item .author-info .title {
      display: block;
      font-size: 15px;
      line-height: 1;
      font-family: var(--font-cs-medium);
      font-weight: 500;
      margin-top: 8px;
      color: var(--color-body); }

.testimonial-slider-v1.testimonial-slider-extra-gap {
  margin-left: -40px;
  margin-right: -40px; }
  .testimonial-slider-v1.testimonial-slider-extra-gap .col,
  .testimonial-slider-v1.testimonial-slider-extra-gap [class*="col-"] {
    padding-left: 40px;
    padding-right: 40px; }

/*======= Testimonials v1 Boxed =======*/
.testimonial-slider-v1.testimonial-v1-boxed .testimonial-item {
  background-color: var(--color-white);
  border-radius: 12px;
  padding: 45px 45px 50px 45px;
  -webkit-box-shadow: -5px -5px 0 0 var(--color-secondary);
  box-shadow: -5px -5px 0 0 var(--color-secondary); }
  .testimonial-slider-v1.testimonial-v1-boxed .testimonial-item .quote-icon {
    color: var(--color-secondary); }
  .testimonial-slider-v1.testimonial-v1-boxed .testimonial-item p {
    font-size: 18px;
    color: var(--color-heading-2); }
  .testimonial-slider-v1.testimonial-v1-boxed .testimonial-item .author-info .name {
    color: var(--color-heading-2); }
  .testimonial-slider-v1.testimonial-v1-boxed .testimonial-item .author-info .title {
    color: var(--color-body-2); }

.testimonial-slider-v1.testimonial-v1-boxed .slick-dots li {
  background: #544466; }
  .testimonial-slider-v1.testimonial-v1-boxed .slick-dots li::after {
    border: 2px solid var(--color-secondary); }
  .testimonial-slider-v1.testimonial-v1-boxed .slick-dots li.slick-active {
    background-color: var(--color-secondary); }

/*======= Testimonials v1 Fancy Boxed =======*/
.testimonial-slider-v1.testimonial-v1-fancy-boxed .testimonial-item {
  background-color: var(--color-white);
  padding: 60px 50px;
  position: relative;
  z-index: 1;
  margin: 45px;
  border-radius: 12px; }
  .testimonial-slider-v1.testimonial-v1-fancy-boxed .testimonial-item::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -30px;
    right: -30px;
    top: 95px;
    bottom: 95px;
    background-color: var(--color-white);
    border-radius: 12px; }
  .testimonial-slider-v1.testimonial-v1-fancy-boxed .testimonial-item::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 12px;
    background-color: var(--color-white);
    -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
    box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1); }

.testimonial-slider-v1.testimonial-v1-fancy-boxed .slick-arrow {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  color: var(--color-heading);
  border: 2px solid rgba(27, 32, 47, 0.1);
  background-color: var(--color-soft-grey);
  font-size: 15px;
  position: absolute;
  left: -60px;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .testimonial-slider-v1.testimonial-v1-fancy-boxed .slick-arrow.next-arrow {
    left: auto;
    right: -60px; }
  .testimonial-slider-v1.testimonial-v1-fancy-boxed .slick-arrow.next-arrow, .testimonial-slider-v1.testimonial-v1-fancy-boxed .slick-arrow:hover {
    background-color: #299cdb;
    border-color: #299cdb;
    color: var(--color-white); }

.testimonial-slider-v1.testimonial-v1-fancy-boxed.testimonial-extra-margin {
  margin-right: 75px;
  margin-left: 0; }

/*======= Testimonials Slider v2  =======*/
.testimonial-slider-v2 .testimonial-item {
  border-radius: 12px;
  background-color: var(--color-white);
  -webkit-box-shadow: 0px 5px 15px 0px rgba(71, 59, 240, 0.1);
  box-shadow: 0px 5px 15px 0px rgba(71, 59, 240, 0.1);
  padding: 40px 80px 40px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 1.5;
  position: relative;
  margin: 15px; }
  .testimonial-slider-v2 .testimonial-item::before {
    content: "";
    position: absolute;
    left: -15px;
    top: -15px;
    bottom: 15px;
    right: 15px;
    border-radius: 12px;
    background-color: var(--color-primary-4);
    z-index: -1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0; }
  .testimonial-slider-v2 .testimonial-item .author-quote {
    margin-right: 30px;
    width: 80px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80px;
    flex: 0 0 80px;
    position: relative; }
    .testimonial-slider-v2 .testimonial-item .author-quote img {
      width: 80px;
      height: 80px;
      border-radius: 50%; }
    .testimonial-slider-v2 .testimonial-item .author-quote i {
      font-size: 56px;
      color: var(--color-primary-4);
      position: absolute;
      left: 50%;
      bottom: -15px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      line-height: 0.5; }
  .testimonial-slider-v2 .testimonial-item .author-name {
    margin-top: 20px;
    font-size: 20px;
    line-height: 1; }
  .testimonial-slider-v2 .testimonial-item .author-position {
    display: block;
    margin-top: 10px;
    color: var(--color-primary-4);
    font-size: 15px;
    font-family: var(--font-cs-medium);
    font-weight: 500; }

.testimonial-slider-v2 .slick-dots {
  margin-top: 60px; }
  .testimonial-slider-v2 .slick-dots li {
    background-color: #d5e1fd; }
    .testimonial-slider-v2 .slick-dots li::after {
      border-color: var(--color-primary-4); }
    .testimonial-slider-v2 .slick-dots li.slick-active {
      background-color: var(--color-primary-4); }

.testimonial-slider-v2 .slick-current .testimonial-item::before {
  opacity: 1;
  visibility: visible; }

/*======= Testimonials Boxes v1  =======*/
.testimonial-boxes-v1 .testimonial-box {
  background-color: var(--color-white);
  text-align: left;
  padding: 45px;
  border-radius: 7px; }
  .testimonial-boxes-v1 .testimonial-box .author-rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px; }
  .testimonial-boxes-v1 .testimonial-box .rating-review {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .testimonial-boxes-v1 .testimonial-box .rating-review li {
      display: inline-block;
      color: #ff8a00;
      font-size: 15px;
      margin-right: 5px; }
  .testimonial-boxes-v1 .testimonial-box .author-brand {
    max-width: 120px; }
  .testimonial-boxes-v1 .testimonial-box .author-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    margin-top: 20px; }
    .testimonial-boxes-v1 .testimonial-box .author-info img {
      width: 55px;
      height: 55px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 55px;
      flex: 0 0 55px;
      margin-right: 20px; }
    .testimonial-boxes-v1 .testimonial-box .author-info .name {
      font-size: 18px;
      line-height: 1; }
      .testimonial-boxes-v1 .testimonial-box .author-info .name .title {
        display: block;
        color: var(--color-body);
        font-size: 15px;
        font-family: var(--font-cs-medium);
        font-weight: 500;
        text-transform: capitalize;
        margin-top: 10px; }

/*======= Testimonials Quote Bordered =======*/
.testimonial-slider-v1.quote-left-bordered .testimonial-item {
  padding: 40px 40px 40px 160px;
  text-align: left;
  border: 1px solid var(--color-offwhite);
  position: relative; }
  .testimonial-slider-v1.quote-left-bordered .testimonial-item .quote-icon {
    position: absolute;
    top: 45px;
    left: 40px;
    color: var(--color-primary-5); }
  .testimonial-slider-v1.quote-left-bordered .testimonial-item .author-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonial-slider-v1.quote-left-bordered .testimonial-item .author-info img {
      margin: 0 20px 0 0;
      width: 70px;
      height: 70px; }
  .testimonial-slider-v1.quote-left-bordered .testimonial-item .name {
    margin-top: 0; }

.testimonial-slider-v1.quote-left-bordered .slick-dots {
  margin-top: 60px;
  justify-content: left; }
  .testimonial-slider-v1.quote-left-bordered .slick-dots li {
    background-color: var(--color-offwhite);
    margin: 0 10px; }
    .testimonial-slider-v1.quote-left-bordered .slick-dots li.slick-active {
      background: var(--color-primary-5); }
    .testimonial-slider-v1.quote-left-bordered .slick-dots li::after {
      border-color: var(--color-primary-5); }

/*======= Testimonials Slider v3  =======*/
.testimonial-slider-v3 .testimonial-item {
  text-align: center;
  background: white;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px 30px 45px;
  -webkit-box-shadow: 0px 0 20px rgba(219, 219, 219, 0.5);
  box-shadow: 0px 0 20px rgba(219, 219, 219, 0.5); }
  .testimonial-slider-v3 .testimonial-item .quote-icon {
    line-height: 1;
    font-size: 80px;
    color: #ffa900; }
  .testimonial-slider-v3 .testimonial-item .name {
    font-size: 18px;
    margin-top: 22px;
    margin-bottom: 3px; }
  .testimonial-slider-v3 .testimonial-item .title {
    font-size: 15px;
    font-family: var(--font-cs-medium); }

.testimonial-slider-v3 .slick-dots {
  margin-top: 60px; }
  .testimonial-slider-v3 .slick-dots li {
    background: rgba(4, 13, 26, 0.2); }
    .testimonial-slider-v3 .slick-dots li.slick-active {
      background: #ffa900; }
    .testimonial-slider-v3 .slick-dots li:after {
      border-color: #ffa900; }

/*======= Testimonials Boxes v2  =======*/
.testimonial-boxes-v2 .testimonial-box {
  position: relative;
  padding: 35px 30px;
  font-size: 17px;
  line-height: 30px;
  border: 1px solid var(--color-dark-4);
  border-radius: 7px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s; }
  .testimonial-boxes-v2 .testimonial-box:hover {
    border-color: var(--color-primary-6); }
  .testimonial-boxes-v2 .testimonial-box::before {
    position: absolute;
    top: 40px;
    right: 20px;
    width: 45px;
    height: 45px;
    background-color: #59b4f3;
    border-radius: 50%;
    content: "\f099";
    font-family: "Font Awesome 5 Brands";
    color: var(--color-white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .testimonial-boxes-v2 .testimonial-box .title {
    font-size: 20px;
    letter-spacing: -1px;
    line-height: 30px;
    margin-bottom: 15px; }
  .testimonial-boxes-v2 .testimonial-box .rating {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px; }
    .testimonial-boxes-v2 .testimonial-box .rating li {
      margin-right: 8px;
      font-size: 13px;
      line-height: 1;
      color: var(--color-primary-6); }
  .testimonial-boxes-v2 .testimonial-box .author-name {
    margin-top: 25px;
    padding-left: 35px;
    display: block;
    line-height: 1;
    position: relative;
    font-size: 18px; }
    .testimonial-boxes-v2 .testimonial-box .author-name::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 2px;
      width: 20px;
      background-color: var(--color-primary-6); }

/*======= Testimonials Boxes v2  =======*/
.testimonial-box-v3-image {
  margin-left: -30px;
  margin-right: -165px;
  position: relative;
  z-index: 1; }
  @media (max-width: 991px) {
    .testimonial-box-v3-image {
      margin: 0 0 55px; } }
  .testimonial-box-v3-image:before {
    height: 450px;
    width: 450px;
    content: '';
    left: 5%;
    top: 50%;
    z-index: -1;
    border-radius: 50%;
    position: absolute;
    -webkit-transform: translateY(-46%);
    -ms-transform: translateY(-46%);
    transform: translateY(-46%);
    -webkit-filter: blur(100px) opacity(0.85);
    filter: blur(100px) opacity(0.85);
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
    @media (max-width: 424px) {
      .testimonial-box-v3-image:before {
        height: 250px;
        width: 250px;
        -webkit-filter: blur(80px);
        filter: blur(80px); } }

/* Customer Review */
.customer-review-wrap .customer-review-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 424px) {
    .customer-review-wrap .customer-review-item {
      display: block; } }
  .customer-review-wrap .customer-review-item .icon {
    line-height: 1;
    font-size: 85px;
    margin-right: 30px;
    color: var(--color-secondary); }
  .customer-review-wrap .customer-review-item p {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-heading); }
    @media (max-width: 424px) {
      .customer-review-wrap .customer-review-item p {
        font-size: 16px; } }
  .customer-review-wrap .customer-review-item .author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .customer-review-wrap .customer-review-item .author img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 15px; }
    .customer-review-wrap .customer-review-item .author h6 {
      margin-top: 8px;
      font-size: 18px; }
    .customer-review-wrap .customer-review-item .author span {
      font-size: 15px;
      font-weight: 500; }

.customer-review-wrap .slick-dots {
  margin-top: 70px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start; }
  .customer-review-wrap .slick-dots li.slick-active {
    background: var(--color-secondary); }
    .customer-review-wrap .slick-dots li.slick-active:after {
      border-color: var(--color-secondary); }

.customer-review-image {
  height: 400px;
  width: 400px;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 50px auto;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media (min-width: 992px) {
    .customer-review-image {
      margin-left: auto; } }
  @media (max-width: 575px) {
    .customer-review-image {
      height: 350px;
      width: 350px; } }
  @media (max-width: 424px) {
    .customer-review-image {
      height: 275px;
      width: 275px; } }
  .customer-review-image .icon {
    line-height: 1;
    font-size: 120px;
    color: var(--color-secondary);
    -webkit-transform: rotate(10deg) translateY(10px);
    -ms-transform: rotate(10deg) translateY(10px);
    transform: rotate(10deg) translateY(10px); }
    @media (max-width: 575px) {
      .customer-review-image .icon {
        font-size: 80px; } }
  .customer-review-image img {
    position: absolute;
    border-radius: 50%;
    -webkit-animation: 5s customZoomInOut infinite;
    animation: 5s customZoomInOut infinite; }
    @media (max-width: 575px) {
      .customer-review-image img {
        max-width: 20%; } }
    .customer-review-image img:nth-child(1) {
      left: -10px;
      top: 20%; }
    .customer-review-image img:nth-child(2) {
      top: -30px;
      right: 10%;
      -webkit-animation-delay: 1s;
      animation-delay: 1s; }
    .customer-review-image img:nth-child(3) {
      left: 10%;
      bottom: 25%;
      -webkit-animation-delay: 4s;
      animation-delay: 4s; }
    .customer-review-image img:nth-child(4) {
      right: 8%;
      bottom: 30%;
      -webkit-animation-delay: 3s;
      animation-delay: 3s; }
    .customer-review-image img:nth-child(5) {
      left: 45%;
      bottom: -30px;
      -webkit-animation-delay: 2s;
      animation-delay: 2s; }
  .customer-review-image:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    left: 0;
    top: 0;
    -webkit-animation: 25s rotateme infinite;
    animation: 25s rotateme infinite;
  }

@-webkit-keyframes customZoomInOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes customZoomInOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.testimonial-boxes-v3 {
  z-index: 1;
  max-width: 500px; }
  .testimonial-boxes-v3 .testimonial-box .author-quote-brand {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .testimonial-boxes-v3 .testimonial-box .author-quote-brand img {
      max-width: 35%; }
  .testimonial-boxes-v3 .testimonial-box .author-comments {
    color: black;
    font-size: 30px;
    line-height: 1.5;
    font-style: italic;
    font-family: var(--font-cs-book); }
    @media (max-width: 575px) {
      .testimonial-boxes-v3 .testimonial-box .author-comments {
        font-size: 25px; } }
    @media (max-width: 424px) {
      .testimonial-boxes-v3 .testimonial-box .author-comments {
        font-size: 20px; } }
  .testimonial-boxes-v3 .testimonial-box .author-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .testimonial-boxes-v3 .testimonial-box .author-info .image {
      max-width: 55px;
      margin-right: 20px; }
      .testimonial-boxes-v3 .testimonial-box .author-info .image img {
        width: 55px;
        height: 55px;
        border-radius: 50%; }
    .testimonial-boxes-v3 .testimonial-box .author-info .name {
      font-size: 22px; }
    .testimonial-boxes-v3 .testimonial-box .author-info .title {
      font-size: 15px;
      font-weight: 500; }
  .testimonial-boxes-v3 .slick-dots {
    margin-top: 60px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
    .testimonial-boxes-v3 .slick-dots li {
      width: 10px;
      height: 10px; }
      .testimonial-boxes-v3 .slick-dots li.slick-active {
        background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
        background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
        background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
      .testimonial-boxes-v3 .slick-dots li:after {
        top: -10px;
        left: -10px;
        border-color: linear-gradient(to bottom, red, #299cdb) 1 100%; }

.testimonial-dots-2 .slick-dots {
  margin-top: 70px; }
  .testimonial-dots-2 .slick-dots li {
    margin: 0 4px;
    height: 10px;
    width: 10px;
    background-color: var(--color-white);
    border-radius: 50%;
    line-height: 1;
    font-size: 0;
    opacity: 0.15; }
    .testimonial-dots-2 .slick-dots li::after {
      display: none; }
    .testimonial-dots-2 .slick-dots li.slick-active {
      background-color: var(--color-primary-6);
      opacity: 1; }

.testimonial-dots-3 .slick-dots {
  margin-top: 50px; }
  .testimonial-dots-3 .slick-dots li {
    margin: 0 4px;
    height: 10px;
    width: 10px;
    background-color: var(--color-heading-5);
    border-radius: 50%;
    line-height: 1;
    font-size: 0;
    opacity: 0.2; }
    .testimonial-dots-3 .slick-dots li::after {
      display: none; }
    .testimonial-dots-3 .slick-dots li.slick-active {
      opacity: 1; }

/*======= Pricing Table  =======*/
.pricing-table {
  padding: 50px 40px;
  border: 2px solid #e5ecfc;
  border-radius: 12px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .pricing-table:hover {
    border-color: #3788d8;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.7);
    box-shadow: 0px 10px 60px 0px rgba(215, 212, 255, 0.7); }
  .pricing-table .plan-name {
    font-size: 17px;
    line-height: 1;
    color: var(--color-white);
    background-color: #3788d8;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 30px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36); }
  .pricing-table .plan-icon {
    display: block;
    margin: auto; }
  .pricing-table .plan-title-area {
    position: relative;
    z-index: 1;
    margin-bottom: 30px; }
    .pricing-table .plan-title-area .plan-shape {
      position: absolute;
      right: 0;
      top: -20px;
      z-index: -1;
      width: 225px;
      height: 220px; }
      .pricing-table .plan-title-area .plan-shape .blob {
        fill: #edf2fd;
        fill-rule: evenodd;
        opacity: 0.7; }
  .pricing-table .plan-cost {
    line-height: 1;
    letter-spacing: -1px; }
    .pricing-table .plan-cost .currency {
      color: #299cdb;
      font-size: 18px;
      top: -25px;
      position: relative; }
    .pricing-table .plan-cost .price {
      font-size: 25px;
      line-height: 1;
      color: var(--color-heading); }
    .pricing-table .plan-cost .plan-type {
      color: #606060;
      font-size: 17px;
      margin-left: 5px; }
  .pricing-table .plan-feature li {
    color: #606060;
    font-size: 17px;
    position: relative;
    line-height: 1.5; }
    .pricing-table .plan-feature li:not(:last-child) {
      margin-bottom: 10px; }
    .pricing-table .plan-feature li:after {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
      font-size: 15px;
      font-weight: 900;
      color: #299cdb; }
    .pricing-table .plan-feature li.disabled-feature:after {
      color: #b2c5f7; }
  .pricing-table .template-btn {
    margin-top: 50px; }
  .pricing-table.pricing-secondary-1:hover {
    border-color: var(--color-secondary);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.18);
    box-shadow: 0px 10px 30px 0px rgba(244, 115, 42, 0.18); }
  .pricing-table.pricing-secondary-1 .plan-name {
    background: -webkit-linear-gradient(305deg, #ff4e00 0%, #ff7200 59%, #fe9500 100%);
    background: -o-linear-gradient(305deg, #ff4e00 0%, #ff7200 59%, #fe9500 100%);
    background: linear-gradient(145deg, #ff4e00 0%, #ff7200 59%, #fe9500 100%);
    -webkit-box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4);
    box-shadow: 0px 7px 25px 0px rgba(255, 78, 0, 0.4); }
  .pricing-table.pricing-secondary-1 .plan-cost .currency {
    color: var(--color-primary-5); }
  .pricing-table.pricing-secondary-1 .plan-feature li:not(.disabled-feature)::after {
    color: var(--color-primary-5); }
  .pricing-table.pricing-secondary-1 .template-btn {
    background-color: var(--color-primary-5);
    -webkit-box-shadow: 0px 10px 30px 0px rgb(85, 128, 255);
    box-shadow: 0px 10px 30px 0px rgb(85, 128, 255);
    border-color: var(--color-primary-5); }
    .pricing-table.pricing-secondary-1 .template-btn:hover {
      background-color: var(--color-heading);
      border-color: var(--color-heading); }

/*======= Pricing Table Two =======*/
.pricing-table-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 50px;
  background-color: var(--color-white);
  padding: 45px;
  border: 2px solid #e5ecfc;
  border-radius: 12px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .pricing-table-two:hover {
    border-color: transparent;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(206, 189, 224, 0.36); }
  .pricing-table-two .left-content,
  .pricing-table-two .right-content {
    width: 50%; }
  .pricing-table-two .plan-name {
    font-size: 17px;
    line-height: 1;
    color: var(--color-white);
    background-color: var(--color-primary-5);
    padding: 10px 20px;
    display: inline-block;
    border-radius: 30px;
    margin-bottom: 35px;
    -webkit-box-shadow: 0px 7px 25px 0px rgb(85, 128, 255);
    box-shadow: 0px 7px 25px 0px rgb(85, 128, 255); }
  .pricing-table-two .plan-cost {
    line-height: 1;
    letter-spacing: -1px; }
    .pricing-table-two .plan-cost .currency {
      color: var(--color-primary-5);
      font-size: 24px;
      top: -25px;
      position: relative; }
    .pricing-table-two .plan-cost .price {
      font-size: 65px;
      line-height: 1;
      color: var(--color-heading-3); }
    .pricing-table-two .plan-cost .plan-type {
      color: var(--color-body-2);
      font-size: 17px;
      margin-left: 5px; }
  .pricing-table-two .plan-feature li {
    color: var(--color-body-2);
    font-size: 17px;
    position: relative;
    line-height: 1.5; }
    .pricing-table-two .plan-feature li:not(:last-child) {
      margin-bottom: 10px; }
    .pricing-table-two .plan-feature li:after {
      position: absolute;
      right: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      content: "\f058";
      font-family: "Font Awesome 5 Pro";
      font-size: 15px;
      font-weight: 900;
      color: var(--color-primary-5); }
    .pricing-table-two .plan-feature li.disabled-feature:after {
      color: #b2c5f7; }
  .pricing-table-two .template-btn {
    margin-top: 30px; }

/*======= Pricing Table Three =======*/
.pricing-table-three {
  padding: 60px 55px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  border: 2px solid var(--color-dark-4);
  border-radius: 7px; }
  .pricing-table-three .plan-name {
    font-size: 15px;
    letter-spacing: 4px;
    color: var(--color-primary-6);
    text-transform: uppercase;
    margin-bottom: 20px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
  .pricing-table-three .price {
    font-size: 65px;
    line-height: 1;
    color: var(--color-white);
    letter-spacing: -2px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 15px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    .pricing-table-three .price .currency {
      font-size: 24px;
      margin-right: 10px;
      position: relative;
      top: 10px; }
  .pricing-table-three .plan-subtitle {
    color: #abb0bf;
    margin-bottom: 35px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    line-height: 1.5;
    font-size: 17px;
    font-family: var(--font-cs-medium);
    font-weight: 500; }
  .pricing-table-three .plan-feature li {
    font-size: 17px;
    line-height: 1.5;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    color: var(--color-white); }
    .pricing-table-three .plan-feature li i {
      color: var(--color-primary-6);
      font-size: 15px;
      margin-right: 10px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
    .pricing-table-three .plan-feature li:not(:last-child) {
      margin-bottom: 15px; }
    .pricing-table-three .plan-feature li.hidden-feature i, .pricing-table-three .plan-feature li.hidden-feature {
      color: var(--color-body-4); }
  .pricing-table-three .pricing-btn {
    margin-top: 35px;
    background-color: var(--color-dark-4);
    color: var(--color-body-4);
    line-height: 1.4;
    padding: 15px 45px;
    border-radius: 30px;
    font-size: 15px;
    font-family: var(--font-cs-bold);
    font-weight: 700; }
    .pricing-table-three .pricing-btn i {
      margin-left: 5px; }
  .pricing-table-three .plan-tag {
    font-size: 13px;
    font-family: var(--font-cs-medium);
    font-weight: 500;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    background-color: var(--color-primary-6);
    color: var(--color-white);
    text-transform: uppercase;
    line-height: 1;
    border-radius: 30px;
    padding: 8px 15px; }
  .pricing-table-three:hover, .pricing-table-three.featured-plan {
    background-color: #1d1e22;
    border-color: #1d1e22; }
  .pricing-table-three:hover .pricing-btn,
  .pricing-table-three.featured-plan .pricing-btn {
    color: var(--color-white);
    background-color: var(--color-primary-6); }
  .pricing-table-three.style-two,
  .pricing-table-three.style-two .pricing-btn {
    border-radius: 0; }
  .pricing-table-three.style-two .plan-name,
  .pricing-table-three.style-two .plan-tag {
    color: var(--color-primary-7); }
  .pricing-table-three.style-two .plan-tag {
    background-color: var(--color-white); }
  .pricing-table-three.style-two .plan-feature li:not(.hidden-feature) i {
    color: var(--color-white); }
  .pricing-table-three.style-two:hover .pricing-btn,
  .pricing-table-three.style-two.featured-plan .pricing-btn {
    color: var(--color-primary-7);
    background-color: var(--color-white); }
  .pricing-table-three.style-two::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url(../img/pricing-shape.png);
    background-repeat: no-repeat;
    background-position: left top;
    opacity: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    visibility: hidden; }
  .pricing-table-three.style-two:hover, .pricing-table-three.style-two.featured-plan {
    background-color: var(--color-primary-7);
    border-color: var(--color-primary-7); }
    .pricing-table-three.style-two:hover .plan-subtitle,
    .pricing-table-three.style-two:hover .plan-name, .pricing-table-three.style-two.featured-plan .plan-subtitle,
    .pricing-table-three.style-two.featured-plan .plan-name {
      color: var(--color-white); }
    .pricing-table-three.style-two:hover .plan-feature li.hidden-feature, .pricing-table-three.style-two.featured-plan .plan-feature li.hidden-feature {
      color: var(--color-white);
      opacity: 0.4; }
    .pricing-table-three.style-two:hover::before, .pricing-table-three.style-two.featured-plan::before {
      visibility: visible;
      opacity: 0.07; }
  .pricing-table-three.style-three {
    border: none;
    border-radius: 0;
    background: white; }
    .pricing-table-three.style-three .plan-name {
      color: var(--color-secondary); }
    .pricing-table-three.style-three .price {
      color: var(--color-heading); }
    .pricing-table-three.style-three .plan-subtitle {
      color: var(--color-body); }
    .pricing-table-three.style-three .plan-feature li {
      color: var(--color-body); }
      .pricing-table-three.style-three .plan-feature li i {
        color: var(--color-secondary); }
      .pricing-table-three.style-three .plan-feature li.hidden-feature {
        color: #696969;
        opacity: 0.35; }
        .pricing-table-three.style-three .plan-feature li.hidden-feature i {
          color: #696969; }
    .pricing-table-three.style-three .pricing-btn {
      color: var(--color-heading);
      background: var(--color-grey); }
    .pricing-table-three.style-three .plan-tag {
      background: var(--color-grey);
      color: var(--color-secondary); }
    .pricing-table-three.style-three:hover, .pricing-table-three.style-three.featured-plan {
      background: #fd695a; }
      .pricing-table-three.style-three:hover .price,
      .pricing-table-three.style-three:hover .plan-name,
      .pricing-table-three.style-three:hover .plan-subtitle,
      .pricing-table-three.style-three:hover .plan-feature li,
      .pricing-table-three.style-three:hover .plan-feature li i, .pricing-table-three.style-three.featured-plan .price,
      .pricing-table-three.style-three.featured-plan .plan-name,
      .pricing-table-three.style-three.featured-plan .plan-subtitle,
      .pricing-table-three.style-three.featured-plan .plan-feature li,
      .pricing-table-three.style-three.featured-plan .plan-feature li i {
        color: white; }
      .pricing-table-three.style-three:hover .pricing-btn, .pricing-table-three.style-three.featured-plan .pricing-btn {
        color: var(--color-secondary); }

/** Pricing Tab **/
.pricing-section-h10 {
  z-index: 1;
  position: relative;
  background-size: cover;
  background-position: center; }
  .pricing-section-h10:before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.88;
    background: black; }

.pricing-plan-tab li a {
  color: white;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  font-weight: 700;
  padding: 5px 25px;
  background: #282a2f; }
  .pricing-plan-tab li a.active {
    background-image: -webkit-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: -o-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%); }

.pricing-plan-tab li:not(:last-child) {
  margin-right: 10px; }

.pricing-wrap-h10 .pricing-table-three.style-two {
  background-color: transparent; }
  .pricing-wrap-h10 .pricing-table-three.style-two .plan-tag {
    color: #299cdb; }
  .pricing-wrap-h10 .pricing-table-three.style-two:after {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -2;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
  .pricing-wrap-h10 .pricing-table-three.style-two:hover, .pricing-wrap-h10 .pricing-table-three.style-two.featured-plan {
    border-color: #1d1e22; }
    .pricing-wrap-h10 .pricing-table-three.style-two:hover .hidden-feature i, .pricing-wrap-h10 .pricing-table-three.style-two.featured-plan .hidden-feature i {
      color: rgba(255, 255, 255, 0.5); }
    .pricing-wrap-h10 .pricing-table-three.style-two:hover .pricing-btn, .pricing-wrap-h10 .pricing-table-three.style-two.featured-plan .pricing-btn {
      color: #299cdb; }
    .pricing-wrap-h10 .pricing-table-three.style-two:hover:after, .pricing-wrap-h10 .pricing-table-three.style-two.featured-plan:after {
      opacity: 1; }

/*======= Programs  =======*/
.programs-area {
  padding-top: 130px;
  padding-bottom: 130px; }
  .programs-area .row {
    margin-left: 0;
    margin-right: 0; }
    .programs-area .row > div {
      padding-left: 0;
      padding-right: 0; }

.program-item {
  z-index: 1;
  position: relative;
  text-align: center;
  margin-right: -1px;
  margin-bottom: -1px;
  padding: 60px 50px 55px;
  border: 1px solid #e5e5e5; }
  @media (max-width: 424px) {
    .program-item {
      padding-left: 25px;
      padding-right: 25px; } }
  .program-item .icon {
    height: 90px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 30px; }
    @media (max-width: 424px) {
      .program-item .icon {
        height: auto; }
        .program-item .icon img {
          max-width: 55px; } }
  .program-item h4 {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 15px; }
  .program-item p {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .program-item .hover-bg {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    background-size: cover;
    background-position: center; }
    .program-item .hover-bg:before {
      height: 100%;
      content: '';
      width: 100%;
      left: 0;
      top: 0;
      position: absolute;
      background-image: -webkit-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
      background-image: -o-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
      background-image: linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%); }
  .program-item:hover .icon {
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1); }
  .program-item:hover h4, .program-item:hover p {
    color: white; }
  .program-item:hover .hover-bg {
    opacity: 1; }

.program-more {
  padding-left: 50px; }
  .program-more h2 {
    font-size: 42px;
    margin-top: -10px;
    margin-bottom: 22px; }
    @media (max-width: 575px) {
      .program-more h2 {
        font-size: 35px; } }
  .program-more p {
    margin-bottom: 25px; }
  @media (max-width: 991px) {
    .program-more {
      padding-left: 0;
      padding-right: 50px; } }
  @media (max-width: 767px) {
    .program-more {
      padding: 50px 0 0; } }

/*======= Team Member =======*/
.team-members .member-box {
  position: relative;
  z-index: 1; }
  .team-members .member-box::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #299cdb;
    border-radius: 7px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform-origin: top;
    transform-origin: top;
    border-radius: 6px;
    z-index: 2;
    opacity: 0.5; }
  .team-members .member-box .member-photo img {
    width: 100%;
    border-radius: 7px; }
  .team-members .member-box .member-info {
    background-color: var(--color-white);
    border-radius: 7px;
    padding: 50px 15px;
    text-align: center;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    position: absolute;
    z-index: 3;
    left: 20px;
    right: 20px;
    bottom: 20px;
    opacity: 1;
    visibility: visible; }
    .team-members .member-box .member-info .name {
      font-size: 20px;
      margin-bottom: 8px;
      line-height: 1.2; }
    .team-members .member-box .member-info .title {
      color: var(--color-secondary);
      font-size: 15px;
      font-family: var(--font-cs-medium);
      font-weight: 500;
      line-height: 1.3; }
    .team-members .member-box .member-info .social-links {
      margin-top: 15px; }
      .team-members .member-box .member-info .social-links li {
        display: inline-block;
        margin: 0 2px;
        visibility: visible;
        opacity: 1;
        -webkit-transform-origin: left;
        -ms-transform-origin: left;
        transform-origin: left;
        position: relative;
        left: 3px;
        -webkit-transition: all 0.3s linear;
        -o-transition: all 0.3s linear;
        transition: all 0.3s linear; }
        .team-members .member-box .member-info .social-links li a {
          display: block;
          background-color: #e5eeff;
          height: 35px;
          width: 35px;
          text-align: center;
          line-height: 35px;
          color: var(--color-primary-5);
          border-radius: 50%;
          font-size: 13px; }
        .team-members .member-box .member-info .social-links li.facebook a {
          background-color: #299cdb;
          color: #fff; }
        .team-members .member-box .member-info .social-links li.twitter a {
          background-color: #ddeffb;
          color: #55aded; }
        .team-members .member-box .member-info .social-links li.youtube a {
          background-color: #ffe5e5;
          color: #ff0303; }
        .team-members .member-box .member-info .social-links li.behance a {
          background-color: #e5eeff;
          color: #0057ff; }
  .team-members .member-box:hover::before {
    opacity: 0.6;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  .team-members .member-box:hover .member-info {
    opacity: 1;
    visibility: visible;
    bottom: 20px; }
    .team-members .member-box:hover .member-info .social-links li {
      visibility: visible;
      opacity: 1;
      -webkit-transform: rotateY(0deg);
      transform: rotateY(0deg); }
      .team-members .member-box:hover .member-info .social-links li:nth-child(2) {
        -webkit-transition-delay: 0.2s;
        -o-transition-delay: 0.2s;
        transition-delay: 0.2s; }
      .team-members .member-box:hover .member-info .social-links li:nth-child(3) {
        -webkit-transition-delay: 0.3s;
        -o-transition-delay: 0.3s;
        transition-delay: 0.3s; }
      .team-members .member-box:hover .member-info .social-links li:nth-child(4) {
        -webkit-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        transition-delay: 0.4s; }

.team-members.team-masonry {
  margin-top: -175px;
  margin-left: -15px;
  margin-right: -15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .team-members.team-masonry .masonry-item {
    max-width: 33.333%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    padding-left: 15px;
    padding-right: 15px; }
    .team-members.team-masonry .masonry-item:nth-child(1) {
      margin-top: 240px; }
    .team-members.team-masonry .masonry-item:nth-child(3) {
      margin-top: 90px; }
    .team-members.team-masonry .masonry-item:nth-child(4) {
      margin-top: -210px;
      margin-left: 33.333333%; }
    .team-members.team-masonry .masonry-item:nth-child(5) {
      margin-top: -60px; }

.team-members-two .member-box {
  position: relative;
  padding-bottom: 50px; }
  .team-members-two .member-box .member-photo img {
    width: 100%; }
  .team-members-two .member-box .member-info {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 0;
    background-color: var(--color-white);
    border-top: 5px solid var(--color-primary-7);
    padding: 20px 15px 25px;
    text-align: center;
    line-height: 1;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 2, 0.1);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 2, 0.1); }
    .team-members-two .member-box .member-info h4 {
      font-size: 22px;
      margin-bottom: 10px; }

/** Ttrainer **/
.trainer-item {
  margin-bottom: 85px;
  position: relative; }
  .trainer-item .image img {
    width: 100%; }
  .trainer-item .content {
    left: 8%;
    width: 84%;
    padding: 20px;
    bottom: -45px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    border-radius: 7px;
    text-align: center;
    position: absolute;
    -webkit-box-shadow: 10px 0 60px rgba(215, 212, 255, 0.6);
    box-shadow: 10px 0 60px rgba(215, 212, 255, 0.6); }
    .trainer-item .content span {
      font-weight: 500; }
  .trainer-item .social-links {
    opacity: 0;
    left: 50%;
    bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%); }
    .trainer-item .social-links li {
      margin: 3px; }
      .trainer-item .social-links li a {
        display: block;
        background-color: #e5eeff;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 35px;
        color: #299cdb;
        border-radius: 50%;
        font-size: 13px; }
      .trainer-item .social-links li.facebook a {
        background-color: #0040e5;
        color: #fff; }
      .trainer-item .social-links li.twitter a {
        background-color: #ddeffb;
        color: #55aded; }
      .trainer-item .social-links li.youtube a {
        background-color: #ffe5e5;
        color: #ff0303; }
      .trainer-item .social-links li.behance a {
        background-color: #e5eeff;
        color: #0057ff; }
  .trainer-item:hover .content {
    bottom: -65px;
    padding-bottom: 65px; }
    .trainer-item:hover .content .social-links {
      opacity: 1;
      -webkit-transition-delay: 0.5s;
      -o-transition-delay: 0.5s;
      transition-delay: 0.5s; }

/*======= Seo Score Box =======*/
.seo-score-box {
  position: relative;
  z-index: 2;
  border-radius: 12px;
  background: -webkit-linear-gradient(340deg, #0040e5 0%, #028ceb 59%, #04d7f1 100%);
  background: -o-linear-gradient(340deg, #0040e5 0%, #028ceb 59%, #04d7f1 100%);
  background: linear-gradient(110deg, #0040e5 0%, #028ceb 59%, #04d7f1 100%);
  padding: 80px 0;
  text-align: center; }
  .seo-score-box .score-box-title {
    font-size: 50px;
    line-height: 1.2;
    color: var(--color-white);
    margin-bottom: 15px; }
  .seo-score-box p {
    color: var(--color-white);
    font-size: 18px; }
  .seo-score-box .score-box-form {
    margin-top: 35px; }
  .seo-score-box .form-group {
    position: relative;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .seo-score-box .form-group:after {
      position: absolute;
      content: "";
      height: 40px;
      width: 3px;
      background-color: #e5ecfc;
      left: 45%; }
    .seo-score-box .form-group input {
      height: 80px;
      padding: 0 20px;
      border: 0;
      font-size: 18px;
      color: var(--color-heading-2); }
    .seo-score-box .form-group input:first-of-type {
      border-radius: 50px 0 0 50px;
      padding-left: 45px;
      width: 45%; }
    .seo-score-box .form-group input:last-of-type {
      border-radius: 0 50px 50px 0;
      padding-right: 213px;
      width: 55%;
      padding-left: 25px; }
    .seo-score-box .form-group button {
      position: absolute;
      right: 15px; }
  .seo-score-box .seo-images {
    position: absolute;
    height: 80%;
    width: 100%;
    bottom: 0;
    background-image: url("../img/particle/announcement.png"), url("../img/particle/launch-rocket.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 20px 0px, bottom right;
    z-index: -1; }
  .seo-score-box.particle-image-two {
    background: #299cdb; }
    .seo-score-box.particle-image-two .seo-images {
      height: 100%;
      background-image: url("../img/particle/announcement.png"), url("../img/particle/seo-lines.png");
      background-position: 2% 100%, top right;
      background-size: inherit; }

/*======= Accordion v1 =======*/
.landio-accordion-v1 .accordion-item:not(:last-child) {
  margin-bottom: 15px; }

.landio-accordion-v1 .accordion-item .accordion-header .accordion-button {
  padding: 20px 30px;
  width: 100%;
  text-align: left;
  background-color: var(--color-white);
  font-family: var(--font-cs-bold);
  font-size: 18px;
  line-height: 1.2;
  border-radius: 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: none;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--color-heading-2); }
  .landio-accordion-v1 .accordion-item .accordion-header .accordion-button::after {
    font-family: "Font Awesome 5 Pro";
    background-image: none;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    content: "\f105";
    margin-left: auto;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 16px;
    margin-top: 2px;
    color: var(--color-heading-2);
    font-weight: 600; }

.landio-accordion-v1 .accordion-item .accordion-body {
  background-color: var(--color-white);
  padding: 0 30px 25px;
  border-radius: 0 0 30px 30px; }

.landio-accordion-v1 .accordion-item .accordion-button[aria-expanded="true"] {
  border-radius: 30px 30px 0 0; }
  .landio-accordion-v1 .accordion-item .accordion-button[aria-expanded="true"]::after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

/*======= Accordion v1 Bordered =======*/
.landio-accordion-v1.accordion-bordered .accordion-item {
  border: 2px solid #edf2fd;
  border-radius: 7px; }
  .landio-accordion-v1.accordion-bordered .accordion-item:not(:last-child) {
    margin-bottom: 15px; }
  .landio-accordion-v1.accordion-bordered .accordion-item .accordion-header .accordion-button {
    padding: 16px 30px;
    border-radius: 0;
    line-height: 1.4; }
    .landio-accordion-v1.accordion-bordered .accordion-item .accordion-header .accordion-button::after {
      content: "\f0a9";
      font-weight: 400; }
  .landio-accordion-v1.accordion-bordered .accordion-item .accordion-body {
    border-radius: 0; }
  .landio-accordion-v1.accordion-bordered .accordion-item .accordion-button[aria-expanded="true"]::after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

/*======= Accordion v2 =======*/
.landio-accordion-v2 .accordion-item {
  background-color: var(--color-white);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 2, 0.06);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 2, 0.06);
  padding: 0px 35px; }
  .landio-accordion-v2 .accordion-item:not(:last-child) {
    margin-bottom: 15px; }
  .landio-accordion-v2 .accordion-item .accordion-header .accordion-button {
    padding: 16px 0;
    width: 100%;
    background-color: transparent;
    font-family: var(--font-cs-medium);
    font-size: 18px;
    line-height: 1.2;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: none;
    position: relative;
    color: var(--color-heading-5);
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .landio-accordion-v2 .accordion-item .accordion-header .accordion-button::before {
      content: "\f105";
      width: 30px;
      height: 30px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 30px;
      flex: 0 0 30px;
      text-align: center;
      line-height: 30px;
      margin-right: 15px;
      border-radius: 50%;
      font-family: "Font Awesome 5 Pro";
      font-size: 15px;
      font-weight: 600;
      color: var(--color-white);
      background-image: none;
      background-color: var(--color-primary-7);
      -webkit-transform: rotate(0);
      -ms-transform: rotate(0);
      transform: rotate(0);
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
  .landio-accordion-v2 .accordion-item .accordion-body {
    padding: 0 0 25px 45px; }
  .landio-accordion-v2 .accordion-item .accordion-button[aria-expanded="true"]::before {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg); }

/*======= Accordion Tab =======*/
.accordion-tab .nav {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 0;
  margin-bottom: 40px; }
  .accordion-tab .nav .nav-item {
    margin: 3px; }
  .accordion-tab .nav .nav-link {
    border: 0;
    border-radius: 5px;
    margin: 0;
    font-family: var(--font-cs-medium);
    font-size: 16px;
    line-height: 1.4;
    color: var(--color-heading-5);
    background-color: transparent;
    padding: 8px 16px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    .accordion-tab .nav .nav-link.active, .accordion-tab .nav .nav-link:hover {
      background-color: var(--color-primary-7);
      color: var(--color-white); }

/*======= Landio Vidoe v1  =======*/
.landio-video {
  position: relative;
  z-index: 3;
  height: 650px;
  border-radius: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center; }
  .landio-video .play-btn {
    z-index: 1;
    height: 80px;
    width: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #299cdb;
    color: var(--color-white);
    border-radius: 50%;
    position: relative;
    z-index: 1; }
    .landio-video .play-btn::before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-transform: translate(-50%, -50%) scale(1.4);
      -ms-transform: translate(-50%, -50%) scale(1.4);
      transform: translate(-50%, -50%) scale(1.4);
      background-color: #299cdb;
      opacity: 0.15; }
    .landio-video .play-btn.color-heading-3, .landio-video .play-btn.color-heading-3::before {
      background-color: var(--color-heading-3); }
    .landio-video .play-btn.color-primary-7, .landio-video .play-btn.color-primary-7::before {
      background-color: var(--color-primary-7); }
  .landio-video::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: var(--color-heading-3);
    z-index: -1;
    border-radius: 10px;
    opacity: 0.3; }

/*======= Info Box =======*/
.info-boxes-wrapper {
  border-radius: 15px;
  background-color: #0146f6;
  -webkit-box-shadow: 0px 2px 14px 0px rgba(0, 64, 229, 0.59);
  box-shadow: 0px 2px 14px 0px rgba(0, 64, 229, 0.59);
  position: relative;
  z-index: 1;
  padding: 70px 105px;
  border-top: 4px solid #dce5fb; }
  .info-boxes-wrapper::after {
    position: absolute;
    content: "";
    width: 2px;
    background-color: #215ffb;
    top: 15%;
    bottom: 15%;
    right: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .info-boxes-wrapper .info-boxes {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 250px; }
  .info-boxes-wrapper .box-item {
    color: var(--color-white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .info-boxes-wrapper .box-item .box-icon {
      margin-right: 40px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 80px;
      flex: 0 0 80px;
      width: 80px;
      font-size: 80px;
      line-height: 0.5; }
    .info-boxes-wrapper .box-item .box-title {
      font-size: 24px;
      font-family: var(--font-cs-medium);
      font-weight: 500;
      margin-bottom: 15px;
      color: var(--color-white); }

/*======= Brand Items =======*/
.brand-items.brand-effect-one img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }
  .brand-items.brand-effect-one img:hover {
    opacity: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0); }

.brand-items .brand-item {
  text-align: center; }

.brand-items .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.brands-item-grid img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-filter: brightness(0.5);
  filter: brightness(0.5); }
  .brands-item-grid img:hover {
    -webkit-filter: brightness(1);
    filter: brightness(1); }

.brand-sectioin-title {
  font-size: 30px;
  font-family: var(--font-cs-medium);
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -1px; }
  .brand-sectioin-title span {
    color: var(--color-secondary); }

.partner-logo img {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0.5; }

.partner-logo a:hover img {
  opacity: 1; }

/*======= Latest News v1 =======*/
.latest-news-v1 .latest-news-box {
  background: var(--color-white);
  padding: 20px 20px 35px 20px;
  border-radius: 10px; }
  .latest-news-v1 .latest-news-box .post-thumbnail {
    position: relative; }
    .latest-news-v1 .latest-news-box .post-thumbnail img {
      border-radius: 10px;
      width: 100%; }
  .latest-news-v1 .latest-news-box .post-tag {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 4px;
    position: absolute;
    left: 15px;
    bottom: 0;
    z-index: 2;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%); }
    .latest-news-v1 .latest-news-box .post-tag a {
      display: block;
      background-color: #299cdb;
      color: var(--color-white);
      padding: 8px 15px;
      border-radius: 7px;
      line-height: 1;
      font-size: 15px;
      font-weight: 700;
      font-family: var(--font-cs-bold); }
  .latest-news-v1 .latest-news-box .post-content {
    padding: 35px 15px 0px 15px; }
  .latest-news-v1 .latest-news-box .post-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
    line-height: 1; }
    .latest-news-v1 .latest-news-box .post-meta a {
      font-size: 15px;
      color: var(--color-body);
      line-height: 1;
      font-size: 15px;
      font-family: var(--font-cs-medium);
      font-weight: 500; }
      .latest-news-v1 .latest-news-box .post-meta a i {
        margin-right: 8px; }
  .latest-news-v1 .latest-news-box .title {
    font-size: 22px;
    margin-bottom: 28px;
    line-height: 1.3; }
    .latest-news-v1 .latest-news-box .title a {
      color: var(--color-heading-2); }
  .latest-news-v1 .latest-news-box .read-more-btn {
    background-color: #e5ecfc;
    font-size: 15px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    color: var(--color-heading-2);
    border-radius: 30px;
    line-height: 1.4;
    padding: 15px 30px; }
    .latest-news-v1 .latest-news-box .read-more-btn i {
      margin-left: 8px; }
  .latest-news-v1 .latest-news-box:hover .read-more-btn {
    background-color: var(--color-heading);
    color: var(--color-white); }

.latest-news-v1.color-secondary .latest-news-box .post-tag a {
  background-color: var(--color-secondary); }

.latest-news-v1.color-secondary .latest-news-box:hover .read-more-btn {
  background-color: var(--color-heading-2);
  color: var(--color-white); }

/*======= Latest News v2 =======*/
.latest-news-v2 .latest-news-box .post-thumbnail {
  overflow: hidden;
  border-radius: 7px; }
  .latest-news-v2 .latest-news-box .post-thumbnail img {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    width: 100%; }

.latest-news-v2 .latest-news-box .post-content {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
  box-shadow: 0px 10px 30px 0px rgba(219, 219, 219, 0.41);
  margin-left: 40px;
  padding: 20px 35px 25px;
  margin-top: -40px;
  position: relative;
  background-color: var(--color-white);
  z-index: 1;
  border-radius: 7px 0 7px 7px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

.latest-news-v2 .latest-news-box .post-meta {
  margin-bottom: 15px; }
  .latest-news-v2 .latest-news-box .post-meta li a {
    color: #606060;
    line-height: 1;
    font-size: 15px;
    font-family: var(--font-cs-medium);
    font-weight: 500; }
    .latest-news-v2 .latest-news-box .post-meta li a i {
      margin-right: 8px;
      color: var(--color-primary-3); }

.latest-news-v2 .latest-news-box .title {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 1.4; }
  .latest-news-v2 .latest-news-box .title a:hover {
    color: var(--color-primary-3); }

.latest-news-v2 .latest-news-box .post-author img {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%; }

.latest-news-v2 .latest-news-box .post-author a {
  color: #87888b;
  font-size: 18px;
  font-family: var(--font-cs-medium);
  font-weight: 500; }

.latest-news-v2 .latest-news-box:hover .post-thumbnail img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.latest-news-v2 .latest-news-box:hover .post-content {
  margin-left: 0;
  border-top-left-radius: 0; }

.latest-news-v2.style-two .latest-news-box .post-thumbnail {
  border-radius: 0; }

.latest-news-v2.style-two .latest-news-box .post-content {
  margin: 0;
  border-radius: 0; }
  .latest-news-v2.style-two .latest-news-box .post-content .post-meta {
    margin-bottom: 5px;
    text-transform: uppercase; }
    .latest-news-v2.style-two .latest-news-box .post-content .post-meta a,
    .latest-news-v2.style-two .latest-news-box .post-content .post-meta a i {
      color: #7a7a7a; }
  .latest-news-v2.style-two .latest-news-box .post-content .title {
    font-size: 22px; }
    @media (max-width: 424px) {
      .latest-news-v2.style-two .latest-news-box .post-content .title {
        font-size: 18px; } }

/* Recent News H12 */
.recent-news-item {
  margin-bottom: 30px; }
  .recent-news-item .image img {
    width: 100%; }
  .recent-news-item .content {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    padding: 30px 35px 40px; }
    @media (max-width: 424px) {
      .recent-news-item .content {
        padding-left: 25px;
        padding-right: 25px; } }
    .recent-news-item .content .category a {
      color: #696969;
      margin-bottom: 5px;
      font-family: var(--font-cs-book); }
      .recent-news-item .content .category a:not(:last-child) {
        margin-right: 5px; }
        .recent-news-item .content .category a:not(:last-child):after {
          content: ','; }
      .recent-news-item .content .category a:hover {
        color: #ffa900; }
    .recent-news-item .content h4 {
      font-size: 22px;
      line-height: 1.4;
      margin-bottom: 25px; }
      @media (max-width: 424px) {
        .recent-news-item .content h4 {
          font-size: 20px; } }
      .recent-news-item .content h4 a:hover {
        color: #ffa900; }
  .recent-news-item:hover .content {
    -webkit-box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5);
    box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5); }

/*======= Contact Boxes v1  =======*/
.contact-info-boxes-v1 .contact-info-box {
  background-color: var(--color-white);
  -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
  box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
  padding: 40px 55px 45px 55px;
  border-radius: 7px;
  margin-bottom: 25px; }
  .contact-info-boxes-v1 .contact-info-box .title {
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 25px; }
    .contact-info-boxes-v1 .contact-info-box .title span {
      color: #299cdb; }
  .contact-info-boxes-v1 .contact-info-box .template-btn {
    padding: 12px 30px; }

/*======= Contact Boxes v2  =======*/
.contact-info-boxes-v2 .contact-info-box .info-body {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
  box-shadow: 0px 0px 30px 0px rgba(234, 234, 234, 0.5);
  padding: 80px 35px 35px 35px;
  margin-top: -45px;
  text-align: center;
  border-radius: 5px;
  line-height: 1.6;
  font-weight: 500;
  font-family: var(--font-cs-medium); }

.contact-info-boxes-v2 .contact-info-box .title {
  font-size: 24px;
  margin-bottom: 10px;
  text-transform: capitalize; }

.contact-info-boxes-v2 .contact-info-box,
.contact-info-boxes-v2 .contact-info-box a {
  color: var(--color-body); }

.contact-info-boxes-v2 .contact-info-box .icon {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 40px;
  line-height: 0.5;
  border-radius: 50px;
  background-color: #299cdb;
  color: var(--color-white);
  -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
  box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
  position: relative;
  z-index: 2; }
  .contact-info-boxes-v2 .contact-info-box .icon.icon-gradient-1 {
    background-image: -webkit-linear-gradient(315deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
    background-image: -o-linear-gradient(315deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
    background-image: linear-gradient(135deg, #f33946 0%, #c81d9a 59%, #9c00ed 100%);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28); }
  .contact-info-boxes-v2 .contact-info-box .icon.icon-gradient-2 {
    background-image: -webkit-linear-gradient(315deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
    background-image: -o-linear-gradient(315deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
    background-image: linear-gradient(135deg, #4639f3 0%, #711df0 59%, #9c00ed 100%);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28);
    box-shadow: 0px 10px 60px 0px rgba(84, 54, 240, 0.28); }
  .contact-info-boxes-v2 .contact-info-box .icon.icon-gradient-3 {
    background-image: -webkit-linear-gradient(315deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
    background-image: -o-linear-gradient(315deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
    background-image: linear-gradient(135deg, #fdba1a 0%, #f55d64 59%, #ed00ad 100%);
    -webkit-box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28);
    box-shadow: 0px 10px 60px 0px rgba(218, 9, 212, 0.28); }

/*======= Contact Form v1  =======*/
.contact-form-v1 {
  padding: 58px 45px;
  border: 2px solid #efeff0;
  border-radius: 7px; }
  .contact-form-v1 .input-field .nice-select, .contact-form-v1 .input-field:not(:last-child) {
    margin-bottom: 15px; }
  .contact-form-v1 .nice-select,
  .contact-form-v1 input,
  .contact-form-v1 textarea {
    border-radius: 7px; }
  .contact-form-v1 textarea {
    height: 160px; }

/*======= Contact Form v2  =======*/
.contact-form-v2 .input-field {
  position: relative;
  z-index: 1; }

.contact-form-v2 label {
  font-size: 18px;
  font-family: var(--font-cs-medium);
  font-weight: 500;
  line-height: 1;
  color: var(--color-heading);
  position: absolute;
  left: 30px;
  top: 33px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  margin: 0; }

.contact-form-v2 .textarea-field label {
  display: none; }

.contact-form-v2 input,
.contact-form-v2 textarea {
  border-radius: 7px;
  background-color: rgba(71, 59, 240, 0.07);
  height: 66px;
  border: 2px solid transparent;
  padding: 0 30px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .contact-form-v2 input:focus,
  .contact-form-v2 textarea:focus {
    border-color: #299cdb;
    padding-left: 115px; }
    .contact-form-v2 input:focus + label,
    .contact-form-v2 textarea:focus + label {
      opacity: 1;
      visibility: visible; }

.contact-form-v2 textarea {
  padding-top: 15px;
  height: 150px; }
  .contact-form-v2 textarea:focus {
    padding-left: 30px; }

.contact-form-v2 .list-unstyled {
  padding-left: 20px; }
  .contact-form-v2 .list-unstyled li {
    color: red; }

.contact-form-v2 .hidden {
  display: none; }

.contact-form-v2 #msgSubmit {
  margin: 5px 0 0 20px; }

.contact-map {
  width: 100%;
  height: 620px; }
  .contact-map iframe {
    width: 100%;
    height: 100%;
    border: none; }

/*======= Progress Bar =======*/
.progress-bars .single-progress:not(:last-child) {
  margin-bottom: 35px; }

.progress-bars .single-progress .progress-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 18px; }

.progress-bars .single-progress .title,
.progress-bars .single-progress .percentage {
  color: var(--color-heading);
  font-size: 18px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1; }

.progress-bars .single-progress .progress-line {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 3px;
  background-color: var(--color-white); }
  .progress-bars .single-progress .progress-line .line-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #299cdb;
    -webkit-transition: linear 1s;
    -o-transition: linear 1s;
    transition: linear 1s; }

.progress-bars .single-progress:nth-child(2) .progress-line .line-inner {
  background-color: var(--color-secondary); }

.progress-bars .single-progress:nth-child(3) .progress-line .line-inner {
  background-color: #ff00fc; }

.progress-bars .single-progress:nth-child(3) .progress-line .line-inner {
  background-color: #00b9ee; }

/*======= Newsletter Form =======*/
.newsletter-form .input-field {
  position: relative; }
  .newsletter-form .input-field input {
    width: 100%;
    height: 95px;
    background-color: var(--color-white);
    border-radius: 45px;
    padding-left: 45px;
    padding-right: 250px;
    font-size: 17px;
    font-weight: 500; }
  .newsletter-form .input-field .template-btn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    top: 15px; }

.newsletter-form.form-two .input-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .newsletter-form.form-two .input-field input {
    border-radius: 7px;
    height: 65px;
    padding-left: 30px;
    padding-right: 0;
    width: calc(100% - 230px); }
  .newsletter-form.form-two .input-field .template-btn {
    position: unset; }

.newsletter-form.form-two.have-form-radius input,
.newsletter-form.form-two.have-form-radius .template-btn {
  border-radius: 50px; }

.newsletter-form-2 {
  position: relative;
  z-index: 1; }
  .newsletter-form-2 input {
    -webkit-box-shadow: 0px 10px 30px 0px rgba(188, 183, 255, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(188, 183, 255, 0.36);
    border-radius: 30px;
    width: 100%;
    font-size: 17px;
    padding: 0 70px 0 30px;
    height: 65px;
    background-color: var(--color-white); }
  .newsletter-form-2 button {
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center;
    padding: 0;
    position: absolute;
    right: 0;
    top: 10px;
    right: 10px;
    background-color: #299cdb;
    border: none;
    border-radius: 50%;
    color: var(--color-white);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    .newsletter-form-2 button:hover {
      background-color: var(--color-heading); }

/*======= Projects =======*/
.recent-projects .recent-project-item .project-thumbnail {
  overflow: hidden;
  border-radius: 7px 7px 0 0; }
  .recent-projects .recent-project-item .project-thumbnail img {
    width: 100%;
    border-radius: 7px 7px 0 0; }

.recent-projects .recent-project-item .project-content {
  border: 2px solid rgba(51, 119, 255, 0.1);
  border-radius: 0 0 7px 7px;
  border-top: 0;
  padding: 40px 35px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  background-color: var(--color-white); }

.recent-projects .recent-project-item .project-cat {
  color: var(--color-primary-4);
  margin-bottom: 15px;
  line-height: 1.3; }

.recent-projects .recent-project-item .title {
  font-size: 22px;
  letter-spacing: -1px;
  line-height: 1.4; }

.recent-projects .recent-project-item .read-more-btn {
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  margin-top: 25px;
  background-color: var(--color-soft-grey);
  color: var(--color-heading);
  line-height: 1.4;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  vertical-align: middle;
  border-radius: 50px; }
  .recent-projects .recent-project-item .read-more-btn i {
    margin-left: 10px; }
  .recent-projects .recent-project-item .read-more-btn:hover {
    background-color: var(--color-primary-4); }

.recent-projects .recent-project-item:hover .project-content {
  -webkit-box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
  box-shadow: 0px 10px 45px 0px rgba(71, 59, 240, 0.1);
  border-color: transparent; }

.recent-projects .recent-project-item:hover .read-more-btn {
  background-color: var(--color-primary-4);
  color: var(--color-white); }

/*======= Video Call To Action =======*/
.video-cta .video-cta-text-block {
  background-color: var(--color-primary-4);
  padding: 60px 50px;
  text-align: center;
  color: var(--color-white);
  border-radius: 7px; }
  .video-cta .video-cta-text-block .title {
    font-size: 36px;
    color: var(--color-white);
    margin-bottom: 25px; }
  .video-cta .video-cta-text-block .template-btn {
    margin-top: 25px;
    padding: 14px 30px; }
    .video-cta .video-cta-text-block .template-btn:not(:hover) {
      border-color: var(--color-white); }

.video-cta .landio-video {
  min-height: 350px;
  height: 100%;
  border-radius: 7px; }
  .video-cta .landio-video .play-btn {
    width: 75px;
    height: 75px;
    background-color: var(--color-primary-4); }
    .video-cta .landio-video .play-btn::before {
      background-color: var(--color-primary-4); }
  .video-cta .landio-video::after {
    background-color: var(--color-secondary-4);
    opacity: 0.5; }

/* Home Ten CTA Video */
.cta-part-h10 {
  z-index: 1;
  position: relative;
  background-size: cover;
  padding: 55px 65px 60px; }
  @media (max-width: 991px) {
    .cta-part-h10 {
      margin-bottom: 30px; } }
  @media (max-width: 424px) {
    .cta-part-h10 {
      padding-left: 25px;
      padding-right: 25px; } }
  .cta-part-h10:before {
    height: 100%;
    content: '';
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    background-image: -webkit-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: -o-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%); }
  .cta-part-h10 .sub-title {
    color: white;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    letter-spacing: 5px;
    display: inline-block;
    text-transform: uppercase; }
  .cta-part-h10 h2 {
    color: white;
    font-size: 42px;
    margin-bottom: 35px; }
    @media (max-width: 767px) {
      .cta-part-h10 h2 {
        font-size: 35px; } }
    @media (max-width: 424px) {
      .cta-part-h10 h2 {
        font-size: 30px; } }

.video-part-h10 {
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 300px;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .video-part-h10 .play-btn {
    height: 85px;
    width: 85px;
    color: white;
    line-height: 70px;
    text-align: center;
    border-radius: 50%;
    border: 7px solid;
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
  .video-part-h10:before {
    height: 100%;
    content: '';
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0.45;
    background: black;
    position: absolute; }

/*======= Service Tab  =======*/
.service-tab .service-tab-nav {
  margin-bottom: 80px; }
  .service-tab .service-tab-nav .nav-tabs {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: none;
    margin: -15px; }
    .service-tab .service-tab-nav .nav-tabs .nav-item {
      padding: 15px;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%; }
    .service-tab .service-tab-nav .nav-tabs .nav-link {
      margin: 0;
      border: none;
      line-height: 1.2;
      font-size: 18px;
      font-family: var(--font-cs-bold);
      font-weight: 700;
      color: var(--color-heading-4);
      letter-spacing: -0.5px;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.1);
      box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.1);
      background-color: var(--color-white);
      padding: 20px 25px;
      display: block; }
      .service-tab .service-tab-nav .nav-tabs .nav-link i {
        margin-right: 10px; }
      .service-tab .service-tab-nav .nav-tabs .nav-link.active, .service-tab .service-tab-nav .nav-tabs .nav-link:hover {
        color: var(--color-white);
        background-color: var(--color-primary-5);
        -webkit-box-shadow: 0px 10px 30px 0px rgba(85, 128, 255, 0.75);
        box-shadow: 0px 10px 30px 0px rgba(85, 128, 255, 0.75); }

.service-tab-section {
  position: relative;
  z-index: 1; }
  .service-tab-section::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 66%;
    z-index: -1;
    background-color: #f7f7f7; }

/* cc Service Style */
.cc-service-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  margin-bottom: 30px;
  padding: 40px 40px 35px;
  -webkit-box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5);
  box-shadow: 10px 0 60px rgba(219, 219, 219, 0.5); }
  @media (max-width: 424px) {
    .cc-service-item {
      display: block;
      padding-left: 33px;
      padding-right: 33px; } }
  .cc-service-item .icon {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    margin-right: 22px;
    margin-bottom: 20px; }
  .cc-service-item .content h4 {
    font-size: 22px;
    margin-bottom: 10px; }
    .cc-service-item .content h4 a:hover {
      color: #ffa900; }

/*======= Screenshot Slider =======*/
.screenshot-slider .screenshot-item img {
  width: 100%;
  border-radius: 30px; }

.screenshot-arrows .slick-arrow {
  width: 45px;
  height: 45px;
  border-radius: 7px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-dark-4);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: none; }
  .screenshot-arrows .slick-arrow:hover, .screenshot-arrows .slick-arrow.next-arrow {
    background-color: var(--color-primary-6); }
  .screenshot-arrows .slick-arrow.next-arrow {
    margin-left: 8px; }

/*======= Booking Form =======*/
.booking-form {
  background-color: var(--color-white);
  -webkit-box-shadow: 0px 10px 60px 0px rgba(232, 232, 232, 0.77);
  box-shadow: 0px 10px 60px 0px rgba(232, 232, 232, 0.77);
  padding: 30px; }
  .booking-form .form-title {
    font-size: 24px;
    position: relative;
    z-index: 1;
    background-color: var(--color-primary-7);
    color: var(--color-white);
    letter-spacing: -0.3px;
    line-height: 1;
    padding: 18px 40px; }
    .booking-form .form-title::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      background-image: url(../img/booking-title-line.png);
      background-size: cover;
      background-position: center;
      opacity: 0.1; }
  .booking-form form {
    padding: 40px;
    background-color: #fdf5e2; }
    .booking-form form .input-field:not(:last-child) {
      margin-bottom: 25px; }
    .booking-form form input {
      height: 55px;
      background-color: var(--color-white);
      font-size: 15px;
      font-style: italic;
      padding: 0px 25px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .booking-form form input:focus {
        color: var(--color-heading-5); }
    .booking-form form button {
      height: 55px;
      background-color: var(--color-heading-5);
      color: var(--color-white);
      text-transform: uppercase;
      font-family: var(--font-cs-medium);
      font-size: 14px;
      border: none;
      padding: 0px 30px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .booking-form form button i {
        margin-left: 10px; }
      .booking-form form button:hover {
        background-color: var(--color-primary-7); }

/*======= Sign In & Sign Up =======*/
.sign-in-up-wrapper {
  font-size: 14px !important;
  overflow-y: scroll;
}

  .sign-in-up-wrapper .form-groups {
    padding: 30px; }
  .sign-in-up-wrapper .form-note {
    padding: 15px 30px;
    border-top: 1px solid var(--color-offwhite);
    font-size: 16px;
    line-height: 1.7; }
  .sign-in-up-wrapper .form-title {
    font-size: 24px;
    margin-bottom: 25px; }
  .sign-in-up-wrapper .field-group {
    position: relative; }
    .sign-in-up-wrapper .field-group:not(:last-child) {
      margin-bottom: 15px; }
    .sign-in-up-wrapper .field-group .icon {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 55px;
      text-align: center;
      font-size: 15px;
      border-right: 1px solid var(--color-offwhite);
      line-height: 30px;
      color: var(--color-heading); }
    .sign-in-up-wrapper .field-group input {
      height: 50px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      text-align: left; }
      .sign-in-up-wrapper .field-group input:focus {
        border-color: #299cdb;
        color: var(--color-heading);
        background-color: transparent; }
    .sign-in-up-wrapper .field-group button {
      width: 100%;
      height: 55px;
      border: none;
      background-color: #299cdb;
      color: var(--color-white);
      font-family: var(--font-cs-bold);
      font-size: 15px;
      font-weight: 700;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s; }
      .sign-in-up-wrapper .field-group button:hover {
        background-color: var(--color-heading); }
  .sign-in-up-wrapper .forgot-password {
    margin-top: 25px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium);
    font-size: 15px;
    font-weight: 500; }
    .sign-in-up-wrapper .forgot-password:hover {
      color: #299cdb; }

/*======= Portfolio Filter =======*/
.portfolio-filter ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .portfolio-filter ul li {
    background-color: var(--color-soft-grey);
    color: var(--color-heading);
    font-family: var(--font-cs-medium);
    font-size: 15px;
    font-weight: 500;
    line-height: 1.4;
    padding: 15px 30px;
    margin: 5px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    .portfolio-filter ul li.active, .portfolio-filter ul li:hover {
      background-color: #299cdb;
      color: var(--color-white); }

/*======= Portfolio One =======*/
.portfolio-box-one {
  position: relative;
  z-index: 1; }
  .portfolio-box-one::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: var(--color-heading);
    content: "";
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden; }
  .portfolio-box-one .thumb {
    width: 100%;
    height: 320px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .portfolio-box-one .content {
    position: absolute;
    left: 0;
    right: 0;
    top: 45%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    z-index: 3;
    padding: 0px 30px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden; }
    .portfolio-box-one .content .tags {
      margin-bottom: 8px; }
      .portfolio-box-one .content .tags a {
        font-size: 14px;
        line-height: 1.4;
        color: var(--color-white); }
    .portfolio-box-one .content .title {
      font-size: 20px; }
      .portfolio-box-one .content .title a {
        color: var(--color-white); }
  .portfolio-box-one:hover::before {
    opacity: 0.8;
    visibility: visible; }
  .portfolio-box-one:hover .content {
    top: 50%;
    visibility: visible;
    opacity: 1; }

/*======= Portfolio Two =======*/
.portfolio-box-two .portfolio-thumb {
  height: 0;
  overflow: hidden;
  border-radius: 5px;
  padding-bottom: 100%; }
  .portfolio-box-two .portfolio-thumb a {
    display: block;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center; }

.portfolio-box-two .title {
  font-size: 20px;
  margin-bottom: 3px;
  margin-top: 25px;
  line-height: 1.2; }

.portfolio-box-two .tags a {
  color: var(--color-body);
  line-height: 1; }

.portfolio-box-two:hover .portfolio-thumb a {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

/*======= Portfolio Three =======*/
.portfolio-box-three {
  position: relative;
  margin-bottom: 30px; }
  .portfolio-box-three .image img {
    width: 100%; }
  .portfolio-box-three .content {
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-image: -webkit-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: -o-linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%);
    background-image: linear-gradient(45deg, rgba(107, 52, 250, 0.87059) 0%, rgba(212, 21, 110, 0.90196) 100%); }
    .portfolio-box-three .content h3 {
      font-size: 30px;
      margin-bottom: 5px; }
    .portfolio-box-three .content a {
      color: white; }
    .portfolio-box-three .content .tags {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 10px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center; }
      .portfolio-box-three .content .tags li {
        position: relative; }
        .portfolio-box-three .content .tags li:not(:last-child) {
          margin-right: 12px;
          padding-right: 12px; }
          .portfolio-box-three .content .tags li:not(:last-child):before {
            content: '';
            height: 5px;
            width: 5px;
            right: -2px;
            background: white;
            position: absolute;
            border-radius: 50%;
            top: calc(50% - 2px); }
    .portfolio-box-three .content .portfolio-btn {
      font-size: 22px; }
    .portfolio-box-three .content:before {
      content: '';
      left: 8%;
      top: 8%;
      z-index: -1;
      position: absolute;
      width: 84%;
      height: 84%;
      border: 2px solid rgba(255, 255, 255, 0.2); }
  .portfolio-box-three:hover .content {
    opacity: 1; }

/*======= Portfolio Details =======*/
.portfolio-info-box {
  background-color: var(--color-soft-grey);
  padding: 40px 45px;
  position: relative;
  z-index: 1; }
  .portfolio-info-box .box-title {
    font-size: 24px;
    margin-bottom: 25px; }
  .portfolio-info-box ul li:not(:last-child) {
    margin-bottom: 15px; }
  .portfolio-info-box ul li span {
    display: block;
    font-size: 15px;
    line-height: 1.8; }
  .portfolio-info-box ul li .info-title {
    font-size: 18px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    line-height: 1.4;
    color: var(--color-heading);
    margin-bottom: 5px; }

.portfolio-details-content .portfolio-title {
  font-size: 38px;
  margin-bottom: 30px; }

/*======= Schedule  =======*/
.schedule-item {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: white;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 5px solid;
  padding: 15px 15px 15px 25px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-border-image: -webkit-gradient(linear, left top, left bottom, from(red), to(#299cdb)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(top, red, #299cdb) 1 100%;
  -o-border-image: -o-linear-gradient(top, red, #299cdb) 1 100%;
  border-image: -webkit-gradient(linear, left top, left bottom, from(red), to(#299cdb)) 1 100%;
  border-image: linear-gradient(to bottom, red, #299cdb) 1 100%; }
  @media (max-width: 767px) {
    .schedule-item {
      display: block; } }
  .schedule-item:not(:last-child) {
    margin-bottom: 20px; }
  .schedule-item h4 {
    font-size: 22px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .schedule-item span {
    font-weight: 500;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
    @media (max-width: 767px) {
      .schedule-item span {
        display: inline-block;
        margin: 5px 10px 0 0; } }
    .schedule-item span i {
      font-size: 16px;
      margin-right: 4px; }
  .schedule-item .admit-btn {
    color: white;
    font-size: 14px;
    font-weight: 600;
    background: black;
    padding: 10px 30px;
    text-transform: uppercase; }
    .schedule-item .admit-btn i {
      margin-left: 5px; }
    .schedule-item .admit-btn:hover {
      color: black;
      background: white; }
    @media (max-width: 767px) {
      .schedule-item .admit-btn {
        font-size: 12px;
        margin-top: 10px;
        padding: 7px 20px; } }
  .schedule-item:before {
    position: absolute;
    content: '';
    height: 100%;
    left: -5px;
    top: 0;
    opacity: 0;
    z-index: -1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    width: calc(100% + 5px);
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
  .schedule-item:hover h4, .schedule-item:hover span {
    color: white; }
  .schedule-item:hover:before {
    opacity: 1; }

/*======= Feature  =======*/
.feature-item {
  z-index: 1;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 45px 50px;
  position: relative;
  margin: 0 -1px -1px 0;
  border: 1px solid #eeeef0; }
  @media (max-width: 767px) {
    .feature-item {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (max-width: 424px) {
    .feature-item {
      display: block; } }
  .feature-item:before {
    content: '';
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    z-index: -1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    background: white;
    position: absolute;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .feature-item .icon {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    width: 85px;
    margin-right: 40px; }
    @media (max-width: 575px) {
      .feature-item .icon {
        margin-right: 20px;
        margin-bottom: 20px; } }
  .feature-item .content h4 {
    font-size: 22px;
    margin-bottom: 10px; }
  .feature-item:hover {
    z-index: 2; }
    .feature-item:hover:before {
      width: calc(100% + 40px);
      height: calc(100% + 40px);
      -webkit-box-shadow: 10px 0 30px rgba(13, 15, 44, 0.1);
      box-shadow: 10px 0 30px rgba(13, 15, 44, 0.1); }

/* Feature style two */
.feature-area-two {
  z-index: 1;
  position: relative; }
  .feature-area-two .feature-bg {
    left: 0;
    bottom: 0;
    z-index: -2;
    height: 75%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    background: rgba(13, 15, 44, 0.9); }
    @media (max-width: 1199px) {
      .feature-area-two .feature-bg {
        height: 85%; } }
    @media (max-width: 575px) {
      .feature-area-two .feature-bg {
        height: 90%; } }
    @media (max-width: 424px) {
      .feature-area-two .feature-bg {
        height: 95%; } }
    .feature-area-two .feature-bg:before {
      top: -25%;
      content: '';
      z-index: -1;
      width: 650px;
      height: 650px;
      border-radius: 50%;
      position: absolute;
      background: #fd695a;
      left: calc(50% - 325px);
      -webkit-filter: blur(150px) opacity(0.65);
      filter: blur(150px) opacity(0.65);
      -webkit-animation: 5s zoomInOut infinite;
      animation: 5s zoomInOut infinite; }
      @media (max-width: 767px) {
        .feature-area-two .feature-bg:before {
          -webkit-filter: blur(150px) opacity(0.25);
          filter: blur(150px) opacity(0.25); } }
    .feature-area-two .feature-bg .circles,
    .feature-area-two .feature-bg .circles:after,
    .feature-area-two .feature-bg .circles:before {
      left: 50%;
      position: absolute;
      border-radius: 50%;
      -webkit-transform: translate(-50%, -58%);
      -ms-transform: translate(-50%, -58%);
      transform: translate(-50%, -58%);
      background: rgba(255, 255, 255, 0.02); }
    .feature-area-two .feature-bg .circles {
      top: 0;
      height: 1450px;
      width: 1450px; }
      @media (max-width: 1199px) {
        .feature-area-two .feature-bg .circles {
          height: 1200px;
          width: 1200px; } }
      @media (max-width: 767px) {
        .feature-area-two .feature-bg .circles {
          height: 800px;
          width: 800px; } }
    .feature-area-two .feature-bg .circles:after {
      height: 90%;
      width: 85%;
      content: '';
      top: 58%; }
    .feature-area-two .feature-bg .circles:before {
      content: '';
      height: 80%;
      width: 75%;
      top: 58%; }

.feature-item-two {
  margin-bottom: 30px; }
  .feature-item-two .icon {
    width: 65px;
    height: 65px;
    color: white;
    font-size: 25px;
    line-height: 65px;
    border-radius: 50%;
    margin-bottom: 35px;
    display: inline-block; }
    .feature-item-two .icon.bg-one {
      background: #2ecea8; }
    .feature-item-two .icon.bg-two {
      background: #fead44; }
    .feature-item-two .icon.bg-three {
      background: #ff00ff; }
  .feature-item-two h4 {
    color: white;
    margin-bottom: 20px; }
  .feature-item-two p {
    color: #9f9f9f;
    font-size: 17px; }

/* Feature Style Three */
.cc-feature-item {
  z-index: 1;
  overflow: hidden;
  background: white;
  border-radius: 7px;
  position: relative;
  text-align: center;
  padding: 50px 30px;
  margin-bottom: 30px; }
  .cc-feature-item .icon {
    margin-bottom: 35px; }
  .cc-feature-item h4 {
    font-size: 22px;
    margin-bottom: 20px; }
  .cc-feature-item p {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin-bottom: 20px; }
  .cc-feature-item .details-btn {
    height: 45px;
    width: 45px;
    color: #696969;
    line-height: 43px;
    border-radius: 50%;
    border: 2px solid #e5e6e8; }
  .cc-feature-item .hover-bg {
    position: absolute;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: -1;
    background: #040d1a; }
    .cc-feature-item .hover-bg img {
      max-width: 95%;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      position: absolute; }
      .cc-feature-item .hover-bg img.left {
        bottom: 0;
        left: 0;
        -webkit-transform: translate(-50%, 50%);
        -ms-transform: translate(-50%, 50%);
        transform: translate(-50%, 50%); }
      .cc-feature-item .hover-bg img.right {
        top: 0;
        right: 0;
        -webkit-transform: translate(50%, -50%);
        -ms-transform: translate(50%, -50%);
        transform: translate(50%, -50%); }
  .cc-feature-item:hover .hover-bg {
    opacity: 1; }
    .cc-feature-item:hover .hover-bg img {
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
      transform: translate(0); }
  .cc-feature-item:hover p, .cc-feature-item:hover h4 a {
    color: white; }
  .cc-feature-item:hover .details-btn {
    color: #040d1a;
    background: #ffa900;
    border-color: #ffa900; }

/*======= Specific Section =======*/
/*======= Counter Section Bordered =======*/
.counter-section-bordered .counter-section-inner {
  background-color: #299cdb;
  background-image: url(../img/particle/section-curve-shape.svg);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  padding: 75px 100px 35px;
  margin: 20px;
  position: relative;
  z-index: 1;
  border-radius: 12px; }
  .counter-section-bordered .counter-section-inner::before {
    content: "";
    position: absolute;
    left: -20px;
    top: -20px;
    right: -20px;
    bottom: -20px;
    background-color: #299cdb;
    opacity: 0.1;
    border-radius: 12px; }
  .counter-section-bordered .counter-section-inner .counter-item {
    padding-bottom: 40px; }

.counter-section-bordered.bordered-secondary-bg .counter-section-inner, .counter-section-bordered.bordered-secondary-bg .counter-section-inner::before {
  background-color: var(--color-secondary); }

/*======= Service With Counter =======*/
.service-with-counter .counter-section {
  position: relative;
  z-index: 2;
  margin-bottom: -115px; }

.service-with-counter .service-section {
  padding-bottom: 130px;
  padding-top: 245px; }

/*======= Faq With Score Box =======*/
.faq-with-seo-score-box .faq-section {
  padding-bottom: 130px;
  padding-top: 270px; }

.faq-with-seo-score-box .seo-score-box {
  position: relative;
  z-index: 2;
  margin-bottom: -150px; }

/*======= Testimonial With Video =======*/
.testimonial-with-video .testimonials-section {
  padding-top: 335px;
  padding-bottom: 130px; }

.testimonial-with-video .landio-video {
  margin-bottom: -245px; }

/*======= Collaborate Section =======*/
.collaborate-section {
  margin-bottom: 115px; }
  .collaborate-section .collaborate-text-block {
    padding-bottom: 130px;
    padding-top: 140px; }
  .collaborate-section .preview-image-v2 {
    margin-bottom: -115px; }
    .collaborate-section .preview-image-v2 img {
      max-height: 800px; }

/*======= Call to Action =======*/
.cta-with-particle.cta-with-particle .container {
  position: relative;
  z-index: 1; }

.cta-with-particle.cta-with-particle .particle-left,
.cta-with-particle.cta-with-particle .particle-right {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  max-width: 160px; }

.cta-with-particle.cta-with-particle .particle-left {
  left: 0; }

.cta-with-particle.cta-with-particle .particle-right {
  right: 0; }

.cta-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px; }
  .cta-btns li {
    padding: 5px; }

.cta-section-with-bg {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-position: center; }
  .cta-section-with-bg::before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-heading);
    opacity: 0.75; }

.cta-section {
  position: relative;
  z-index: 1; }
  .cta-section .cta-absolute-image {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1; }

/*======= Section With Map =======*/
.section-with-map-bg {
  position: relative;
  z-index: 1; }
  .section-with-map-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../img/section-map.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain; }

/*======= Section One Third Map =======*/
.section-one-third-left-map,
.section-one-third-right-map {
  position: relative;
  z-index: 1; }
  .section-one-third-left-map::before,
  .section-one-third-right-map::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: 33.33%;
    height: 100%;
    z-index: -1;
    background-color: var(--color-heading-3);
    background-image: url(../img/section-map-2.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover; }

.section-one-third-left-map::before {
  right: auto;
  left: 0; }

/*======= Section Dots Line =======*/
.section-line-dots {
  position: relative;
  z-index: 1; }
  .section-line-dots::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(../img/dots-line-pattern-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

/*======= Simple Call to action =======*/
.simple-cta {
  background-color: #299cdb;
  padding: 40px 0;
  position: relative;
  z-index: 1; }
  .simple-cta .cta-title {
    color: var(--color-white);
    letter-spacing: -1px;
    line-height: 1.3;
    font-size: 24px;
    font-weight: 400;
    font-family: var(--font-cs-book); }
  .simple-cta::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    /*background-image: url(../img/cta/simple-cta-bg.png);*/
    background-size: cover;
    background-position: center; }

/*======= Faq With Score Box =======*/
.latest-news-with-seo-box .latest-news-section {
  padding-bottom: 130px;
  padding-top: 320px; }

.latest-news-with-seo-box .seo-score-box {
  position: relative;
  z-index: 2;
  margin-bottom: -200px; }

/*======= Half Dots Pattern =======*/
.section-half-dots-pattern,
.section-half-dots-pattern-fixed {
  position: relative;
  z-index: 1; }
  .section-half-dots-pattern::before,
  .section-half-dots-pattern-fixed::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: var(--color-soft-grey);
    background-image: url(../img/dots-pattern-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

/*======= Half Dots Pattern =======*/
.section-half-dots-pattern-fixed::before {
  background-size: contain;
  background-position: top center;
  background-attachment: fixed; }

/*======= Service Details =======*/
.service-details-content .service-title {
  font-size: 42px;
  margin-bottom: 30px; }

.service-details-content .service-subtitle {
  font-size: 30px;
  margin-bottom: 25px; }

/*======= Counter With Video Cta =======*/
.counter-with-vide-cta .counter-section {
  padding-top: 130px;
  padding-bottom: 305px; }

.counter-with-vide-cta .video-cta {
  position: relative;
  z-index: 2;
  margin-top: -305px; }

/*======= Section Author particle =======*/
.section-author-particle {
  position: relative;
  z-index: 1; }
  .section-author-particle .author-particle-image img {
    position: absolute;
    z-index: -1;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    -webkit-box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.49);
    box-shadow: 0px 10px 60px 0px rgba(51, 119, 255, 0.49); }
    .section-author-particle .author-particle-image img.image-1 {
      left: 9.5%;
      top: 31.5%; }
    .section-author-particle .author-particle-image img.image-2 {
      left: 5%;
      bottom: 23%; }
    .section-author-particle .author-particle-image img.image-3 {
      right: 9.5%;
      top: 31.5%; }
    .section-author-particle .author-particle-image img.image-4 {
      right: 5%;
      bottom: 23%; }

/*======= Benefit Section =======*/
.benefit-section .e-wallet-boxed-container {
  position: relative;
  z-index: 1; }

.benefit-section .container {
  position: static; }

.benefit-section .benefit-content {
  padding-top: 110px;
  padding-bottom: 110px; }

.benefit-section .benefit-preview-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end; }
  .benefit-section .benefit-preview-images .image-one {
    margin-top: -140px; }
  .benefit-section .benefit-preview-images .image-two {
    margin-bottom: -140px; }

/*======= Newsletter =======*/
.newsletter-area {
  position: relative; }
  .newsletter-area .newsletter-particle-effect img {
    position: absolute; }
    .newsletter-area .newsletter-particle-effect img.particle-1 {
      left: 10%;
      top: 15%; }
    .newsletter-area .newsletter-particle-effect img.particle-2 {
      left: 15%;
      bottom: 20%; }
    .newsletter-area .newsletter-particle-effect img.particle-3 {
      right: 15%;
      top: 20%; }
    .newsletter-area .newsletter-particle-effect img.particle-4 {
      left: 25%;
      bottom: 8%; }

/*======= Dots Pattern =======*/
.section-dots-pattern {
  position: relative;
  z-index: 1; }
  .section-dots-pattern::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../img/dots-pattern-bg.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }

/*======= CTA Video =======*/
.cta-video-area {
  z-index: 2;
  position: relative; }

/*======= Schedule =======*/
.schedule-area {
  z-index: 1;
  position: relative; }
  .schedule-area::before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 120%;
    background: #f9fafe; }
  .schedule-area .schedule-right-image {
    position: absolute;
    right: 5%;
    bottom: 0;
    z-index: -1;
    max-width: 90%; }
    @media (max-width: 1199px) {
      .schedule-area .schedule-right-image {
        display: none; } }

.schedules-wrap {
  position: relative; }
  .schedules-wrap::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    -webkit-filter: blur(80px);
    filter: blur(80px);
    -webkit-transform: translate(-60%, -40%);
    -ms-transform: translate(-60%, -40%);
    transform: translate(-60%, -40%);
    background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
    background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
  .schedules-wrap::after {
    position: absolute;
    content: "";
    left: -5%;
    bottom: 0;
    z-index: -1;
    width: 105%;
    height: 100%;
    opacity: 0.20;
  }

/** Portfolio Section **/
@media (min-width: 1400px) {
  .portfolio-section-three {
    padding-left: 55px;
    padding-right: 55px; } }

.portfolio-title-filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .portfolio-title-filter .common-heading {
    max-width: 450px; }

.portfolio-filter-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .portfolio-filter-two li {
    z-index: 1;
    color: black;
    cursor: pointer;
    font-size: 16px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-weight: 700;
    padding: 1px 18px;
    border-radius: 5px;
    position: relative; }
    .portfolio-filter-two li:before {
      content: '';
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      position: absolute;
      border-radius: 5px;
      background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
      background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
      background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
    .portfolio-filter-two li:hover, .portfolio-filter-two li.active {
      color: white; }
      .portfolio-filter-two li:hover:before, .portfolio-filter-two li.active:before {
        opacity: 1; }
    .portfolio-filter-two li:not(:last-child) {
      margin: 0 5px 5px 0; }

/* Home Eleven */
.home-11 .sub-title {
  font-size: 18px;
  color: var(--color-secondary);
  font-family: var(--font-cs-medium); }

.home-11 h2 {
  line-height: 1.4; }
  @media (min-width: 1200px) {
    .home-11 h2 {
      font-size: 42px; } }
  @media (max-width: 575px) {
    .home-11 h2 {
      font-size: 30px; } }
  @media (max-width: 424px) {
    .home-11 h2 {
      font-size: 27px; } }

.home-11 .check-list li::after {
  color: var(--color-secondary); }

/* Home Twelve */
.home-twelve .template-btn {
  border: none;
  color: #040d1a;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ffa900; }
  .home-twelve .template-btn:hover {
    color: #040d1a;
    background: #edeeef; }
  .home-twelve .template-btn.style-two {
    background: #edeeef; }
    .home-twelve .template-btn.style-two:hover {
      background: #ffa900; }
  .home-twelve .template-btn.style-three {
    border: 1px solid #282a33; }
    .home-twelve .template-btn.style-three:not(:hover) {
      color: #edeeef;
      background: transparent; }

/* Responsive Gap */
@media (max-width: 991px) {
  .discover-image,
  .promote-content,
  .combination-image,
  .prototyping-image,
  .design-layout-image,
  .customer-review-content {
    margin-bottom: 55px; } }

.pricing-section-h11 {
  overflow: hidden;
  position: relative;
  z-index: 1; }
  .pricing-section-h11:before {
    top: 0;
    content: '';
    z-index: -1;
    width: 650px;
    height: 650px;
    border-radius: 50%;
    position: absolute;
    background: #fd695a;
    left: calc(50% - 325px);
    -webkit-filter: blur(150px) opacity(0.35);
    filter: blur(150px) opacity(0.35); }
    @media (max-width: 767px) {
      .pricing-section-h11:before {
        -webkit-filter: blur(150px) opacity(0.25);
        filter: blur(150px) opacity(0.25); } }

/** Trainer Section **/
.trainer-area {
  z-index: 1;
  position: relative; }
  .trainer-area .trainer-bg {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: -1;
    opacity: 0.15;
    max-width: 100%;
    width: -moz-max-content;
    width: max-content;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%); }

/** Home Ten Blog **/
.blog-area-h10 {
  background: #f9fafe; }

/** Discover Section **/
.discover-content {
  max-width: 450px; }
  .discover-content .check-list-2 li {
    color: #040d1a;
    font-family: var(--font-cs-medium); }
    .discover-content .check-list-2 li:before {
      top: 16px;
      color: #040d1a;
      background: #ffa900; }

/* Promote Section */
.promote-area {
  background-repeat: no-repeat;
  background-color: #040d1a; }

.promote-content h2 {
  color: white; }

.promote-content p {
  color: #abb0bf; }

.promote-content .cc-service-item {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent; }
  .promote-content .cc-service-item h4 a:not(hover) {
    color: white; }

/*======= Blog Standard =======*/
.blog-post-items .single-blog-post:not(:last-child) {
  margin-bottom: 60px; }

.blog-post-items .single-blog-post .post-thumbnail {
  overflow: hidden;
  margin-bottom: 35px; }
  .blog-post-items .single-blog-post .post-thumbnail img {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.blog-post-items .single-blog-post:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.blog-post-items .single-blog-post .post-meta {
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 5px; }
  .blog-post-items .single-blog-post .post-meta li {
    margin-bottom: 15px;
    line-height: 1; }
    .blog-post-items .single-blog-post .post-meta li a {
      margin: 0;
      display: block;
      font-size: 17px;
      color: var(--color-body); }
      .blog-post-items .single-blog-post .post-meta li a:hover {
        color: #299cdb; }
    .blog-post-items .single-blog-post .post-meta li i {
      margin-right: 10px; }
    .blog-post-items .single-blog-post .post-meta li:not(:last-child) {
      margin-right: 25px; }

.blog-post-items .single-blog-post .post-title {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 15px; }

.blog-post-items .single-blog-post .post-read-more {
  display: inline-block;
  padding: 15px 40px;
  font-size: 15px;
  font-family: var(--font-cs-bold);
  font-weight: 700;
  line-height: 1.4;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  vertical-align: middle;
  border-radius: 50px;
  background-color: var(--color-offwhite);
  border-color: var(--color-offwhite);
  color: var(--color-heading);
  margin-top: 35px;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .blog-post-items .single-blog-post .post-read-more i {
    margin-left: 10px; }
  .blog-post-items .single-blog-post .post-read-more:hover {
    color: var(--color-white);
    background-color: #299cdb;
    border-color: #299cdb;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36); }

.blog-post-items .single-blog-post.no-thumbnail {
  background-color: var(--color-offwhite);
  padding: 40px 45px; }
  .blog-post-items .single-blog-post.no-thumbnail .post-read-more:not(:hover) {
    background-color: var(--color-white); }

.blog-post-items .single-blog-post.thumbnail-cover {
  position: relative;
  z-index: 1;
  padding: 40px 45px; }
  .blog-post-items .single-blog-post.thumbnail-cover .post-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    margin: 0; }
    .blog-post-items .single-blog-post.thumbnail-cover .post-thumbnail img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center; }
  .blog-post-items .single-blog-post.thumbnail-cover::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: var(--color-heading);
    opacity: 0.8; }
  .blog-post-items .single-blog-post.thumbnail-cover .post-read-more {
    margin-top: 15px;
    color: var(--color-white);
    background-color: #299cdb;
    border-color: #299cdb;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.36); }
  .blog-post-items .single-blog-post.thumbnail-cover .post-meta li a,
  .blog-post-items .single-blog-post.thumbnail-cover .post-title a {
    color: var(--color-white); }

/*======= Blog Details =======*/
.blog-details-content .post-thumbnail {
  overflow: hidden;
  margin-bottom: 35px; }
  .blog-details-content .post-thumbnail img {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.blog-details-content .post-title {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 15px; }

.blog-details-content .post-meta {
  line-height: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 5px; }
  .blog-details-content .post-meta li {
    margin-bottom: 15px;
    line-height: 1; }
    .blog-details-content .post-meta li a {
      margin: 0;
      display: block;
      font-size: 17px;
      color: var(--color-body); }
      .blog-details-content .post-meta li a:hover {
        color: #299cdb; }
    .blog-details-content .post-meta li i {
      margin-right: 10px; }
    .blog-details-content .post-meta li:not(:last-child) {
      margin-right: 25px; }

.blog-details-content .post-title {
  font-size: 30px;
  line-height: 1.3;
  margin-bottom: 15px; }

.blog-details-content blockquote {
  background-color: var(--color-offwhite);
  padding: 45px 50px;
  margin: 40px 0; }
  .blog-details-content blockquote p {
    font-size: 24px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    color: var(--color-heading);
    line-height: 1.5;
    margin-bottom: 10px; }
  .blog-details-content blockquote cite {
    font-size: 18px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    font-style: normal;
    color: var(--color-heading);
    line-height: 1;
    position: relative;
    padding-left: 60px; }
    .blog-details-content blockquote cite::before {
      position: absolute;
      content: "";
      height: 3px;
      width: 40px;
      background: #299cdb;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

.blog-details-content .post-subtitle {
  font-size: 30px;
  margin-bottom: 15px; }

.blog-details-content .related-tags,
.blog-details-content .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .blog-details-content .related-tags .item-heading,
  .blog-details-content .social-links .item-heading {
    color: var(--color-heading);
    min-width: 120px;
    font-size: 18px;
    font-family: var(--font-cs-bold);
    font-weight: 700; }

.blog-details-content .related-tags {
  margin-bottom: 10px; }
  .blog-details-content .related-tags li {
    margin-right: 10px;
    margin-bottom: 10px; }
  .blog-details-content .related-tags a {
    color: var(--color-heading);
    display: block;
    background-color: var(--color-offwhite);
    padding: 0px 11px;
    border-radius: 5px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-size: 15px;
    font-family: var(--font-cs-medium);
    font-weight: 500; }
    .blog-details-content .related-tags a:hover {
      background-color: #299cdb;
      color: var(--color-white); }

.blog-details-content .social-links li {
  margin-right: 15px; }

.blog-details-content .social-links a {
  color: var(--color-body);
  line-height: 1; }
  .blog-details-content .social-links a:hover {
    color: #299cdb; }

.blog-details-content .post-author-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--color-offwhite);
  padding: 35px 50px;
  margin-top: 30px; }
  .blog-details-content .post-author-box .author-thumbnail {
    width: 160px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px;
    margin-right: 35px; }
    .blog-details-content .post-author-box .author-thumbnail img {
      border-radius: 50%; }
  .blog-details-content .post-author-box .name {
    font-size: 22px;
    line-height: 1;
    margin-bottom: 10px; }
  .blog-details-content .post-author-box .social-links {
    margin-top: 15px; }

/*======= Blog Sidebar =======*/
.blog-sidebar .widget:not(:last-child) {
  margin-bottom: 55px; }

.blog-sidebar .widget .widget-title {
  border-bottom: 1px solid var(--color-offwhite);
  padding-bottom: 15px;
  font-size: 24px;
  line-height: 1.1;
  position: relative;
  margin-bottom: 30px; }
  .blog-sidebar .widget .widget-title::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 60px;
    background-color: #299cdb;
    left: 0;
    bottom: -2px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
  .blog-sidebar .widget .widget-title:hover::after {
    -webkit-transform: translate(15px, 0px);
    -ms-transform: translate(15px, 0px);
    transform: translate(15px, 0px); }

.blog-sidebar .widget.category-widget {
  background-color: #f3f6f9;
  padding: 30px 35px; }
  .blog-sidebar .widget.category-widget li:not(:last-child) {
    margin-bottom: 10px; }
  .blog-sidebar .widget.category-widget li a {
    color: var(--color-heading);
    font-size: 17px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-transform: capitalize;
    display: block;
    position: relative; }
    .blog-sidebar .widget.category-widget li a::after {
      display: inline-block;
      position: absolute;
      right: 0;
      content: "\f105";
      font-family: "Font Awesome 5 Pro";
      font-weight: 400; }
    .blog-sidebar .widget.category-widget li a:hover {
      color: #299cdb; }

.blog-sidebar .widget.latest-post-widget .popular-posts-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .blog-sidebar .widget.latest-post-widget .popular-posts-item:not(:last-child) {
    margin-bottom: 15px; }
  .blog-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-thumbnail {
    width: 75px;
    height: 75px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    margin-right: 20px;
    border-radius: 7px;
    overflow: hidden; }
    .blog-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-thumbnail img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 7px; }
  .blog-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-title {
    font-size: 18px;
    line-height: 1.4; }
    .blog-sidebar .widget.latest-post-widget .popular-posts-item .popular-posts-title a:hover {
      color: #299cdb; }
  .blog-sidebar .widget.latest-post-widget .popular-posts-item .posts-date {
    color: var(--color-body);
    font-size: 16px; }
    .blog-sidebar .widget.latest-post-widget .popular-posts-item .posts-date i {
      margin-right: 7px; }

.blog-sidebar .widget.widget-tag-cloud .tags a {
  display: inline-block;
  padding: 3px 20px;
  background-color: var(--color-offwhite);
  border-radius: 50px;
  font-size: 17px;
  color: var(--color-body);
  margin: 0 5px 10px 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  .blog-sidebar .widget.widget-tag-cloud .tags a:hover {
    background-color: var(--color-primary-5);
    color: var(--color-white); }

.blog-sidebar .widget.search-widget {
  background-color: var(--color-primary-5);
  padding: 25px 30px; }
  .blog-sidebar .widget.search-widget h4 {
    color: var(--color-white);
    margin-bottom: 15px;
    text-transform: capitalize; }
  .blog-sidebar .widget.search-widget form {
    position: relative; }
    .blog-sidebar .widget.search-widget form input {
      height: 60px;
      width: 100%;
      border-radius: 30px;
      background-color: #f3f6f9;
      padding-left: 25px;
      padding-right: 55px; }
    .blog-sidebar .widget.search-widget form button {
      height: 45px;
      width: 45px;
      border-radius: 50px;
      position: absolute;
      top: 8px;
      right: 8px;
      background: #299cdb;
      color: var(--color-white);
      border: none; }

.blog-sidebar .widget.testimonial-widget {
  padding: 35px;
  background-color: var(--color-heading); }
  .blog-sidebar .widget.testimonial-widget .single-testimonial {
    color: var(--color-white);
    font-size: 18px; }
    .blog-sidebar .widget.testimonial-widget .single-testimonial .author {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 30px; }
      .blog-sidebar .widget.testimonial-widget .single-testimonial .author img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 10px; }
    .blog-sidebar .widget.testimonial-widget .single-testimonial .name {
      color: var(--color-white);
      font-size: 18px; }
    .blog-sidebar .widget.testimonial-widget .single-testimonial .position {
      display: block;
      color: #a5abbc;
      font-size: 15px;
      text-transform: capitalize;
      margin-top: 10px; }
  .blog-sidebar .widget.testimonial-widget .slick-dots {
    margin-top: 40px;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
    .blog-sidebar .widget.testimonial-widget .slick-dots li {
      margin: 0 10px 0 0; }
      .blog-sidebar .widget.testimonial-widget .slick-dots li::after {
        display: none; }
      .blog-sidebar .widget.testimonial-widget .slick-dots li:not(.slick-active) {
        background-color: #393e4c; }

/*======= Post Pagination =======*/
.post-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 70px; }
  .post-pagination li {
    margin-right: 10px;
    margin-top: 10px; }
  .post-pagination a {
    border: 2px solid var(--color-offwhite);
    border-radius: 5px;
    color: var(--color-heading);
    height: 50px;
    width: 50px;
    font-weight: 700;
    font-size: 18px;
    font-family: var(--font-cs-bold);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .post-pagination a.active {
      background: #299cdb;
      color: var(--color-white);
      border: 2px solid #299cdb;
      -webkit-box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.5);
      box-shadow: 0px 10px 30px 0px rgba(71, 59, 240, 0.5); }

/*======= Comment Template =======*/
.comments-template {
  margin-top: 70px; }
  .comments-template .template-title {
    font-size: 24px;
    margin-bottom: 40px; }
  .comments-template .comments-list .comment:not(:last-child) {
    margin-bottom: 40px; }
  .comments-template .comments-list .comment .children {
    margin-top: 40px;
    padding-left: 60px; }
  .comments-template .comments-list .comment .comment-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
  .comments-template .comments-list .comment .avatar {
    width: 100px;
    height: 100px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    margin-right: 30px; }
  .comments-template .comments-list .comment .author-name {
    color: var(--color-heading);
    font-size: 20px;
    font-family: var(--font-cs-bold);
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 14px; }
    .comments-template .comments-list .comment .author-name .date {
      font-size: 17px;
      font-family: var(--font-cs-medium);
      font-weight: 500; }
  .comments-template .comments-list .comment .reply {
    font-size: 17px;
    font-family: var(--font-cs-medium);
    font-weight: 500;
    color: #415674;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-top: 5px;
    text-transform: uppercase; }
    .comments-template .comments-list .comment .reply::after {
      content: "\f178";
      display: inline-block;
      font-family: "Font Awesome 5 Pro";
      padding-left: 10px; }
  .comments-template .comments-respond {
    margin-top: 70px;
    padding-top: 70px;
    border-top: 1px solid var(--color-offwhite); }
    .comments-template .comments-respond .input-field {
      position: relative; }
      .comments-template .comments-respond .input-field .icon {
        color: #299cdb;
        font-size: 17px;
        position: absolute;
        right: 20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
      .comments-template .comments-respond .input-field.textarea-field .icon {
        top: 20px;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0); }
    .comments-template .comments-respond input,
    .comments-template .comments-respond textarea {
      height: 65px;
      background-color: transparent;
      border: 2px solid var(--color-offwhite);
      border-radius: 5px;
      padding-left: 20px;
      padding-right: 50px; }
    .comments-template .comments-respond textarea {
      height: 140px; }

/*======= Products =======*/
.shop-shorter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .shop-shorter .sort-text {
    font-size: 18px;
    margin-right: auto;
    font-family: var(--font-cs-medium); }
  .shop-shorter .products-dropdown {

    }
    .shop-shorter .products-dropdown .nice-select {
      height: 50px;
      padding-right: 60px;
      color: var(--color-heading);
      background: transparent;
      border: 1px solid #e8e8e8; }
      .shop-shorter .products-dropdown .nice-select .list {
        width: auto; }
  .shop-shorter .grid-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .shop-shorter .grid-list li a {
      color: var(--color-heading); }
    .shop-shorter .grid-list li:not(last-child) {
      margin-right: 20px; }

.product-item {
  position: relative;
  text-align: center;
  padding: 35px 30px;
  background: #fafafa;
  box-shadow: 0 2px 3px #C8D0D8;
  margin-bottom: 30px; }
  .product-item .badge {
    top: 15px;
    right: 15px;
    color: white;
    padding: 6px 15px;
    position: absolute;
    text-transform: uppercase;
    background: var(--color-secondary); }
  .product-item .image {
    width: 200px;
    height: 200px;
    background: white;
    position: relative;
    border-radius: 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .product-item .image img {
      max-width: 165px; }
    .product-item .image .cart-btn {
      top: 50%;
      left: 50%;
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      background: white;
      padding: 5px 18px;
      border-radius: 3px;
      width: -moz-max-content;
      width: max-content;
      position: absolute;
      color: var(--color-heading);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }
    .product-item .image:before {
      position: absolute;
      content: '';
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      -webkit-transition: 0.5s;
      -o-transition: 0.5s;
      transition: 0.5s;
      border-radius: 50%;
      background: var(--color-secondary); }
  .product-item h6 {
    font-size: 18px;
    margin-top: 25px; }
  .product-item .ratting i {
    font-size: 12px;
    margin-right: 2px;
    color: var(--color-secondary); }
  .product-item .price {
    font-size: 16px; }
    .product-item .price del:before,
    .product-item .price span:before {
      content: '$'; }
    .product-item .price span {
      font-weight: 600;
      color: var(--color-secondary); }
      .product-item .price span:not(first-child) {
        margin-left: 10px; }
  .product-item:hover .image .cart-btn {
    opacity: 1; }
  .product-item:hover .image:before {
    opacity: 0.85; }

/*======= Shop Details =======*/
.product-details-images .preview-images {
  padding: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fafafa;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  @media (max-width: 424px) {
    .product-details-images .preview-images {
      padding: 35px; } }

.product-details-images .thumb-images .thumb-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fafafa;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: calc(33.333% - 20px); }
  @media (max-width: 424px) {
    .product-details-images .thumb-images .thumb-item {
      padding: 15px;
      width: calc(33.333% - 10px); } }

@media (max-width: 991px) {
  .product-details-images {
    margin-bottom: 55px; } }

.product-details-content .ratting-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .product-details-content .ratting-price .ratting {
    padding-right: 10px; }
    .product-details-content .ratting-price .ratting i {
      font-size: 14px;
      margin-right: 3px;
      color: var(--color-secondary); }
  .product-details-content .ratting-price .price {
    font-size: 24px;
    color: var(--color-heading);
    font-family: var(--font-cs-bold); }
    .product-details-content .ratting-price .price:before {
      content: '$'; }

.product-details-content .add-to-cart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .product-details-content .add-to-cart input,
  .product-details-content .add-to-cart .love {
    width: auto;
    height: auto;
    padding: 8px 25px;
    border-radius: 5px;
    margin-bottom: 10px;
    background: transparent;
    border: 1px solid #e8e8e8; }
  .product-details-content .add-to-cart .template-btn {
    padding-top: 12px;
    border-radius: 5px;
    margin: 0 10px 10px;
    padding-bottom: 12px; }

.product-details-content .category-tags li {
  margin-bottom: 5px; }
  .product-details-content .category-tags li b {
    width: 80px;
    display: inline-block; }
  .product-details-content .category-tags li span {
    margin-right: 10px; }
  .product-details-content .category-tags li a {
    color: var(--color-body); }
    .product-details-content .category-tags li a:hover {
      color: var(--color-secondary); }
    .product-details-content .category-tags li a:not(:last-child):after {
      content: ','; }

.product-information-tab li {
  margin-bottom: 10px; }
  .product-information-tab li:not(:last-child) {
    margin-right: 15px; }
  .product-information-tab li a {
    font-size: 20px;
    padding: 8px 55px;
    border-radius: 5px;
    color: var(--color-heading);
    border: 1px solid #e8e8e8;
    font-family: var(--font-cs-medium); }
    .product-information-tab li a.active {
      color: white;
      background: var(--color-secondary);
      border-color: var(--color-secondary); }

.review-form .your-ratting {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .review-form .your-ratting h6 {
    font-size: 18px;
    margin-right: 10px; }
  .review-form .your-ratting .ratting i {
    font-size: 14px;
    margin-left: 4px;
    color: var(--color-secondary); }

.review-form .form-group {
  margin-bottom: 30px; }

.review-form input,
.review-form textarea,
.review-form .nice-select {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 5px;
  background: #fafafa;
  border: 1px solid #fafafa; }
  .review-form input .current, .review-form input::-webkit-input-placeholder,
  .review-form textarea .current,
  .review-form textarea::-webkit-input-placeholder,
  .review-form .nice-select .current,
  .review-form .nice-select::-webkit-input-placeholder {
    font-size: 20px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium); }
  .review-form input .current, .review-form input:-ms-input-placeholder,
  .review-form textarea .current,
  .review-form textarea:-ms-input-placeholder,
  .review-form .nice-select .current,
  .review-form .nice-select:-ms-input-placeholder {
    font-size: 20px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium); }
  .review-form input .current, .review-form input::-ms-input-placeholder,
  .review-form textarea .current,
  .review-form textarea::-ms-input-placeholder,
  .review-form .nice-select .current,
  .review-form .nice-select::-ms-input-placeholder {
    font-size: 20px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium); }
  .review-form input .current, .review-form input::placeholder,
  .review-form textarea .current,
  .review-form textarea::placeholder,
  .review-form .nice-select .current,
  .review-form .nice-select::placeholder {
    font-size: 20px;
    color: var(--color-heading);
    font-family: var(--font-cs-medium); }
  .review-form input:focus,
  .review-form textarea:focus,
  .review-form .nice-select:focus {
    border-color: var(--color-secondary); }

.review-form textarea {
  height: 150px; }

.popular-product .tagline {
  color: var(--color-secondary); }

/*======= Shop Sidebar =======*/
@media (max-width: 991px) {
  .shop-sidebar {
    margin-bottom: 55px; } }

.shop-sidebar .widget {
  padding: 30px 25px;
  border: 1px solid #e8e8e8; }
  .shop-sidebar .widget:not(:last-child) {
    margin-bottom: 35px; }
  .shop-sidebar .widget:hover .widget-title::after {
    -webkit-transform: translate(15px, 0px);
    -ms-transform: translate(15px, 0px);
    transform: translate(15px, 0px); }

.shop-sidebar .widget-title {
  border-bottom: 1px solid var(--color-offwhite);
  padding-bottom: 15px;
  font-size: 24px;
  line-height: 1.1;
  position: relative;
  margin-bottom: 30px; }
  .shop-sidebar .widget-title::after {
    position: absolute;
    content: "";
    height: 2px;
    width: 60px;
    background-color: #299cdb;
    left: 0;
    bottom: -2px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }

.shop-sidebar .search-widget {
  padding: 0;
  border: none; }
  .shop-sidebar .search-widget form {
    padding: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: var(--color-soft-grey); }
    .shop-sidebar .search-widget form input {
      height: 44px;
      padding-right: 0;
      padding-left: 20px; }
    .shop-sidebar .search-widget form button {
      -webkit-box-flex: 0;
      -ms-flex: none;
      flex: none;
      width: 44px;
      height: 44px;
      color: white;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      border-radius: 5px;
      background: var(--color-secondary); }

.shop-sidebar .category-widget li {
  position: relative; }
  .shop-sidebar .category-widget li:not(:last-child) {
    margin-bottom: 10px; }
  .shop-sidebar .category-widget li a {
    display: block;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    position: relative;
    text-transform: capitalize;
    color: var(--color-heading); }
    .shop-sidebar .category-widget li a:hover {
      color: #299cdb; }
  .shop-sidebar .category-widget li:after {
    top: 0;
    right: 0;
    content: "\f105";
    font-weight: 400;
    position: absolute;
    display: inline-block;
    font-family: "Font Awesome 5 Pro"; }

.shop-sidebar .products-widget li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .shop-sidebar .products-widget li .image {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    max-width: 65px;
    margin-right: 15px; }
  .shop-sidebar .products-widget li .ratting i {
    font-size: 12px;
    margin-right: 2px;
    color: var(--color-secondary); }
  .shop-sidebar .products-widget li h6 {
    font-size: 18px; }
  .shop-sidebar .products-widget li .price {
    font-size: 16px; }
    .shop-sidebar .products-widget li .price:before {
      content: '$'; }
  .shop-sidebar .products-widget li:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e8e8e8; }

.shop-sidebar .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-bottom: -7px; }
  .shop-sidebar .tags a {
    font-size: 15px;
    font-weight: 500;
    padding: 3px 13px;
    border-radius: 3px;
    margin: 0 7px 7px 0;
    color: var(--color-heading);
    background: var(--color-soft-grey); }
    .shop-sidebar .tags a:hover {
      color: white;
      background: var(--color-secondary); }

.shop-sidebar .widget-price-filter .ui-widget-header {
  background: var(--color-secondary); }

.shop-sidebar .widget-price-filter .ui-widget.ui-widget-content {
  height: 3px;
  border: none;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
  background: #e7e8eb; }

.shop-sidebar .widget-price-filter .ui-state-default {
  border: none;
  top: -6px;
  margin-left: 0;
  cursor: pointer;
  width: 3px;
  height: 15px;
  outline: none;
  background: var(--color-secondary); }

.shop-sidebar .widget-price-filter .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .shop-sidebar .widget-price-filter .price span {
    margin-right: 5px; }
    .shop-sidebar .widget-price-filter .price span:before {
      content: '$'; }
  .shop-sidebar .widget-price-filter .price input {
    padding: 0;
    height: auto;
    font-size: 18px;
    font-weight: 500;
    margin-left: 5px;
    background: transparent; }

/*======= Post Pagination =======*/
.shop-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .shop-pagination li {
    margin-right: 10px;
    margin-top: 10px; }
  .shop-pagination .page-link {
    border: 1px solid var(--color-offwhite);
    color: var(--color-heading);
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .shop-pagination .page-link:hover {
      color: white;
      background: var(--color-primary-5);
      border-color: var(--color-primary-5); }
  .shop-pagination .active .page-link {
    background: var(--color-primary-5);
    border-color: var(--color-primary-5); }

/*======= Footer =======*/
.footer-widgets .widget {
  position: relative;
  margin-bottom: 50px;
  color: var(--color-body); }
  .footer-widgets .widget a:not(.template-btn) {
    color: var(--color-body); }
    .footer-widgets .widget a:not(.template-btn):hover {
      color: #299cdb; }
  .footer-widgets .widget .copyright-text a {
    color: #299cdb; }
  .footer-widgets .widget .social-links li {
    display: inline-block; }
    .footer-widgets .widget .social-links li a {
      height: 35px;
      width: 35px;
      background: #ecebfd;
      border-radius: 50%;
      font-size: 14px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      margin-right: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      color: var(--color-heading); }
      .footer-widgets .widget .social-links li a:hover {
        background-color: #299cdb;
        color: var(--color-white); }
  .footer-widgets .widget .social-links.primary-color-3 a:hover {
    background-color: var(--color-primary-3);
    color: var(--color-white);
    -webkit-box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36);
    box-shadow: 0px 10px 30px 0px rgba(226, 92, 106, 0.36); }
  .footer-widgets .widget .social-links.bordered-style li a {
    background-color: transparent;
    border: 2px solid #299cdb;
    color: #299cdb; }
    .footer-widgets .widget .social-links.bordered-style li a:hover {
      background-color: #299cdb;
      color: var(--color-white); }
  .footer-widgets .widget .download-links {
    margin-top: 20px; }
    .footer-widgets .widget .download-links li {
      display: inline-block;
      margin-right: 15px;
      line-height: 1; }
      .footer-widgets .widget .download-links li a {
        font-size: 24px; }
        .footer-widgets .widget .download-links li a:hover {
          color: var(--color-primary-5); }
  .footer-widgets .widget .widget-title {
    font-size: 22px;
    margin-bottom: 35px; }
  .footer-widgets .widget.text-block-widget img {
    margin-bottom: 30px; }
  .footer-widgets .widget.text-block-widget .contact-link {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-heading);
    font-family: var(--font-cs-bold);
    margin-top: 15px; }
    .footer-widgets .widget.text-block-widget .contact-link i {
      color: var(--color-primary-5); }
  .footer-widgets .widget.nav-widget ul li a {
    padding-left: 20px;
    position: relative; }
    .footer-widgets .widget.nav-widget ul li a::before {
      content: "\f054";
      position: absolute;
      left: 0;
      font-family: "Font Awesome 5 Pro";
      font-weight: 800;
      font-size: 14px;
      top: 2px; }
  .footer-widgets .widget.nav-widget ul li:not(:last-child) {
    margin-bottom: 10px; }
  .footer-widgets .widget.contact-widget li a {
    position: relative;
    padding-left: 35px; }
    .footer-widgets .widget.contact-widget li a i {
      position: absolute;
      left: 0;
      top: 7px;
      color: #299cdb; }
    .footer-widgets .widget.contact-widget li a:hover {
      color: #299cdb; }
  .footer-widgets .widget.contact-widget li:not(:last-child) {
    margin-bottom: 10px; }
  .footer-widgets .widget.instagram-feed .instagram-images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: -5px; }
    .footer-widgets .widget.instagram-feed .instagram-images .single-img {
      padding: 5px;
      border-radius: 7px;
      position: relative; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img a {
        display: block; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img img {
        width: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
        border-radius: 7px; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img::after {
        content: "";
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        z-index: 1;
        background-color: var(--color-primary-4);
        border-radius: 7px;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img a {
        position: absolute;
        top: 5px;
        right: 5px;
        bottom: 5px;
        left: 5px;
        z-index: 2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        color: var(--color-white);
        font-size: 24px;
        -webkit-transform: scale(0.5);
        -ms-transform: scale(0.5);
        transform: scale(0.5);
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img:hover::after {
        opacity: 0.75;
        visibility: visible; }
      .footer-widgets .widget.instagram-feed .instagram-images .single-img:hover a {
        opacity: 1;
        visibility: visible;
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1); }
  .footer-widgets .widget .newsletter-form form {
    position: relative; }
    .footer-widgets .widget .newsletter-form form input {
      height: 70px;
      border: 2px solid var(--color-dark-4);
      background-color: transparent;
      border-radius: 7px;
      padding-right: 80px;
      padding-left: 30px; }
    .footer-widgets .widget .newsletter-form form button {
      position: absolute;
      z-index: 2;
      right: 30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 45px;
      width: 45px;
      border-radius: 50%;
      border: none;
      background-color: var(--color-primary-6);
      color: var(--color-white);
      font-size: 15px; }

.footer-copyright {
  padding: 30px 0; }
  .footer-copyright .social-links li {
    display: inline-block; }
    .footer-copyright .social-links li a {
      font-size: 15px;
      -webkit-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
      margin-left: 15px;
      color: var(--color-body-4); }
      .footer-copyright .social-links li a:hover {
        color: var(--color-primary-6); }

.back-to-top {
  position: fixed;
  right: 0;
  bottom: 30px;
  z-index: 999;
  width: 40px;
  height: 40px;
  background-color: var(--color-heading);
  color: var(--color-white);
  font-size: 20px;
  text-align: center;
  line-height: 40px;
  border-radius: 3px;
  border: 1px solid var(--color-white);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden; }
  .back-to-top:hover {
    color: var(--color-white); }
  .back-to-top.show {
    right: 30px;
    visibility: visible;
    opacity: 1; }

.template-footer.footer-with-image {
  position: relative;
  z-index: 1;
  border-bottom: 5px solid #299cdb;
  background-image: url(../img/footer-bg.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain; }

.template-footer.footer-white-color .footer-widgets .widget,
.template-footer.footer-white-color .footer-widgets .widget a {
  color: var(--color-white); }

.template-footer.footer-white-color .footer-widgets .widget a:hover {
  color: var(--color-white); }

.template-footer.footer-white-color .footer-widgets .widget .widget-title {
  color: var(--color-white); }

.template-footer.footer-white-color .footer-widgets .widget.contact-widget li a i {
  color: var(--color-white); }

.template-footer.footer-white-color .footer-widgets .widget .social-links li a {
  background-color: var(--color-white);
  color: var(--color-heading); }
  .template-footer.footer-white-color .footer-widgets .widget .social-links li a:hover {
    background-color: var(--color-heading);
    color: var(--color-white); }

.template-footer.footer-white-color .footer-widgets .widget .social-links.bordered-style li a {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
  color: var(--color-white); }
  .template-footer.footer-white-color .footer-widgets .widget .social-links.bordered-style li a:hover {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--color-white); }

.template-footer.footer-white-color .footer-copyright,
.template-footer.footer-white-color .footer-copyright a {
  color: var(--color-white); }

.template-footer.footer-secondary-4-bg {
  background-color: var(--color-secondary-4); }
  .template-footer.footer-secondary-4-bg .footer-widgets .widget,
  .template-footer.footer-secondary-4-bg .footer-widgets .widget a {
    color: #8496c6; }
  .template-footer.footer-secondary-4-bg .footer-widgets .widget a:hover {
    color: var(--color-white); }
  .template-footer.footer-secondary-4-bg .footer-widgets .widget .widget-title {
    color: var(--color-white); }
  .template-footer.footer-secondary-4-bg .footer-widgets .widget.contact-widget li a i {
    color: var(--color-primary-4); }
  .template-footer.footer-secondary-4-bg .footer-widgets .widget .social-links li a {
    background-color: #404767;
    color: #8496c6; }
    .template-footer.footer-secondary-4-bg .footer-widgets .widget .social-links li a:hover {
      background-color: var(--color-primary-4);
      color: var(--color-white); }
  .template-footer.footer-secondary-4-bg .footer-copyright {
    border-top: 1px solid #394565; }
    .template-footer.footer-secondary-4-bg .footer-copyright,
    .template-footer.footer-secondary-4-bg .footer-copyright a {
      color: #8496c6; }

.template-footer.footer-bordered .footer-widgets {
  padding-top: 80px;
  padding-bottom: 30px; }
  .template-footer.footer-bordered .footer-widgets .widget,
  .template-footer.footer-bordered .footer-widgets .widget a {
    color: var(--color-body-4); }
  .template-footer.footer-bordered .footer-widgets .widget a:hover {
    color: var(--color-white); }
  .template-footer.footer-bordered .footer-widgets .widget .widget-title {
    color: var(--color-white); }
  .template-footer.footer-bordered .footer-widgets .widget.contact-widget li a i {
    color: var(--color-body-4); }

.template-footer.footer-bordered .footer-copyright {
  border-top: 1px solid var(--color-dark-4);
  padding: 20px 0; }
  .template-footer.footer-bordered .footer-copyright,
  .template-footer.footer-bordered .footer-copyright a {
    color: var(--color-body-4); }

.template-footer.footer-bordered .footer-widget-left {
  padding-right: 80px;
  position: relative; }
  .template-footer.footer-bordered .footer-widget-left::after {
    content: "";
    position: absolute;
    width: 1px;
    top: -80px;
    bottom: -80px;
    right: 20px;
    background-color: var(--color-dark-4); }

.template-footer.webinar-footer .newsletters-title h4 {
  font-size: 45px; }

.template-footer.webinar-footer .newsletters-form form {
  padding-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .template-footer.webinar-footer .newsletters-form form input {
    height: 55px;
    padding: 0 30px;
    font-size: 17px;
    font-weight: 500; }
  .template-footer.webinar-footer .newsletters-form form button {
    height: 55px;
    padding: 0 35px;
    margin-left: 10px;
    background-color: var(--color-primary-7);
    color: var(--color-white);
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    min-width: 200px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    .template-footer.webinar-footer .newsletters-form form button:hover {
      background-color: var(--color-heading-5); }

.template-footer.webinar-footer .copyright-area {
  padding: 30px 0;
  border-top: 1px solid #e0e0e0; }
  .template-footer.webinar-footer .copyright-area .footer-nav li {
    display: inline-block; }
    .template-footer.webinar-footer .copyright-area .footer-nav li:not(:first-child) {
      margin-left: 25px; }
    .template-footer.webinar-footer .copyright-area .footer-nav li a {
      color: var(--color-body);
      font-size: 17px; }
  .template-footer.webinar-footer .copyright-area .copyright-text {
    font-size: 17px; }
    .template-footer.webinar-footer .copyright-area .copyright-text a {
      color: var(--color-primary-7); }
      .template-footer.webinar-footer .copyright-area .copyright-text a i {
        margin-left: 5px; }

.template-footer.footer-h10 {
  z-index: 1;
  color: #a7a6a6;
  position: relative;
  background-color: black; }
  .template-footer.footer-h10 .widget {
    color: #a7a6a6; }
    .template-footer.footer-h10 .widget .widget-title {
      color: white; }
    .template-footer.footer-h10 .widget ul li a {
      color: #a7a6a6; }
      .template-footer.footer-h10 .widget ul li a:hover {
        color: var(--color-primary-3); }
    .template-footer.footer-h10 .widget .social-links a:hover {
      color: white;
      background-image: -webkit-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
      background-image: -o-linear-gradient(45deg, #6b34fa 0%, #d4156e 100%);
      background-image: linear-gradient(45deg, #6b34fa 0%, #d4156e 100%); }
  .template-footer.footer-h10 .news-widget {
    max-width: 285px; }
    @media (min-width: 768px) {
      .template-footer.footer-h10 .news-widget {
        margin-right: -10px; } }
    .template-footer.footer-h10 .news-widget li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .template-footer.footer-h10 .news-widget li img {
        -webkit-box-flex: 0;
        -ms-flex: none;
        flex: none;
        max-width: 80px;
        margin-right: 20px; }
      .template-footer.footer-h10 .news-widget li .content h5 {
        font-size: 18px;
        line-height: 1.5; }
        .template-footer.footer-h10 .news-widget li .content h5 a {
          color: white; }
      .template-footer.footer-h10 .news-widget li:not(:last-child) {
        margin-bottom: 20px; }
  @media (min-width: 768px) {
    .template-footer.footer-h10 .newsletter-widget {
      margin-left: 20px; } }
  .template-footer.footer-h10 .newsletter-widget input {
    height: 55px;
    margin-bottom: 15px; }
    .template-footer.footer-h10 .newsletter-widget input::-webkit-input-placeholder {
      color: black;
      font-weight: 700; }
    .template-footer.footer-h10 .newsletter-widget input:-ms-input-placeholder {
      color: black;
      font-weight: 700; }
    .template-footer.footer-h10 .newsletter-widget input::-ms-input-placeholder {
      color: black;
      font-weight: 700; }
    .template-footer.footer-h10 .newsletter-widget input::placeholder {
      color: black;
      font-weight: 700; }
  .template-footer.footer-h10 .newsletter-widget button {
    padding-top: 17px;
    padding-bottom: 17px; }
  .template-footer.footer-h10 .footer-copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.15); }
    .template-footer.footer-h10 .footer-copyright a {
      color: #a7a6a6; }
  .template-footer.footer-h10 .footer-bg {
    top: 50%;
    left: 50%;
    z-index: -1;
    opacity: 0.15;
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    -webkit-transform: translate(-66%, -50%);
    -ms-transform: translate(-66%, -50%);
    transform: translate(-66%, -50%); }
  .template-footer.footer-h10.style-two {
    background: #040d1a; }
    .template-footer.footer-h10.style-two .newsletter-widget input {
      border-radius: 50px; }
    .template-footer.footer-h10.style-two .widget {
      color: #abb0bf; }
      .template-footer.footer-h10.style-two .widget ul li > a {
        color: #abb0bf; }
      .template-footer.footer-h10.style-two .widget .social-links a:hover {
        color: #040d1a;
        background: #ffa900; }
    .template-footer.footer-h10.style-two .footer-copyright,
    .template-footer.footer-h10.style-two .footer-copyright a {
      color: #abb0bf; }
    .template-footer.footer-h10.style-two .footer-bg-shape {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: -1; }

.template-footer.footer-h11 {
  background: #0d0f2c; }
  .template-footer.footer-h11 .footer-top-part {
    z-index: 1;
    position: relative;
    background: #12153a;
    padding: 65px 25px 80px 25px; }
    .template-footer.footer-h11 .footer-top-part .footer-top-content {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      text-align: center; }
      .template-footer.footer-h11 .footer-top-part .footer-top-content h2, .template-footer.footer-h11 .footer-top-part .footer-top-content h6 {
        color: white; }
      .template-footer.footer-h11 .footer-top-part .footer-top-content h6 {
        font-size: 18px;
        margin-top: 5px; }
      .template-footer.footer-h11 .footer-top-part .footer-top-content form {
        padding: 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        background: white;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 35px; }
        @media (max-width: 575px) {
          .template-footer.footer-h11 .footer-top-part .footer-top-content form {
            display: block; } }
        .template-footer.footer-h11 .footer-top-part .footer-top-content form input {
          height: 50px;
          margin-right: 0;
          font-size: 17px;
          background: transparent; }
          .template-footer.footer-h11 .footer-top-part .footer-top-content form input::-webkit-input-placeholder {
            color: var(--color-heading);
            font-family: var(--font-cs-medium); }
          .template-footer.footer-h11 .footer-top-part .footer-top-content form input:-ms-input-placeholder {
            color: var(--color-heading);
            font-family: var(--font-cs-medium); }
          .template-footer.footer-h11 .footer-top-part .footer-top-content form input::-ms-input-placeholder {
            color: var(--color-heading);
            font-family: var(--font-cs-medium); }
          .template-footer.footer-h11 .footer-top-part .footer-top-content form input::placeholder {
            color: var(--color-heading);
            font-family: var(--font-cs-medium); }
        .template-footer.footer-h11 .footer-top-part .footer-top-content form button {
          -webkit-box-flex: 0;
          -ms-flex: none;
          flex: none;
          padding-top: 13px;
          padding-bottom: 13px; }
          @media (max-width: 575px) {
            .template-footer.footer-h11 .footer-top-part .footer-top-content form button {
              width: 100%; } }
    .template-footer.footer-h11 .footer-top-part .left-image,
    .template-footer.footer-h11 .footer-top-part .right-image {
      bottom: 0;
      z-index: -1;
      position: absolute; }
      @media (max-width: 767px) {
        .template-footer.footer-h11 .footer-top-part .left-image,
        .template-footer.footer-h11 .footer-top-part .right-image {
          display: none; } }
    .template-footer.footer-h11 .footer-top-part .left-image {
      left: 4%;
      max-width: 15%; }
    .template-footer.footer-h11 .footer-top-part .right-image {
      right: 0;
      max-width: 30%; }
  .template-footer.footer-h11 .widget-title {
    color: white; }
  .template-footer.footer-h11 .nav-widget ul li a {
    color: #b6b8da; }
    .template-footer.footer-h11 .nav-widget ul li a:hover {
      color: #fd695a; }
  .template-footer.footer-h11 .social-links li a {
    color: #6e6b8a;
    background: rgba(255, 255, 255, 0.1); }
    .template-footer.footer-h11 .social-links li a:hover {
      color: white;
      background: #fd695a; }
  .template-footer.footer-h11 .footer-copyright {
    border-top: 1px solid #1d204b; }
    .template-footer.footer-h11 .footer-copyright p {
      color: #b6b8da; }
      .template-footer.footer-h11 .footer-copyright p a {
        color: #fd695a; }

/*======= Page Title  =======*/
.page-title-area {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: var(--color-soft-grey-2);
  position: relative;
  z-index: 1;
  overflow: hidden; }
  .page-title-area .page-title {
    font-size: 65px;
    margin-bottom: 20px; }
  .page-title-area .breadcrumb-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .page-title-area .breadcrumb-nav li {
      position: relative;
      line-height: 1;
      font-size: 24px;
      font-family: var(--font-cs-medium);
      font-weight: 500;
      color: var(--color-heading); }
      .page-title-area .breadcrumb-nav li:not(:last-child)::after {
        display: inline-block;
        content: "\f105";
        padding-left: 12px;
        padding-right: 12px;
        font-family: "Font Awesome 5 Pro";
        font-size: inherit;
        color: var(--color-heading);
        position: relative;
        top: 2px; }
  .page-title-area .page-title-content {
    position: relative;
    z-index: 1; }
  .page-title-area .page-title-effect img {
    position: absolute; }
  .page-title-area .page-title-effect .particle-1 {
    top: 30%;
    left: 15%; }
  .page-title-area .page-title-effect .particle-2 {
    top: 45%;
    left: 19%; }
  .page-title-area .page-title-effect .particle-3 {
    right: 26%;
    top: 20%; }
  .page-title-area .page-title-effect .particle-4 {
    left: 25%;
    bottom: 15%; }
  .page-title-area .page-title-effect .particle-5 {
    right: 15%;
    bottom: 36%; }

/*======= 404  =======*/
.error-page-content h2 {
  margin-bottom: 20px;
  font-size: 85px;
  line-height: 1; }

.error-page-content p {
  font-size: 30px;
  font-family: var(--font-cs-medium);
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-heading); }

/*======= Coming Soon =======*/
.coming-soon-area {
  background-color: var(--color-soft-grey-2);
  padding: 150px 0 100px; }
  .coming-soon-area .title {
    margin-bottom: 25px;
    font-size: 92px;
    line-height: 1;
    letter-spacing: -1px; }
  .coming-soon-area .newsletter-notice {
    color: var(--color-heading);
    font-size: 20px;
    font-family: var(--font-cs-medium);
    padding-top: 30px; }

.coming-soon-page .template-header {
  padding: 20px 0;
  border-bottom: 2px solid rgba(220, 221, 224, 0.5); }
  .coming-soon-page .template-header .off-canvas-btn span {
    background-color: #299cdb; }
