.feedback_btn  {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 30px;
    bottom: 100px;
    outline: none;
    user-select: none;
    cursor: pointer;
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 20px;
    z-index: 999;
    background: #3377ff;
    color: #fff;
    border-radius: 50%;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.3);
    transition: 0.2s opacity ease-in-out;
}

.feedback_wrapper{
    position: fixed;
    right: -500px;
    bottom: 95px;
    z-index: 9999;
    background: #fff;
    max-width: 360px;
    width: 100%;
    border-radius: 10px 10px 0 10px;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.3);
    transition: ease-in-out all .5s;
}

.feedback_show{
    display: block !important;
    right: 50px;
}


.feedback_wrapper .content{
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.feedback_wrapper .outer{
    height: 135px;
    width: 135px;
    overflow: hidden;
}
.feedback_wrapper .outer .emojis{
    height: 500%;
    display: flex;
    flex-direction: column;
}
.feedback_wrapper .outer .emojis li{
    height: 20%;
    width: 100%;
    list-style: none;
    transition: all 0.3s ease;
}
.feedback_wrapper .outer li img{
    height: 100%;
    width: 100%;
}


.feedback_wrapper .stars{
    margin-top: 30px;
}
.feedback_wrapper .stars label{
    font-size: 30px;
    margin: 0 3px;
    color: #ccc;
}

.feedback_wrapper .feeback_footer{
    border-top: 1px solid #ccc;
    background: #f2f2f2;
    width: 100%;
    height: 55px;
    padding: 0 20px;
    border-radius: 0 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.feedback_wrapper .feeback_footer span{
    font-size: 17px;
    font-weight: 400;
}

