@import url('./assets/front/css/bootstrap.min.css');
@import url('./assets/front/css/slick.min.css');
@import url('./assets/front/css/magnific-popup.min.css');
@import url('./assets/front/css/nice-select.min.css');
@import url('./assets/front/css/animate.min.css');
@import url('./assets/front/css/jquery-ui.min.css');
@import url('./assets/front/fonts/fontawesome/css/all.min.css');
@import url('./assets/front/fonts/flaticon/flaticon.css');
@import url('./assets/front/css/spacing.min.css');
@import url('./assets/front/css/style.css');
@import url('./assets/front/css/responsive.css');

.center_register{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 30px;
    padding-right: 30px;
}

.shop-select-container {
    z-index: 9999; /* Ajustez la valeur si nécessaire */
    position: absolute; /* Assurez-vous que le positionnement est correct */
}



.selected_lng{
    border-bottom: 3px solid #0b0b0b !important;
}

.checked_box{
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.checked_header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.checked_header b small i{
    font-size: 10px;
}

.checked_header input{
    width: 10px;
    height: 10px;
    text-align: right;
}

.checked_header b{
  color: #028ad8;
}

.checked_content p{
    font-size: 14px;
}

.checked_box:has(> .checked_header > input:checked),.checked_header:has(>input:checked) b{
    border: 1px solid #028ad8;
    color: #fff !important;
    background: #028ad8;
}

.theme-list{padding:30px 0}
/*.theme-list a:hover .theme-preview{-webkit-filter:grayscale(0);filter:grayscale(0);opacity:1;-webkit-transform:translateY(-5px);transform:translateY(-5px)}*/
/*.theme-list a .theme-preview{display:block;border-radius:14px;-webkit-box-shadow:20px 20px 60px #d9d9d9,-20px -20px 60px #fff;box-shadow:20px 20px 60px #d9d9d9,-20px -20px 60px #fff;-webkit-filter:grayscale(1);filter:grayscale(1);opacity:.6;-webkit-transition:all .3s;transition:all .3s}*/
/*@media only screen and (min-width: 768px)and (max-width: 1024px)and (orientation: portrait){*/
/*    .theme-list{display:-webkit-box;display:-ms-flexbox;display:flex*/
/*    }*/
/*}*/
/*.theme-list .column{width:50%;min-width:50%;-webkit-box-flex:2;-ms-flex-positive:2;flex-grow:2}*/

.cursor-pointer{
    cursor: pointer;
}

.theme-item{
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.theme-item:hover {
    transition: all ease-in-out .5s;
    box-shadow:20px 20px 60px rgba(2, 138, 216, 0.78),-20px -20px 60px #fff;
}



.theme-item p{
    position: absolute;
    bottom: 25px;
    right: 20px;
    background: #4a934a;
    color: #fff;
    padding: 5px 7px;
    border-radius: 4px;
}

.theme-item.premium p{
    background: #028ad8 !important;
}

.pricing_box{
     border: 1px solid #ccc;
     padding: 10px;
    border-radius: 14px;
}

.pricing_box_header{
    text-align: center;
    border-bottom: 1px solid #ccc;
}
.pricing_box_header img{
    width: 45%;
}
.pricing_box_header h3{
    padding: 0 10px 5px 10px;
}
.pricing_box_header p{
    padding: 5px 10px 25px 10px;
    font-weight: 100;
}

.pricing_box_price{
    border-bottom: 1px solid #ccc;
    padding: 20px 10px;
    text-align: center;
}

.pricing_box_price h3{
    font-size: 30px;
}

.pricing_box_price h3 small{
    font-size: 15px;
    font-weight: 100 !important;
}

.pricing_box_content{
    /*text-align: center;*/
    padding-left: 30px;
    padding-top: 10px;
    font-size: 15px;
}

.pricing_box_footer{
    width: 100%;
}
.pricing_box_footer button, .send-btn{
    width: 100%;
    margin-top: 10px;
    outline: none;
    background: none;
    color: #fff;
    border: none;
    padding: 10px;
    background: #2f84fb;
    border-radius: 5px;
    margin-bottom: 10px;
}

.pricing_box_footer button:hover,
.pricing_box_footer button:active
{
 transition: all ease-in-out .5s;
    color: #2f84fb;
    background: #fff;
}

.middle_small_loading{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    display: flex;
    flex-direction: row;
}
.middle_small_loading span{
    margin : 3px 10px 3px 10px;
    font-weight: lighter;
}

.finish_container{
    text-align: center;
    padding: 40px 0;
    height: 100vh;
    overflow: hidden;
    background: #EBF0F5;
}


.finish_card h1 {
    color: #88B04B;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.finish_card i {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left:-15px;
    text-align: center;
}
.finish_card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    width: 100%;
}
.finish_card p {
    color: #404F5E;
    font-size:20px;
    margin: 0;
}

.center_rating{
  position: relative;
    left: 30%;
}

.left__panel{
    height: 100vh;
    background:  linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)), url("./assets/background_register.jpg");
    background-size: cover;
}


.reg_panel_all{
    background: #E7E7F6;
    overflow: hidden;
}


.left__panel__content {
    position: absolute;
    bottom: 30vh;
    color: #fff;
    width: calc(100% - 50px);
}



@media (min-width: 992px) {
    .form__content{
        position: absolute;
        margin-top: 20vh;
        left: 50%;
        width: 700px;
        transform: translateX(-50%);
    }

    .form-reg{
        background-color: #fff;
        height: 100vh;
        position: absolute;
        right: 0;
        left: 0;
    }
}

@media (max-width: 992px) {
    .col-lg-4:has(.left__panel__content){
        display: none;
    }
}


.left__panel__content img{
    position: absolute;
     left: 60%;
     top: 50%;
    transform: translate(-50%,-50%);
}

.header__left__panel{
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.header__left__panel h3  {
    color: #fff;
}



.right__panel__content {
    background: #fff;
    height: 70vh;
    margin-top: 15vh;
    position: relative;
    left: -13px;
    width: calc(100% - 250px);
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    padding: 20px;
    justify-content: center;
}



@media (max-width: 1400px) {
    .left__panel__content {
        height: 90vh;
        margin-top: 5vh;
        right: -50px;
        width: calc(100% - 38px);
    }
    .right__panel__content {
        height: 90vh;
        margin-top: 5vh;
        left: -13px;
        width: calc(100% - 50px);
    }

    .right__panel__content input ,  .right__panel__content select{
        height: 40px !important;
    }

    .right__panel__content select {
        height: 40px !important;
    }
}



.login_panel {
    height: 50vh;
    margin-top: 5vh;
    position: relative;
    left: -13px;
    padding: 20px;
    justify-content: center;
}




.loading_margin{
    position: absolute;
    top: 50%;
    left: 50%;
    width : 100%;
    transform: translate(-50%,-50%);
}

.selected_lng_white{
    border-bottom: 3px solid #fff !important;
}

.header_custom_pricing{
    text-align: center;
}

.header_custom_pricing h2{
    font-style: normal;
    font-size: 50px;
    font-weight: 400;
    color: #222222;
    line-height: 88px;
}

.header_custom_pricing p{
    font-size: 40px;
    line-height: 52px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
    opacity: 0.6;
}

.pricing_panel{
    padding: 10px;
    border-radius: 18px;
    margin-top: 50px;
}

.pricing_header{
    text-transform: uppercase;
    font-weight: bold;
}
.pricing_price{
    font-weight: bold;
    text-align: center;
    padding: 10px;
    margin-top: 50px;
}

.pricing_content{
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #fff;
}

.pricing_panel button{
    border: none;
    background: none;
    outline: none;
    margin-top: 10px;
    padding: 10px 50px;
    border-radius: 6px;
    align-self: center;
    width: 100%;
}



.main {
    margin-top: 100px !important;
    width: 100%;
    margin: 0 auto;
}
.price-table {
    width: 100%;
    border-collapse: collapse;
    border: 0 none;
}
.price-table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.price-table tr td {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
    padding: 21px 24px;
}
.price-table tr td:first-child {
    border-left: 0 none;

}

.price-table tr:first-child  {
    border-bottom: none !important;
}

.price-table tr td:not(:first-child) {
    text-align: center;
}
.price-table tr:nth-child(even) {
    background-color: #FFFFFF;
}
.price-table tr:hover {
    background-color: #EEEEEE;
}
.price-table .fa-check {
    color: #299cdb;
}
.price-table .fa-times {
    color: #D8D6E3;
}

/* Highlighted column */
.price-table tr:nth-child(4) td:nth-child(4) {
    background-color: rgba(216, 214, 227, 0.25);
}
.price-table tr td:nth-child(4) {
    background-color: rgba(216, 214, 227, 0.15);
    padding: 8px 48px;

}
.price-table tr td:nth-child(4) .fa-check,
.price-table tr:nth-child(4) td:nth-child(4) .fa-check {
    /* color: #ffffff; */
}
/**/

.price-table tr.price-table-head td {
    font-size: 23px;
    font-weight: 600;
    padding-top: 20px;
    padding-bottom: 20px;
    text-transform: uppercase;
}
.price-table tr.price-table-head {
    color: #FFFFFF;
    background-color: #299cdb;
    border-bottom: none !important;

}
.price-table td.price {
    padding: 16px 24px;
    font-size: 30px;
    font-weight: 600;

}
.price-table td.price a {
    background-color: #299cdb;
    color: #FFFFFF;
    padding: 12px 32px;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 4px;
}
.price-table td.price-table-popular {
    border-top: 3px solid #299cdb;
    color: #299cdb;
    text-transform: uppercase;
    font-size: 12px;
    padding: 12px 48px;
    font-weight: 700;
}
.price-table .price-blank {
    background-color: #fafafa;
    border: 0 none;
}

.price-table-help{
    font-size: 19px;
    padding-top: 10px;
    padding-bottom: 10px;
}




.color-violet .container-button {
    background-color: #98f;
}
.color-violet .container-button:before {
    background-color: #98f;
}
.color-violet .w-item span {
    color: #98f;
}

.color-green .container-button {
    background-color: #1bbc9d;
}
.color-green .container-button:before {
    background-color: #1bbc9d;
}
.color-green .w-item span {
    color: #1bbc9d;
}

.color-red .container-button {
    background-color: #DB343A;
}
.color-red .container-button:before {
    background-color: #DB343A;
}
.color-red .w-item span {
    color: #DB343A;
}

.color-pink .container-button {
    background-color: #E91E63;
}
.color-pink .container-button:before {
    background-color: #E91E63;
}
.color-pink .w-item span {
    color: #E91E63;
}

.color-blue .container-button {
    background-color: #299cdb;
}
.color-blue .container-button:before {
    background-color: #299cdb;
}
.color-blue .w-item span {
    color: #299cdb;
}

.pricing_group{
    background-color: #D4D9ED;
    padding: 100px;
}

.w-table {
    display: table;
    width: 100%;
    height: 100%;
}

.w-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.w-container {
    position: relative;
    max-width: 100%;
    margin: 30px auto 0;
    font-size: 0;
    text-align: center;
}
.w-container .w-card {
    cursor: pointer;
    background-color: #fff;
    display: inline-block;
    vertical-align: top;
    width: 300px;
    color: #333;
    margin: 20px;
    font-size: 30px;
    font-weight: 300;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.w-container .w-card .card-header {
    overflow: hidden;
    padding: 20px 0 0;
}


.w-title {
    font-weight: bold !important;

}

.w-container .w-card .card-header .w-title, .w-container .w-card .card-header .w-price {
    position: relative;
    text-transform: uppercase;
    z-index: 2;
}
.w-container .w-card .card-header .w-price {
    font-size: 42px;
    font-weight: 500;
    padding: 1px 20px 20px;
}
.w-container .w-card .card-header .w-price sub {
    font-size: 12px !important;

}
.w-container .w-card .card-content {
    padding: 20px;
}
.w-container .w-card .card-content p {
    font-size: 18px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}
.w-container .w-card .card-content .w-item:last-child {
    border-bottom: none;
}
.w-container .w-card .card-content .w-item span {
    font-weight: 500;
}
.w-container .w-card .container-button {
    position: relative;
}
.w-container .w-card .container-button .w-button {
    position: relative;
    display: block;
    padding: 20px;
    color: #fff;
    font-weight: 400;
    z-index: 2;
    text-decoration: none;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.w-container .w-card .container-button:before, .w-container .w-card .container-button:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 50%;
    width: 0%;
    height: 0%;
    border-radius: 50%;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.w-container .w-card .container-button:after {
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.1);
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
}
.w-container .w-card .container-button:hover .w-button {
    padding: 0px 20px 40px;
}
.w-container .w-card .container-button:hover:before {
    top: -200px;
    left: -100px;
    width: 500px;
    height: 500px;
}
.w-container .w-card .container-button:hover:after {
    top: -200px;
    left: -50px;
    width: 400px;
    height: 400px;

}
.w-container .w-card:hover {
    z-index: 2;

}
.w-container .w-card:hover .w-button {
    opacity: 1;
}

.shopListSearch{
    display: flex;
    justify-content: center;
    padding: 25px;
}

.shopListSearch input {
    width: 30vw;
    border: 1px solid #d4d4d4;
    padding: 13px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}

.shopListSearch button{
    outline: none;
    border: none;
    background: none;
    background: #299cdb;
    padding: 13px 30px;
    color: #fff;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}


.doc_page{
    width: 100%;
    border-top: 1px solid #d4d4d4;
    display: flex;

}

.doc_left_content{
    position: relative;
    width: 15vw;
    margin: 0;
    padding: 10px;
    border-right: 1px solid #d4d4d4;

}


.doc_left_content::-webkit-scrollbar {
    width: 5px;
}

.doc_left_content::-webkit-scrollbar-track {
    background: #f1f1f1;
}


.doc_left_content::-webkit-scrollbar-thumb {
    background: #888;
}

.doc_left_content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.doc_left_content ul li span.active{
    color: #299cdb;
}




.doc_content_content{
    width: 70vw;
    padding: 40px;
    overflow-y: visible;
}

.doc_left_content ul li span{
    padding: 10px;
    color: #101010;
    font-weight: 500;
    font-size: 18px;
}
.doc_left_content ul li:first-child{
    padding-top: 20px;
}

.doc_left_content ul li span:hover{
    color: #299cdb;
    transition: all ease-in-out .5s;
}

.doc_right_content{
    width: 15vw;
    position: relative;
    right: 0;
    border-left: 1px solid #d4d4d4;
    padding: 20px;

}

.doc_content_content h1{
    font-size: 45px;
    padding-bottom: 20px;
}

.doc_right_content ul{
    list-style: -moz-japanese-informal;
}

.doc_right_content ul li {
    padding: 10px;
}

.shop_list_card{
    padding-bottom: 20px;
    border-radius: 6px;
    transition: all ease-in-out .5s;
    justify-self: center;
    justify-items: center;
    text-align: center;
    border : 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
}

.shop_list_card:hover{
    box-shadow: 0 1px 3px rgba(51, 119, 255, 0.62);
}

.shop_list_card img{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

}



.shop_list_card .content {
    padding: 10px;

}

.shop_list_card .content h6{
    font-size: 25px;
    text-transform: capitalize;
    font-weight: bold;
    opacity: .9;
}

.selected_active {
    transition: ease-in-out all .3s;
     background: #299cdb !important;
    color: #fff;
    font-weight: 100;
}

.list_category_parent{
    width: 100%;
    margin-top: 30px;
}

.shop_category_list::-webkit-scrollbar {
    width: 0.5em !important;
    height: 0.5em !important;
}

.shop_category_list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.shop_category_list::-webkit-scrollbar-thumb {
    background-color: darkgrey !important;
    outline: 1px solid slategrey !important;
}


.shop_category_list {
    text-align: center !important;
    margin: 0 auto 50px;
    overflow-x: auto;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.shop_category_list li {
    padding: 2px 10px;
    font-size: 13px;
    background: #d4d4d4;
    display: flex;
    margin: 5px;
    flex: 0 0 auto;
    border-radius: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.return_top{
    margin-top: -150px !important;
}

.recap_boxes{
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 10px;
    height: 300px;
    text-align: center;
}


.our-team .team-row {
    margin-left: -40px;
    margin-right: -40px;
}

.our-team .team-wrap {
    padding: 0 40px;
}

.our-team .container-fluid {
    padding: 0 50px;
}

.team-img img {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    overflow: hidden;
    width: 100%;
}

.team-member,
.team-img {
    position: relative;
    overflow: hidden;
}

.team-title {
    margin: 30px 0 7px;
}

.overlay {
    background-color: rgba(20,20,20,.7);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 5%;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.team-details p {
    color: #fff;
}

.team-img:hover .team-details {
    opacity: 1;
    margin-top: -80px;
}

.team-img:hover .overlay {
    opacity: 1;
}

.socials a {
    display: inline-block;
    width: 37px;
    height: 37px;
    background-color: transparent;
}

.socials i {
    line-height: 37px;
    color: #616161;
    font-size: 14px;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    text-align: center;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.team-details .socials i {
    color: #fff;
}

.socials a:hover i {
    color: #fff;
    background-color: #355c7d;
}


.social-bottom{
    display: flex;
}

.social-bottom li a{
    padding: 5px;
    margin: 3px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.template_picture_list{
    border-right: 1px solid #c1c1c1;
    height: 600px;
}

.template_hover_box{
    padding: 14px;
    cursor: pointer;
    overflow-y: hidden;
    position: relative;
    height: 150px;
    margin-bottom: 20px;
}

.template_hover_box img{
    height: auto;
    width: 220px;
    padding: 10px;
    border : 1px solid #ccc;

}

.template_hover_box:hover img{
    transition: all  ease-in-out .3s;
}

.template_hover_box.active img{
    border-left: 4px solid #299cdb;
    border-right: 4px solid #299cdb;
    padding: 10px;
    transition: all  ease-in-out .3s;
}

.template_showing_content{
    padding: 10px;

}

.template_showing_content_element{
    height : 400px;
    width: 100%;
    overflow: hidden;
    position : relative;
    border: 1px solid #aaa;
    border-radius: 9px;
}

.template_showing_content img{
    height: auto;
    transform: translateY(0px);
    transition: 5s;
}

.template_showing_content_element:hover img{
    transform: translateY(calc(-100% + 400px));
}

.template_showing_content p{
    margin-top: 20px;
    margin-bottom: 20px;
    opacity: .8;
    font-size: 13px;
}

.template_showing_content h3 small{
    cursor: none !important;
    font-size: 9px;
}

.template_showing_content_header{
    display: flex;
    justify-content: space-between;
}

.page_404{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-47%,-50%);
    width: 100vw;
    background:#fff;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}


.four_zero_four_bg h1{
    font-size:80px;
    color: #299cdb;
    margin-bottom: 30px;
    font-weight: bold;
}

.four_zero_four_bg h3{
    font-size:80px;
}


.contant_box_404{ margin-top:-50px;}

.warning-content {
    position:absolute;
    top:25%;
    width:550px;
    height:400px;
    text-align:center;
    left: 35%;
    margin:0;
    background: rgba(255, 255, 255, 0.34);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 35px 20px;
}


.trial__flex__end{
    display: flex;
    justify-content: flex-end;
}

.trial__flex__end span{
    padding: 2px 6px;
    background: #ffc533;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 400;
}

.trial__brand{
    position: fixed;
    z-index: 9999999999999;
    bottom: -100px;
    right: 0;
    left: 0;
    background: #299cdb;
    -webkit-box-shadow: 1px -7px 5px 0px rgba(51, 119, 255, 0.23);
    -moz-box-shadow: 1px -7px 5px 0px rgba(51, 119, 255, 0.23);
    box-shadow: 1px -7px 5px 0px rgba(51, 119, 255, 0.23);

}

.trial__brand__left{
    bottom: 0;
    padding: 20px;
   border-right: 3px solid #fff;
}

.trial__brand__left h1{
    color: #fff !important;

}

.trial__brand__left{
    text-align: center;
}
.trial__brand__left i{
    font-size: 25px;
    color: #fff;
    font-weight: 100;
}

.trial__brand__content_list{
    display: flex;
    padding-top: 20px;
}

.trial__brand__content_list a{
    margin-left: 20px ;
}

.from__hide__bottom{
    bottom: 0;
    transition: 1s ease-in-out all;
}

.trial__brand__close{
    font-size : 40px;
    float : right;
    padding: 5px 30px;
    cursor : pointer;
    color : #fff
}

.trial__brand__close:hover{
    transform: scale(1.001);
}

.ribbon {
    position: absolute;
    left: 7px; top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}
.ribbon span {
    font-size: 15px;
    font-weight: bold;
    color: #FFF;
    text-align: center;
    line-height: 20px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 100px;
    display: block;
    background: #ef5350;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; left: -21px;
}
.ribbon span::before {
    content: "";
    position: absolute; left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #ef5350;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #ef5350;
}
.ribbon span::after {
    content: "";
    position: absolute; right: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #ef5350;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #ef5350;
}

.avatarRounded{
    border-radius : 50%;
    width : 50px !important;
}

.facebook__connect{
    display: inline-block;
    width: 54px;
    height: 54px;
    line-height: 60px;
    text-align: center;
    border: 1px solid #E0E2E3;
    font-size: 32px;
}

.facebook__connect i{
    color: #165bba;
}

.facebook__connect:hover{
    border: 1px solid #ccc;

}

.empty_template{
    border : 1px dashed #101010;
    border-radius : 6px;
    height: 300px;
    text-align : center;
    cursor: pointer;
}

.empty_template .content {
   display : block;
    padding-top: 100px;
  justify-content : center;
    justify-items: center;
}

.empty_template img{
    width : 100px;
}


.custom_template .icon {
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
}

.custom_template .card-wrapper {
    position: relative;
    cursor: pointer;
}
.custom_template .card-item {
    width: 100%;
    height: 300px;
    overflow-y: hidden;
    position: relative;
    border-radius: 4px;
}
.custom_template .card-item:hover  .card-image:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 10%);
}
.custom_template .card-item:hover .card-image img {
    transform: translateY(calc(-100% + 450px));
}
.custom_template .card-item .card-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.custom_template .card-item .card-image:after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    background: black;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.1) 10%);
}
.custom_template .card-item .card-image img {
    width: 100%;
    height: auto;
    transform: translateY(0px);
    transition: 4s;
}
.custom_template .card-item .card-content {
    width: 100%;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 20px;
}

.custom_template .card-item .card-content .card-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    color: #fff;

}


.tp_select .css-13cymwt-control{
    width : 100%;
    height : 55px !important;
    border-radius: 0 !important;
}

.server__return__error {
    text-align: center;
    margin: auto;
    padding: 4em;
}
.server__return__error img {
    width: 256px;
    height: 225px;
}
.server__return__error h1 {
    margin-top: 1rem;
    font-size: 25px;
    text-align: center;
}
.server__return__error h1 span {
    font-size: 60px;
}
.server__return__error p {
    margin-top: 1rem;
}
.server__return__error p.info {
    margin-top: 4em;
    font-size: 12px;
}
.server__return__error p.info a {
    text-decoration: none;
    color: #5454ce;
}



.fixed_map_recap {
    position: fixed;
    width: 250px;
    right: 30px;
    top: 320px;
    background: #fff;
    border-radius: 5px;
    padding: 20px 50px;
    z-index: 9999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999
}



.feedback__card {
    background: linear-gradient(to top, #fff, #fff);
    width: 90%;
    max-width: 400px;
    padding: 30px;
    border-radius: 30px;
    flex-direction: column;
    justify-content: center;
    -webkit-box-shadow: 0px -2px 21px -7px rgba(204, 204, 204, 1);
    -moz-box-shadow: 0px -2px 21px -7px rgba(204, 204, 204, 1);
    box-shadow: 0px -2px 21px -7px rgba(204, 204, 204, 1);
}


.feedback__card header{
    display: flex;
    flex-direction : row;
    justify-content: space-between;
}

.feedback__card header span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    border-radius: 50%;
    height: 48px;
}

.feedback__card header span:last-child{
    color: #fff;
    cursor: pointer;
}

.feedback__card header span img {
    width: 20px;
    height: 20px;
}
.feedback__card  h3 {
    margin: 20px 0 10px 0;
    color: #fefefe;
    font-size: 24px;
    text-align: center;
}
.feedback__card  p {
    color: #8d94a3;
    font-size: 15px;
    text-align: center;
}
.feedback__card .list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 30px 0;
}
.feedback__card .list li span {
    background-color: #ccc;
    color: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s;
}
.feedback__card .list li span:focus {
    background-color: #7a8696;
    color: white;
}
.feedback__card .list li span:hover {
    background-color: #f97618;
    color: white;
}

.feedback__card .list .choosed {
    background-color: #f97618;
    color: white;
}

.feedback__card button {
    width: 50%;
    left: 25%;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 600;
    padding: 10px;
    font-family: "Poppins", serif;
    border-radius: 30px;
    border: none;
    color: #141519;
    cursor: pointer;
    transition: 0.3s background-color;
}
.feedback__card button:hover {
    background-color: #fff;
    color: #f97618;
}

.feedback__card #feedresult {
    display: none;
    text-align: center;
}
.feedback__card #feedresult .image {
    margin: 20px  auto;
}
.feedback__card #feedresult p {
    font-size: 16px;
}
.feedback__card #feedresult .ss {
    margin: 25px 0;
}
.feedback__card #feedresult #selected {
    background-color: #33373a;
    padding: 5px 15px;
    border-radius: 15px;
    color: #f97618;
}

.black__fb__wrapper{
    position: fixed;
    right: -500px;
    bottom: 150px;
    z-index: 9999;
    transition: ease-in-out all .5s;
}

.black__fb__wrapper__show{
    display: block !important;
    right: 50px;
}


.border__round{
    border: 1px solid #101010 !important;
}


.guirlande {
    pointer-events: none;
    z-index: 10;
    width: 100%;
    height: 150px;
    position: absolute;
    top: 150px;
    left: 0
}

.guirlande1 {
    height: 120px;
    background: url(./assets/guirlande1.png) repeat-x 300% top;
    top: -23px
}

.guirlande2 {
    background: url(./assets/guirlande2.png) repeat-x 70% top;
    top: -71px
}

.guirlande3 {
    background: url(./assets/guirlande3.png) repeat-x 10% top;
    top: -50px
}


.superZIndex ._1yCVn{
    z-index: 9999999 !important;
}

.incrustrer{
    z-index: 1 !important;
     margin-top: -150px;
}

.incrustre{
    position: absolute;
    top: 110px;
    z-index : 0 !important;
    left: 165px;
    right: 0;
    height: 380px;
    width: 600px;
    border-radius: 15px;
}

.custom__panel{
    padding: 10px;
    width: 100%;
    justify-content: center;
    border-radius: 6px;
     background: #f1f1f1;
    border: 1px solid #CCCCCC;
}

.custom__panel h4{
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;

}

.custom__panel p{
    text-align: center;
    margin-bottom: 20px;
    font-size: 13px;
}

.custom__panel button{
   text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}


.pricing-switcher {
    text-align: center;
}
.pricing-switcher .fieldset {
    display: inline-block;
    position: relative;
    padding: 5px;
    /*border-radius: 50em;*/
    background-color: #fff;
    border: 1px solid #299cdb;
}
.pricing-switcher input[type=radio] {
    position: absolute;
    opacity: 0;
}
.pricing-switcher label {
    position: relative;
    z-index: 1;
    display: inline-block;
    float: left;
    width: 140px;
    height: 30px;
    line-height: 40px;
    cursor: pointer;
    color: #299cdb;
    text-decoration: none;
    font-weight: bold;
    transition-delay: 0.1s;
    transition: color 0.3s ease;
}
.pricing-switcher input[type=radio]:checked + label {
    color: #FFFFFF;
    text-decoration: none;
}
.pricing-switcher .switch {
    /* floating background */
    position: absolute;
    top: 5px;
    left: 5px;
    height: 40px;
    width: 130px;
    background-color: #299cdb;
    /*border-radius: 50em;*/
    transition: transform 0.3s;
}
.pricing-switcher input[type=radio]:checked + label + .switch,
.pricing-switcher input[type=radio]:checked + label:nth-of-type(n) + .switch {
    -webkit-transform: translateX(150px);
    -moz-transform: translateX(150px);
    -ms-transform: translateX(150px);
    -o-transform: translateX(150px);
    transform: translateX(150px);
}



#scrollable-container {
    max-height: 100vh;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
}

@media (max-width: 1366px) and (max-height: 768px) {
    #scrollable-container {
        max-height: calc(100vh - 80px);
        overflow-y: scroll;
    }
}