.loading__body {
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}


#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
}
#loader {
    display: block;
    position: relative;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #028ad8;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #028ad8;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #028ad8;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.gray-text{
    color: rgb(143, 143, 143);
}

.underline{
    text-decoration: underline;
}

.loading_content_center{
    position: relative;
    text-align: center;
    margin-top: 22%;
    margin-bottom: 22%;
}

.loading_content_center_reg{
    position: relative;
    text-align: center;
    margin-top: 30%;
}


.subloading {
    display: grid;
    place-content: center;
    min-height: 100vh;
    margin: 0;
    padding: 2px;
    box-sizing: border-box;
    overflow: hidden;
}


.subloading #dots {
    display: inline-flex;
}
.dots--animate .sub__dot.z {
    -webkit-animation: scale 0.8s 0.2s forwards;
    animation: scale 0.8s 0.2s forwards;
}
.dots--animate .sub__dot.f, .dots--animate .sub__dot.s {
    -webkit-animation: right 0.5s forwards;
    animation: right 0.5s forwards;
}
.dots--animate .sub__dot.l {
    -webkit-animation: rightDown 0.4s 0.1s forwards linear, drop 2s 0.4s forwards linear;
    animation: rightDown 0.4s 0.1s forwards linear, drop 2s 0.4s forwards linear;
}

.sub__dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #212121;
    border-radius: 10px;
    position: relative;
    margin-left: 6px;
}
.sub__dot.z {
    position: absolute;
    transform: scale(0);
}
@-webkit-keyframes scale {
    100% {
        transform: scale(1);
    }
}
@keyframes scale {
    100% {
        transform: scale(1);
    }
}
.sub__dot.f, .sub__dot.s {
    transform: translateX(0px);
}
@-webkit-keyframes right {
    100% {
        transform: translateX(16px);
    }
}
@keyframes right {
    100% {
        transform: translateX(16px);
    }
}
.sub__dot.t {
    background: transparent;
}
.sub__dot .l {
    margin-left: 0;
    position: absolute;
    top: 0;
    left: 0;
}
@-webkit-keyframes rightDown {
    50% {
        top: 4px;
        left: 16px;
    }
    100% {
        top: 12px;
        left: 24px;
    }
}
@keyframes rightDown {
    50% {
        top: 4px;
        left: 16px;
    }
    100% {
        top: 12px;
        left: 24px;
    }
}
@-webkit-keyframes drop {
    100% {
        transform: translate(70px, calc(35px + (100vh/2)));
    }
}
@keyframes drop {
    100% {
        transform: translate(70px, calc(35px + (100vh/2)));
    }
}
