.custom-radio input{
  display: none;
}
.radio-btn{
    margin: 20px 10px;
    width: 100%;
    min-width: 250px;
    height: 200px;
    border: 1px solid rgba(51, 119, 255, 0.23);
    display: inline-block;
    border-radius: 10px;
    position: relative;
    text-align: center;
    cursor: pointer;
}



.radio-btn i.ri-check-fill {
    color: #fff;
    background: #3377ff;
    font-size: 20px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%) scale(2);
    border-radius : 50px;
    padding:0 5px;
    transition: .2s;
    pointer-events: none;
    opacity: 0;
}

 .radio-btn .radio-body-content{
    width: 100%;
    height: 80px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
}

 .radio-body-content i{
    color: #3377ff;
    line-height: 80px;
    font-size: 60px;
     font-weight: lighter;

 }

 .radio-body-content h3{
    color: #3377ff;
    font-size: 14px;
    font-weight: 500;
     margin-top: 20px;
    text-transform: uppercase;
}

.custom-radio input:checked + .radio-btn{
    border:  2px solid #3377ff;
}

.custom-radio input:checked + .radio-btn > i.ri-check-fill{
    opacity: 1;
    transform: translateX(-50%) scale(1);
}

.currencyCode {
    background: none;
    color: rgb(0 0 0 / 50%);
    border-color: rgb(0 0 0 / 50%);
    border-radius: 0.75em;
    padding: 0.25em;
    vertical-align: middle;
}

.custom-img-container{
    width: 200px;
    max-width: 200px;
    height: 200px;
    border: 0.5px solid #ddd
}

.custom-img-container input{
    display: none;
}

.custom-img-container img{
    width : 100%;
    z-index: -1;
}


.custom-img-container .icon-zone{
     margin-top: 160px;
    background: rgba(0,0,0,0.6);
    z-index: 1;
    display: flex;
    justify-content: center;
    height: 40px;
    padding-top: 10px;
}

.custom-img-container .icon-zone i{
    width: 40px;
    height: 40px;
    font-size: 13px;
    padding: 0.1em 0.3em;
    border-radius: 50%;
    color: #fff;
    background: rgba(0,0,0,0.8);
    margin: 8px;
    cursor: pointer;
}


