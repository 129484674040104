.filepicker__form__element {
    width: auto;
    height: auto;
    border: 1px solid #e6e0e0;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.filepicker__form__element input {
    display: none;
}

.filepicker__form__element {
    position: relative; /* Permet de positionner les éléments enfants en absolu */
    width: 100%;
    height: 200px; /* Taille fixe pour le conteneur */
    overflow: hidden;
}

.filepicker__image-wrapper {
    width: 100%;
    height: 100%;
}

.filepicker__image-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Contient l'image sans la déformer */
}

.filepicker__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 14px;
    padding: 5px;
}


.filepicker__form__element div span {
    color: #fff;
    font-size: 14px;
}
