.card__block__section{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Poppins", serif;
}

.card__block__wrapper{
    margin: 30px auto;
    width: 80%;
}

.card__block__wrapper img{
    max-width: 320px;
    float: left;
    border-radius: 10px;
    border: 3px solid #2a2f34;
    margin-right: 15px;
}

.card__block__wrapper .card__block__text__box{
  font-size: 16px;
}

.card__block__wrapper .card__block__text__box h2{
  font-size: 42px;
}