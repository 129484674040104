.cookie_wrapper{
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: #fff;
    max-width: 365px;
    border-radius: 15px;
    text-align: center;
    padding: 25px 25px 30px 25px;
    box-shadow: 1px 7px 14px -5px rgba(0,0,0,0.6);
    text-align: center;
    z-index: 99999;
}


.cookie_wrapper img{
    max-width: 150px;
}
.cookie_wrapper .content{
    margin-top: 20px;
}
.cookie_wrapper header{
    font-size: 25px;
    font-weight: 600;
}
.cookie_content{
    margin-top: 10px;
}

.cookie_content svg{
    width: 30%;
}

.cookie_content p{
    color: #858585;
    margin: 5px 0 20px 0;
}
.cookie_content .cookie_buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}
.cookie_buttons .item{
    margin: 0 10px;
}
.cookie_buttons button{
    padding: 10px 20px;
    background:  #3377ff;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
}
.cookie_buttons button:hover{
    transform: scale(0.97);
}
