@import url("./css/animate.css");
@import url("./css/swiper-bundle.css");
@import url("./css/flaticon_shofy.css");
@import url("./css/spacing.css");
@import url("./css/main.css");
@import url("./css/font-awesome-pro.css");


.slide-hidden{
    display: none !important
}
