.otp-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.otp-input input {
    width: 60px;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 5px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin: 0 5px;
}

.otp-input input:focus {
    outline: none;
    border-color: #007bff;
}
