@charset "UTF-8";
/**
 * Utility classes for spacing:
 * A wide range of shorthand margin and padding utility classes to modify an
 * an element’s appearance.
 * Prefix understanding: u means utility which is a prefix for root namespace,
 * s means spacing which is a prefix for sub namespace, m means margin,
 * p means padding. e.g. `u-s-m-t-22` means apply top margin 22 pixels that by default converted to rem unit.
 */
/* Margin with value `auto`. */
.u-s-m-t-auto {
  margin-top: auto; }

.u-s-m-r-auto {
  margin-right: auto; }

.u-s-m-b-auto {
  margin-bottom: auto; }

.u-s-m-l-auto {
  margin-left: auto; }

.u-s-m-x-auto {
  margin-left: auto;
  margin-right: auto; }

.u-s-m-y-auto {
  margin-top: auto;
  margin-bottom: auto; }

.u-s-m-xy-auto {
  margin: auto; }

/* Margin with value `0`. */
.u-s-m-t-0 {
  margin-top: 0; }

.u-s-m-r-0 {
  margin-right: 0; }

.u-s-m-b-0 {
  margin-bottom: 0; }

.u-s-m-l-0 {
  margin-left: 0; }

.u-s-m-x-0 {
  margin-left: 0;
  margin-right: 0; }

.u-s-m-y-0 {
  margin-top: 0;
  margin-bottom: 0; }

.u-s-m-xy-0 {
  margin: 0; }

/* Padding with value `0`. */
.u-s-p-t-0 {
  padding-top: 0; }

.u-s-p-r-0 {
  padding-right: 0; }

.u-s-p-b-0 {
  padding-bottom: 0; }

.u-s-p-l-0 {
  padding-left: 0; }

.u-s-p-x-0 {
  padding-left: 0;
  padding-right: 0; }

.u-s-p-y-0 {
  padding-top: 0;
  padding-bottom: 0; }

.u-s-p-xy-0 {
  padding: 0; }

/* Margin with value `1`. */
.u-s-m-t-1 {
  margin-top: 0.0625rem; }

.u-s-m-r-1 {
  margin-right: 0.0625rem; }

.u-s-m-b-1 {
  margin-bottom: 0.0625rem; }

.u-s-m-l-1 {
  margin-left: 0.0625rem; }

.u-s-m-x-1 {
  margin-left: 0.0625rem;
  margin-right: 0.0625rem; }

.u-s-m-y-1 {
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem; }

.u-s-m-xy-1 {
  margin: 0.0625rem; }

/* Padding with value `1`. */
.u-s-p-t-1 {
  padding-top: 0.0625rem; }

.u-s-p-r-1 {
  padding-right: 0.0625rem; }

.u-s-p-b-1 {
  padding-bottom: 0.0625rem; }

.u-s-p-l-1 {
  padding-left: 0.0625rem; }

.u-s-p-x-1 {
  padding-left: 0.0625rem;
  padding-right: 0.0625rem; }

.u-s-p-y-1 {
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem; }

.u-s-p-xy-1 {
  padding: 0.0625rem; }

/* Margin with value `2`. */
.u-s-m-t-2 {
  margin-top: 0.125rem; }

.u-s-m-r-2 {
  margin-right: 0.125rem; }

.u-s-m-b-2 {
  margin-bottom: 0.125rem; }

.u-s-m-l-2 {
  margin-left: 0.125rem; }

.u-s-m-x-2 {
  margin-left: 0.125rem;
  margin-right: 0.125rem; }

.u-s-m-y-2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem; }

.u-s-m-xy-2 {
  margin: 0.125rem; }

/* Padding with value `2`. */
.u-s-p-t-2 {
  padding-top: 0.125rem; }

.u-s-p-r-2 {
  padding-right: 0.125rem; }

.u-s-p-b-2 {
  padding-bottom: 0.125rem; }

.u-s-p-l-2 {
  padding-left: 0.125rem; }

.u-s-p-x-2 {
  padding-left: 0.125rem;
  padding-right: 0.125rem; }

.u-s-p-y-2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; }

.u-s-p-xy-2 {
  padding: 0.125rem; }

/* Margin with value `3`. */
.u-s-m-t-3 {
  margin-top: 0.1875rem; }

.u-s-m-r-3 {
  margin-right: 0.1875rem; }

.u-s-m-b-3 {
  margin-bottom: 0.1875rem; }

.u-s-m-l-3 {
  margin-left: 0.1875rem; }

.u-s-m-x-3 {
  margin-left: 0.1875rem;
  margin-right: 0.1875rem; }

.u-s-m-y-3 {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem; }

.u-s-m-xy-3 {
  margin: 0.1875rem; }

/* Padding with value `3`. */
.u-s-p-t-3 {
  padding-top: 0.1875rem; }

.u-s-p-r-3 {
  padding-right: 0.1875rem; }

.u-s-p-b-3 {
  padding-bottom: 0.1875rem; }

.u-s-p-l-3 {
  padding-left: 0.1875rem; }

.u-s-p-x-3 {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem; }

.u-s-p-y-3 {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem; }

.u-s-p-xy-3 {
  padding: 0.1875rem; }

/* Margin with value `4`. */
.u-s-m-t-4 {
  margin-top: 0.25rem; }

.u-s-m-r-4 {
  margin-right: 0.25rem; }

.u-s-m-b-4 {
  margin-bottom: 0.25rem; }

.u-s-m-l-4 {
  margin-left: 0.25rem; }

.u-s-m-x-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.u-s-m-y-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.u-s-m-xy-4 {
  margin: 0.25rem; }

/* Padding with value `4`. */
.u-s-p-t-4 {
  padding-top: 0.25rem; }

.u-s-p-r-4 {
  padding-right: 0.25rem; }

.u-s-p-b-4 {
  padding-bottom: 0.25rem; }

.u-s-p-l-4 {
  padding-left: 0.25rem; }

.u-s-p-x-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.u-s-p-y-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.u-s-p-xy-4 {
  padding: 0.25rem; }

/* Margin with value `5`. */
.u-s-m-t-5 {
  margin-top: 0.3125rem; }

.u-s-m-r-5 {
  margin-right: 0.3125rem; }

.u-s-m-b-5 {
  margin-bottom: 0.3125rem; }

.u-s-m-l-5 {
  margin-left: 0.3125rem; }

.u-s-m-x-5 {
  margin-left: 0.3125rem;
  margin-right: 0.3125rem; }

.u-s-m-y-5 {
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem; }

.u-s-m-xy-5 {
  margin: 0.3125rem; }

/* Padding with value `5`. */
.u-s-p-t-5 {
  padding-top: 0.3125rem; }

.u-s-p-r-5 {
  padding-right: 0.3125rem; }

.u-s-p-b-5 {
  padding-bottom: 0.3125rem; }

.u-s-p-l-5 {
  padding-left: 0.3125rem; }

.u-s-p-x-5 {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem; }

.u-s-p-y-5 {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem; }

.u-s-p-xy-5 {
  padding: 0.3125rem; }

/* Margin with value `6`. */
.u-s-m-t-6 {
  margin-top: 0.375rem; }

.u-s-m-r-6 {
  margin-right: 0.375rem; }

.u-s-m-b-6 {
  margin-bottom: 0.375rem; }

.u-s-m-l-6 {
  margin-left: 0.375rem; }

.u-s-m-x-6 {
  margin-left: 0.375rem;
  margin-right: 0.375rem; }

.u-s-m-y-6 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem; }

.u-s-m-xy-6 {
  margin: 0.375rem; }

/* Padding with value `6`. */
.u-s-p-t-6 {
  padding-top: 0.375rem; }

.u-s-p-r-6 {
  padding-right: 0.375rem; }

.u-s-p-b-6 {
  padding-bottom: 0.375rem; }

.u-s-p-l-6 {
  padding-left: 0.375rem; }

.u-s-p-x-6 {
  padding-left: 0.375rem;
  padding-right: 0.375rem; }

.u-s-p-y-6 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem; }

.u-s-p-xy-6 {
  padding: 0.375rem; }

/* Margin with value `7`. */
.u-s-m-t-7 {
  margin-top: 0.4375rem; }

.u-s-m-r-7 {
  margin-right: 0.4375rem; }

.u-s-m-b-7 {
  margin-bottom: 0.4375rem; }

.u-s-m-l-7 {
  margin-left: 0.4375rem; }

.u-s-m-x-7 {
  margin-left: 0.4375rem;
  margin-right: 0.4375rem; }

.u-s-m-y-7 {
  margin-top: 0.4375rem;
  margin-bottom: 0.4375rem; }

.u-s-m-xy-7 {
  margin: 0.4375rem; }

/* Padding with value `7`. */
.u-s-p-t-7 {
  padding-top: 0.4375rem; }

.u-s-p-r-7 {
  padding-right: 0.4375rem; }

.u-s-p-b-7 {
  padding-bottom: 0.4375rem; }

.u-s-p-l-7 {
  padding-left: 0.4375rem; }

.u-s-p-x-7 {
  padding-left: 0.4375rem;
  padding-right: 0.4375rem; }

.u-s-p-y-7 {
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem; }

.u-s-p-xy-7 {
  padding: 0.4375rem; }

/* Margin with value `8`. */
.u-s-m-t-8 {
  margin-top: 0.5rem; }

.u-s-m-r-8 {
  margin-right: 0.5rem; }

.u-s-m-b-8 {
  margin-bottom: 0.5rem; }

.u-s-m-l-8 {
  margin-left: 0.5rem; }

.u-s-m-x-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.u-s-m-y-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.u-s-m-xy-8 {
  margin: 0.5rem; }

/* Padding with value `8`. */
.u-s-p-t-8 {
  padding-top: 0.5rem; }

.u-s-p-r-8 {
  padding-right: 0.5rem; }

.u-s-p-b-8 {
  padding-bottom: 0.5rem; }

.u-s-p-l-8 {
  padding-left: 0.5rem; }

.u-s-p-x-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.u-s-p-y-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.u-s-p-xy-8 {
  padding: 0.5rem; }

/* Margin with value `9`. */
.u-s-m-t-9 {
  margin-top: 0.5625rem; }

.u-s-m-r-9 {
  margin-right: 0.5625rem; }

.u-s-m-b-9 {
  margin-bottom: 0.5625rem; }

.u-s-m-l-9 {
  margin-left: 0.5625rem; }

.u-s-m-x-9 {
  margin-left: 0.5625rem;
  margin-right: 0.5625rem; }

.u-s-m-y-9 {
  margin-top: 0.5625rem;
  margin-bottom: 0.5625rem; }

.u-s-m-xy-9 {
  margin: 0.5625rem; }

/* Padding with value `9`. */
.u-s-p-t-9 {
  padding-top: 0.5625rem; }

.u-s-p-r-9 {
  padding-right: 0.5625rem; }

.u-s-p-b-9 {
  padding-bottom: 0.5625rem; }

.u-s-p-l-9 {
  padding-left: 0.5625rem; }

.u-s-p-x-9 {
  padding-left: 0.5625rem;
  padding-right: 0.5625rem; }

.u-s-p-y-9 {
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem; }

.u-s-p-xy-9 {
  padding: 0.5625rem; }

/* Margin with value `10`. */
.u-s-m-t-10 {
  margin-top: 0.625rem; }

.u-s-m-r-10 {
  margin-right: 0.625rem; }

.u-s-m-b-10 {
  margin-bottom: 0.625rem; }

.u-s-m-l-10 {
  margin-left: 0.625rem; }

.u-s-m-x-10 {
  margin-left: 0.625rem;
  margin-right: 0.625rem; }

.u-s-m-y-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem; }

.u-s-m-xy-10 {
  margin: 0.625rem; }

/* Padding with value `10`. */
.u-s-p-t-10 {
  padding-top: 0.625rem; }

.u-s-p-r-10 {
  padding-right: 0.625rem; }

.u-s-p-b-10 {
  padding-bottom: 0.625rem; }

.u-s-p-l-10 {
  padding-left: 0.625rem; }

.u-s-p-x-10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.u-s-p-y-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem; }

.u-s-p-xy-10 {
  padding: 0.625rem; }

/* Margin with value `11`. */
.u-s-m-t-11 {
  margin-top: 0.6875rem; }

.u-s-m-r-11 {
  margin-right: 0.6875rem; }

.u-s-m-b-11 {
  margin-bottom: 0.6875rem; }

.u-s-m-l-11 {
  margin-left: 0.6875rem; }

.u-s-m-x-11 {
  margin-left: 0.6875rem;
  margin-right: 0.6875rem; }

.u-s-m-y-11 {
  margin-top: 0.6875rem;
  margin-bottom: 0.6875rem; }

.u-s-m-xy-11 {
  margin: 0.6875rem; }

/* Padding with value `11`. */
.u-s-p-t-11 {
  padding-top: 0.6875rem; }

.u-s-p-r-11 {
  padding-right: 0.6875rem; }

.u-s-p-b-11 {
  padding-bottom: 0.6875rem; }

.u-s-p-l-11 {
  padding-left: 0.6875rem; }

.u-s-p-x-11 {
  padding-left: 0.6875rem;
  padding-right: 0.6875rem; }

.u-s-p-y-11 {
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem; }

.u-s-p-xy-11 {
  padding: 0.6875rem; }

/* Margin with value `12`. */
.u-s-m-t-12 {
  margin-top: 0.75rem; }

.u-s-m-r-12 {
  margin-right: 0.75rem; }

.u-s-m-b-12 {
  margin-bottom: 0.75rem; }

.u-s-m-l-12 {
  margin-left: 0.75rem; }

.u-s-m-x-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.u-s-m-y-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.u-s-m-xy-12 {
  margin: 0.75rem; }

/* Padding with value `12`. */
.u-s-p-t-12 {
  padding-top: 0.75rem; }

.u-s-p-r-12 {
  padding-right: 0.75rem; }

.u-s-p-b-12 {
  padding-bottom: 0.75rem; }

.u-s-p-l-12 {
  padding-left: 0.75rem; }

.u-s-p-x-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.u-s-p-y-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.u-s-p-xy-12 {
  padding: 0.75rem; }

/* Margin with value `13`. */
.u-s-m-t-13 {
  margin-top: 0.8125rem; }

.u-s-m-r-13 {
  margin-right: 0.8125rem; }

.u-s-m-b-13 {
  margin-bottom: 0.8125rem; }

.u-s-m-l-13 {
  margin-left: 0.8125rem; }

.u-s-m-x-13 {
  margin-left: 0.8125rem;
  margin-right: 0.8125rem; }

.u-s-m-y-13 {
  margin-top: 0.8125rem;
  margin-bottom: 0.8125rem; }

.u-s-m-xy-13 {
  margin: 0.8125rem; }

/* Padding with value `13`. */
.u-s-p-t-13 {
  padding-top: 0.8125rem; }

.u-s-p-r-13 {
  padding-right: 0.8125rem; }

.u-s-p-b-13 {
  padding-bottom: 0.8125rem; }

.u-s-p-l-13 {
  padding-left: 0.8125rem; }

.u-s-p-x-13 {
  padding-left: 0.8125rem;
  padding-right: 0.8125rem; }

.u-s-p-y-13 {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem; }

.u-s-p-xy-13 {
  padding: 0.8125rem; }

/* Margin with value `14`. */
.u-s-m-t-14 {
  margin-top: 0.875rem; }

.u-s-m-r-14 {
  margin-right: 0.875rem; }

.u-s-m-b-14 {
  margin-bottom: 0.875rem; }

.u-s-m-l-14 {
  margin-left: 0.875rem; }

.u-s-m-x-14 {
  margin-left: 0.875rem;
  margin-right: 0.875rem; }

.u-s-m-y-14 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem; }

.u-s-m-xy-14 {
  margin: 0.875rem; }

/* Padding with value `14`. */
.u-s-p-t-14 {
  padding-top: 0.875rem; }

.u-s-p-r-14 {
  padding-right: 0.875rem; }

.u-s-p-b-14 {
  padding-bottom: 0.875rem; }

.u-s-p-l-14 {
  padding-left: 0.875rem; }

.u-s-p-x-14 {
  padding-left: 0.875rem;
  padding-right: 0.875rem; }

.u-s-p-y-14 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem; }

.u-s-p-xy-14 {
  padding: 0.875rem; }

/* Margin with value `15`. */
.u-s-m-t-15 {
  margin-top: 0.9375rem; }

.u-s-m-r-15 {
  margin-right: 0.9375rem; }

.u-s-m-b-15 {
  margin-bottom: 0.9375rem; }

.u-s-m-l-15 {
  margin-left: 0.9375rem; }

.u-s-m-x-15 {
  margin-left: 0.9375rem;
  margin-right: 0.9375rem; }

.u-s-m-y-15 {
  margin-top: 0.9375rem;
  margin-bottom: 0.9375rem; }

.u-s-m-xy-15 {
  margin: 0.9375rem; }

/* Padding with value `15`. */
.u-s-p-t-15 {
  padding-top: 0.9375rem; }

.u-s-p-r-15 {
  padding-right: 0.9375rem; }

.u-s-p-b-15 {
  padding-bottom: 0.9375rem; }

.u-s-p-l-15 {
  padding-left: 0.9375rem; }

.u-s-p-x-15 {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem; }

.u-s-p-y-15 {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem; }

.u-s-p-xy-15 {
  padding: 0.9375rem; }

/* Margin with value `16`. */
.u-s-m-t-16 {
  margin-top: 1rem; }

.u-s-m-r-16 {
  margin-right: 1rem; }

.u-s-m-b-16 {
  margin-bottom: 1rem; }

.u-s-m-l-16 {
  margin-left: 1rem; }

.u-s-m-x-16 {
  margin-left: 1rem;
  margin-right: 1rem; }

.u-s-m-y-16 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.u-s-m-xy-16 {
  margin: 1rem; }

/* Padding with value `16`. */
.u-s-p-t-16 {
  padding-top: 1rem; }

.u-s-p-r-16 {
  padding-right: 1rem; }

.u-s-p-b-16 {
  padding-bottom: 1rem; }

.u-s-p-l-16 {
  padding-left: 1rem; }

.u-s-p-x-16 {
  padding-left: 1rem;
  padding-right: 1rem; }

.u-s-p-y-16 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.u-s-p-xy-16 {
  padding: 1rem; }

/* Margin with value `17`. */
.u-s-m-t-17 {
  margin-top: 1.0625rem; }

.u-s-m-r-17 {
  margin-right: 1.0625rem; }

.u-s-m-b-17 {
  margin-bottom: 1.0625rem; }

.u-s-m-l-17 {
  margin-left: 1.0625rem; }

.u-s-m-x-17 {
  margin-left: 1.0625rem;
  margin-right: 1.0625rem; }

.u-s-m-y-17 {
  margin-top: 1.0625rem;
  margin-bottom: 1.0625rem; }

.u-s-m-xy-17 {
  margin: 1.0625rem; }

/* Padding with value `17`. */
.u-s-p-t-17 {
  padding-top: 1.0625rem; }

.u-s-p-r-17 {
  padding-right: 1.0625rem; }

.u-s-p-b-17 {
  padding-bottom: 1.0625rem; }

.u-s-p-l-17 {
  padding-left: 1.0625rem; }

.u-s-p-x-17 {
  padding-left: 1.0625rem;
  padding-right: 1.0625rem; }

.u-s-p-y-17 {
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem; }

.u-s-p-xy-17 {
  padding: 1.0625rem; }

/* Margin with value `18`. */
.u-s-m-t-18 {
  margin-top: 1.125rem; }

.u-s-m-r-18 {
  margin-right: 1.125rem; }

.u-s-m-b-18 {
  margin-bottom: 1.125rem; }

.u-s-m-l-18 {
  margin-left: 1.125rem; }

.u-s-m-x-18 {
  margin-left: 1.125rem;
  margin-right: 1.125rem; }

.u-s-m-y-18 {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem; }

.u-s-m-xy-18 {
  margin: 1.125rem; }

/* Padding with value `18`. */
.u-s-p-t-18 {
  padding-top: 1.125rem; }

.u-s-p-r-18 {
  padding-right: 1.125rem; }

.u-s-p-b-18 {
  padding-bottom: 1.125rem; }

.u-s-p-l-18 {
  padding-left: 1.125rem; }

.u-s-p-x-18 {
  padding-left: 1.125rem;
  padding-right: 1.125rem; }

.u-s-p-y-18 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem; }

.u-s-p-xy-18 {
  padding: 1.125rem; }

/* Margin with value `19`. */
.u-s-m-t-19 {
  margin-top: 1.1875rem; }

.u-s-m-r-19 {
  margin-right: 1.1875rem; }

.u-s-m-b-19 {
  margin-bottom: 1.1875rem; }

.u-s-m-l-19 {
  margin-left: 1.1875rem; }

.u-s-m-x-19 {
  margin-left: 1.1875rem;
  margin-right: 1.1875rem; }

.u-s-m-y-19 {
  margin-top: 1.1875rem;
  margin-bottom: 1.1875rem; }

.u-s-m-xy-19 {
  margin: 1.1875rem; }

/* Padding with value `19`. */
.u-s-p-t-19 {
  padding-top: 1.1875rem; }

.u-s-p-r-19 {
  padding-right: 1.1875rem; }

.u-s-p-b-19 {
  padding-bottom: 1.1875rem; }

.u-s-p-l-19 {
  padding-left: 1.1875rem; }

.u-s-p-x-19 {
  padding-left: 1.1875rem;
  padding-right: 1.1875rem; }

.u-s-p-y-19 {
  padding-top: 1.1875rem;
  padding-bottom: 1.1875rem; }

.u-s-p-xy-19 {
  padding: 1.1875rem; }

/* Margin with value `20`. */
.u-s-m-t-20 {
  margin-top: 1.25rem; }

.u-s-m-r-20 {
  margin-right: 1.25rem; }

.u-s-m-b-20 {
  margin-bottom: 1.25rem; }

.u-s-m-l-20 {
  margin-left: 1.25rem; }

.u-s-m-x-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.u-s-m-y-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.u-s-m-xy-20 {
  margin: 1.25rem; }

/* Padding with value `20`. */
.u-s-p-t-20 {
  padding-top: 1.25rem; }

.u-s-p-r-20 {
  padding-right: 1.25rem; }

.u-s-p-b-20 {
  padding-bottom: 1.25rem; }

.u-s-p-l-20 {
  padding-left: 1.25rem; }

.u-s-p-x-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.u-s-p-y-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.u-s-p-xy-20 {
  padding: 1.25rem; }

/* Margin with value `21`. */
.u-s-m-t-21 {
  margin-top: 1.3125rem; }

.u-s-m-r-21 {
  margin-right: 1.3125rem; }

.u-s-m-b-21 {
  margin-bottom: 1.3125rem; }

.u-s-m-l-21 {
  margin-left: 1.3125rem; }

.u-s-m-x-21 {
  margin-left: 1.3125rem;
  margin-right: 1.3125rem; }

.u-s-m-y-21 {
  margin-top: 1.3125rem;
  margin-bottom: 1.3125rem; }

.u-s-m-xy-21 {
  margin: 1.3125rem; }

/* Padding with value `21`. */
.u-s-p-t-21 {
  padding-top: 1.3125rem; }

.u-s-p-r-21 {
  padding-right: 1.3125rem; }

.u-s-p-b-21 {
  padding-bottom: 1.3125rem; }

.u-s-p-l-21 {
  padding-left: 1.3125rem; }

.u-s-p-x-21 {
  padding-left: 1.3125rem;
  padding-right: 1.3125rem; }

.u-s-p-y-21 {
  padding-top: 1.3125rem;
  padding-bottom: 1.3125rem; }

.u-s-p-xy-21 {
  padding: 1.3125rem; }

/* Margin with value `22`. */
.u-s-m-t-22 {
  margin-top: 1.375rem; }

.u-s-m-r-22 {
  margin-right: 1.375rem; }

.u-s-m-b-22 {
  margin-bottom: 1.375rem; }

.u-s-m-l-22 {
  margin-left: 1.375rem; }

.u-s-m-x-22 {
  margin-left: 1.375rem;
  margin-right: 1.375rem; }

.u-s-m-y-22 {
  margin-top: 1.375rem;
  margin-bottom: 1.375rem; }

.u-s-m-xy-22 {
  margin: 1.375rem; }

/* Padding with value `22`. */
.u-s-p-t-22 {
  padding-top: 1.375rem; }

.u-s-p-r-22 {
  padding-right: 1.375rem; }

.u-s-p-b-22 {
  padding-bottom: 1.375rem; }

.u-s-p-l-22 {
  padding-left: 1.375rem; }

.u-s-p-x-22 {
  padding-left: 1.375rem;
  padding-right: 1.375rem; }

.u-s-p-y-22 {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem; }

.u-s-p-xy-22 {
  padding: 1.375rem; }

/* Margin with value `23`. */
.u-s-m-t-23 {
  margin-top: 1.4375rem; }

.u-s-m-r-23 {
  margin-right: 1.4375rem; }

.u-s-m-b-23 {
  margin-bottom: 1.4375rem; }

.u-s-m-l-23 {
  margin-left: 1.4375rem; }

.u-s-m-x-23 {
  margin-left: 1.4375rem;
  margin-right: 1.4375rem; }

.u-s-m-y-23 {
  margin-top: 1.4375rem;
  margin-bottom: 1.4375rem; }

.u-s-m-xy-23 {
  margin: 1.4375rem; }

/* Padding with value `23`. */
.u-s-p-t-23 {
  padding-top: 1.4375rem; }

.u-s-p-r-23 {
  padding-right: 1.4375rem; }

.u-s-p-b-23 {
  padding-bottom: 1.4375rem; }

.u-s-p-l-23 {
  padding-left: 1.4375rem; }

.u-s-p-x-23 {
  padding-left: 1.4375rem;
  padding-right: 1.4375rem; }

.u-s-p-y-23 {
  padding-top: 1.4375rem;
  padding-bottom: 1.4375rem; }

.u-s-p-xy-23 {
  padding: 1.4375rem; }

/* Margin with value `24`. */
.u-s-m-t-24 {
  margin-top: 1.5rem; }

.u-s-m-r-24 {
  margin-right: 1.5rem; }

.u-s-m-b-24 {
  margin-bottom: 1.5rem; }

.u-s-m-l-24 {
  margin-left: 1.5rem; }

.u-s-m-x-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.u-s-m-y-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.u-s-m-xy-24 {
  margin: 1.5rem; }

/* Padding with value `24`. */
.u-s-p-t-24 {
  padding-top: 1.5rem; }

.u-s-p-r-24 {
  padding-right: 1.5rem; }

.u-s-p-b-24 {
  padding-bottom: 1.5rem; }

.u-s-p-l-24 {
  padding-left: 1.5rem; }

.u-s-p-x-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.u-s-p-y-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.u-s-p-xy-24 {
  padding: 1.5rem; }

/* Margin with value `25`. */
.u-s-m-t-25 {
  margin-top: 1.5625rem; }

.u-s-m-r-25 {
  margin-right: 1.5625rem; }

.u-s-m-b-25 {
  margin-bottom: 1.5625rem; }

.u-s-m-l-25 {
  margin-left: 1.5625rem; }

.u-s-m-x-25 {
  margin-left: 1.5625rem;
  margin-right: 1.5625rem; }

.u-s-m-y-25 {
  margin-top: 1.5625rem;
  margin-bottom: 1.5625rem; }

.u-s-m-xy-25 {
  margin: 1.5625rem; }

/* Padding with value `25`. */
.u-s-p-t-25 {
  padding-top: 1.5625rem; }

.u-s-p-r-25 {
  padding-right: 1.5625rem; }

.u-s-p-b-25 {
  padding-bottom: 1.5625rem; }

.u-s-p-l-25 {
  padding-left: 1.5625rem; }

.u-s-p-x-25 {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem; }

.u-s-p-y-25 {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem; }

.u-s-p-xy-25 {
  padding: 1.5625rem; }

/* Margin with value `26`. */
.u-s-m-t-26 {
  margin-top: 1.625rem; }

.u-s-m-r-26 {
  margin-right: 1.625rem; }

.u-s-m-b-26 {
  margin-bottom: 1.625rem; }

.u-s-m-l-26 {
  margin-left: 1.625rem; }

.u-s-m-x-26 {
  margin-left: 1.625rem;
  margin-right: 1.625rem; }

.u-s-m-y-26 {
  margin-top: 1.625rem;
  margin-bottom: 1.625rem; }

.u-s-m-xy-26 {
  margin: 1.625rem; }

/* Padding with value `26`. */
.u-s-p-t-26 {
  padding-top: 1.625rem; }

.u-s-p-r-26 {
  padding-right: 1.625rem; }

.u-s-p-b-26 {
  padding-bottom: 1.625rem; }

.u-s-p-l-26 {
  padding-left: 1.625rem; }

.u-s-p-x-26 {
  padding-left: 1.625rem;
  padding-right: 1.625rem; }

.u-s-p-y-26 {
  padding-top: 1.625rem;
  padding-bottom: 1.625rem; }

.u-s-p-xy-26 {
  padding: 1.625rem; }

/* Margin with value `27`. */
.u-s-m-t-27 {
  margin-top: 1.6875rem; }

.u-s-m-r-27 {
  margin-right: 1.6875rem; }

.u-s-m-b-27 {
  margin-bottom: 1.6875rem; }

.u-s-m-l-27 {
  margin-left: 1.6875rem; }

.u-s-m-x-27 {
  margin-left: 1.6875rem;
  margin-right: 1.6875rem; }

.u-s-m-y-27 {
  margin-top: 1.6875rem;
  margin-bottom: 1.6875rem; }

.u-s-m-xy-27 {
  margin: 1.6875rem; }

/* Padding with value `27`. */
.u-s-p-t-27 {
  padding-top: 1.6875rem; }

.u-s-p-r-27 {
  padding-right: 1.6875rem; }

.u-s-p-b-27 {
  padding-bottom: 1.6875rem; }

.u-s-p-l-27 {
  padding-left: 1.6875rem; }

.u-s-p-x-27 {
  padding-left: 1.6875rem;
  padding-right: 1.6875rem; }

.u-s-p-y-27 {
  padding-top: 1.6875rem;
  padding-bottom: 1.6875rem; }

.u-s-p-xy-27 {
  padding: 1.6875rem; }

/* Margin with value `28`. */
.u-s-m-t-28 {
  margin-top: 1.75rem; }

.u-s-m-r-28 {
  margin-right: 1.75rem; }

.u-s-m-b-28 {
  margin-bottom: 1.75rem; }

.u-s-m-l-28 {
  margin-left: 1.75rem; }

.u-s-m-x-28 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.u-s-m-y-28 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.u-s-m-xy-28 {
  margin: 1.75rem; }

/* Padding with value `28`. */
.u-s-p-t-28 {
  padding-top: 1.75rem; }

.u-s-p-r-28 {
  padding-right: 1.75rem; }

.u-s-p-b-28 {
  padding-bottom: 1.75rem; }

.u-s-p-l-28 {
  padding-left: 1.75rem; }

.u-s-p-x-28 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.u-s-p-y-28 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.u-s-p-xy-28 {
  padding: 1.75rem; }

/* Margin with value `29`. */
.u-s-m-t-29 {
  margin-top: 1.8125rem; }

.u-s-m-r-29 {
  margin-right: 1.8125rem; }

.u-s-m-b-29 {
  margin-bottom: 1.8125rem; }

.u-s-m-l-29 {
  margin-left: 1.8125rem; }

.u-s-m-x-29 {
  margin-left: 1.8125rem;
  margin-right: 1.8125rem; }

.u-s-m-y-29 {
  margin-top: 1.8125rem;
  margin-bottom: 1.8125rem; }

.u-s-m-xy-29 {
  margin: 1.8125rem; }

/* Padding with value `29`. */
.u-s-p-t-29 {
  padding-top: 1.8125rem; }

.u-s-p-r-29 {
  padding-right: 1.8125rem; }

.u-s-p-b-29 {
  padding-bottom: 1.8125rem; }

.u-s-p-l-29 {
  padding-left: 1.8125rem; }

.u-s-p-x-29 {
  padding-left: 1.8125rem;
  padding-right: 1.8125rem; }

.u-s-p-y-29 {
  padding-top: 1.8125rem;
  padding-bottom: 1.8125rem; }

.u-s-p-xy-29 {
  padding: 1.8125rem; }

/* Margin with value `30`. */
.u-s-m-t-30 {
  margin-top: 1.875rem; }

.u-s-m-r-30 {
  margin-right: 1.875rem; }

.u-s-m-b-30 {
  margin-bottom: 1.875rem; }

.u-s-m-l-30 {
  margin-left: 1.875rem; }

.u-s-m-x-30 {
  margin-left: 1.875rem;
  margin-right: 1.875rem; }

.u-s-m-y-30 {
  margin-top: 1.875rem;
  margin-bottom: 1.875rem; }

.u-s-m-xy-30 {
  margin: 1.875rem; }

/* Padding with value `30`. */
.u-s-p-t-30 {
  padding-top: 1.875rem; }

.u-s-p-r-30 {
  padding-right: 1.875rem; }

.u-s-p-b-30 {
  padding-bottom: 1.875rem; }

.u-s-p-l-30 {
  padding-left: 1.875rem; }

.u-s-p-x-30 {
  padding-left: 1.875rem;
  padding-right: 1.875rem; }

.u-s-p-y-30 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }

.u-s-p-xy-30 {
  padding: 1.875rem; }

/* Margin with value `31`. */
.u-s-m-t-31 {
  margin-top: 1.9375rem; }

.u-s-m-r-31 {
  margin-right: 1.9375rem; }

.u-s-m-b-31 {
  margin-bottom: 1.9375rem; }

.u-s-m-l-31 {
  margin-left: 1.9375rem; }

.u-s-m-x-31 {
  margin-left: 1.9375rem;
  margin-right: 1.9375rem; }

.u-s-m-y-31 {
  margin-top: 1.9375rem;
  margin-bottom: 1.9375rem; }

.u-s-m-xy-31 {
  margin: 1.9375rem; }

/* Padding with value `31`. */
.u-s-p-t-31 {
  padding-top: 1.9375rem; }

.u-s-p-r-31 {
  padding-right: 1.9375rem; }

.u-s-p-b-31 {
  padding-bottom: 1.9375rem; }

.u-s-p-l-31 {
  padding-left: 1.9375rem; }

.u-s-p-x-31 {
  padding-left: 1.9375rem;
  padding-right: 1.9375rem; }

.u-s-p-y-31 {
  padding-top: 1.9375rem;
  padding-bottom: 1.9375rem; }

.u-s-p-xy-31 {
  padding: 1.9375rem; }

/* Margin with value `32`. */
.u-s-m-t-32 {
  margin-top: 2rem; }

.u-s-m-r-32 {
  margin-right: 2rem; }

.u-s-m-b-32 {
  margin-bottom: 2rem; }

.u-s-m-l-32 {
  margin-left: 2rem; }

.u-s-m-x-32 {
  margin-left: 2rem;
  margin-right: 2rem; }

.u-s-m-y-32 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.u-s-m-xy-32 {
  margin: 2rem; }

/* Padding with value `32`. */
.u-s-p-t-32 {
  padding-top: 2rem; }

.u-s-p-r-32 {
  padding-right: 2rem; }

.u-s-p-b-32 {
  padding-bottom: 2rem; }

.u-s-p-l-32 {
  padding-left: 2rem; }

.u-s-p-x-32 {
  padding-left: 2rem;
  padding-right: 2rem; }

.u-s-p-y-32 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.u-s-p-xy-32 {
  padding: 2rem; }

/* Margin with value `33`. */
.u-s-m-t-33 {
  margin-top: 2.0625rem; }

.u-s-m-r-33 {
  margin-right: 2.0625rem; }

.u-s-m-b-33 {
  margin-bottom: 2.0625rem; }

.u-s-m-l-33 {
  margin-left: 2.0625rem; }

.u-s-m-x-33 {
  margin-left: 2.0625rem;
  margin-right: 2.0625rem; }

.u-s-m-y-33 {
  margin-top: 2.0625rem;
  margin-bottom: 2.0625rem; }

.u-s-m-xy-33 {
  margin: 2.0625rem; }

/* Padding with value `33`. */
.u-s-p-t-33 {
  padding-top: 2.0625rem; }

.u-s-p-r-33 {
  padding-right: 2.0625rem; }

.u-s-p-b-33 {
  padding-bottom: 2.0625rem; }

.u-s-p-l-33 {
  padding-left: 2.0625rem; }

.u-s-p-x-33 {
  padding-left: 2.0625rem;
  padding-right: 2.0625rem; }

.u-s-p-y-33 {
  padding-top: 2.0625rem;
  padding-bottom: 2.0625rem; }

.u-s-p-xy-33 {
  padding: 2.0625rem; }

/* Margin with value `34`. */
.u-s-m-t-34 {
  margin-top: 2.125rem; }

.u-s-m-r-34 {
  margin-right: 2.125rem; }

.u-s-m-b-34 {
  margin-bottom: 2.125rem; }

.u-s-m-l-34 {
  margin-left: 2.125rem; }

.u-s-m-x-34 {
  margin-left: 2.125rem;
  margin-right: 2.125rem; }

.u-s-m-y-34 {
  margin-top: 2.125rem;
  margin-bottom: 2.125rem; }

.u-s-m-xy-34 {
  margin: 2.125rem; }

/* Padding with value `34`. */
.u-s-p-t-34 {
  padding-top: 2.125rem; }

.u-s-p-r-34 {
  padding-right: 2.125rem; }

.u-s-p-b-34 {
  padding-bottom: 2.125rem; }

.u-s-p-l-34 {
  padding-left: 2.125rem; }

.u-s-p-x-34 {
  padding-left: 2.125rem;
  padding-right: 2.125rem; }

.u-s-p-y-34 {
  padding-top: 2.125rem;
  padding-bottom: 2.125rem; }

.u-s-p-xy-34 {
  padding: 2.125rem; }

/* Margin with value `35`. */
.u-s-m-t-35 {
  margin-top: 2.1875rem; }

.u-s-m-r-35 {
  margin-right: 2.1875rem; }

.u-s-m-b-35 {
  margin-bottom: 2.1875rem; }

.u-s-m-l-35 {
  margin-left: 2.1875rem; }

.u-s-m-x-35 {
  margin-left: 2.1875rem;
  margin-right: 2.1875rem; }

.u-s-m-y-35 {
  margin-top: 2.1875rem;
  margin-bottom: 2.1875rem; }

.u-s-m-xy-35 {
  margin: 2.1875rem; }

/* Padding with value `35`. */
.u-s-p-t-35 {
  padding-top: 2.1875rem; }

.u-s-p-r-35 {
  padding-right: 2.1875rem; }

.u-s-p-b-35 {
  padding-bottom: 2.1875rem; }

.u-s-p-l-35 {
  padding-left: 2.1875rem; }

.u-s-p-x-35 {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem; }

.u-s-p-y-35 {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem; }

.u-s-p-xy-35 {
  padding: 2.1875rem; }

/* Margin with value `36`. */
.u-s-m-t-36 {
  margin-top: 2.25rem; }

.u-s-m-r-36 {
  margin-right: 2.25rem; }

.u-s-m-b-36 {
  margin-bottom: 2.25rem; }

.u-s-m-l-36 {
  margin-left: 2.25rem; }

.u-s-m-x-36 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.u-s-m-y-36 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.u-s-m-xy-36 {
  margin: 2.25rem; }

/* Padding with value `36`. */
.u-s-p-t-36 {
  padding-top: 2.25rem; }

.u-s-p-r-36 {
  padding-right: 2.25rem; }

.u-s-p-b-36 {
  padding-bottom: 2.25rem; }

.u-s-p-l-36 {
  padding-left: 2.25rem; }

.u-s-p-x-36 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.u-s-p-y-36 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.u-s-p-xy-36 {
  padding: 2.25rem; }

/* Margin with value `37`. */
.u-s-m-t-37 {
  margin-top: 2.3125rem; }

.u-s-m-r-37 {
  margin-right: 2.3125rem; }

.u-s-m-b-37 {
  margin-bottom: 2.3125rem; }

.u-s-m-l-37 {
  margin-left: 2.3125rem; }

.u-s-m-x-37 {
  margin-left: 2.3125rem;
  margin-right: 2.3125rem; }

.u-s-m-y-37 {
  margin-top: 2.3125rem;
  margin-bottom: 2.3125rem; }

.u-s-m-xy-37 {
  margin: 2.3125rem; }

/* Padding with value `37`. */
.u-s-p-t-37 {
  padding-top: 2.3125rem; }

.u-s-p-r-37 {
  padding-right: 2.3125rem; }

.u-s-p-b-37 {
  padding-bottom: 2.3125rem; }

.u-s-p-l-37 {
  padding-left: 2.3125rem; }

.u-s-p-x-37 {
  padding-left: 2.3125rem;
  padding-right: 2.3125rem; }

.u-s-p-y-37 {
  padding-top: 2.3125rem;
  padding-bottom: 2.3125rem; }

.u-s-p-xy-37 {
  padding: 2.3125rem; }

/* Margin with value `38`. */
.u-s-m-t-38 {
  margin-top: 2.375rem; }

.u-s-m-r-38 {
  margin-right: 2.375rem; }

.u-s-m-b-38 {
  margin-bottom: 2.375rem; }

.u-s-m-l-38 {
  margin-left: 2.375rem; }

.u-s-m-x-38 {
  margin-left: 2.375rem;
  margin-right: 2.375rem; }

.u-s-m-y-38 {
  margin-top: 2.375rem;
  margin-bottom: 2.375rem; }

.u-s-m-xy-38 {
  margin: 2.375rem; }

/* Padding with value `38`. */
.u-s-p-t-38 {
  padding-top: 2.375rem; }

.u-s-p-r-38 {
  padding-right: 2.375rem; }

.u-s-p-b-38 {
  padding-bottom: 2.375rem; }

.u-s-p-l-38 {
  padding-left: 2.375rem; }

.u-s-p-x-38 {
  padding-left: 2.375rem;
  padding-right: 2.375rem; }

.u-s-p-y-38 {
  padding-top: 2.375rem;
  padding-bottom: 2.375rem; }

.u-s-p-xy-38 {
  padding: 2.375rem; }

/* Margin with value `39`. */
.u-s-m-t-39 {
  margin-top: 2.4375rem; }

.u-s-m-r-39 {
  margin-right: 2.4375rem; }

.u-s-m-b-39 {
  margin-bottom: 2.4375rem; }

.u-s-m-l-39 {
  margin-left: 2.4375rem; }

.u-s-m-x-39 {
  margin-left: 2.4375rem;
  margin-right: 2.4375rem; }

.u-s-m-y-39 {
  margin-top: 2.4375rem;
  margin-bottom: 2.4375rem; }

.u-s-m-xy-39 {
  margin: 2.4375rem; }

/* Padding with value `39`. */
.u-s-p-t-39 {
  padding-top: 2.4375rem; }

.u-s-p-r-39 {
  padding-right: 2.4375rem; }

.u-s-p-b-39 {
  padding-bottom: 2.4375rem; }

.u-s-p-l-39 {
  padding-left: 2.4375rem; }

.u-s-p-x-39 {
  padding-left: 2.4375rem;
  padding-right: 2.4375rem; }

.u-s-p-y-39 {
  padding-top: 2.4375rem;
  padding-bottom: 2.4375rem; }

.u-s-p-xy-39 {
  padding: 2.4375rem; }

/* Margin with value `40`. */
.u-s-m-t-40 {
  margin-top: 2.5rem; }

.u-s-m-r-40 {
  margin-right: 2.5rem; }

.u-s-m-b-40 {
  margin-bottom: 2.5rem; }

.u-s-m-l-40 {
  margin-left: 2.5rem; }

.u-s-m-x-40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.u-s-m-y-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.u-s-m-xy-40 {
  margin: 2.5rem; }

/* Padding with value `40`. */
.u-s-p-t-40 {
  padding-top: 2.5rem; }

.u-s-p-r-40 {
  padding-right: 2.5rem; }

.u-s-p-b-40 {
  padding-bottom: 2.5rem; }

.u-s-p-l-40 {
  padding-left: 2.5rem; }

.u-s-p-x-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.u-s-p-y-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.u-s-p-xy-40 {
  padding: 2.5rem; }

/* Margin with value `41`. */
.u-s-m-t-41 {
  margin-top: 2.5625rem; }

.u-s-m-r-41 {
  margin-right: 2.5625rem; }

.u-s-m-b-41 {
  margin-bottom: 2.5625rem; }

.u-s-m-l-41 {
  margin-left: 2.5625rem; }

.u-s-m-x-41 {
  margin-left: 2.5625rem;
  margin-right: 2.5625rem; }

.u-s-m-y-41 {
  margin-top: 2.5625rem;
  margin-bottom: 2.5625rem; }

.u-s-m-xy-41 {
  margin: 2.5625rem; }

/* Padding with value `41`. */
.u-s-p-t-41 {
  padding-top: 2.5625rem; }

.u-s-p-r-41 {
  padding-right: 2.5625rem; }

.u-s-p-b-41 {
  padding-bottom: 2.5625rem; }

.u-s-p-l-41 {
  padding-left: 2.5625rem; }

.u-s-p-x-41 {
  padding-left: 2.5625rem;
  padding-right: 2.5625rem; }

.u-s-p-y-41 {
  padding-top: 2.5625rem;
  padding-bottom: 2.5625rem; }

.u-s-p-xy-41 {
  padding: 2.5625rem; }

/* Margin with value `42`. */
.u-s-m-t-42 {
  margin-top: 2.625rem; }

.u-s-m-r-42 {
  margin-right: 2.625rem; }

.u-s-m-b-42 {
  margin-bottom: 2.625rem; }

.u-s-m-l-42 {
  margin-left: 2.625rem; }

.u-s-m-x-42 {
  margin-left: 2.625rem;
  margin-right: 2.625rem; }

.u-s-m-y-42 {
  margin-top: 2.625rem;
  margin-bottom: 2.625rem; }

.u-s-m-xy-42 {
  margin: 2.625rem; }

/* Padding with value `42`. */
.u-s-p-t-42 {
  padding-top: 2.625rem; }

.u-s-p-r-42 {
  padding-right: 2.625rem; }

.u-s-p-b-42 {
  padding-bottom: 2.625rem; }

.u-s-p-l-42 {
  padding-left: 2.625rem; }

.u-s-p-x-42 {
  padding-left: 2.625rem;
  padding-right: 2.625rem; }

.u-s-p-y-42 {
  padding-top: 2.625rem;
  padding-bottom: 2.625rem; }

.u-s-p-xy-42 {
  padding: 2.625rem; }

/* Margin with value `43`. */
.u-s-m-t-43 {
  margin-top: 2.6875rem; }

.u-s-m-r-43 {
  margin-right: 2.6875rem; }

.u-s-m-b-43 {
  margin-bottom: 2.6875rem; }

.u-s-m-l-43 {
  margin-left: 2.6875rem; }

.u-s-m-x-43 {
  margin-left: 2.6875rem;
  margin-right: 2.6875rem; }

.u-s-m-y-43 {
  margin-top: 2.6875rem;
  margin-bottom: 2.6875rem; }

.u-s-m-xy-43 {
  margin: 2.6875rem; }

/* Padding with value `43`. */
.u-s-p-t-43 {
  padding-top: 2.6875rem; }

.u-s-p-r-43 {
  padding-right: 2.6875rem; }

.u-s-p-b-43 {
  padding-bottom: 2.6875rem; }

.u-s-p-l-43 {
  padding-left: 2.6875rem; }

.u-s-p-x-43 {
  padding-left: 2.6875rem;
  padding-right: 2.6875rem; }

.u-s-p-y-43 {
  padding-top: 2.6875rem;
  padding-bottom: 2.6875rem; }

.u-s-p-xy-43 {
  padding: 2.6875rem; }

/* Margin with value `44`. */
.u-s-m-t-44 {
  margin-top: 2.75rem; }

.u-s-m-r-44 {
  margin-right: 2.75rem; }

.u-s-m-b-44 {
  margin-bottom: 2.75rem; }

.u-s-m-l-44 {
  margin-left: 2.75rem; }

.u-s-m-x-44 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.u-s-m-y-44 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.u-s-m-xy-44 {
  margin: 2.75rem; }

/* Padding with value `44`. */
.u-s-p-t-44 {
  padding-top: 2.75rem; }

.u-s-p-r-44 {
  padding-right: 2.75rem; }

.u-s-p-b-44 {
  padding-bottom: 2.75rem; }

.u-s-p-l-44 {
  padding-left: 2.75rem; }

.u-s-p-x-44 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.u-s-p-y-44 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.u-s-p-xy-44 {
  padding: 2.75rem; }

/* Margin with value `45`. */
.u-s-m-t-45 {
  margin-top: 2.8125rem; }

.u-s-m-r-45 {
  margin-right: 2.8125rem; }

.u-s-m-b-45 {
  margin-bottom: 2.8125rem; }

.u-s-m-l-45 {
  margin-left: 2.8125rem; }

.u-s-m-x-45 {
  margin-left: 2.8125rem;
  margin-right: 2.8125rem; }

.u-s-m-y-45 {
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem; }

.u-s-m-xy-45 {
  margin: 2.8125rem; }

/* Padding with value `45`. */
.u-s-p-t-45 {
  padding-top: 2.8125rem; }

.u-s-p-r-45 {
  padding-right: 2.8125rem; }

.u-s-p-b-45 {
  padding-bottom: 2.8125rem; }

.u-s-p-l-45 {
  padding-left: 2.8125rem; }

.u-s-p-x-45 {
  padding-left: 2.8125rem;
  padding-right: 2.8125rem; }

.u-s-p-y-45 {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem; }

.u-s-p-xy-45 {
  padding: 2.8125rem; }

/* Margin with value `46`. */
.u-s-m-t-46 {
  margin-top: 2.875rem; }

.u-s-m-r-46 {
  margin-right: 2.875rem; }

.u-s-m-b-46 {
  margin-bottom: 2.875rem; }

.u-s-m-l-46 {
  margin-left: 2.875rem; }

.u-s-m-x-46 {
  margin-left: 2.875rem;
  margin-right: 2.875rem; }

.u-s-m-y-46 {
  margin-top: 2.875rem;
  margin-bottom: 2.875rem; }

.u-s-m-xy-46 {
  margin: 2.875rem; }

/* Padding with value `46`. */
.u-s-p-t-46 {
  padding-top: 2.875rem; }

.u-s-p-r-46 {
  padding-right: 2.875rem; }

.u-s-p-b-46 {
  padding-bottom: 2.875rem; }

.u-s-p-l-46 {
  padding-left: 2.875rem; }

.u-s-p-x-46 {
  padding-left: 2.875rem;
  padding-right: 2.875rem; }

.u-s-p-y-46 {
  padding-top: 2.875rem;
  padding-bottom: 2.875rem; }

.u-s-p-xy-46 {
  padding: 2.875rem; }

/* Margin with value `47`. */
.u-s-m-t-47 {
  margin-top: 2.9375rem; }

.u-s-m-r-47 {
  margin-right: 2.9375rem; }

.u-s-m-b-47 {
  margin-bottom: 2.9375rem; }

.u-s-m-l-47 {
  margin-left: 2.9375rem; }

.u-s-m-x-47 {
  margin-left: 2.9375rem;
  margin-right: 2.9375rem; }

.u-s-m-y-47 {
  margin-top: 2.9375rem;
  margin-bottom: 2.9375rem; }

.u-s-m-xy-47 {
  margin: 2.9375rem; }

/* Padding with value `47`. */
.u-s-p-t-47 {
  padding-top: 2.9375rem; }

.u-s-p-r-47 {
  padding-right: 2.9375rem; }

.u-s-p-b-47 {
  padding-bottom: 2.9375rem; }

.u-s-p-l-47 {
  padding-left: 2.9375rem; }

.u-s-p-x-47 {
  padding-left: 2.9375rem;
  padding-right: 2.9375rem; }

.u-s-p-y-47 {
  padding-top: 2.9375rem;
  padding-bottom: 2.9375rem; }

.u-s-p-xy-47 {
  padding: 2.9375rem; }

/* Margin with value `48`. */
.u-s-m-t-48 {
  margin-top: 3rem; }

.u-s-m-r-48 {
  margin-right: 3rem; }

.u-s-m-b-48 {
  margin-bottom: 3rem; }

.u-s-m-l-48 {
  margin-left: 3rem; }

.u-s-m-x-48 {
  margin-left: 3rem;
  margin-right: 3rem; }

.u-s-m-y-48 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.u-s-m-xy-48 {
  margin: 3rem; }

/* Padding with value `48`. */
.u-s-p-t-48 {
  padding-top: 3rem; }

.u-s-p-r-48 {
  padding-right: 3rem; }

.u-s-p-b-48 {
  padding-bottom: 3rem; }

.u-s-p-l-48 {
  padding-left: 3rem; }

.u-s-p-x-48 {
  padding-left: 3rem;
  padding-right: 3rem; }

.u-s-p-y-48 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.u-s-p-xy-48 {
  padding: 3rem; }

/* Margin with value `49`. */
.u-s-m-t-49 {
  margin-top: 3.0625rem; }

.u-s-m-r-49 {
  margin-right: 3.0625rem; }

.u-s-m-b-49 {
  margin-bottom: 3.0625rem; }

.u-s-m-l-49 {
  margin-left: 3.0625rem; }

.u-s-m-x-49 {
  margin-left: 3.0625rem;
  margin-right: 3.0625rem; }

.u-s-m-y-49 {
  margin-top: 3.0625rem;
  margin-bottom: 3.0625rem; }

.u-s-m-xy-49 {
  margin: 3.0625rem; }

/* Padding with value `49`. */
.u-s-p-t-49 {
  padding-top: 3.0625rem; }

.u-s-p-r-49 {
  padding-right: 3.0625rem; }

.u-s-p-b-49 {
  padding-bottom: 3.0625rem; }

.u-s-p-l-49 {
  padding-left: 3.0625rem; }

.u-s-p-x-49 {
  padding-left: 3.0625rem;
  padding-right: 3.0625rem; }

.u-s-p-y-49 {
  padding-top: 3.0625rem;
  padding-bottom: 3.0625rem; }

.u-s-p-xy-49 {
  padding: 3.0625rem; }

/* Margin with value `50`. */
.u-s-m-t-50 {
  margin-top: 3.125rem; }

.u-s-m-r-50 {
  margin-right: 3.125rem; }

.u-s-m-b-50 {
  margin-bottom: 3.125rem; }

.u-s-m-l-50 {
  margin-left: 3.125rem; }

.u-s-m-x-50 {
  margin-left: 3.125rem;
  margin-right: 3.125rem; }

.u-s-m-y-50 {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.u-s-m-xy-50 {
  margin: 3.125rem; }

/* Padding with value `50`. */
.u-s-p-t-50 {
  padding-top: 3.125rem; }

.u-s-p-r-50 {
  padding-right: 3.125rem; }

.u-s-p-b-50 {
  padding-bottom: 3.125rem; }

.u-s-p-l-50 {
  padding-left: 3.125rem; }

.u-s-p-x-50 {
  padding-left: 3.125rem;
  padding-right: 3.125rem; }

.u-s-p-y-50 {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem; }

.u-s-p-xy-50 {
  padding: 3.125rem; }

/* Margin with value `51`. */
.u-s-m-t-51 {
  margin-top: 3.1875rem; }

.u-s-m-r-51 {
  margin-right: 3.1875rem; }

.u-s-m-b-51 {
  margin-bottom: 3.1875rem; }

.u-s-m-l-51 {
  margin-left: 3.1875rem; }

.u-s-m-x-51 {
  margin-left: 3.1875rem;
  margin-right: 3.1875rem; }

.u-s-m-y-51 {
  margin-top: 3.1875rem;
  margin-bottom: 3.1875rem; }

.u-s-m-xy-51 {
  margin: 3.1875rem; }

/* Padding with value `51`. */
.u-s-p-t-51 {
  padding-top: 3.1875rem; }

.u-s-p-r-51 {
  padding-right: 3.1875rem; }

.u-s-p-b-51 {
  padding-bottom: 3.1875rem; }

.u-s-p-l-51 {
  padding-left: 3.1875rem; }

.u-s-p-x-51 {
  padding-left: 3.1875rem;
  padding-right: 3.1875rem; }

.u-s-p-y-51 {
  padding-top: 3.1875rem;
  padding-bottom: 3.1875rem; }

.u-s-p-xy-51 {
  padding: 3.1875rem; }

/* Margin with value `52`. */
.u-s-m-t-52 {
  margin-top: 3.25rem; }

.u-s-m-r-52 {
  margin-right: 3.25rem; }

.u-s-m-b-52 {
  margin-bottom: 3.25rem; }

.u-s-m-l-52 {
  margin-left: 3.25rem; }

.u-s-m-x-52 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.u-s-m-y-52 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.u-s-m-xy-52 {
  margin: 3.25rem; }

/* Padding with value `52`. */
.u-s-p-t-52 {
  padding-top: 3.25rem; }

.u-s-p-r-52 {
  padding-right: 3.25rem; }

.u-s-p-b-52 {
  padding-bottom: 3.25rem; }

.u-s-p-l-52 {
  padding-left: 3.25rem; }

.u-s-p-x-52 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.u-s-p-y-52 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.u-s-p-xy-52 {
  padding: 3.25rem; }

/* Margin with value `53`. */
.u-s-m-t-53 {
  margin-top: 3.3125rem; }

.u-s-m-r-53 {
  margin-right: 3.3125rem; }

.u-s-m-b-53 {
  margin-bottom: 3.3125rem; }

.u-s-m-l-53 {
  margin-left: 3.3125rem; }

.u-s-m-x-53 {
  margin-left: 3.3125rem;
  margin-right: 3.3125rem; }

.u-s-m-y-53 {
  margin-top: 3.3125rem;
  margin-bottom: 3.3125rem; }

.u-s-m-xy-53 {
  margin: 3.3125rem; }

/* Padding with value `53`. */
.u-s-p-t-53 {
  padding-top: 3.3125rem; }

.u-s-p-r-53 {
  padding-right: 3.3125rem; }

.u-s-p-b-53 {
  padding-bottom: 3.3125rem; }

.u-s-p-l-53 {
  padding-left: 3.3125rem; }

.u-s-p-x-53 {
  padding-left: 3.3125rem;
  padding-right: 3.3125rem; }

.u-s-p-y-53 {
  padding-top: 3.3125rem;
  padding-bottom: 3.3125rem; }

.u-s-p-xy-53 {
  padding: 3.3125rem; }

/* Margin with value `54`. */
.u-s-m-t-54 {
  margin-top: 3.375rem; }

.u-s-m-r-54 {
  margin-right: 3.375rem; }

.u-s-m-b-54 {
  margin-bottom: 3.375rem; }

.u-s-m-l-54 {
  margin-left: 3.375rem; }

.u-s-m-x-54 {
  margin-left: 3.375rem;
  margin-right: 3.375rem; }

.u-s-m-y-54 {
  margin-top: 3.375rem;
  margin-bottom: 3.375rem; }

.u-s-m-xy-54 {
  margin: 3.375rem; }

/* Padding with value `54`. */
.u-s-p-t-54 {
  padding-top: 3.375rem; }

.u-s-p-r-54 {
  padding-right: 3.375rem; }

.u-s-p-b-54 {
  padding-bottom: 3.375rem; }

.u-s-p-l-54 {
  padding-left: 3.375rem; }

.u-s-p-x-54 {
  padding-left: 3.375rem;
  padding-right: 3.375rem; }

.u-s-p-y-54 {
  padding-top: 3.375rem;
  padding-bottom: 3.375rem; }

.u-s-p-xy-54 {
  padding: 3.375rem; }

/* Margin with value `55`. */
.u-s-m-t-55 {
  margin-top: 3.4375rem; }

.u-s-m-r-55 {
  margin-right: 3.4375rem; }

.u-s-m-b-55 {
  margin-bottom: 3.4375rem; }

.u-s-m-l-55 {
  margin-left: 3.4375rem; }

.u-s-m-x-55 {
  margin-left: 3.4375rem;
  margin-right: 3.4375rem; }

.u-s-m-y-55 {
  margin-top: 3.4375rem;
  margin-bottom: 3.4375rem; }

.u-s-m-xy-55 {
  margin: 3.4375rem; }

/* Padding with value `55`. */
.u-s-p-t-55 {
  padding-top: 3.4375rem; }

.u-s-p-r-55 {
  padding-right: 3.4375rem; }

.u-s-p-b-55 {
  padding-bottom: 3.4375rem; }

.u-s-p-l-55 {
  padding-left: 3.4375rem; }

.u-s-p-x-55 {
  padding-left: 3.4375rem;
  padding-right: 3.4375rem; }

.u-s-p-y-55 {
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem; }

.u-s-p-xy-55 {
  padding: 3.4375rem; }

/* Margin with value `56`. */
.u-s-m-t-56 {
  margin-top: 3.5rem; }

.u-s-m-r-56 {
  margin-right: 3.5rem; }

.u-s-m-b-56 {
  margin-bottom: 3.5rem; }

.u-s-m-l-56 {
  margin-left: 3.5rem; }

.u-s-m-x-56 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.u-s-m-y-56 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.u-s-m-xy-56 {
  margin: 3.5rem; }

/* Padding with value `56`. */
.u-s-p-t-56 {
  padding-top: 3.5rem; }

.u-s-p-r-56 {
  padding-right: 3.5rem; }

.u-s-p-b-56 {
  padding-bottom: 3.5rem; }

.u-s-p-l-56 {
  padding-left: 3.5rem; }

.u-s-p-x-56 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.u-s-p-y-56 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.u-s-p-xy-56 {
  padding: 3.5rem; }

/* Margin with value `57`. */
.u-s-m-t-57 {
  margin-top: 3.5625rem; }

.u-s-m-r-57 {
  margin-right: 3.5625rem; }

.u-s-m-b-57 {
  margin-bottom: 3.5625rem; }

.u-s-m-l-57 {
  margin-left: 3.5625rem; }

.u-s-m-x-57 {
  margin-left: 3.5625rem;
  margin-right: 3.5625rem; }

.u-s-m-y-57 {
  margin-top: 3.5625rem;
  margin-bottom: 3.5625rem; }

.u-s-m-xy-57 {
  margin: 3.5625rem; }

/* Padding with value `57`. */
.u-s-p-t-57 {
  padding-top: 3.5625rem; }

.u-s-p-r-57 {
  padding-right: 3.5625rem; }

.u-s-p-b-57 {
  padding-bottom: 3.5625rem; }

.u-s-p-l-57 {
  padding-left: 3.5625rem; }

.u-s-p-x-57 {
  padding-left: 3.5625rem;
  padding-right: 3.5625rem; }

.u-s-p-y-57 {
  padding-top: 3.5625rem;
  padding-bottom: 3.5625rem; }

.u-s-p-xy-57 {
  padding: 3.5625rem; }

/* Margin with value `58`. */
.u-s-m-t-58 {
  margin-top: 3.625rem; }

.u-s-m-r-58 {
  margin-right: 3.625rem; }

.u-s-m-b-58 {
  margin-bottom: 3.625rem; }

.u-s-m-l-58 {
  margin-left: 3.625rem; }

.u-s-m-x-58 {
  margin-left: 3.625rem;
  margin-right: 3.625rem; }

.u-s-m-y-58 {
  margin-top: 3.625rem;
  margin-bottom: 3.625rem; }

.u-s-m-xy-58 {
  margin: 3.625rem; }

/* Padding with value `58`. */
.u-s-p-t-58 {
  padding-top: 3.625rem; }

.u-s-p-r-58 {
  padding-right: 3.625rem; }

.u-s-p-b-58 {
  padding-bottom: 3.625rem; }

.u-s-p-l-58 {
  padding-left: 3.625rem; }

.u-s-p-x-58 {
  padding-left: 3.625rem;
  padding-right: 3.625rem; }

.u-s-p-y-58 {
  padding-top: 3.625rem;
  padding-bottom: 3.625rem; }

.u-s-p-xy-58 {
  padding: 3.625rem; }

/* Margin with value `59`. */
.u-s-m-t-59 {
  margin-top: 3.6875rem; }

.u-s-m-r-59 {
  margin-right: 3.6875rem; }

.u-s-m-b-59 {
  margin-bottom: 3.6875rem; }

.u-s-m-l-59 {
  margin-left: 3.6875rem; }

.u-s-m-x-59 {
  margin-left: 3.6875rem;
  margin-right: 3.6875rem; }

.u-s-m-y-59 {
  margin-top: 3.6875rem;
  margin-bottom: 3.6875rem; }

.u-s-m-xy-59 {
  margin: 3.6875rem; }

/* Padding with value `59`. */
.u-s-p-t-59 {
  padding-top: 3.6875rem; }

.u-s-p-r-59 {
  padding-right: 3.6875rem; }

.u-s-p-b-59 {
  padding-bottom: 3.6875rem; }

.u-s-p-l-59 {
  padding-left: 3.6875rem; }

.u-s-p-x-59 {
  padding-left: 3.6875rem;
  padding-right: 3.6875rem; }

.u-s-p-y-59 {
  padding-top: 3.6875rem;
  padding-bottom: 3.6875rem; }

.u-s-p-xy-59 {
  padding: 3.6875rem; }

/* Margin with value `60`. */
.u-s-m-t-60 {
  margin-top: 3.75rem; }

.u-s-m-r-60 {
  margin-right: 3.75rem; }

.u-s-m-b-60 {
  margin-bottom: 3.75rem; }

.u-s-m-l-60 {
  margin-left: 3.75rem; }

.u-s-m-x-60 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.u-s-m-y-60 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.u-s-m-xy-60 {
  margin: 3.75rem; }

/* Padding with value `60`. */
.u-s-p-t-60 {
  padding-top: 3.75rem; }

.u-s-p-r-60 {
  padding-right: 3.75rem; }

.u-s-p-b-60 {
  padding-bottom: 3.75rem; }

.u-s-p-l-60 {
  padding-left: 3.75rem; }

.u-s-p-x-60 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.u-s-p-y-60 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.u-s-p-xy-60 {
  padding: 3.75rem; }

/* Margin with value `61`. */
.u-s-m-t-61 {
  margin-top: 3.8125rem; }

.u-s-m-r-61 {
  margin-right: 3.8125rem; }

.u-s-m-b-61 {
  margin-bottom: 3.8125rem; }

.u-s-m-l-61 {
  margin-left: 3.8125rem; }

.u-s-m-x-61 {
  margin-left: 3.8125rem;
  margin-right: 3.8125rem; }

.u-s-m-y-61 {
  margin-top: 3.8125rem;
  margin-bottom: 3.8125rem; }

.u-s-m-xy-61 {
  margin: 3.8125rem; }

/* Padding with value `61`. */
.u-s-p-t-61 {
  padding-top: 3.8125rem; }

.u-s-p-r-61 {
  padding-right: 3.8125rem; }

.u-s-p-b-61 {
  padding-bottom: 3.8125rem; }

.u-s-p-l-61 {
  padding-left: 3.8125rem; }

.u-s-p-x-61 {
  padding-left: 3.8125rem;
  padding-right: 3.8125rem; }

.u-s-p-y-61 {
  padding-top: 3.8125rem;
  padding-bottom: 3.8125rem; }

.u-s-p-xy-61 {
  padding: 3.8125rem; }

/* Margin with value `62`. */
.u-s-m-t-62 {
  margin-top: 3.875rem; }

.u-s-m-r-62 {
  margin-right: 3.875rem; }

.u-s-m-b-62 {
  margin-bottom: 3.875rem; }

.u-s-m-l-62 {
  margin-left: 3.875rem; }

.u-s-m-x-62 {
  margin-left: 3.875rem;
  margin-right: 3.875rem; }

.u-s-m-y-62 {
  margin-top: 3.875rem;
  margin-bottom: 3.875rem; }

.u-s-m-xy-62 {
  margin: 3.875rem; }

/* Padding with value `62`. */
.u-s-p-t-62 {
  padding-top: 3.875rem; }

.u-s-p-r-62 {
  padding-right: 3.875rem; }

.u-s-p-b-62 {
  padding-bottom: 3.875rem; }

.u-s-p-l-62 {
  padding-left: 3.875rem; }

.u-s-p-x-62 {
  padding-left: 3.875rem;
  padding-right: 3.875rem; }

.u-s-p-y-62 {
  padding-top: 3.875rem;
  padding-bottom: 3.875rem; }

.u-s-p-xy-62 {
  padding: 3.875rem; }

/* Margin with value `63`. */
.u-s-m-t-63 {
  margin-top: 3.9375rem; }

.u-s-m-r-63 {
  margin-right: 3.9375rem; }

.u-s-m-b-63 {
  margin-bottom: 3.9375rem; }

.u-s-m-l-63 {
  margin-left: 3.9375rem; }

.u-s-m-x-63 {
  margin-left: 3.9375rem;
  margin-right: 3.9375rem; }

.u-s-m-y-63 {
  margin-top: 3.9375rem;
  margin-bottom: 3.9375rem; }

.u-s-m-xy-63 {
  margin: 3.9375rem; }

/* Padding with value `63`. */
.u-s-p-t-63 {
  padding-top: 3.9375rem; }

.u-s-p-r-63 {
  padding-right: 3.9375rem; }

.u-s-p-b-63 {
  padding-bottom: 3.9375rem; }

.u-s-p-l-63 {
  padding-left: 3.9375rem; }

.u-s-p-x-63 {
  padding-left: 3.9375rem;
  padding-right: 3.9375rem; }

.u-s-p-y-63 {
  padding-top: 3.9375rem;
  padding-bottom: 3.9375rem; }

.u-s-p-xy-63 {
  padding: 3.9375rem; }

/* Margin with value `64`. */
.u-s-m-t-64 {
  margin-top: 4rem; }

.u-s-m-r-64 {
  margin-right: 4rem; }

.u-s-m-b-64 {
  margin-bottom: 4rem; }

.u-s-m-l-64 {
  margin-left: 4rem; }

.u-s-m-x-64 {
  margin-left: 4rem;
  margin-right: 4rem; }

.u-s-m-y-64 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.u-s-m-xy-64 {
  margin: 4rem; }

/* Padding with value `64`. */
.u-s-p-t-64 {
  padding-top: 4rem; }

.u-s-p-r-64 {
  padding-right: 4rem; }

.u-s-p-b-64 {
  padding-bottom: 4rem; }

.u-s-p-l-64 {
  padding-left: 4rem; }

.u-s-p-x-64 {
  padding-left: 4rem;
  padding-right: 4rem; }

.u-s-p-y-64 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.u-s-p-xy-64 {
  padding: 4rem; }

/* Margin with value `65`. */
.u-s-m-t-65 {
  margin-top: 4.0625rem; }

.u-s-m-r-65 {
  margin-right: 4.0625rem; }

.u-s-m-b-65 {
  margin-bottom: 4.0625rem; }

.u-s-m-l-65 {
  margin-left: 4.0625rem; }

.u-s-m-x-65 {
  margin-left: 4.0625rem;
  margin-right: 4.0625rem; }

.u-s-m-y-65 {
  margin-top: 4.0625rem;
  margin-bottom: 4.0625rem; }

.u-s-m-xy-65 {
  margin: 4.0625rem; }

/* Padding with value `65`. */
.u-s-p-t-65 {
  padding-top: 4.0625rem; }

.u-s-p-r-65 {
  padding-right: 4.0625rem; }

.u-s-p-b-65 {
  padding-bottom: 4.0625rem; }

.u-s-p-l-65 {
  padding-left: 4.0625rem; }

.u-s-p-x-65 {
  padding-left: 4.0625rem;
  padding-right: 4.0625rem; }

.u-s-p-y-65 {
  padding-top: 4.0625rem;
  padding-bottom: 4.0625rem; }

.u-s-p-xy-65 {
  padding: 4.0625rem; }

/* Margin with value `66`. */
.u-s-m-t-66 {
  margin-top: 4.125rem; }

.u-s-m-r-66 {
  margin-right: 4.125rem; }

.u-s-m-b-66 {
  margin-bottom: 4.125rem; }

.u-s-m-l-66 {
  margin-left: 4.125rem; }

.u-s-m-x-66 {
  margin-left: 4.125rem;
  margin-right: 4.125rem; }

.u-s-m-y-66 {
  margin-top: 4.125rem;
  margin-bottom: 4.125rem; }

.u-s-m-xy-66 {
  margin: 4.125rem; }

/* Padding with value `66`. */
.u-s-p-t-66 {
  padding-top: 4.125rem; }

.u-s-p-r-66 {
  padding-right: 4.125rem; }

.u-s-p-b-66 {
  padding-bottom: 4.125rem; }

.u-s-p-l-66 {
  padding-left: 4.125rem; }

.u-s-p-x-66 {
  padding-left: 4.125rem;
  padding-right: 4.125rem; }

.u-s-p-y-66 {
  padding-top: 4.125rem;
  padding-bottom: 4.125rem; }

.u-s-p-xy-66 {
  padding: 4.125rem; }

/* Margin with value `67`. */
.u-s-m-t-67 {
  margin-top: 4.1875rem; }

.u-s-m-r-67 {
  margin-right: 4.1875rem; }

.u-s-m-b-67 {
  margin-bottom: 4.1875rem; }

.u-s-m-l-67 {
  margin-left: 4.1875rem; }

.u-s-m-x-67 {
  margin-left: 4.1875rem;
  margin-right: 4.1875rem; }

.u-s-m-y-67 {
  margin-top: 4.1875rem;
  margin-bottom: 4.1875rem; }

.u-s-m-xy-67 {
  margin: 4.1875rem; }

/* Padding with value `67`. */
.u-s-p-t-67 {
  padding-top: 4.1875rem; }

.u-s-p-r-67 {
  padding-right: 4.1875rem; }

.u-s-p-b-67 {
  padding-bottom: 4.1875rem; }

.u-s-p-l-67 {
  padding-left: 4.1875rem; }

.u-s-p-x-67 {
  padding-left: 4.1875rem;
  padding-right: 4.1875rem; }

.u-s-p-y-67 {
  padding-top: 4.1875rem;
  padding-bottom: 4.1875rem; }

.u-s-p-xy-67 {
  padding: 4.1875rem; }

/* Margin with value `68`. */
.u-s-m-t-68 {
  margin-top: 4.25rem; }

.u-s-m-r-68 {
  margin-right: 4.25rem; }

.u-s-m-b-68 {
  margin-bottom: 4.25rem; }

.u-s-m-l-68 {
  margin-left: 4.25rem; }

.u-s-m-x-68 {
  margin-left: 4.25rem;
  margin-right: 4.25rem; }

.u-s-m-y-68 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem; }

.u-s-m-xy-68 {
  margin: 4.25rem; }

/* Padding with value `68`. */
.u-s-p-t-68 {
  padding-top: 4.25rem; }

.u-s-p-r-68 {
  padding-right: 4.25rem; }

.u-s-p-b-68 {
  padding-bottom: 4.25rem; }

.u-s-p-l-68 {
  padding-left: 4.25rem; }

.u-s-p-x-68 {
  padding-left: 4.25rem;
  padding-right: 4.25rem; }

.u-s-p-y-68 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem; }

.u-s-p-xy-68 {
  padding: 4.25rem; }

/* Margin with value `69`. */
.u-s-m-t-69 {
  margin-top: 4.3125rem; }

.u-s-m-r-69 {
  margin-right: 4.3125rem; }

.u-s-m-b-69 {
  margin-bottom: 4.3125rem; }

.u-s-m-l-69 {
  margin-left: 4.3125rem; }

.u-s-m-x-69 {
  margin-left: 4.3125rem;
  margin-right: 4.3125rem; }

.u-s-m-y-69 {
  margin-top: 4.3125rem;
  margin-bottom: 4.3125rem; }

.u-s-m-xy-69 {
  margin: 4.3125rem; }

/* Padding with value `69`. */
.u-s-p-t-69 {
  padding-top: 4.3125rem; }

.u-s-p-r-69 {
  padding-right: 4.3125rem; }

.u-s-p-b-69 {
  padding-bottom: 4.3125rem; }

.u-s-p-l-69 {
  padding-left: 4.3125rem; }

.u-s-p-x-69 {
  padding-left: 4.3125rem;
  padding-right: 4.3125rem; }

.u-s-p-y-69 {
  padding-top: 4.3125rem;
  padding-bottom: 4.3125rem; }

.u-s-p-xy-69 {
  padding: 4.3125rem; }

/* Margin with value `70`. */
.u-s-m-t-70 {
  margin-top: 4.375rem; }

.u-s-m-r-70 {
  margin-right: 4.375rem; }

.u-s-m-b-70 {
  margin-bottom: 4.375rem; }

.u-s-m-l-70 {
  margin-left: 4.375rem; }

.u-s-m-x-70 {
  margin-left: 4.375rem;
  margin-right: 4.375rem; }

.u-s-m-y-70 {
  margin-top: 4.375rem;
  margin-bottom: 4.375rem; }

.u-s-m-xy-70 {
  margin: 4.375rem; }

/* Padding with value `70`. */
.u-s-p-t-70 {
  padding-top: 4.375rem; }

.u-s-p-r-70 {
  padding-right: 4.375rem; }

.u-s-p-b-70 {
  padding-bottom: 4.375rem; }

.u-s-p-l-70 {
  padding-left: 4.375rem; }

.u-s-p-x-70 {
  padding-left: 4.375rem;
  padding-right: 4.375rem; }

.u-s-p-y-70 {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem; }

.u-s-p-xy-70 {
  padding: 4.375rem; }

/* Margin with value `71`. */
.u-s-m-t-71 {
  margin-top: 4.4375rem; }

.u-s-m-r-71 {
  margin-right: 4.4375rem; }

.u-s-m-b-71 {
  margin-bottom: 4.4375rem; }

.u-s-m-l-71 {
  margin-left: 4.4375rem; }

.u-s-m-x-71 {
  margin-left: 4.4375rem;
  margin-right: 4.4375rem; }

.u-s-m-y-71 {
  margin-top: 4.4375rem;
  margin-bottom: 4.4375rem; }

.u-s-m-xy-71 {
  margin: 4.4375rem; }

/* Padding with value `71`. */
.u-s-p-t-71 {
  padding-top: 4.4375rem; }

.u-s-p-r-71 {
  padding-right: 4.4375rem; }

.u-s-p-b-71 {
  padding-bottom: 4.4375rem; }

.u-s-p-l-71 {
  padding-left: 4.4375rem; }

.u-s-p-x-71 {
  padding-left: 4.4375rem;
  padding-right: 4.4375rem; }

.u-s-p-y-71 {
  padding-top: 4.4375rem;
  padding-bottom: 4.4375rem; }

.u-s-p-xy-71 {
  padding: 4.4375rem; }

/* Margin with value `72`. */
.u-s-m-t-72 {
  margin-top: 4.5rem; }

.u-s-m-r-72 {
  margin-right: 4.5rem; }

.u-s-m-b-72 {
  margin-bottom: 4.5rem; }

.u-s-m-l-72 {
  margin-left: 4.5rem; }

.u-s-m-x-72 {
  margin-left: 4.5rem;
  margin-right: 4.5rem; }

.u-s-m-y-72 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem; }

.u-s-m-xy-72 {
  margin: 4.5rem; }

/* Padding with value `72`. */
.u-s-p-t-72 {
  padding-top: 4.5rem; }

.u-s-p-r-72 {
  padding-right: 4.5rem; }

.u-s-p-b-72 {
  padding-bottom: 4.5rem; }

.u-s-p-l-72 {
  padding-left: 4.5rem; }

.u-s-p-x-72 {
  padding-left: 4.5rem;
  padding-right: 4.5rem; }

.u-s-p-y-72 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem; }

.u-s-p-xy-72 {
  padding: 4.5rem; }

/* Margin with value `73`. */
.u-s-m-t-73 {
  margin-top: 4.5625rem; }

.u-s-m-r-73 {
  margin-right: 4.5625rem; }

.u-s-m-b-73 {
  margin-bottom: 4.5625rem; }

.u-s-m-l-73 {
  margin-left: 4.5625rem; }

.u-s-m-x-73 {
  margin-left: 4.5625rem;
  margin-right: 4.5625rem; }

.u-s-m-y-73 {
  margin-top: 4.5625rem;
  margin-bottom: 4.5625rem; }

.u-s-m-xy-73 {
  margin: 4.5625rem; }

/* Padding with value `73`. */
.u-s-p-t-73 {
  padding-top: 4.5625rem; }

.u-s-p-r-73 {
  padding-right: 4.5625rem; }

.u-s-p-b-73 {
  padding-bottom: 4.5625rem; }

.u-s-p-l-73 {
  padding-left: 4.5625rem; }

.u-s-p-x-73 {
  padding-left: 4.5625rem;
  padding-right: 4.5625rem; }

.u-s-p-y-73 {
  padding-top: 4.5625rem;
  padding-bottom: 4.5625rem; }

.u-s-p-xy-73 {
  padding: 4.5625rem; }

/* Margin with value `74`. */
.u-s-m-t-74 {
  margin-top: 4.625rem; }

.u-s-m-r-74 {
  margin-right: 4.625rem; }

.u-s-m-b-74 {
  margin-bottom: 4.625rem; }

.u-s-m-l-74 {
  margin-left: 4.625rem; }

.u-s-m-x-74 {
  margin-left: 4.625rem;
  margin-right: 4.625rem; }

.u-s-m-y-74 {
  margin-top: 4.625rem;
  margin-bottom: 4.625rem; }

.u-s-m-xy-74 {
  margin: 4.625rem; }

/* Padding with value `74`. */
.u-s-p-t-74 {
  padding-top: 4.625rem; }

.u-s-p-r-74 {
  padding-right: 4.625rem; }

.u-s-p-b-74 {
  padding-bottom: 4.625rem; }

.u-s-p-l-74 {
  padding-left: 4.625rem; }

.u-s-p-x-74 {
  padding-left: 4.625rem;
  padding-right: 4.625rem; }

.u-s-p-y-74 {
  padding-top: 4.625rem;
  padding-bottom: 4.625rem; }

.u-s-p-xy-74 {
  padding: 4.625rem; }

/* Margin with value `75`. */
.u-s-m-t-75 {
  margin-top: 4.6875rem; }

.u-s-m-r-75 {
  margin-right: 4.6875rem; }

.u-s-m-b-75 {
  margin-bottom: 4.6875rem; }

.u-s-m-l-75 {
  margin-left: 4.6875rem; }

.u-s-m-x-75 {
  margin-left: 4.6875rem;
  margin-right: 4.6875rem; }

.u-s-m-y-75 {
  margin-top: 4.6875rem;
  margin-bottom: 4.6875rem; }

.u-s-m-xy-75 {
  margin: 4.6875rem; }

/* Padding with value `75`. */
.u-s-p-t-75 {
  padding-top: 4.6875rem; }

.u-s-p-r-75 {
  padding-right: 4.6875rem; }

.u-s-p-b-75 {
  padding-bottom: 4.6875rem; }

.u-s-p-l-75 {
  padding-left: 4.6875rem; }

.u-s-p-x-75 {
  padding-left: 4.6875rem;
  padding-right: 4.6875rem; }

.u-s-p-y-75 {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem; }

.u-s-p-xy-75 {
  padding: 4.6875rem; }

/* Margin with value `76`. */
.u-s-m-t-76 {
  margin-top: 4.75rem; }

.u-s-m-r-76 {
  margin-right: 4.75rem; }

.u-s-m-b-76 {
  margin-bottom: 4.75rem; }

.u-s-m-l-76 {
  margin-left: 4.75rem; }

.u-s-m-x-76 {
  margin-left: 4.75rem;
  margin-right: 4.75rem; }

.u-s-m-y-76 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem; }

.u-s-m-xy-76 {
  margin: 4.75rem; }

/* Padding with value `76`. */
.u-s-p-t-76 {
  padding-top: 4.75rem; }

.u-s-p-r-76 {
  padding-right: 4.75rem; }

.u-s-p-b-76 {
  padding-bottom: 4.75rem; }

.u-s-p-l-76 {
  padding-left: 4.75rem; }

.u-s-p-x-76 {
  padding-left: 4.75rem;
  padding-right: 4.75rem; }

.u-s-p-y-76 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem; }

.u-s-p-xy-76 {
  padding: 4.75rem; }

/* Margin with value `77`. */
.u-s-m-t-77 {
  margin-top: 4.8125rem; }

.u-s-m-r-77 {
  margin-right: 4.8125rem; }

.u-s-m-b-77 {
  margin-bottom: 4.8125rem; }

.u-s-m-l-77 {
  margin-left: 4.8125rem; }

.u-s-m-x-77 {
  margin-left: 4.8125rem;
  margin-right: 4.8125rem; }

.u-s-m-y-77 {
  margin-top: 4.8125rem;
  margin-bottom: 4.8125rem; }

.u-s-m-xy-77 {
  margin: 4.8125rem; }

/* Padding with value `77`. */
.u-s-p-t-77 {
  padding-top: 4.8125rem; }

.u-s-p-r-77 {
  padding-right: 4.8125rem; }

.u-s-p-b-77 {
  padding-bottom: 4.8125rem; }

.u-s-p-l-77 {
  padding-left: 4.8125rem; }

.u-s-p-x-77 {
  padding-left: 4.8125rem;
  padding-right: 4.8125rem; }

.u-s-p-y-77 {
  padding-top: 4.8125rem;
  padding-bottom: 4.8125rem; }

.u-s-p-xy-77 {
  padding: 4.8125rem; }

/* Margin with value `78`. */
.u-s-m-t-78 {
  margin-top: 4.875rem; }

.u-s-m-r-78 {
  margin-right: 4.875rem; }

.u-s-m-b-78 {
  margin-bottom: 4.875rem; }

.u-s-m-l-78 {
  margin-left: 4.875rem; }

.u-s-m-x-78 {
  margin-left: 4.875rem;
  margin-right: 4.875rem; }

.u-s-m-y-78 {
  margin-top: 4.875rem;
  margin-bottom: 4.875rem; }

.u-s-m-xy-78 {
  margin: 4.875rem; }

/* Padding with value `78`. */
.u-s-p-t-78 {
  padding-top: 4.875rem; }

.u-s-p-r-78 {
  padding-right: 4.875rem; }

.u-s-p-b-78 {
  padding-bottom: 4.875rem; }

.u-s-p-l-78 {
  padding-left: 4.875rem; }

.u-s-p-x-78 {
  padding-left: 4.875rem;
  padding-right: 4.875rem; }

.u-s-p-y-78 {
  padding-top: 4.875rem;
  padding-bottom: 4.875rem; }

.u-s-p-xy-78 {
  padding: 4.875rem; }

/* Margin with value `79`. */
.u-s-m-t-79 {
  margin-top: 4.9375rem; }

.u-s-m-r-79 {
  margin-right: 4.9375rem; }

.u-s-m-b-79 {
  margin-bottom: 4.9375rem; }

.u-s-m-l-79 {
  margin-left: 4.9375rem; }

.u-s-m-x-79 {
  margin-left: 4.9375rem;
  margin-right: 4.9375rem; }

.u-s-m-y-79 {
  margin-top: 4.9375rem;
  margin-bottom: 4.9375rem; }

.u-s-m-xy-79 {
  margin: 4.9375rem; }

/* Padding with value `79`. */
.u-s-p-t-79 {
  padding-top: 4.9375rem; }

.u-s-p-r-79 {
  padding-right: 4.9375rem; }

.u-s-p-b-79 {
  padding-bottom: 4.9375rem; }

.u-s-p-l-79 {
  padding-left: 4.9375rem; }

.u-s-p-x-79 {
  padding-left: 4.9375rem;
  padding-right: 4.9375rem; }

.u-s-p-y-79 {
  padding-top: 4.9375rem;
  padding-bottom: 4.9375rem; }

.u-s-p-xy-79 {
  padding: 4.9375rem; }

/* Margin with value `80`. */
.u-s-m-t-80 {
  margin-top: 5rem; }

.u-s-m-r-80 {
  margin-right: 5rem; }

.u-s-m-b-80 {
  margin-bottom: 5rem; }

.u-s-m-l-80 {
  margin-left: 5rem; }

.u-s-m-x-80 {
  margin-left: 5rem;
  margin-right: 5rem; }

.u-s-m-y-80 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.u-s-m-xy-80 {
  margin: 5rem; }

/* Padding with value `80`. */
.u-s-p-t-80 {
  padding-top: 5rem; }

.u-s-p-r-80 {
  padding-right: 5rem; }

.u-s-p-b-80 {
  padding-bottom: 5rem; }

.u-s-p-l-80 {
  padding-left: 5rem; }

.u-s-p-x-80 {
  padding-left: 5rem;
  padding-right: 5rem; }

.u-s-p-y-80 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.u-s-p-xy-80 {
  padding: 5rem; }

/* Margin with value `81`. */
.u-s-m-t-81 {
  margin-top: 5.0625rem; }

.u-s-m-r-81 {
  margin-right: 5.0625rem; }

.u-s-m-b-81 {
  margin-bottom: 5.0625rem; }

.u-s-m-l-81 {
  margin-left: 5.0625rem; }

.u-s-m-x-81 {
  margin-left: 5.0625rem;
  margin-right: 5.0625rem; }

.u-s-m-y-81 {
  margin-top: 5.0625rem;
  margin-bottom: 5.0625rem; }

.u-s-m-xy-81 {
  margin: 5.0625rem; }

/* Padding with value `81`. */
.u-s-p-t-81 {
  padding-top: 5.0625rem; }

.u-s-p-r-81 {
  padding-right: 5.0625rem; }

.u-s-p-b-81 {
  padding-bottom: 5.0625rem; }

.u-s-p-l-81 {
  padding-left: 5.0625rem; }

.u-s-p-x-81 {
  padding-left: 5.0625rem;
  padding-right: 5.0625rem; }

.u-s-p-y-81 {
  padding-top: 5.0625rem;
  padding-bottom: 5.0625rem; }

.u-s-p-xy-81 {
  padding: 5.0625rem; }

/* Margin with value `82`. */
.u-s-m-t-82 {
  margin-top: 5.125rem; }

.u-s-m-r-82 {
  margin-right: 5.125rem; }

.u-s-m-b-82 {
  margin-bottom: 5.125rem; }

.u-s-m-l-82 {
  margin-left: 5.125rem; }

.u-s-m-x-82 {
  margin-left: 5.125rem;
  margin-right: 5.125rem; }

.u-s-m-y-82 {
  margin-top: 5.125rem;
  margin-bottom: 5.125rem; }

.u-s-m-xy-82 {
  margin: 5.125rem; }

/* Padding with value `82`. */
.u-s-p-t-82 {
  padding-top: 5.125rem; }

.u-s-p-r-82 {
  padding-right: 5.125rem; }

.u-s-p-b-82 {
  padding-bottom: 5.125rem; }

.u-s-p-l-82 {
  padding-left: 5.125rem; }

.u-s-p-x-82 {
  padding-left: 5.125rem;
  padding-right: 5.125rem; }

.u-s-p-y-82 {
  padding-top: 5.125rem;
  padding-bottom: 5.125rem; }

.u-s-p-xy-82 {
  padding: 5.125rem; }

/* Margin with value `83`. */
.u-s-m-t-83 {
  margin-top: 5.1875rem; }

.u-s-m-r-83 {
  margin-right: 5.1875rem; }

.u-s-m-b-83 {
  margin-bottom: 5.1875rem; }

.u-s-m-l-83 {
  margin-left: 5.1875rem; }

.u-s-m-x-83 {
  margin-left: 5.1875rem;
  margin-right: 5.1875rem; }

.u-s-m-y-83 {
  margin-top: 5.1875rem;
  margin-bottom: 5.1875rem; }

.u-s-m-xy-83 {
  margin: 5.1875rem; }

/* Padding with value `83`. */
.u-s-p-t-83 {
  padding-top: 5.1875rem; }

.u-s-p-r-83 {
  padding-right: 5.1875rem; }

.u-s-p-b-83 {
  padding-bottom: 5.1875rem; }

.u-s-p-l-83 {
  padding-left: 5.1875rem; }

.u-s-p-x-83 {
  padding-left: 5.1875rem;
  padding-right: 5.1875rem; }

.u-s-p-y-83 {
  padding-top: 5.1875rem;
  padding-bottom: 5.1875rem; }

.u-s-p-xy-83 {
  padding: 5.1875rem; }

/* Margin with value `84`. */
.u-s-m-t-84 {
  margin-top: 5.25rem; }

.u-s-m-r-84 {
  margin-right: 5.25rem; }

.u-s-m-b-84 {
  margin-bottom: 5.25rem; }

.u-s-m-l-84 {
  margin-left: 5.25rem; }

.u-s-m-x-84 {
  margin-left: 5.25rem;
  margin-right: 5.25rem; }

.u-s-m-y-84 {
  margin-top: 5.25rem;
  margin-bottom: 5.25rem; }

.u-s-m-xy-84 {
  margin: 5.25rem; }

/* Padding with value `84`. */
.u-s-p-t-84 {
  padding-top: 5.25rem; }

.u-s-p-r-84 {
  padding-right: 5.25rem; }

.u-s-p-b-84 {
  padding-bottom: 5.25rem; }

.u-s-p-l-84 {
  padding-left: 5.25rem; }

.u-s-p-x-84 {
  padding-left: 5.25rem;
  padding-right: 5.25rem; }

.u-s-p-y-84 {
  padding-top: 5.25rem;
  padding-bottom: 5.25rem; }

.u-s-p-xy-84 {
  padding: 5.25rem; }

/* Margin with value `85`. */
.u-s-m-t-85 {
  margin-top: 5.3125rem; }

.u-s-m-r-85 {
  margin-right: 5.3125rem; }

.u-s-m-b-85 {
  margin-bottom: 5.3125rem; }

.u-s-m-l-85 {
  margin-left: 5.3125rem; }

.u-s-m-x-85 {
  margin-left: 5.3125rem;
  margin-right: 5.3125rem; }

.u-s-m-y-85 {
  margin-top: 5.3125rem;
  margin-bottom: 5.3125rem; }

.u-s-m-xy-85 {
  margin: 5.3125rem; }

/* Padding with value `85`. */
.u-s-p-t-85 {
  padding-top: 5.3125rem; }

.u-s-p-r-85 {
  padding-right: 5.3125rem; }

.u-s-p-b-85 {
  padding-bottom: 5.3125rem; }

.u-s-p-l-85 {
  padding-left: 5.3125rem; }

.u-s-p-x-85 {
  padding-left: 5.3125rem;
  padding-right: 5.3125rem; }

.u-s-p-y-85 {
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem; }

.u-s-p-xy-85 {
  padding: 5.3125rem; }

/* Margin with value `86`. */
.u-s-m-t-86 {
  margin-top: 5.375rem; }

.u-s-m-r-86 {
  margin-right: 5.375rem; }

.u-s-m-b-86 {
  margin-bottom: 5.375rem; }

.u-s-m-l-86 {
  margin-left: 5.375rem; }

.u-s-m-x-86 {
  margin-left: 5.375rem;
  margin-right: 5.375rem; }

.u-s-m-y-86 {
  margin-top: 5.375rem;
  margin-bottom: 5.375rem; }

.u-s-m-xy-86 {
  margin: 5.375rem; }

/* Padding with value `86`. */
.u-s-p-t-86 {
  padding-top: 5.375rem; }

.u-s-p-r-86 {
  padding-right: 5.375rem; }

.u-s-p-b-86 {
  padding-bottom: 5.375rem; }

.u-s-p-l-86 {
  padding-left: 5.375rem; }

.u-s-p-x-86 {
  padding-left: 5.375rem;
  padding-right: 5.375rem; }

.u-s-p-y-86 {
  padding-top: 5.375rem;
  padding-bottom: 5.375rem; }

.u-s-p-xy-86 {
  padding: 5.375rem; }

/* Margin with value `87`. */
.u-s-m-t-87 {
  margin-top: 5.4375rem; }

.u-s-m-r-87 {
  margin-right: 5.4375rem; }

.u-s-m-b-87 {
  margin-bottom: 5.4375rem; }

.u-s-m-l-87 {
  margin-left: 5.4375rem; }

.u-s-m-x-87 {
  margin-left: 5.4375rem;
  margin-right: 5.4375rem; }

.u-s-m-y-87 {
  margin-top: 5.4375rem;
  margin-bottom: 5.4375rem; }

.u-s-m-xy-87 {
  margin: 5.4375rem; }

/* Padding with value `87`. */
.u-s-p-t-87 {
  padding-top: 5.4375rem; }

.u-s-p-r-87 {
  padding-right: 5.4375rem; }

.u-s-p-b-87 {
  padding-bottom: 5.4375rem; }

.u-s-p-l-87 {
  padding-left: 5.4375rem; }

.u-s-p-x-87 {
  padding-left: 5.4375rem;
  padding-right: 5.4375rem; }

.u-s-p-y-87 {
  padding-top: 5.4375rem;
  padding-bottom: 5.4375rem; }

.u-s-p-xy-87 {
  padding: 5.4375rem; }

/* Margin with value `88`. */
.u-s-m-t-88 {
  margin-top: 5.5rem; }

.u-s-m-r-88 {
  margin-right: 5.5rem; }

.u-s-m-b-88 {
  margin-bottom: 5.5rem; }

.u-s-m-l-88 {
  margin-left: 5.5rem; }

.u-s-m-x-88 {
  margin-left: 5.5rem;
  margin-right: 5.5rem; }

.u-s-m-y-88 {
  margin-top: 5.5rem;
  margin-bottom: 5.5rem; }

.u-s-m-xy-88 {
  margin: 5.5rem; }

/* Padding with value `88`. */
.u-s-p-t-88 {
  padding-top: 5.5rem; }

.u-s-p-r-88 {
  padding-right: 5.5rem; }

.u-s-p-b-88 {
  padding-bottom: 5.5rem; }

.u-s-p-l-88 {
  padding-left: 5.5rem; }

.u-s-p-x-88 {
  padding-left: 5.5rem;
  padding-right: 5.5rem; }

.u-s-p-y-88 {
  padding-top: 5.5rem;
  padding-bottom: 5.5rem; }

.u-s-p-xy-88 {
  padding: 5.5rem; }

/* Margin with value `89`. */
.u-s-m-t-89 {
  margin-top: 5.5625rem; }

.u-s-m-r-89 {
  margin-right: 5.5625rem; }

.u-s-m-b-89 {
  margin-bottom: 5.5625rem; }

.u-s-m-l-89 {
  margin-left: 5.5625rem; }

.u-s-m-x-89 {
  margin-left: 5.5625rem;
  margin-right: 5.5625rem; }

.u-s-m-y-89 {
  margin-top: 5.5625rem;
  margin-bottom: 5.5625rem; }

.u-s-m-xy-89 {
  margin: 5.5625rem; }

/* Padding with value `89`. */
.u-s-p-t-89 {
  padding-top: 5.5625rem; }

.u-s-p-r-89 {
  padding-right: 5.5625rem; }

.u-s-p-b-89 {
  padding-bottom: 5.5625rem; }

.u-s-p-l-89 {
  padding-left: 5.5625rem; }

.u-s-p-x-89 {
  padding-left: 5.5625rem;
  padding-right: 5.5625rem; }

.u-s-p-y-89 {
  padding-top: 5.5625rem;
  padding-bottom: 5.5625rem; }

.u-s-p-xy-89 {
  padding: 5.5625rem; }

/* Margin with value `90`. */
.u-s-m-t-90 {
  margin-top: 5.625rem; }

.u-s-m-r-90 {
  margin-right: 5.625rem; }

.u-s-m-b-90 {
  margin-bottom: 5.625rem; }

.u-s-m-l-90 {
  margin-left: 5.625rem; }

.u-s-m-x-90 {
  margin-left: 5.625rem;
  margin-right: 5.625rem; }

.u-s-m-y-90 {
  margin-top: 5.625rem;
  margin-bottom: 5.625rem; }

.u-s-m-xy-90 {
  margin: 5.625rem; }

/* Padding with value `90`. */
.u-s-p-t-90 {
  padding-top: 5.625rem; }

.u-s-p-r-90 {
  padding-right: 5.625rem; }

.u-s-p-b-90 {
  padding-bottom: 5.625rem; }

.u-s-p-l-90 {
  padding-left: 5.625rem; }

.u-s-p-x-90 {
  padding-left: 5.625rem;
  padding-right: 5.625rem; }

.u-s-p-y-90 {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem; }

.u-s-p-xy-90 {
  padding: 5.625rem; }

/* Margin with value `91`. */
.u-s-m-t-91 {
  margin-top: 5.6875rem; }

.u-s-m-r-91 {
  margin-right: 5.6875rem; }

.u-s-m-b-91 {
  margin-bottom: 5.6875rem; }

.u-s-m-l-91 {
  margin-left: 5.6875rem; }

.u-s-m-x-91 {
  margin-left: 5.6875rem;
  margin-right: 5.6875rem; }

.u-s-m-y-91 {
  margin-top: 5.6875rem;
  margin-bottom: 5.6875rem; }

.u-s-m-xy-91 {
  margin: 5.6875rem; }

/* Padding with value `91`. */
.u-s-p-t-91 {
  padding-top: 5.6875rem; }

.u-s-p-r-91 {
  padding-right: 5.6875rem; }

.u-s-p-b-91 {
  padding-bottom: 5.6875rem; }

.u-s-p-l-91 {
  padding-left: 5.6875rem; }

.u-s-p-x-91 {
  padding-left: 5.6875rem;
  padding-right: 5.6875rem; }

.u-s-p-y-91 {
  padding-top: 5.6875rem;
  padding-bottom: 5.6875rem; }

.u-s-p-xy-91 {
  padding: 5.6875rem; }

/* Margin with value `92`. */
.u-s-m-t-92 {
  margin-top: 5.75rem; }

.u-s-m-r-92 {
  margin-right: 5.75rem; }

.u-s-m-b-92 {
  margin-bottom: 5.75rem; }

.u-s-m-l-92 {
  margin-left: 5.75rem; }

.u-s-m-x-92 {
  margin-left: 5.75rem;
  margin-right: 5.75rem; }

.u-s-m-y-92 {
  margin-top: 5.75rem;
  margin-bottom: 5.75rem; }

.u-s-m-xy-92 {
  margin: 5.75rem; }

/* Padding with value `92`. */
.u-s-p-t-92 {
  padding-top: 5.75rem; }

.u-s-p-r-92 {
  padding-right: 5.75rem; }

.u-s-p-b-92 {
  padding-bottom: 5.75rem; }

.u-s-p-l-92 {
  padding-left: 5.75rem; }

.u-s-p-x-92 {
  padding-left: 5.75rem;
  padding-right: 5.75rem; }

.u-s-p-y-92 {
  padding-top: 5.75rem;
  padding-bottom: 5.75rem; }

.u-s-p-xy-92 {
  padding: 5.75rem; }

/* Margin with value `93`. */
.u-s-m-t-93 {
  margin-top: 5.8125rem; }

.u-s-m-r-93 {
  margin-right: 5.8125rem; }

.u-s-m-b-93 {
  margin-bottom: 5.8125rem; }

.u-s-m-l-93 {
  margin-left: 5.8125rem; }

.u-s-m-x-93 {
  margin-left: 5.8125rem;
  margin-right: 5.8125rem; }

.u-s-m-y-93 {
  margin-top: 5.8125rem;
  margin-bottom: 5.8125rem; }

.u-s-m-xy-93 {
  margin: 5.8125rem; }

/* Padding with value `93`. */
.u-s-p-t-93 {
  padding-top: 5.8125rem; }

.u-s-p-r-93 {
  padding-right: 5.8125rem; }

.u-s-p-b-93 {
  padding-bottom: 5.8125rem; }

.u-s-p-l-93 {
  padding-left: 5.8125rem; }

.u-s-p-x-93 {
  padding-left: 5.8125rem;
  padding-right: 5.8125rem; }

.u-s-p-y-93 {
  padding-top: 5.8125rem;
  padding-bottom: 5.8125rem; }

.u-s-p-xy-93 {
  padding: 5.8125rem; }

/* Margin with value `94`. */
.u-s-m-t-94 {
  margin-top: 5.875rem; }

.u-s-m-r-94 {
  margin-right: 5.875rem; }

.u-s-m-b-94 {
  margin-bottom: 5.875rem; }

.u-s-m-l-94 {
  margin-left: 5.875rem; }

.u-s-m-x-94 {
  margin-left: 5.875rem;
  margin-right: 5.875rem; }

.u-s-m-y-94 {
  margin-top: 5.875rem;
  margin-bottom: 5.875rem; }

.u-s-m-xy-94 {
  margin: 5.875rem; }

/* Padding with value `94`. */
.u-s-p-t-94 {
  padding-top: 5.875rem; }

.u-s-p-r-94 {
  padding-right: 5.875rem; }

.u-s-p-b-94 {
  padding-bottom: 5.875rem; }

.u-s-p-l-94 {
  padding-left: 5.875rem; }

.u-s-p-x-94 {
  padding-left: 5.875rem;
  padding-right: 5.875rem; }

.u-s-p-y-94 {
  padding-top: 5.875rem;
  padding-bottom: 5.875rem; }

.u-s-p-xy-94 {
  padding: 5.875rem; }

/* Margin with value `95`. */
.u-s-m-t-95 {
  margin-top: 5.9375rem; }

.u-s-m-r-95 {
  margin-right: 5.9375rem; }

.u-s-m-b-95 {
  margin-bottom: 5.9375rem; }

.u-s-m-l-95 {
  margin-left: 5.9375rem; }

.u-s-m-x-95 {
  margin-left: 5.9375rem;
  margin-right: 5.9375rem; }

.u-s-m-y-95 {
  margin-top: 5.9375rem;
  margin-bottom: 5.9375rem; }

.u-s-m-xy-95 {
  margin: 5.9375rem; }

/* Padding with value `95`. */
.u-s-p-t-95 {
  padding-top: 5.9375rem; }

.u-s-p-r-95 {
  padding-right: 5.9375rem; }

.u-s-p-b-95 {
  padding-bottom: 5.9375rem; }

.u-s-p-l-95 {
  padding-left: 5.9375rem; }

.u-s-p-x-95 {
  padding-left: 5.9375rem;
  padding-right: 5.9375rem; }

.u-s-p-y-95 {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem; }

.u-s-p-xy-95 {
  padding: 5.9375rem; }

/* Margin with value `96`. */
.u-s-m-t-96 {
  margin-top: 6rem; }

.u-s-m-r-96 {
  margin-right: 6rem; }

.u-s-m-b-96 {
  margin-bottom: 6rem; }

.u-s-m-l-96 {
  margin-left: 6rem; }

.u-s-m-x-96 {
  margin-left: 6rem;
  margin-right: 6rem; }

.u-s-m-y-96 {
  margin-top: 6rem;
  margin-bottom: 6rem; }

.u-s-m-xy-96 {
  margin: 6rem; }

/* Padding with value `96`. */
.u-s-p-t-96 {
  padding-top: 6rem; }

.u-s-p-r-96 {
  padding-right: 6rem; }

.u-s-p-b-96 {
  padding-bottom: 6rem; }

.u-s-p-l-96 {
  padding-left: 6rem; }

.u-s-p-x-96 {
  padding-left: 6rem;
  padding-right: 6rem; }

.u-s-p-y-96 {
  padding-top: 6rem;
  padding-bottom: 6rem; }

.u-s-p-xy-96 {
  padding: 6rem; }

/* Margin with value `97`. */
.u-s-m-t-97 {
  margin-top: 6.0625rem; }

.u-s-m-r-97 {
  margin-right: 6.0625rem; }

.u-s-m-b-97 {
  margin-bottom: 6.0625rem; }

.u-s-m-l-97 {
  margin-left: 6.0625rem; }

.u-s-m-x-97 {
  margin-left: 6.0625rem;
  margin-right: 6.0625rem; }

.u-s-m-y-97 {
  margin-top: 6.0625rem;
  margin-bottom: 6.0625rem; }

.u-s-m-xy-97 {
  margin: 6.0625rem; }

/* Padding with value `97`. */
.u-s-p-t-97 {
  padding-top: 6.0625rem; }

.u-s-p-r-97 {
  padding-right: 6.0625rem; }

.u-s-p-b-97 {
  padding-bottom: 6.0625rem; }

.u-s-p-l-97 {
  padding-left: 6.0625rem; }

.u-s-p-x-97 {
  padding-left: 6.0625rem;
  padding-right: 6.0625rem; }

.u-s-p-y-97 {
  padding-top: 6.0625rem;
  padding-bottom: 6.0625rem; }

.u-s-p-xy-97 {
  padding: 6.0625rem; }

/* Margin with value `98`. */
.u-s-m-t-98 {
  margin-top: 6.125rem; }

.u-s-m-r-98 {
  margin-right: 6.125rem; }

.u-s-m-b-98 {
  margin-bottom: 6.125rem; }

.u-s-m-l-98 {
  margin-left: 6.125rem; }

.u-s-m-x-98 {
  margin-left: 6.125rem;
  margin-right: 6.125rem; }

.u-s-m-y-98 {
  margin-top: 6.125rem;
  margin-bottom: 6.125rem; }

.u-s-m-xy-98 {
  margin: 6.125rem; }

/* Padding with value `98`. */
.u-s-p-t-98 {
  padding-top: 6.125rem; }

.u-s-p-r-98 {
  padding-right: 6.125rem; }

.u-s-p-b-98 {
  padding-bottom: 6.125rem; }

.u-s-p-l-98 {
  padding-left: 6.125rem; }

.u-s-p-x-98 {
  padding-left: 6.125rem;
  padding-right: 6.125rem; }

.u-s-p-y-98 {
  padding-top: 6.125rem;
  padding-bottom: 6.125rem; }

.u-s-p-xy-98 {
  padding: 6.125rem; }

/* Margin with value `99`. */
.u-s-m-t-99 {
  margin-top: 6.1875rem; }

.u-s-m-r-99 {
  margin-right: 6.1875rem; }

.u-s-m-b-99 {
  margin-bottom: 6.1875rem; }

.u-s-m-l-99 {
  margin-left: 6.1875rem; }

.u-s-m-x-99 {
  margin-left: 6.1875rem;
  margin-right: 6.1875rem; }

.u-s-m-y-99 {
  margin-top: 6.1875rem;
  margin-bottom: 6.1875rem; }

.u-s-m-xy-99 {
  margin: 6.1875rem; }

/* Padding with value `99`. */
.u-s-p-t-99 {
  padding-top: 6.1875rem; }

.u-s-p-r-99 {
  padding-right: 6.1875rem; }

.u-s-p-b-99 {
  padding-bottom: 6.1875rem; }

.u-s-p-l-99 {
  padding-left: 6.1875rem; }

.u-s-p-x-99 {
  padding-left: 6.1875rem;
  padding-right: 6.1875rem; }

.u-s-p-y-99 {
  padding-top: 6.1875rem;
  padding-bottom: 6.1875rem; }

.u-s-p-xy-99 {
  padding: 6.1875rem; }

/* Margin with value `100`. */
.u-s-m-t-100 {
  margin-top: 6.25rem; }

.u-s-m-r-100 {
  margin-right: 6.25rem; }

.u-s-m-b-100 {
  margin-bottom: 6.25rem; }

.u-s-m-l-100 {
  margin-left: 6.25rem; }

.u-s-m-x-100 {
  margin-left: 6.25rem;
  margin-right: 6.25rem; }

.u-s-m-y-100 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem; }

.u-s-m-xy-100 {
  margin: 6.25rem; }

/* Padding with value `100`. */
.u-s-p-t-100 {
  padding-top: 6.25rem; }

.u-s-p-r-100 {
  padding-right: 6.25rem; }

.u-s-p-b-100 {
  padding-bottom: 6.25rem; }

.u-s-p-l-100 {
  padding-left: 6.25rem; }

.u-s-p-x-100 {
  padding-left: 6.25rem;
  padding-right: 6.25rem; }

.u-s-p-y-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem; }

.u-s-p-xy-100 {
  padding: 6.25rem; }

/* Margin with value `101`. */
.u-s-m-t-101 {
  margin-top: 6.3125rem; }

.u-s-m-r-101 {
  margin-right: 6.3125rem; }

.u-s-m-b-101 {
  margin-bottom: 6.3125rem; }

.u-s-m-l-101 {
  margin-left: 6.3125rem; }

.u-s-m-x-101 {
  margin-left: 6.3125rem;
  margin-right: 6.3125rem; }

.u-s-m-y-101 {
  margin-top: 6.3125rem;
  margin-bottom: 6.3125rem; }

.u-s-m-xy-101 {
  margin: 6.3125rem; }

/* Padding with value `101`. */
.u-s-p-t-101 {
  padding-top: 6.3125rem; }

.u-s-p-r-101 {
  padding-right: 6.3125rem; }

.u-s-p-b-101 {
  padding-bottom: 6.3125rem; }

.u-s-p-l-101 {
  padding-left: 6.3125rem; }

.u-s-p-x-101 {
  padding-left: 6.3125rem;
  padding-right: 6.3125rem; }

.u-s-p-y-101 {
  padding-top: 6.3125rem;
  padding-bottom: 6.3125rem; }

.u-s-p-xy-101 {
  padding: 6.3125rem; }

/* Margin with value `102`. */
.u-s-m-t-102 {
  margin-top: 6.375rem; }

.u-s-m-r-102 {
  margin-right: 6.375rem; }

.u-s-m-b-102 {
  margin-bottom: 6.375rem; }

.u-s-m-l-102 {
  margin-left: 6.375rem; }

.u-s-m-x-102 {
  margin-left: 6.375rem;
  margin-right: 6.375rem; }

.u-s-m-y-102 {
  margin-top: 6.375rem;
  margin-bottom: 6.375rem; }

.u-s-m-xy-102 {
  margin: 6.375rem; }

/* Padding with value `102`. */
.u-s-p-t-102 {
  padding-top: 6.375rem; }

.u-s-p-r-102 {
  padding-right: 6.375rem; }

.u-s-p-b-102 {
  padding-bottom: 6.375rem; }

.u-s-p-l-102 {
  padding-left: 6.375rem; }

.u-s-p-x-102 {
  padding-left: 6.375rem;
  padding-right: 6.375rem; }

.u-s-p-y-102 {
  padding-top: 6.375rem;
  padding-bottom: 6.375rem; }

.u-s-p-xy-102 {
  padding: 6.375rem; }

/* Margin with value `103`. */
.u-s-m-t-103 {
  margin-top: 6.4375rem; }

.u-s-m-r-103 {
  margin-right: 6.4375rem; }

.u-s-m-b-103 {
  margin-bottom: 6.4375rem; }

.u-s-m-l-103 {
  margin-left: 6.4375rem; }

.u-s-m-x-103 {
  margin-left: 6.4375rem;
  margin-right: 6.4375rem; }

.u-s-m-y-103 {
  margin-top: 6.4375rem;
  margin-bottom: 6.4375rem; }

.u-s-m-xy-103 {
  margin: 6.4375rem; }

/* Padding with value `103`. */
.u-s-p-t-103 {
  padding-top: 6.4375rem; }

.u-s-p-r-103 {
  padding-right: 6.4375rem; }

.u-s-p-b-103 {
  padding-bottom: 6.4375rem; }

.u-s-p-l-103 {
  padding-left: 6.4375rem; }

.u-s-p-x-103 {
  padding-left: 6.4375rem;
  padding-right: 6.4375rem; }

.u-s-p-y-103 {
  padding-top: 6.4375rem;
  padding-bottom: 6.4375rem; }

.u-s-p-xy-103 {
  padding: 6.4375rem; }

/* Margin with value `104`. */
.u-s-m-t-104 {
  margin-top: 6.5rem; }

.u-s-m-r-104 {
  margin-right: 6.5rem; }

.u-s-m-b-104 {
  margin-bottom: 6.5rem; }

.u-s-m-l-104 {
  margin-left: 6.5rem; }

.u-s-m-x-104 {
  margin-left: 6.5rem;
  margin-right: 6.5rem; }

.u-s-m-y-104 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem; }

.u-s-m-xy-104 {
  margin: 6.5rem; }

/* Padding with value `104`. */
.u-s-p-t-104 {
  padding-top: 6.5rem; }

.u-s-p-r-104 {
  padding-right: 6.5rem; }

.u-s-p-b-104 {
  padding-bottom: 6.5rem; }

.u-s-p-l-104 {
  padding-left: 6.5rem; }

.u-s-p-x-104 {
  padding-left: 6.5rem;
  padding-right: 6.5rem; }

.u-s-p-y-104 {
  padding-top: 6.5rem;
  padding-bottom: 6.5rem; }

.u-s-p-xy-104 {
  padding: 6.5rem; }

/* Margin with value `105`. */
.u-s-m-t-105 {
  margin-top: 6.5625rem; }

.u-s-m-r-105 {
  margin-right: 6.5625rem; }

.u-s-m-b-105 {
  margin-bottom: 6.5625rem; }

.u-s-m-l-105 {
  margin-left: 6.5625rem; }

.u-s-m-x-105 {
  margin-left: 6.5625rem;
  margin-right: 6.5625rem; }

.u-s-m-y-105 {
  margin-top: 6.5625rem;
  margin-bottom: 6.5625rem; }

.u-s-m-xy-105 {
  margin: 6.5625rem; }

/* Padding with value `105`. */
.u-s-p-t-105 {
  padding-top: 6.5625rem; }

.u-s-p-r-105 {
  padding-right: 6.5625rem; }

.u-s-p-b-105 {
  padding-bottom: 6.5625rem; }

.u-s-p-l-105 {
  padding-left: 6.5625rem; }

.u-s-p-x-105 {
  padding-left: 6.5625rem;
  padding-right: 6.5625rem; }

.u-s-p-y-105 {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem; }

.u-s-p-xy-105 {
  padding: 6.5625rem; }

/* Margin with value `106`. */
.u-s-m-t-106 {
  margin-top: 6.625rem; }

.u-s-m-r-106 {
  margin-right: 6.625rem; }

.u-s-m-b-106 {
  margin-bottom: 6.625rem; }

.u-s-m-l-106 {
  margin-left: 6.625rem; }

.u-s-m-x-106 {
  margin-left: 6.625rem;
  margin-right: 6.625rem; }

.u-s-m-y-106 {
  margin-top: 6.625rem;
  margin-bottom: 6.625rem; }

.u-s-m-xy-106 {
  margin: 6.625rem; }

/* Padding with value `106`. */
.u-s-p-t-106 {
  padding-top: 6.625rem; }

.u-s-p-r-106 {
  padding-right: 6.625rem; }

.u-s-p-b-106 {
  padding-bottom: 6.625rem; }

.u-s-p-l-106 {
  padding-left: 6.625rem; }

.u-s-p-x-106 {
  padding-left: 6.625rem;
  padding-right: 6.625rem; }

.u-s-p-y-106 {
  padding-top: 6.625rem;
  padding-bottom: 6.625rem; }

.u-s-p-xy-106 {
  padding: 6.625rem; }

/* Margin with value `107`. */
.u-s-m-t-107 {
  margin-top: 6.6875rem; }

.u-s-m-r-107 {
  margin-right: 6.6875rem; }

.u-s-m-b-107 {
  margin-bottom: 6.6875rem; }

.u-s-m-l-107 {
  margin-left: 6.6875rem; }

.u-s-m-x-107 {
  margin-left: 6.6875rem;
  margin-right: 6.6875rem; }

.u-s-m-y-107 {
  margin-top: 6.6875rem;
  margin-bottom: 6.6875rem; }

.u-s-m-xy-107 {
  margin: 6.6875rem; }

/* Padding with value `107`. */
.u-s-p-t-107 {
  padding-top: 6.6875rem; }

.u-s-p-r-107 {
  padding-right: 6.6875rem; }

.u-s-p-b-107 {
  padding-bottom: 6.6875rem; }

.u-s-p-l-107 {
  padding-left: 6.6875rem; }

.u-s-p-x-107 {
  padding-left: 6.6875rem;
  padding-right: 6.6875rem; }

.u-s-p-y-107 {
  padding-top: 6.6875rem;
  padding-bottom: 6.6875rem; }

.u-s-p-xy-107 {
  padding: 6.6875rem; }

/* Margin with value `108`. */
.u-s-m-t-108 {
  margin-top: 6.75rem; }

.u-s-m-r-108 {
  margin-right: 6.75rem; }

.u-s-m-b-108 {
  margin-bottom: 6.75rem; }

.u-s-m-l-108 {
  margin-left: 6.75rem; }

.u-s-m-x-108 {
  margin-left: 6.75rem;
  margin-right: 6.75rem; }

.u-s-m-y-108 {
  margin-top: 6.75rem;
  margin-bottom: 6.75rem; }

.u-s-m-xy-108 {
  margin: 6.75rem; }

/* Padding with value `108`. */
.u-s-p-t-108 {
  padding-top: 6.75rem; }

.u-s-p-r-108 {
  padding-right: 6.75rem; }

.u-s-p-b-108 {
  padding-bottom: 6.75rem; }

.u-s-p-l-108 {
  padding-left: 6.75rem; }

.u-s-p-x-108 {
  padding-left: 6.75rem;
  padding-right: 6.75rem; }

.u-s-p-y-108 {
  padding-top: 6.75rem;
  padding-bottom: 6.75rem; }

.u-s-p-xy-108 {
  padding: 6.75rem; }

/* Margin with value `109`. */
.u-s-m-t-109 {
  margin-top: 6.8125rem; }

.u-s-m-r-109 {
  margin-right: 6.8125rem; }

.u-s-m-b-109 {
  margin-bottom: 6.8125rem; }

.u-s-m-l-109 {
  margin-left: 6.8125rem; }

.u-s-m-x-109 {
  margin-left: 6.8125rem;
  margin-right: 6.8125rem; }

.u-s-m-y-109 {
  margin-top: 6.8125rem;
  margin-bottom: 6.8125rem; }

.u-s-m-xy-109 {
  margin: 6.8125rem; }

/* Padding with value `109`. */
.u-s-p-t-109 {
  padding-top: 6.8125rem; }

.u-s-p-r-109 {
  padding-right: 6.8125rem; }

.u-s-p-b-109 {
  padding-bottom: 6.8125rem; }

.u-s-p-l-109 {
  padding-left: 6.8125rem; }

.u-s-p-x-109 {
  padding-left: 6.8125rem;
  padding-right: 6.8125rem; }

.u-s-p-y-109 {
  padding-top: 6.8125rem;
  padding-bottom: 6.8125rem; }

.u-s-p-xy-109 {
  padding: 6.8125rem; }

/* Margin with value `110`. */
.u-s-m-t-110 {
  margin-top: 6.875rem; }

.u-s-m-r-110 {
  margin-right: 6.875rem; }

.u-s-m-b-110 {
  margin-bottom: 6.875rem; }

.u-s-m-l-110 {
  margin-left: 6.875rem; }

.u-s-m-x-110 {
  margin-left: 6.875rem;
  margin-right: 6.875rem; }

.u-s-m-y-110 {
  margin-top: 6.875rem;
  margin-bottom: 6.875rem; }

.u-s-m-xy-110 {
  margin: 6.875rem; }

/* Padding with value `110`. */
.u-s-p-t-110 {
  padding-top: 6.875rem; }

.u-s-p-r-110 {
  padding-right: 6.875rem; }

.u-s-p-b-110 {
  padding-bottom: 6.875rem; }

.u-s-p-l-110 {
  padding-left: 6.875rem; }

.u-s-p-x-110 {
  padding-left: 6.875rem;
  padding-right: 6.875rem; }

.u-s-p-y-110 {
  padding-top: 6.875rem;
  padding-bottom: 6.875rem; }

.u-s-p-xy-110 {
  padding: 6.875rem; }

/* Margin with value `111`. */
.u-s-m-t-111 {
  margin-top: 6.9375rem; }

.u-s-m-r-111 {
  margin-right: 6.9375rem; }

.u-s-m-b-111 {
  margin-bottom: 6.9375rem; }

.u-s-m-l-111 {
  margin-left: 6.9375rem; }

.u-s-m-x-111 {
  margin-left: 6.9375rem;
  margin-right: 6.9375rem; }

.u-s-m-y-111 {
  margin-top: 6.9375rem;
  margin-bottom: 6.9375rem; }

.u-s-m-xy-111 {
  margin: 6.9375rem; }

/* Padding with value `111`. */
.u-s-p-t-111 {
  padding-top: 6.9375rem; }

.u-s-p-r-111 {
  padding-right: 6.9375rem; }

.u-s-p-b-111 {
  padding-bottom: 6.9375rem; }

.u-s-p-l-111 {
  padding-left: 6.9375rem; }

.u-s-p-x-111 {
  padding-left: 6.9375rem;
  padding-right: 6.9375rem; }

.u-s-p-y-111 {
  padding-top: 6.9375rem;
  padding-bottom: 6.9375rem; }

.u-s-p-xy-111 {
  padding: 6.9375rem; }

/* Margin with value `112`. */
.u-s-m-t-112 {
  margin-top: 7rem; }

.u-s-m-r-112 {
  margin-right: 7rem; }

.u-s-m-b-112 {
  margin-bottom: 7rem; }

.u-s-m-l-112 {
  margin-left: 7rem; }

.u-s-m-x-112 {
  margin-left: 7rem;
  margin-right: 7rem; }

.u-s-m-y-112 {
  margin-top: 7rem;
  margin-bottom: 7rem; }

.u-s-m-xy-112 {
  margin: 7rem; }

/* Padding with value `112`. */
.u-s-p-t-112 {
  padding-top: 7rem; }

.u-s-p-r-112 {
  padding-right: 7rem; }

.u-s-p-b-112 {
  padding-bottom: 7rem; }

.u-s-p-l-112 {
  padding-left: 7rem; }

.u-s-p-x-112 {
  padding-left: 7rem;
  padding-right: 7rem; }

.u-s-p-y-112 {
  padding-top: 7rem;
  padding-bottom: 7rem; }

.u-s-p-xy-112 {
  padding: 7rem; }

/* Margin with value `113`. */
.u-s-m-t-113 {
  margin-top: 7.0625rem; }

.u-s-m-r-113 {
  margin-right: 7.0625rem; }

.u-s-m-b-113 {
  margin-bottom: 7.0625rem; }

.u-s-m-l-113 {
  margin-left: 7.0625rem; }

.u-s-m-x-113 {
  margin-left: 7.0625rem;
  margin-right: 7.0625rem; }

.u-s-m-y-113 {
  margin-top: 7.0625rem;
  margin-bottom: 7.0625rem; }

.u-s-m-xy-113 {
  margin: 7.0625rem; }

/* Padding with value `113`. */
.u-s-p-t-113 {
  padding-top: 7.0625rem; }

.u-s-p-r-113 {
  padding-right: 7.0625rem; }

.u-s-p-b-113 {
  padding-bottom: 7.0625rem; }

.u-s-p-l-113 {
  padding-left: 7.0625rem; }

.u-s-p-x-113 {
  padding-left: 7.0625rem;
  padding-right: 7.0625rem; }

.u-s-p-y-113 {
  padding-top: 7.0625rem;
  padding-bottom: 7.0625rem; }

.u-s-p-xy-113 {
  padding: 7.0625rem; }

/* Margin with value `114`. */
.u-s-m-t-114 {
  margin-top: 7.125rem; }

.u-s-m-r-114 {
  margin-right: 7.125rem; }

.u-s-m-b-114 {
  margin-bottom: 7.125rem; }

.u-s-m-l-114 {
  margin-left: 7.125rem; }

.u-s-m-x-114 {
  margin-left: 7.125rem;
  margin-right: 7.125rem; }

.u-s-m-y-114 {
  margin-top: 7.125rem;
  margin-bottom: 7.125rem; }

.u-s-m-xy-114 {
  margin: 7.125rem; }

/* Padding with value `114`. */
.u-s-p-t-114 {
  padding-top: 7.125rem; }

.u-s-p-r-114 {
  padding-right: 7.125rem; }

.u-s-p-b-114 {
  padding-bottom: 7.125rem; }

.u-s-p-l-114 {
  padding-left: 7.125rem; }

.u-s-p-x-114 {
  padding-left: 7.125rem;
  padding-right: 7.125rem; }

.u-s-p-y-114 {
  padding-top: 7.125rem;
  padding-bottom: 7.125rem; }

.u-s-p-xy-114 {
  padding: 7.125rem; }

/* Margin with value `115`. */
.u-s-m-t-115 {
  margin-top: 7.1875rem; }

.u-s-m-r-115 {
  margin-right: 7.1875rem; }

.u-s-m-b-115 {
  margin-bottom: 7.1875rem; }

.u-s-m-l-115 {
  margin-left: 7.1875rem; }

.u-s-m-x-115 {
  margin-left: 7.1875rem;
  margin-right: 7.1875rem; }

.u-s-m-y-115 {
  margin-top: 7.1875rem;
  margin-bottom: 7.1875rem; }

.u-s-m-xy-115 {
  margin: 7.1875rem; }

/* Padding with value `115`. */
.u-s-p-t-115 {
  padding-top: 7.1875rem; }

.u-s-p-r-115 {
  padding-right: 7.1875rem; }

.u-s-p-b-115 {
  padding-bottom: 7.1875rem; }

.u-s-p-l-115 {
  padding-left: 7.1875rem; }

.u-s-p-x-115 {
  padding-left: 7.1875rem;
  padding-right: 7.1875rem; }

.u-s-p-y-115 {
  padding-top: 7.1875rem;
  padding-bottom: 7.1875rem; }

.u-s-p-xy-115 {
  padding: 7.1875rem; }

/* Margin with value `116`. */
.u-s-m-t-116 {
  margin-top: 7.25rem; }

.u-s-m-r-116 {
  margin-right: 7.25rem; }

.u-s-m-b-116 {
  margin-bottom: 7.25rem; }

.u-s-m-l-116 {
  margin-left: 7.25rem; }

.u-s-m-x-116 {
  margin-left: 7.25rem;
  margin-right: 7.25rem; }

.u-s-m-y-116 {
  margin-top: 7.25rem;
  margin-bottom: 7.25rem; }

.u-s-m-xy-116 {
  margin: 7.25rem; }

/* Padding with value `116`. */
.u-s-p-t-116 {
  padding-top: 7.25rem; }

.u-s-p-r-116 {
  padding-right: 7.25rem; }

.u-s-p-b-116 {
  padding-bottom: 7.25rem; }

.u-s-p-l-116 {
  padding-left: 7.25rem; }

.u-s-p-x-116 {
  padding-left: 7.25rem;
  padding-right: 7.25rem; }

.u-s-p-y-116 {
  padding-top: 7.25rem;
  padding-bottom: 7.25rem; }

.u-s-p-xy-116 {
  padding: 7.25rem; }

/* Margin with value `117`. */
.u-s-m-t-117 {
  margin-top: 7.3125rem; }

.u-s-m-r-117 {
  margin-right: 7.3125rem; }

.u-s-m-b-117 {
  margin-bottom: 7.3125rem; }

.u-s-m-l-117 {
  margin-left: 7.3125rem; }

.u-s-m-x-117 {
  margin-left: 7.3125rem;
  margin-right: 7.3125rem; }

.u-s-m-y-117 {
  margin-top: 7.3125rem;
  margin-bottom: 7.3125rem; }

.u-s-m-xy-117 {
  margin: 7.3125rem; }

/* Padding with value `117`. */
.u-s-p-t-117 {
  padding-top: 7.3125rem; }

.u-s-p-r-117 {
  padding-right: 7.3125rem; }

.u-s-p-b-117 {
  padding-bottom: 7.3125rem; }

.u-s-p-l-117 {
  padding-left: 7.3125rem; }

.u-s-p-x-117 {
  padding-left: 7.3125rem;
  padding-right: 7.3125rem; }

.u-s-p-y-117 {
  padding-top: 7.3125rem;
  padding-bottom: 7.3125rem; }

.u-s-p-xy-117 {
  padding: 7.3125rem; }

/* Margin with value `118`. */
.u-s-m-t-118 {
  margin-top: 7.375rem; }

.u-s-m-r-118 {
  margin-right: 7.375rem; }

.u-s-m-b-118 {
  margin-bottom: 7.375rem; }

.u-s-m-l-118 {
  margin-left: 7.375rem; }

.u-s-m-x-118 {
  margin-left: 7.375rem;
  margin-right: 7.375rem; }

.u-s-m-y-118 {
  margin-top: 7.375rem;
  margin-bottom: 7.375rem; }

.u-s-m-xy-118 {
  margin: 7.375rem; }

/* Padding with value `118`. */
.u-s-p-t-118 {
  padding-top: 7.375rem; }

.u-s-p-r-118 {
  padding-right: 7.375rem; }

.u-s-p-b-118 {
  padding-bottom: 7.375rem; }

.u-s-p-l-118 {
  padding-left: 7.375rem; }

.u-s-p-x-118 {
  padding-left: 7.375rem;
  padding-right: 7.375rem; }

.u-s-p-y-118 {
  padding-top: 7.375rem;
  padding-bottom: 7.375rem; }

.u-s-p-xy-118 {
  padding: 7.375rem; }

/* Margin with value `119`. */
.u-s-m-t-119 {
  margin-top: 7.4375rem; }

.u-s-m-r-119 {
  margin-right: 7.4375rem; }

.u-s-m-b-119 {
  margin-bottom: 7.4375rem; }

.u-s-m-l-119 {
  margin-left: 7.4375rem; }

.u-s-m-x-119 {
  margin-left: 7.4375rem;
  margin-right: 7.4375rem; }

.u-s-m-y-119 {
  margin-top: 7.4375rem;
  margin-bottom: 7.4375rem; }

.u-s-m-xy-119 {
  margin: 7.4375rem; }

/* Padding with value `119`. */
.u-s-p-t-119 {
  padding-top: 7.4375rem; }

.u-s-p-r-119 {
  padding-right: 7.4375rem; }

.u-s-p-b-119 {
  padding-bottom: 7.4375rem; }

.u-s-p-l-119 {
  padding-left: 7.4375rem; }

.u-s-p-x-119 {
  padding-left: 7.4375rem;
  padding-right: 7.4375rem; }

.u-s-p-y-119 {
  padding-top: 7.4375rem;
  padding-bottom: 7.4375rem; }

.u-s-p-xy-119 {
  padding: 7.4375rem; }

/* Margin with value `120`. */
.u-s-m-t-120 {
  margin-top: 7.5rem; }

.u-s-m-r-120 {
  margin-right: 7.5rem; }

.u-s-m-b-120 {
  margin-bottom: 7.5rem; }

.u-s-m-l-120 {
  margin-left: 7.5rem; }

.u-s-m-x-120 {
  margin-left: 7.5rem;
  margin-right: 7.5rem; }

.u-s-m-y-120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem; }

.u-s-m-xy-120 {
  margin: 7.5rem; }

/* Padding with value `120`. */
.u-s-p-t-120 {
  padding-top: 7.5rem; }

.u-s-p-r-120 {
  padding-right: 7.5rem; }

.u-s-p-b-120 {
  padding-bottom: 7.5rem; }

.u-s-p-l-120 {
  padding-left: 7.5rem; }

.u-s-p-x-120 {
  padding-left: 7.5rem;
  padding-right: 7.5rem; }

.u-s-p-y-120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem; }

.u-s-p-xy-120 {
  padding: 7.5rem; }

